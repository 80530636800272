export const Language = "es";
// export const API_URL = "https://webapi.ebol.cl/v1/api/";
// export const API_URL = "https://ebolapi.syprojects.world/v1/api/";
export const MODE = "production"; // ['test' , 'production','uat']
let api_url = "";
if (MODE === "production") api_url = "https://webapi.ebol.cl/v1/api/";
else if (MODE === "test") api_url = "https://testing-webapi.ebol.cl/v1/api/";
// else api_url = "http://ebolapi.syprojects.world:8000/api/";
// else api_url = "http://ebolapi.syprojects.world:8182/api/";
// else api_url = "http://ebolapi.syprojects.world:6002/api/";
else api_url = "http://ebolapi.syprojects.world:6004/api/";
// else api_url = "http://ebol.local-test-server:6002/api/";

// else api_url = "https://ebolapi.syprojects.world/v1/api/";
export const API_URL = api_url;

export const LIMIT = 5;
export const BARCODE_XML_TEMPLATE_ID = "5f5c907f49566761be9afc02";
export const BILL_TEMPLATE_ID = "5faaa79bd39ed300067e7def";
export const HEADER_ORIGIN_KEY = "p-source";
export const HEADER_ORIGIN = "portal";
export const DISPATCH_TYPE = 2;

export const emailRegxPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

// modules
export const RESTAURANT = "Restaurant"

// Barcode values
export const BARCODE_RESPONSE_HEIGHT = 306;
export const BARCODE_RESPONSE_WIDTH = 297;
export const BARCODE_WIDTH = 300;
export const BARCODE_P_LEFT = 50;
export const BARCODE_P_WIDTH = 550;

export const updateNumber = value => {
  value = value.toString();
  let status = value.includes("-");
  if (status === false) {
    value =
      value.substr(0, value.length - 1) + "-" + value.substr(value.length - 1);
  }
  return value;
};

export const roundWithTwoDecimals = (value) => {
  console.log("🚀 ~ file: Calculator.js ~ line 217 ~ Calculator ~ value", value)
  if (value) {
    console.log('value type is', typeof (value));
    if (typeof (value) === 'string') {
      console.log('value before assign', value);
      value = value.replace('$', '');
      console.log('value before 2nd assign', value);
      value = value.replace(/\./g, '');
      console.log('value after assign', value);
    }
    console.log('before return value', Math.round(value * 100) / 100);
    return Math.round(value * 100) / 100;
  }
};


export const formateNumber = (value, onlyValue) => {
  if (value.toString().includes('.')) {
    value = value.toString().replace('.', ',')
  }
  let formattedValue = "";
  if (!onlyValue) {
    formattedValue += "$"
  }
  formattedValue += value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.',)
  return formattedValue;
};


export const getBarcodeValuesFromResponse = (apiResponse) => {
  let responseHeight = BARCODE_RESPONSE_HEIGHT;
  let responseWidth = BARCODE_RESPONSE_WIDTH;
  let pLeft = BARCODE_P_LEFT;
  let pWidth = BARCODE_P_WIDTH;
  let barcodeImage = "";

  if (apiResponse['barcode-image-height']) responseHeight = apiResponse['barcode-image-height'];
  if (apiResponse['barcode-image-width']) responseWidth = apiResponse['barcode-image-width'];
  if (apiResponse['barcode-p-pLeft']) pLeft = apiResponse['barcode-p-pLeft'];
  if (apiResponse['barcode-p-pWidth']) pWidth = apiResponse['barcode-p-pWidth'];
  if (apiResponse['barcode']) barcodeImage = apiResponse['barcode'];


  return {
    responseHeight,
    responseWidth,
    pLeft,
    pWidth,
    barcodeImage
  }
}

export const getBarcodeImageHeight = (barcodeWidth, responseHeight, responseWidth) => {
  let barcodeHeight = (barcodeWidth * responseHeight) / responseWidth;
  barcodeHeight = Math.round(barcodeHeight);
  return barcodeHeight;
}

export const getItemMainPrice = (data, type) => {
  let mainPriceWithoutTax = data.mainPrice / 1.19;
  mainPriceWithoutTax = roundWithTwoDecimals(mainPriceWithoutTax);
  let itemMainPrice = 0;
  if (type === 4) {
    itemMainPrice = Math.round(data.mainPrice);
  } else if (type === 1) {
    itemMainPrice = mainPriceWithoutTax;
  } else {
    itemMainPrice = data.mainPrice;
  }
  return formateNumber(itemMainPrice);
}

export const getTotalPriceOfItem = (data, type) => {
  let mainPriceWithoutTax = data.mainPrice / 1.19;
  mainPriceWithoutTax = roundWithTwoDecimals(mainPriceWithoutTax);
  let quantity = data.quantity.toString().replace(/\,/g, '.');
  quantity = parseFloat(quantity);


  let total = quantity * data.mainPrice;
  let totalPriceWithoutTax = mainPriceWithoutTax * quantity;

  let totalPrice = 0;
  if (type === 2 || type === 4 || type === 6) {
    totalPrice = Math.round(data.FAWT);
  } else if (type === 5) {
    totalPrice = parseFloat(data.FAWT);
  } else if (type === 1) {
    totalPrice = Math.round(totalPriceWithoutTax);
  } else {
    totalPrice = total;
  }
  return formateNumber(totalPrice);
}
