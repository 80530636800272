import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormCheckbox
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordStrengthBar from "react-password-strength-bar";
import styles from "./../Style.module.scss";
import { Redirect, Link } from "react-router-dom";
import { strings } from "../../localization";
import { Language, API_URL, updateNumber } from "../../config";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      phone: "",
      email: "",
      address: "",
      username: "",
      password: "",
      loader: "",
      src: "",
      id: "",
      type: "create",
      button: false,
      hide: true,
      redirect: false,
      status: true,
      hasRestaurantModulePermission: false,
      selectedUserRole: "",
      userRoleData: [],
      fullName: "",
    };
  }

  componentDidMount() {
    // alert(payload);
    // console.log(payload);

    // alert("");
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;
    // alert(id);
    // alert(type);

    // alert(type);

    if (type !== undefined) {
      this.setState({
        type: type
      });
      if (id) {
        fetch(`${API_URL}users/${id}`, {
          method: "GET",
          credentials: "include"
        })
          .then(res => res.json())
          .then(({ data }) => {
            console.log(data);
            this.setState({
              email: data.email,
              username: data.userName,
              address: data.address,
              phone: data.phone,
              status: data.status,
              id: "/" + id,
              selectedUserRole: data.restaurantUserRole,
              fullName: data.fullName,
            });
            // alert(data.status)
          });
      }
    }
    this.getUserRoles();
  }

  getUserRoles = async () => {
    let hasPermission = false;
    let organizationData = JSON.parse(localStorage.getItem("organisation_info"));
    if (organizationData !== undefined && organizationData !== null &&
      organizationData['data']['restaurantModule'] !== undefined && organizationData['data']['restaurantModule'] !== null
    ) {
      hasPermission = organizationData['data']['restaurantModule'];
      this.setState({
        hasRestaurantModulePermission: hasPermission
      })
    }
    console.log('hasPermission', hasPermission)
    if (hasPermission) {
      fetch(`${API_URL}user-role?module=restaurant`, {
        method: "GET",
        credentials: "include"
      })
        .then(res => res.json())
        .then(({ result }) => {
          console.log('response of role api', result)
          if (result) {
            this.setState({
              userRoleData: result
            })
          }
        });
    }
  }

  handleUserRoleChange(e) {
    this.setState({
      selectedUserRole: e.target.value
    })
  }

  //   componentDidMount() {
  //     let user_info = localStorage.getItem("login_info");
  //     user_info = JSON.parse(user_info);
  //     var user_id = user_info.id;
  //     axios
  //       .post("http://localhost/lumen/public/api/get-user-details", {
  //         id: user_id
  //       })
  //       .then(response => {
  //         let user_data = response.data;
  //         this.setState({
  //           fname: user_data.first_name,
  //           lname: user_data.last_name,
  //           email: user_data.email,
  //           preview: user_data.profile_pic
  //         });
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   }
  hideSwitch = () => {
    this.setState({ hide: !this.state.hide });
  };
  Capitalize(str) {
    return str !== "edit" ? strings.create : strings.edit;
    // return str.charAt(0).toUpperCase() + str.slice(1);
  }
  render() {
    const { phone, email, address, username, password, hide, fullName } = this.state;

    const isValidData = () => {
      if (phone === '') {
        return true;
      } else if (email === '') {
        return true;
      } else if (address === '') {
        return true;
      } else if (username === '') {
        return true;
      } else if (password === "" && this.state.id == "") {
        return true;
      } else if (this.state.hasRestaurantModulePermission) {
        if (fullName === null || fullName === '') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    const handleClick = () => {
      // alert(this.state.status);
      if (
        isValidData()
      ) {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });

        let localData = localStorage.getItem("user_info");

        let newData = JSON.parse(localData);
        // localData = localData.data;
        let organisationID = newData.data.organisationID;

        // console.log(localData);


        let string = {
          email: email,
          userName: username,
          address: address,
          phone: phone,
          organisationID: organisationID,
          status: this.state.status,
          restaurantUserRole: this.state.selectedUserRole,
          fullName: fullName,
        };
        if (password !== "") {
          string.password = password;
        }

        fetch(`${API_URL}users${this.state.id}`, {
          method: this.state.id ? "put" : "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(string)
        })
          .then(res => {
            if (res.status == 200) {
              toast.success(strings.successfully_created, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false });
            }
            return res.json();
          })
          .then(res => {
            if (res.message) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              this.setState({ loader: "", button: false });
            }
          })
          .catch(err => {
            // console.warn(err);
            this.setState({ loader: "", button: false });
          });
      }
    };
    return (
      <div>
        {this.state.redirect ? (
          <Redirect
            push
            to={{
              pathname: "/users",
              state: { message: `User ${this.state.type} successfully` }
            }}
          />
        ) : null}
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              // title={
              //   (this.props.match.params.type == "edit" ? "Edit " : "Create ") +
              //   "Customer"
              // }
              title={this.Capitalize(this.state.type)}
              subtitle={strings.overview}
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
          <Row>
            <Col lg="6" md="10" sm="12">
              <Card small className="mb-4">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form id="ProfileForm">
                          {/* 
                             
                              <FormInput
                                type="text"
                                id="full_name"
                                value={full_name}
                                placeholder="Full Name"
                                onChange={e =>
                                  this.setState({ full_name: e.target.value })
                                }
                              />
                            */}
                          <label htmlFor="userName">{strings.userName}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">person</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="userName"
                              value={username}
                              placeholder={strings.userName}
                              onBlur={() => {
                                let value = updateNumber(username);
                                this.setState({
                                  username: value
                                })
                              }}
                              onChange={e =>
                                this.setState({ username: e.target.value })
                              }
                            />
                          </InputGroup>
                          <label htmlFor="fullName">{strings.f_name}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">person</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="fullName"
                              value={fullName}
                              placeholder={strings.f_name}
                              onChange={e =>
                                this.setState({ fullName: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="phone">{strings.phone}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">phone</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="phone"
                              value={phone}
                              placeholder={strings.phone}
                              onChange={e =>
                                this.setState({ phone: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="email">{strings.email}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">email</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="email"
                              placeholder={strings.email}
                              value={email}
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                            />{" "}
                          </InputGroup>

                          <label htmlFor="address">Dirección</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">business</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="address"
                              placeholder="Dirección"
                              value={address}
                              onChange={e =>
                                this.setState({ address: e.target.value })
                              }
                            />{" "}
                          </InputGroup>



                          {/* <label htmlFor="username">{strings.user_code}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">code</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="username"
                              placeholder={strings.user_code}
                              value={username}
                              onChange={e =>
                                this.setState({ username: e.target.value })
                              }
                            />{" "}
                          </InputGroup> */}

                          {/* </Col>
                          </Row>
                          <Row form>
                            <Col lg="12" className="form-group"> */}
                          <label htmlFor="password">{strings.password}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">vpn_key</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type={hide ? "password" : "input"}
                              id="password"
                              placeholder={strings.password}
                              value={password}
                              onChange={e =>
                                this.setState({ password: e.target.value })
                              }
                            />{" "}
                            <span
                              className={styles.password__show}
                              onClick={this.hideSwitch}
                            >
                              {hide ? (
                                <i
                                  className="material-icons"
                                  style={{ top: "-5px", zIndex: "9999" }}
                                >
                                  visibility
                                </i>
                              ) : (
                                <i
                                  className="material-icons"
                                  style={{ top: "-5px", zIndex: "9999" }}
                                >
                                  visibility_off
                                </i>
                              )}
                            </span>
                          </InputGroup>

                          <PasswordStrengthBar password={password} />

                          {/* {this.state.type === "edit" ? ( */}
                          <InputGroup seamless className="mb-3">
                            <FormCheckbox
                              toggle
                              medium
                              onChange={() =>
                                this.setState({
                                  status: !this.state.status
                                })
                              }
                              checked={this.state.status}
                            // {...this.state.status && "defaultChecked"}
                            >
                              {strings.status}
                            </FormCheckbox>
                          </InputGroup>

                          {/* <InputGroup className="mb-3">
                            <FormCheckbox
                              toggle
                              medium
                              onChange={() =>
                                this.setState({
                                  status: !this.state.status
                                })
                              }
                              checked={this.state.status}
                            // {...this.state.status && "defaultChecked"}
                            >
                              {strings.status}
                            </FormCheckbox>
                          </InputGroup> */}



                          {/* ) : null} */}
                          {/* </Col>
                          </Row> */}
                          {/* <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="profile">Profile Picture</label>
                              <div className="mt-2 d-flex justify-content-around">
                                {/* <Avatar
                                  height={200}
                                  imageWidth={200}
                                  onCrop={this.onCrop}
                                  onClose={this.onClose}
                                /> */}
                          {/* <img
                                  src={this.state.preview}
                                  height="100"
                                  alt="Preview"
                                /> 
                              </div>
                            </Col>
                          </Row> */}
                          {
                            this.state.hasRestaurantModulePermission &&
                            <select
                              className="form-control mb-3"
                              onChange={e => this.handleUserRoleChange(e)}
                              value={this.state.selectedUserRole}
                            >
                              <option value="" disabled>
                                {strings.select_user_role}
                              </option>
                              {
                                this.state.userRoleData &&
                                this.state.userRoleData.length > 0 &&
                                this.state.userRoleData.map((userRole, index) => {
                                  return <option label={userRole['label']} value={userRole['value']} />
                                })
                              }
                            </select>
                          }
                          <ToastContainer />
                          <Button
                            theme="accent"
                            onClick={() => handleClick()}
                            disabled={this.state.button}
                          >
                            <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i>
                            {this.state.type !== "edit"
                              ? strings.save
                              : strings.update}
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Link to={"/users"} className="float-left">
                <Button
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                  style={{
                    marginTop: "-84px",
                    position: "relative",
                    left: "-100px"
                  }}
                >
                  {strings.go_back}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
