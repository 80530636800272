import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormRadio
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { strings } from "./../../localization";
import { API_URL, Language } from "./../../config";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import moment from "moment";
import Select from "react-select";
import './items.css'
// import { Modal } from "react-bootstrap";
// import Avatar from "react-avatar-edit";

const customStyles = {
  control: (provided) => ({
    ...provided,
    marginTop: '5px',
    marginBottom: '15px',
    fontSize: '14px'
  }),
};

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      item_code: "",
      description: "",
      barcodeNumber: "",
      stock: "",
      price: "",
      loader: "",
      src: "",
      id: "",
      button: false,
      isExcelFileAddModalOpened: false,
      excelData: "",
      expirationDate: "",
      itemTypes: [],
      selectedItemType: null,
      kitchens: [],
      bars: [],
      selectedKitchenOrBar: null,
      hasRestaurantPermission: false
    };
  }

  componentDidMount() {
    // let data = this.props.match.params;
    // let id = this.props.match.params.id;
    // alert(data.id);

    let restaurantPermission = false;
    let organizationData = JSON.parse(localStorage.getItem("organisation_info"));
    if (organizationData !== undefined && organizationData !== null &&
      organizationData['data'] !== undefined && organizationData['data'] !== null &&
      organizationData['data']['restaurantModule'] !== undefined && organizationData['data']['restaurantModule'] !== null
    ) {
      restaurantPermission = organizationData['data']['restaurantModule'];
    }
    this.setState({
      hasRestaurantPermission: restaurantPermission
    })

    if (restaurantPermission) {
      this.getInitialValues();
    }
    if (this.props.match.params.id) {
      fetch(`${API_URL}items/${this.props.match.params.id}`, {
        method: "GET",
        credentials: "include"
      })
        .then(res => res.json())
        .then(({ data }) => {
          console.log('data', data);
          let parsedDate = "";
          console.log('data.expirationDate ', data.expirationDate)
          if (data.expirationDate !== undefined && data.expirationDate !== null && data.expirationDate !== "") {
            parsedDate = moment(data.expirationDate).toDate()
          }
          console.log("🚀 ~ file: manage.js:62 ~ EditUserProfile ~ .then ~ parsedDate:", parsedDate)
          this.setState({
            item_code: data.code,
            description: data.name,
            barcodeNumber: data.barcodeNumber,
            price: data.price,
            stock: data.stock,
            id: "/" + this.props.match.params.id,
            expirationDate: parsedDate,
            selectedItemType: data.restaurantItemType,
            selectedKitchenOrBar: data.preparationArea
          });
        });
    }
  }

  getInitialValues() {
    fetch(`${API_URL}restaurant-item-type`, {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        console.log('response of restaurant item type get api', res);
        const result = res['result'];
        if (result && result.length > 0) {
          this.setState({
            itemTypes: result
          })
          this.getKitchenData();
        }
      })
      .catch(err => {
        console.log('catch block error at getting restaurant type', err);
      });
  }

  getKitchenData = () => {
    let kitchenList = [];
    fetch(`${API_URL}restaurant-kitchen`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        console.log('response of restaurant kitchen get api', res);
        const result = res['result'];
        if (result && result.length > 0) {
          result.length > 0 && result.map((item) => {
            kitchenList.push({
              label: item.data.kitchenName,
              value: item._id
            })
          })
          this.setState({
            kitchens: kitchenList
          })
          this.getBarData();
        }
      })
      .catch(err => {
        console.log('catch block error at getting kitchen list', err);
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  }

  getBarData = () => {
    const barList = [];
    fetch(`${API_URL}restaurant-bar`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        console.log('response of restaurant bar', res);
        const result = res['result'];
        if (result && result.length > 0) {
          result.map((item) => {
            barList.push({
              label: item.data.barName,
              value: item._id
            })
          })
          this.setState({
            bars: barList
          })
        }
      })
      .catch(err => {
        console.log('catch block error at getting bar list', err);
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  }

  handleKitchenOrBarSelection = (e) => {
    console.log('event', e);
    const selectedValue = e;
    if (selectedValue) {
      this.setState({
        selectedKitchenOrBar: selectedValue
      })
    } else {
      this.setState({
        selectedKitchenOrBar: ""
      })
    }
  }


  render() {
    const { description, item_code, stock, price, barcodeNumber, expirationDate, selectedItemType, selectedKitchenOrBar } = this.state;
    console.log("🚀 ~ file: manage.js:76 ~ EditUserProfile ~ render ~ expirationDate:", expirationDate)
    const { history } = this.props;
    console.log('history', history);
    let newRegex = /^[0-9a-zA-Z ]*$/;

    const isValidData = () => {
      if (description === '') {
        return true;
      } else if (item_code === '') {
        return true;
      } else if (stock === '') {
        return true;
      } else if (barcodeNumber === '') {
        return true;
      } else if (price === '') {
        return true;
      } else if (this.state.hasRestaurantPermission) {
        console.log('in hasRestaurantModulePermission if condition')
        if (selectedItemType === null || selectedItemType === '') {
          return true;
        } else if (selectedKitchenOrBar === null || selectedKitchenOrBar === '') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    const handleClick = () => {
      this.setState({
        button: true
      })
      if (
        isValidData()
      ) {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({
          button: false
        })
      } else if (!newRegex.test(description)) {
        toast.error(strings.description_valid_error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          button: false
        })
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });
        // let user_info = localStorage.getItem("login_info");
        // user_info = JSON.parse(user_info);
        // var user_id = user_info.id;
        let string = {
          name: this.state.description,
          code: this.state.item_code,
          barcodeNumber: this.state.barcodeNumber,
          price: this.state.price,
          stock: this.state.stock,
          expirationDate: expirationDate !== undefined && expirationDate !== null && expirationDate !== "" ? moment(expirationDate).format("YYYY-MM-DD") : ""
        };

        if (this.state.hasRestaurantPermission) {
          string["restaurantItemType"] = this.state.selectedItemType.value
          string["preparationArea"] = this.state.selectedKitchenOrBar.value
        }

        console.log('bodyData of items', string);

        fetch(`${API_URL}items${this.state.id}`, {
          method: this.state.id ? "put" : "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(string)
        })
          .then(res => {
            if (res.status == 200) {
              toast.success(strings.successfully_created, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false });

              setTimeout(() => {
                history.goBack();
              }, 1000)
            }
            return res.json();
          })
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.warn(err);
          });

        // this.setState({ loader: "fa fa-spinner fa-spin", button: true });
        // // let user_info = localStorage.getItem("login_info");
        // // user_info = JSON.parse(user_info);
        // // var user_id = user_info.id;
        // let string = JSON.stringify({
        //   name: this.state.description,
        //   code: this.state.item_code,
        //   barcodeNumber: this.state.barcodeNumber,
        //   price: this.state.price,
        //   stock: this.state.stock
        // });

        // // console.log(strings);

        // fetch(`${API_URL}items${this.state.id}`, {
        //   method: this.state.id ? "put" : "post",
        //   credentials: "include",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json"
        //   },
        //   body: string
        // })
        //   .then(res => {
        //     if (res.status == 200) {
        //       toast.success(strings.successfully_created, {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: false,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined
        //       });

        //       this.setState({ loader: "", button: false });
        //     }
        //     return res.json();
        //   })
        //   .then(res => {
        //     console.log(res);
        //   })
        //   .catch(err => {
        //     console.warn(err);
        //   });
      }
    };
    return (
      <div>
        <Container
          fluid
          className="main-content-container px-4"
          style={{ marginBottom: "200px" }}
        >
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={this.props.match.params.id ? strings.edit_item : strings.manage_item}
              subtitle={strings.overview}
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
          <Row>
            <Col lg="6" md="10" sm="12">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">{this.props.match.params.id ? strings.edit_item : strings.manage_item}</h6>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form id="ProfileForm">
                          {/* <Row form>
                            <Col lg="12" className="form-group"> */}
                          <label htmlFor="code">{strings.item_code}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">code</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="code"
                              value={item_code}
                              placeholder={strings.item_code}
                              onChange={e =>
                                this.setState({ item_code: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="description">
                            {strings.item_description}
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">description</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="description"
                              maxLength="20"
                              placeholder={strings.item_description}
                              value={description}
                              onChange={e =>
                                this.setState({ description: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="barcodeNumber">{strings.item_barcode}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">qr_code_2</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="barcodeNumber"
                              placeholder={strings.item_barcode}
                              value={barcodeNumber}
                              onChange={e =>
                                this.setState({ barcodeNumber: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="stock">{strings.item_stock}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">graphic_eq</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="stock"
                              placeholder={strings.item_stock}
                              value={stock}
                              onChange={e =>
                                this.setState({ stock: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="price">{strings.item_price}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">attach_money</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="price"
                              placeholder={strings.item_price}
                              value={price}
                              onChange={e =>
                                this.setState({ price: e.target.value })
                              }
                            />
                          </InputGroup>

                          {/* Expiration date */}
                          <label htmlFor="price">{strings.expiration_date}</label>
                          <DatePicker
                            className="mt-2 form-control mb-3"
                            onChange={e => {
                              console.log('original date is', e)
                              console.log("date is", new Date(e));
                              this.setState({ expirationDate: e });
                            }}
                            value={this.state.expirationDate}
                          // maxDate={new Date()}
                          />

                          {
                            this.state.hasRestaurantPermission &&
                            <>
                              <p className="mb-2" style={{
                                fontWeight: '400',
                                color: '#212529',
                                fontSize: 13,
                                fontFamily: "Fira Sans, sans-serif"
                              }}>Seleccionar tipo de artículo:</p>
                              {
                                this.state.itemTypes.length > 0 && this.state.itemTypes.map((item, index) => {
                                  return (
                                    <FormRadio
                                      inline
                                      name={item.label}
                                      className="pl-0"
                                      checked={this.state.selectedItemType && this.state.selectedItemType.value === item.value}
                                      onChange={() => {
                                        this.setState({
                                          selectedItemType: item,
                                          selectedKitchenOrBar: ""
                                        })
                                      }}
                                    >
                                      {item.label}
                                    </FormRadio>
                                  )
                                })
                              }
                              {
                                this.state.selectedItemType && this.state.selectedItemType.label === "Comer" &&
                                <Select
                                  options={this.state.kitchens}
                                  onChange={this.handleKitchenOrBarSelection}
                                  placeholder={strings.select_kitchens}
                                  value={this.state.selectedKitchenOrBar}
                                  styles={customStyles}
                                />
                              }
                              {
                                this.state.selectedItemType && this.state.selectedItemType.label === "Beber" &&
                                <Select
                                  options={this.state.bars}
                                  onChange={this.handleKitchenOrBarSelection}
                                  placeholder={strings.select_bars}
                                  value={this.state.selectedKitchenOrBar}
                                  styles={customStyles}
                                />
                              }
                            </>
                          }


                          <ToastContainer />
                          <Button
                            theme="accent"
                            onClick={() => handleClick()}
                            disabled={this.state.button}
                          >
                            <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i>{" "}
                            {strings.save}
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Link to={"/items"} className="float-left">
                <Button
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                  style={{
                    marginTop: "-84px",
                    position: "relative",
                    left: "-100px"
                  }}
                >
                  {strings.go_back}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
