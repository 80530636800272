import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { strings } from '../../localization/Login';

export default class SuccessMessage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
        <div>
            <div className="d-flex justify-content-center align-items-center mt-4">
                <div 
                className="bg-success"
                style={{
                    height: '50px',
                    width: '50px',
                    borderRadius: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <i className="fa fa-2x fa-check text-white"></i>
                </div>
            </div>
            <div className="mt-2">
                <p className="fs-6 p-3 text-center" style={{letterSpacing:'0.3px'}}>{this.props.message}</p>
            </div>
            <div className="mt-5 d-flex justify-content-end align-items-end">
              <Link to={'/login'} className="fs-6" style={{letterSpacing:'0.3px'}}>{strings.back_to_login}</Link>
            </div>
       </div>
    )
  }
}
