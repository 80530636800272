import React from "react";
import styles from "./../../src/components/Login/svg.module.css";

const Custom = ({ children }) => (
  <>
    <div className={styles.container} id="backgroundImage">
      <div className={styles.svg_container}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 495">
          <path
            id="curve2"
            d="M0,352S291.69,352,565,352C945,352,1099,352,1487,352,1779.39,352,1920,352,1920,352V352H0V48.38Z"
            transform="translate(0, 145)"
          />
          <path
            id="curve1"
            d="M0,285S278,285,552,285C1070,285,1064,285,1478.38 285,1780,285,1920,285,1920,285V285H1V19.8Z"
            transform="translate(0, 215)"
          />
          <path
            id="curve0"
            d="M0,199V195S195,195,499.5,195C990.58,195,1065,195,1427.25,195,1712.37,195,1887,195,1920,195V196Z"
            transform="translate(0, 305)"
          />
        </svg>
      </div>
    </div>
    {/* <Container fluid> */}
    {/* <Row>
      <Col
        className="d-flex p-0 m-auto"
        lg={{ size: 12 }}
        md={{ size: 12 }}
        sm="12"
        tag="main"
      > */}
    {children}
    {/* </Col>
    </Row> */}
    {/* </Container> */}
  </>
);

export default Custom;
