import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  Container
} from "shards-react";
import PageTitle from "./../../components/common/PageTitle";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { strings } from "./../../localization";
import {
  Language,
  API_URL,
  BARCODE_XML_TEMPLATE_ID,
  BILL_TEMPLATE_ID
} from "./../../config";
import XMLViewer from "react-xml-viewer";
import Loader from "react-loader-spinner";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      xml: "",
      visible: true,
      redirect: false
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;
    let barcode = this.props.match.params.barcode;

    let temp_id = BILL_TEMPLATE_ID;

    if (barcode !== undefined) temp_id = BARCODE_XML_TEMPLATE_ID;

    if (type !== undefined) {
      if (id) {
        fetch(`${API_URL}templates/${temp_id}/${type}/${id}`, {
          method: "get",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(result => {
            this.setState({ xml: result.data, visible: false });
          })
          .catch(error => console.log("error", error));
      }
    }
  }

  render() {
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle
                title={"View Transaction"}
                subtitle={strings.overview}
                md="12"
                className="ml-sm-auto mr-sm-auto"
              />
            </Col>
            <Col>
              <Link to={"/transactions"} className="float-right">
                <Button id="go_back" theme="light" className="mb-1 mr-1 mt-3">
                  Go Back
                </Button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <Card small className="mb-4">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    {this.state.visible ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Loader
                          type="Puff"
                          color="#00BFFF"
                          height={100}
                          width={100}
                          visible={this.state.visible}
                        />
                      </div>
                    ) : (
                      <XMLViewer xml={this.state.xml} />
                    )}
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
