import React from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import SearchFilter from "./../search_filter";
import { strings } from "./../../localization";
import { Language, API_URL, LIMIT } from "./../../config";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  FormInput
} from "shards-react";
// import $ from "jquery";
import PageTitle from "../../components/common/PageTitle";
// import SweetAlert from "react-bootstrap-sweetalert";
import swal from "sweetalert2";
window.Swal = swal;
const filters = [
  {
    name: "Title"
  },
  {
    name: "Rut Emissor"
  },
  {
    name: "Rut Emissor Name"
  },
  {
    name: "Type Of Document"
  },
  {
    name: "Folio Start"
  },
  {
    name: "Folio End"
  },
  {
    name: "Folio Date"
  }
];
class Customers extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0,
    skip: 0,
    limit: LIMIT,
    currentPage: 1,
    prev: true,
    loading: true,
    next: false
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: "title",
        text: "Título"
      },
      {
        key: "rutEmissor",
        text: "Rut Emisor"
      },
      {
        key: "rutEmissorName",
        text: "Nombre o razón social"
      },
      {
        key: "typeOfDocument",
        text: "Tipo de documento"
      },
      {
        key: "folioStart",
        text: "Folio inicial"
      },
      {
        key: "folioEnd",
        text: "Folio final"
      },
      {
        key: "folioDate",
        text: "Fecha folio"
      },
      // {
      //   key: "action",
      //   text: strings.action,
      //   className: "action",
      //   cell: record => {
      //     return (
      //       //   /create-customer
      //       <div className="mt-2">
      //         <Link
      //           to={{
      //             pathname: "/manage-folio/edit/" + record.id
      //           }}
      //         >
      //           <Button theme="success" className="mb-2 mr-1">
      //             {strings.edit}
      //           </Button>
      //         </Link>
      //         <Button
      //           theme="danger"
      //           className="mb-2 mr-1"
      //           onClick={() => this.deleteRecord(record.id)}
      //         >
      //           {strings.delete}
      //         </Button>
      //       </div>
      //     );
      //   }
      // }
    ];
    this.config = {
      page_size: 10,
      show_length_menu: false,
      show_info: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        extra: false
      }
    };
  }

  deleteRecord(id) {
    swal
      .fire({
        title: strings.are_you_sure,
        text: "You want to delete this folio ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E209D",
        cancelButtonColor: "#D61E11",
        confirmButtonText: strings.yes_delete,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(`${API_URL}folios/${id}`, {
            method: "delete",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(res => {
              if (res.status == 200) {
                this.getData();
                swal.fire(strings.deleted, strings.success_deleted, "success");
              }
              return res.json();
            })
            .catch(err => {
              swal.showValidationMessage(`Request failed: ${err}`);
            });
        }
      })
      .then(result => {});
  }

  componentDidMount() {
    this.getData();
  }

  getData = (skip = 0, params = "") => {
    this.setState({
      loading: true
    });

    fetch(`${API_URL}folios?limit=${this.state.limit}&skip=${skip}${params}`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];

          res.result.map(item => {
            let obj = item.data;
            obj = Object.assign({ id: item._id }, obj);
            array.push(obj);
          });

          if (res.result.length !== this.state.limit) {
            this.setState({
              next: true
            });
          }

          this.setState({
            total: res.result.length + 1,
            records: array,
            loading: false
          });
        } else {
          this.setState({
            next: true,
            loading: false,
            records: []
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  pageIncrement() {
    if (!this.state.next) {
      let skip = this.state.currentPage * this.state.limit;
      this.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    } else {
      this.setState({
        next: false
      });
    }
  }

  render() {
    //Datatable HTML

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {/* <a onClick={() => this.deleteThisGoal()} className="btn btn-danger">
          Open alert
        </a>
        {this.state.alert} */}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Folios"
            subtitle={strings.overview}
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <SearchFilter getData={this.getData} filters={filters} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                {/* <Link to="/manage-folio/create">
                  <Button theme="primary" className="mb-2 float-right">
                    <i class="material-icons">card_travel</i> Create Folio
                  </Button>
                </Link> */}
                <div className="MainDiv">
                  <div className="table-responsive">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      loading={this.state.loading}
                    />
                    <nav className="d-flex justify-content-center align-items-center">
                      <ul className="pagination">
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageDecrement()}
                            style={
                              this.state.prev
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Anterior
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageIncrement()}
                            style={
                              this.state.next
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Próxima
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Customers;
