import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import UsersOverview from "../components/blog/UsersOverview";
import UsersByDevice from "../components/blog/UsersByDevice";
import { API_URL } from "../config";
// import NewDraft from "./../components/blog/NewDraft";
// import Discussions from "./../components/blog/Discussions";
// import TopReferrals from "./../components/common/TopReferrals";

const dataArray = [
  {label: 'Transacciones',value:'4390',data: [0, 1, 2, 3, 4, 5, 7]},
  {label: 'Total de usuarios',value:'490',data: [3, 1, 2, 7, 4, 5, 7]},
  {label: 'Documentos emitidos',value:'439',data: [6, 1, 2, 3, 5, 2, 7]},
  {label: 'Total',value:'430',data: [7, 5, 2, 3, 4, 5, 7]},
]

//  fetch(`${API_URL}authlogin`, {
//       mode: "cors",
//       method: "POST",
//       credentials: "include",
//       headers: headers,
//       body: JSON.stringify(bodyData)
//     })

const metricsData = []

const BlogOverview = ({ smallStats }) => {
  console.log('smallStats',smallStats)
  const [metricsData,setMetricsData] = useState([])
  const [pieChartData,setPieChartData] = useState([])
  console.log("🚀 ~ file: Dashboard.js ~ line 35 ~ BlogOverview ~ pieChartData", pieChartData)
  const [areaChart,seAreaChart] = useState([])


   useEffect(() => {
   fetch(`${API_URL}dashboard`,{method: "GET",credentials: 'include'})
      .then((res) => res.json())
      .then((res) => {
        console.log('res of dashboard api is',res)
        console.log('pie chart data is',res.pie_chart)
        console.log('line chart data is',res.area_chart)
        setMetricsData(res.metrics)
        setPieChartData(res.pie_chart)
        seAreaChart(res.area_chart)
        // metricsData.push(res.metrics)
      })
      .catch((err) => {console.log('error at dashboard api call',err)})
   },[])
  return(
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle
        title="Menú Principal"
        className="text-sm-left mb-3"
      />
    </Row>

    {/* Small Stats Blocks */}
    <Row>
      {metricsData.map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
          <SmallStats
            id={`small-stats-${idx}`}
            variation="1"
            // chartData={stats.datasets}
            // chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
            // percentage={stats.percentage}
            // increase={stats.increase}
            // decrease={stats.decrease}
          />
        </Col>
      ))}
    </Row>

    <Row>
      {/* Users Overview */}
      <Col lg="8" md="12" sm="12" className="mb-4">
        <UsersOverview areaChart={areaChart} />
      </Col>

      {/* Users by Device */}
      <Col lg="4" md="6" sm="12" className="mb-4">      
        <UsersByDevice pieChartData={pieChartData} />
      </Col>
    </Row>
  </Container>
  
  )


}



BlogOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};


// metricsData.map((item) => {
//   console.log('item', item)
 BlogOverview.defaultProps = {
    smallStats: [
    {
      label: "Transacciones",
      value: "4,390",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [0, 1, 2, 3, 4, 5, 7]
          // data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "Total de usuarios",
      value: "4",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [0, 2, 3, 3, 1, 2, 4]
          // data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "Documentos emitidos",
      value: "3",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "Total",
      value: "29",
      percentage: "2.71%",
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
    // {
    //   label: "Requests This Month",
    //   value: "17,281",
    //   percentage: "2.4%",
    //   increase: false,
    //   decrease: true,
    //   chartLabels: [null, null, null, null, null, null, null],
    //   attrs: { md: "4", sm: "6" },
    //   datasets: [
    //     {
    //       label: "Today",
    //       fill: "start",
    //       borderWidth: 1.5,
    //       backgroundColor: "rgb(0,123,255,0.1)",
    //       borderColor: "rgb(0,123,255)",
    //       data: [3, 2, 3, 2, 4, 5, 4]
    //     }
    //   ]
    // }
  ]
}  
// })

export default BlogOverview;



//todo
/*
BlogOverview.defaultProps = {
  smallStats: [
    {
      label: "Transacciones",
      value: "4,390",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [0, 1, 2, 3, 4, 5, 7]
          // data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "Total de usuarios",
      value: "4",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [0, 2, 3, 3, 1, 2, 4]
          // data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "Documentos emitidos",
      value: "3",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "Total",
      value: "29",
      percentage: "2.71%",
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
    // {
    //   label: "Requests This Month",
    //   value: "17,281",
    //   percentage: "2.4%",
    //   increase: false,
    //   decrease: true,
    //   chartLabels: [null, null, null, null, null, null, null],
    //   attrs: { md: "4", sm: "6" },
    //   datasets: [
    //     {
    //       label: "Today",
    //       fill: "start",
    //       borderWidth: 1.5,
    //       backgroundColor: "rgb(0,123,255,0.1)",
    //       borderColor: "rgb(0,123,255)",
    //       data: [3, 2, 3, 2, 4, 5, 4]
    //     }
    //   ]
    // }
  ]
};


*/



//todo 
// const BlogOverview = ({ smallStats }) => (
//   <Container fluid className="main-content-container px-4">
//     {/* Page Header */}
//     <Row noGutters className="page-header py-4">
//       <PageTitle
//         title="Menú Principal"
//         className="text-sm-left mb-3"
//       />
//     </Row>

//     {/* Small Stats Blocks */}
//     <Row>
//       {smallStats.map((stats, idx) => (
//         <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
//           <SmallStats
//             id={`small-stats-${idx}`}
//             variation="1"
//             chartData={stats.datasets}
//             chartLabels={stats.chartLabels}
//             label={stats.label}
//             value={stats.value}
//             percentage={stats.percentage}
//             increase={stats.increase}
//             decrease={stats.decrease}
//           />
//         </Col>
//       ))}
//     </Row>

//     <Row>
//       {/* Users Overview */}
//       <Col lg="8" md="12" sm="12" className="mb-4">
//         <UsersOverview />
//       </Col>

//       {/* Users by Device */}
//       <Col lg="4" md="6" sm="12" className="mb-4">
//         <UsersByDevice />
//       </Col>
//     </Row>
//   </Container>
// );
