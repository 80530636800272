import React, { Component } from "react";
import LoginForm from "../components/Login";
// import { Container, Row, Col } from "shards-react";

export default class Login extends Component {
  render() {
    return (
      // <div className="auth-wrapper">
      //   <div className="auth-inner">
      <LoginForm />
      //   </div>
      // </div>
    );
  }
}
