import React from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import Modal from 'react-bootstrap/Modal';
import { read, utils } from 'xlsx';
import { strings } from "./../../localization";
import { Language, API_URL, LIMIT } from "./../../config";
import { Container, Row, Col, Card, Button, CardBody } from "shards-react";
import SearchFilter from "./../search_filter";

import PageTitle from "../../components/common/PageTitle";
// import Autosuggest from "react-autosuggest";
import swal from "sweetalert2";
import moment from "moment";
window.Swal = swal;
const filters = [
  {
    name: "Code"
  },
  {
    name: "Name"
  },
  {
    name: "Barcode Number"
  },
  {
    name: "Price"
  },
  {
    name: "Stock"
  },
];

class Customers extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0,
    skip: 0,
    limit: LIMIT,
    currentPage: 1,
    prev: true,
    loading: true,
    next: false,
    value: "",
    suggestions: [],
    showLoader: false,
    paramValue: "",
    isExcelFileAddModalOpened: false,
    validExcelData: [],
    validExcelDataForApi: [],
    invalidExcelData: [],
    selectedExcelFile: "",
    showSuccessMsg: false,
    successMsg: "",
    showErrorMsg: false,
    errorMsg: "",
    isLoading: false,
    isDownloadCsvLoading: false,
    isUploadCsvLoading: false,
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: "code",
        text: "SKU"
      },
      {
        key: "name",
        text: "Nombre"
      },
      {
        key: "barcodeNumber",
        text: "Código de barra"
      },
      {
        key: "stock",
        text: "Stock"
      },
      {
        key: "price",
        text: "Precio total con IVA incluido"
      },
      {
        key: "expirationDate",
        text: strings.expiration_date
      },
      {
        key: "action",
        text: "Acción",
        // width: 100,
        sortable: false,
        cell: record => {
          return (
            //   /create-customer
            <div className="mt-2">
              <Link
                to={{
                  pathname: "/manage-item/" + record.id,
                  state: {
                    type: "edit",
                    name: record.name,
                    code: record.code,
                    price: record.price,
                    stock: record.stock
                  }
                }}
              >
                <Button theme="success" className="mb-2 mr-1">
                  {strings.edit}
                </Button>
              </Link>
              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={() => this.deleteItem(record.id)}
              >
                {strings.delete}
              </Button>
            </div>
          );
        }
      }
    ];
    this.config = {
      // page_size: 10,
      show_length_menu: false,
      show_info: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        extra: false
      }
    };
  }

  deleteItem(id) {
    swal
      .fire({
        title: strings.are_you_sure,
        text: strings.delete_item,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: strings.yes_cancel,
        confirmButtonColor: "#2E209D",
        cancelButtonColor: "#D61E11",
        confirmButtonText: strings.yes_delete,
        showLoaderOnConfirm: true,
        preConfirm: login => {
          return fetch(`${API_URL}items/${id}`, {
            method: "delete",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(res => {
              if (res.status == 200) {
                this.getData();

                swal.fire(
                  strings.deleted,
                  strings.success_item_deleted,
                  "success"
                );
              }
              return res.json();
            })
            .catch(err => {
              swal.showValidationMessage(`Request failed: ${err}`);
            });
        }
      })
      .then(result => { });
  }

  arrayIndexMove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  componentDidMount() {
    let restaurantPermission = false;
    let organizationData = JSON.parse(localStorage.getItem("organisation_info"));
    if (organizationData !== undefined && organizationData !== null &&
      organizationData['data']['restaurantModule'] !== undefined && organizationData['data']['restaurantModule'] !== null
    ) {
      restaurantPermission = organizationData['data']['restaurantModule'];
    }
    if (restaurantPermission) {
      let itemType = {
        key: "restaurantItemType",
        text: strings.item_type,
        cell: record => {
          const value = record.restaurantItemType ? record.restaurantItemType.label : ""
          return (
            <p>{value}</p>
          )
        }
      };
      this.columns.push(itemType);
      this.arrayIndexMove(this.columns, this.columns.length - 1, 6)
      let preparationArea = {
        key: "preparationArea",
        text: strings.preparation_area,
        cell: record => {
          const value = record.preparationArea ? record.preparationArea.label : ""
          return (
            <p>{value}</p>
          )
        }
      };
      this.columns.push(preparationArea);
      this.arrayIndexMove(this.columns, this.columns.length - 1, 7)
    }
    this.getData();
  }

  getData = (skip = 0, params = "") => {
    this.setState({
      loading: true
    });

    fetch(`${API_URL}items?limit=${this.state.limit}&skip=${skip}${params}`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];

          res.result.map(item => {
            let obj = item.data;
            if (obj['expirationDate'] !== undefined && obj['expirationDate'] !== null && obj['expirationDate'] !== "") {
              obj['expirationDate'] = moment(obj['expirationDate']).format('YYYY-MM-DD');
            }
            console.log("🚀 ~ file: index.js:208 ~ Customers ~ obj:", obj)
            obj = Object.assign({ id: item._id }, obj);
            array.push(obj);
          });

          if (res.result.length !== this.state.limit) {
            this.setState({
              next: true
            });
          }

          this.setState({
            total: res.result.length + 1,
            records: array,
            loading: false
          });
        } else {
          this.setState({
            next: true,
            loading: false,
            records: []
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  getParamValue = (value) => {
    console.log("🚀 ~ file: index.js ~ line 222 ~ Customers ~ value", value)
    this.setState({ paramValue: value });
  }

  pageIncrement() {
    if (!this.state.next) {
      let skip = this.state.currentPage * this.state.limit;
      this.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  downloadCsvFile() {
    // let param = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}&type=csv`;
    // let url = `${API_URL}sales/${this.state.reportType}${param}`;
    this.setState({ showLoader: true });
    let params = `${this.state.paramValue}&type=csv`;
    let url = `${API_URL}items?${params}`;

    fetch(`${url}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then(data => {
        console.log('sales download csv api response ......', data);
        this.setState({ showLoader: false })
        let csvContent =
          'data:text/csv;charset=utf-8,' +
          data.result.map(e => e.join(',')).join('\n');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'ebol_items.csv');
        document.body.appendChild(link);

        link.click();
      })
      .catch(err => {
        console.log('sales download csv api error ......', err);
      });
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    } else {
      this.setState({
        next: false
      });
    }
  }

  handleAddExcelFile() {
    this.setState({
      isExcelFileAddModalOpened: true,
    });
  }

  handleInputChange = (e) => {
    console.log("🚀 ~ file: manage.js:82 ~ EditUserProfile ~ e:", e)
    const dashedDatePattern = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    const slashDatePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    let selectFiles = e.target.files[0];
    const filteredData = [];
    const validData = [];
    const validDataForApi = [];
    const invalidData = [];
    let expiredDate = "";
    if (selectFiles) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        const bstr = e.target.result;
        const workbook = read(bstr, { type: rABS ? "binary" : "array" });
        const workSheetName = workbook.SheetNames[0];
        const workSheet = workbook.Sheets[workSheetName];
        // console.log('rABS', rABS, 'workbook', workbook);
        const data = utils.sheet_to_json(workSheet);
        // console.log("🚀 ~ file: manage.js:129 ~ EditUserProfile ~ data:", data)
        if (data && data.length > 0) {
          console.log("🚀 ~ file: index.js:336 ~ Customers ~ data.map ~ invalidData:", invalidData)
          // validation
          data.map((singleItem, index) => {
            console.log('filteredData item', singleItem)
            if (
              singleItem['SKU'] && typeof singleItem['SKU'] == "number" &&
              singleItem['Nombre'] && typeof singleItem['Nombre'] == "string" &&
              (singleItem['Codigo de barra Stock'] === undefined || (singleItem['Codigo de barra Stock'] && typeof singleItem['Codigo de barra Stock'] === "number")) &&
              singleItem['Stock'] && typeof singleItem['Stock'] === "number" &&
              singleItem['Precio total con IVA incluido'] && typeof singleItem['Precio total con IVA incluido'] === "number" && singleItem['Precio total con IVA incluido'] > 0 &&
              (singleItem[strings.expiration_day] === undefined || (singleItem[strings.expiration_day] !== undefined && singleItem[strings.expiration_day].toString().length <= 2 && singleItem[strings.expiration_day] > 0 && singleItem[strings.expiration_day] <= 31)) &&
              (singleItem[strings.expiration_month] === undefined || (singleItem[strings.expiration_month] !== undefined && singleItem[strings.expiration_month].toString().length <= 2 && singleItem[strings.expiration_month] > 0 && singleItem[strings.expiration_month] <= 12)) &&
              (singleItem[strings.expiration_year] === undefined || (singleItem[strings.expiration_year] !== undefined && singleItem[strings.expiration_year].toString().length === 4))
            ) {

              let changeKeyNameItem = {
                code: singleItem['SKU'] !== undefined ? singleItem['SKU'].toString() : "",
                name: singleItem['Nombre'] !== undefined ? singleItem['Nombre'] : "",
                barcodeNumber: singleItem['Codigo de barra Stock'] !== undefined ? singleItem['Codigo de barra Stock'].toString() : "",
                stock: singleItem['Stock'] !== undefined ? singleItem['Stock'].toString() : "",
                price: singleItem['Precio total con IVA incluido'] !== undefined ? singleItem['Precio total con IVA incluido'].toString() : "",
                expiryDay: singleItem[strings.expiration_day] !== undefined ? singleItem[strings.expiration_day].toString() : "",
                expiryMonth: singleItem[strings.expiration_month] !== undefined ? singleItem[strings.expiration_month].toString() : "",
                expiryYear: singleItem[strings.expiration_year] !== undefined ? singleItem[strings.expiration_year].toString() : "",
              };

              let validDataObject = {
                code: changeKeyNameItem['code'],
                name: changeKeyNameItem['name'],
                barcodeNumber: changeKeyNameItem['barcodeNumber'],
                stock: changeKeyNameItem['stock'],
                price: changeKeyNameItem['price'],
                // expirationDate: `${changeKeyNameItem['expiryYear']}-${changeKeyNameItem['expiryMonth']}-${changeKeyNameItem['expiryDay']}`,
              }

              if (changeKeyNameItem['expiryYear'] && changeKeyNameItem['expiryMonth'] && changeKeyNameItem['expiryDay']) {
                expiredDate = `${changeKeyNameItem['expiryYear']}-${changeKeyNameItem['expiryMonth']}-${changeKeyNameItem['expiryDay']}`
              }
              validDataObject['expirationDate'] = expiredDate;

              console.log("🚀 ~ file: index.js:378 ~ Customers ~ data.map ~ validDataObject:", validDataObject)
              validDataForApi.push(validDataObject);
              // validData.push(singleItem);
              validData.push(changeKeyNameItem);
            } else {
              invalidData.push(singleItem)
            }
          })
        }
        console.log("🚀 ~ file: index.js:359 ~ Customers ~ filteredData.map ~ validDataForApi:", validDataForApi)
        this.setState({
          validExcelData: validData,
          validExcelDataForApi: validDataForApi,
          invalidExcelData: invalidData,
          selectedExcelFile: selectFiles,
        });
      };
      if (rABS) reader.readAsBinaryString(e.target.files[0]);
      else reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  downloadDemoCsvFile = () => {
    let statusCode = 0;
    console.log('clicked')
    this.setState({
      isDownloadCsvLoading: true
    });
    let url = `${API_URL}items/downloadSampleCSV`
    fetch(`${url}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then((res) => {
        statusCode = res.status;
        return res.text();
      })
      .then(res => {
        console.log("🚀 ~ file: index.js:393 ~ Customers ~ res:", res)
        this.setState({ isDownloadCsvLoading: false });
        // const data = JSON.stringify(res);
        // console.log('sales download csv api response ......', data);
        if (statusCode === 200) {
          let csvContent =
            'data:text/csv;charset=utf-8,' +
            res;
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'ebol_items_demo.csv');
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(err => {
        this.setState({
          isDownloadCsvLoading: true
        });
        console.log('sales download demo csv api error ......', err);
      });
  }

  showInfoMessage(type, msg) {
    type = type.toLocaleLowerCase();
    console.log('message type: ' + type);
    if (type === 'success') {
      this.setState({
        showSuccessMsg: true,
        successMsg: msg
      });
      setTimeout(() => {
        this.setState({
          showSuccessMsg: false,
          successMsg: ''
        });
      }, [3000]);
    } else {
      this.setState({
        showErrorMsg: true,
        errorMsg: msg
      });
      setTimeout(() => {
        this.setState({
          showErrorMsg: false,
          errorMsg: ''
        });
      }, [3000]);
    }
  }

  handleUploadButton = () => {
    this.setState({
      isUploadCsvLoading: true,
    });
    if (this.state.selectedExcelFile !== undefined && this.state.selectedExcelFile !== "" && this.state.validExcelDataForApi && this.state.validExcelDataForApi.length > 0) {
      console.log('selectedExcelFile name', this.state.selectedExcelFile['name'])
      console.log('selectedExcelFile file', this.state.selectedExcelFile)
      let url = `${API_URL}items/upload`;


      // const formData = new FormData();
      // formData.append('name', this.state.selectedExcelFile['name']);
      // formData.append('file', this.state.selectedExcelFile);

      const bodyData = {
        "data": this.state.validExcelDataForApi
      }
      console.log("🚀 ~ file: index.js:423 ~ Customers ~ bodyData:", bodyData)
      fetch(`${url}`, {
        method: 'POST',
        // body: formData,
        body: JSON.stringify(bodyData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: 'include'
      })
        .then((res) => {
          console.log("🚀 ~ file: index.js:381 ~ Customers ~ .then ~ res:", res)
          const data = res.json();
          console.log("🚀 ~ file: index.js:416 ~ Customers ~ .then ~ data:", data)
          this.setState({
            isUploadCsvLoading: false,
          });
          if (res.status === 200) {
            if (data.message) {
              this.showInfoMessage("success", data.message)
            }
            this.setState({
              isExcelFileAddModalOpened: false,
              validExcelData: [],
              invalidExcelData: [],
              selectedExcelFile: "",
            });
            window.location.reload();
          } else {
            this.showInfoMessage("error", data.message !== undefined ? data.message : "Something went wrong")
          }
        }).catch(err => {
          console.log('Upload items catch error', err);
          this.setState({
            isExcelFileAddModalOpened: false,
            isUploadCsvLoading: false,
            validExcelData: [],
            invalidExcelData: [],
            selectedExcelFile: "",
          });
        })
    } else {
      this.showInfoMessage("error", "Seleccione un archivo para cargar")
      this.setState({
        isUploadCsvLoading: false,
      });
    }
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Items"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <SearchFilter getData={this.getData} getParamValue={this.getParamValue} filters={filters} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <div className="d-flex justify-content-between align-items-center'">
                  <div className="d-flex align-items-center">
                    <Link to="manage-item">
                      <Button theme="primary" className="float-right">
                        <i class="material-icons">add</i> Crear Item
                      </Button>
                    </Link>
                    <Button
                      theme="accent"
                      onClick={() => this.handleAddExcelFile()}
                      style={{
                        marginLeft: 10
                      }}
                    >
                      <i class="material-icons">upload</i> {strings.add_excel_file}
                    </Button>
                  </div>
                  {/* <div className="d-flex align-items-center">
              <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                </div> */}
                  <Button
                    theme="primary"
                    // className="mb-1 mt-3"
                    style={{ width: '10%', height: 50, }}
                    disabled={this.state.showLoader ? true : false}
                    onClick={() => {
                      this.downloadCsvFile();
                    }}
                  >
                    {this.state.showLoader && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
                    Descargar CSV
                  </Button>
                </div>
                <div className="MainDiv">
                  <div className="table-responsive">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      loading={this.state.loading}
                    />
                    <nav className="d-flex justify-content-center align-items-center">
                      <ul className="pagination">
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageDecrement()}
                            style={
                              this.state.prev
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Anterior
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageIncrement()}
                            style={
                              this.state.next
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Próxima
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ToastContainer /> */}
        <Modal
          show={this.state.isExcelFileAddModalOpened}
          onHide={() => {
            console.log('clicked on close modal');
            this.setState({
              isExcelFileAddModalOpened: false,
              validExcelData: [],
              invalidExcelData: [],
              selectedExcelFile: "",
            })
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='container-fluid'>
              <div className='mt-2 d-flex justify-content-between'>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  multiple
                  onChange={(e) => this.handleInputChange(e)}
                />
                {/* <a style={{ marginLeft: 'auto' }} href="https://file-examples.com/wp-content/storage/2017/02/file_example_XLS_10.xls" target="_blank" > Download Demo File </a> */}
                <Button
                  theme="primary"
                  onClick={this.downloadDemoCsvFile}
                  disabled={this.state.isDownloadCsvLoading}
                >
                  {this.state.isDownloadCsvLoading && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
                  Download Demo File
                </Button>

              </div>
              <p style={{ color: 'red', marginTop: 3 }}>
                {/* *Accepted file format (.csv, .xlsx, .xls) */}
                {strings.accepted_file_format}
              </p>
              {this.state.invalidExcelData && this.state.invalidExcelData.length > 0 &&
                <div className='accordion mt-3' id='invalidAccordion'>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button text-white" style={{ backgroundColor: "#ff6666" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        {this.state.invalidExcelData.length} {strings.invalid_items}
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#invalidAccordion">
                      <div className="accordion-body">
                        <div className='table-responsive'>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th>SKU</th>
                                <th>Nombre</th>
                                <th>Codigo de barra Stock</th>
                                <th>Stock</th>
                                <th>Precio total con IVA incluido</th>
                                <th>{strings.expiration_day}</th>
                                <th>{strings.expiration_month}</th>
                                <th>{strings.expiration_year}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.invalidExcelData.map((singleData, index) => (
                                <tr key={index}>
                                  {/* {Object.keys(singleData).map((key) => (
                                    <td key={key}>{singleData[key]}</td>
                                  ))} */}
                                  <td>{singleData['SKU'] !== undefined ? singleData['SKU'] : ''}</td>
                                  <td>{singleData['Nombre'] !== undefined ? singleData['Nombre'] : ''}</td>
                                  <td>{singleData['Codigo de barra Stock'] !== undefined ? singleData['Codigo de barra Stock'] : ''}</td>
                                  <td>{singleData['Stock'] !== undefined ? singleData['Stock'] : ''}</td>
                                  <td>{singleData['Precio total con IVA incluido'] !== undefined ? singleData['Precio total con IVA incluido'] : ''}</td>
                                  <td>{singleData[strings.expiration_day] !== undefined ? singleData[strings.expiration_day] : ''}</td>
                                  <td>{singleData[strings.expiration_month] !== undefined ? singleData[strings.expiration_month] : ''}</td>
                                  <td>{singleData[strings.expiration_year] !== undefined ? singleData[strings.expiration_year] : ''}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {this.state.validExcelData &&
                this.state.validExcelData.length > 0 &&
                <div className='accordion mt-3' id='validAccordion'>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button text-white" style={{ backgroundColor: "#00cc00" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {this.state.validExcelData.length} {strings.valid_items}
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#validAccordion">
                      <div className="accordion-body">
                        <div className='table-responsive'>
                          <table className='table'>
                            <thead>
                              <tr>
                                {/* Dynamic rows from data code */}
                                {/* {
                                      Object.keys(this.state.validExcelData[0]).map((key) => (
                                        <th key={key}>{key}</th>
                                      ))
                                    } */}
                                <th>SKU</th>
                                <th>Nombre</th>
                                <th>Codigo de barra Stock</th>
                                <th>Stock</th>
                                <th>Precio total con IVA incluido</th>
                                <th>{strings.expiration_day}</th>
                                <th>{strings.expiration_month}</th>
                                <th>{strings.expiration_year}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.validExcelData.map((singleData, index) => (
                                <tr key={index}>
                                  {/* {Object.keys(singleData).map((key) => (
                                    <td key={key}>{singleData[key]}</td>
                                  ))} */}
                                  <td>{singleData['code']}</td>
                                  <td>{singleData['name']}</td>
                                  <td>{singleData['barcodeNumber']}</td>
                                  <td>{singleData['stock']}</td>
                                  <td>{singleData['price']}</td>
                                  <td>{singleData['expiryDay']}</td>
                                  <td>{singleData['expiryMonth']}</td>
                                  <td>{singleData['expiryYear']}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {this.state.showSuccessMsg &&
                <div class="alert alert-success alert-dismissible fade show d-flex justify-content-between align-items-center mt-2" role="alert">
                  <p className="text-white">{this.state.successMsg}</p>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={() => {
                    this.setState({
                      showSuccessMsg: false,
                      successMsg: ""
                    });
                  }} aria-label="Close"></button>
                </div>
              }
              {this.state.showErrorMsg &&
                <div class="alert alert-dismissible fade show d-flex justify-content-between align-items-center mt-2" style={{ backgroundColor: '#ff6666' }} role="alert">
                  <p className="text-white">{this.state.errorMsg}</p>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" onClick={() => {
                    this.setState({
                      showErrorMsg: false,
                      errorMsg: ""
                    });
                  }} aria-label="Close"></button>
                </div>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.isUploadCsvLoading}
              theme="primary"
              onClick={this.handleUploadButton}
            >
              {this.state.isUploadCsvLoading && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
              {strings.upload}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
export default Customers;
