import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, FormInput, FormTextarea, Alert } from 'shards-react'
import { strings } from '../../localization';
import PageTitle from '../../components/common/PageTitle';
import './Restaurant.css';
import ManageFoodItemsDialog from '../../components/common/ManageFoodItemsDialog';
import { API_URL, formateNumber } from '../../config';

function RestaurantFoodSelection(props) {
    console.log('props of food selection', props);
    const { state } = props.location;
    const { id, type, orderId, } = props.match.params;
    const { history } = props;

    // food name input states
    const [searchedFoodName, setSearchedFoodName] = useState("");

    // edit food items states
    const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
    const [editFoodDialogItem, setEditFoodDialogItem] = useState('');
    const [editFoodItemIndex, setEditFoodItemIndex] = useState('');
    const [isFoodItemSaveButtonLoading, setIsFoodItemSaveButtonLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [foodItems, setFoodItems] = useState([]);
    const [foodItemData, setFoodItemData] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    // alert states
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('danger');

    console.log('table id', id)
    console.log('type', type);
    console.log('orderId', orderId);

    const CommonBtn = ({ name, onClick, isLoading }) => {
        return (
            <Button
                theme="primary"
                className="mx-1"
                style={{ width: 'auto', height: 40 }}
                onClick={onClick}
            >
                {
                    isLoading
                        ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                        : name
                }
            </Button>
        )
    }

    const getFoodItems = async () => {
        setIsLoading(true);
        let statusCode = 0;
        const apiUrl = `${API_URL}items`
        console.log('apiUrl', apiUrl);
        fetch(apiUrl, {
            method: 'get',
            credentials: 'include'
        })
            .then((res) => {
                statusCode = res.status;
                return res.json()
            })
            .then((res) => {
                console.log('items get api response', res)
                if (statusCode === 200) {
                    var data = res.result;
                    if (data.length > 0) {
                        let draftItems = state && state.draftItems ? state.draftItems : [];
                        // This block is used to manage draft items which got from route params
                        if (draftItems && draftItems.length > 0) {
                            console.log('draftItems', draftItems);
                            draftItems.map((draftItem, index) => {
                                let matchedFoodIndex = data.findIndex((foodItem) => foodItem['_id'] === draftItem["itemID"]);
                                if (matchedFoodIndex !== -1) {
                                    console.log('matchedFoodItem', matchedFoodIndex);
                                    data[matchedFoodIndex]['data']['quantity'] = draftItem['quantity'];
                                    data[matchedFoodIndex]['data']['note'] = draftItem['note'] ? draftItem['note'] : "";
                                }
                            })
                            state.draftItems = [];
                        }
                        setFoodItems(data);
                        setFoodItemData(data);
                    } else {
                        setFoodItems([]);
                        setFoodItemData([]);
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log('items get api catch block err', err)
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getFoodItems();
    }, [])

    const handleAddFoodItem = (foodItem, index) => {
        console.log('foodItem in add food item', foodItem)
        setIsEditDialogVisible(true);
        setEditFoodItemIndex(index);
        setEditFoodDialogItem(foodItem['data']);
    }

    const handleEditFoodItem = (foodItem, index) => {
        console.log('foodItem in edit food item', foodItem)
        setIsEditDialogVisible(true);
        setEditFoodItemIndex(index);
        setEditFoodDialogItem(foodItem['data']);
    }

    const handleFoodItemDialogSaveButtonPressed = (updatedItem, index) => {
        setIsLoading(true);
        console.log('updatedItem', updatedItem);
        console.log('index', index);
        const oldFoodItemsData = [...foodItems];
        const oldItemsDataArray = [...foodItemData];
        const foodItemsId = oldFoodItemsData[index]['_id'];
        oldFoodItemsData[index]['data']['quantity'] = updatedItem['quantity'];
        if (updatedItem['note']) {
            oldFoodItemsData[index]['data']['note'] = updatedItem['note'];
        }
        console.log('old food item index', oldFoodItemsData[index]);
        const updateItemIndex = oldItemsDataArray.findIndex((item, index) => item['_id'] === foodItemsId);
        if (updateItemIndex && updateItemIndex !== -1) {
            oldItemsDataArray[updateItemIndex]['data']['quantity'] = updatedItem['quantity'];
            if (updatedItem['note']) {
                oldItemsDataArray[updateItemIndex]['data']['note'] = updatedItem['note'];
            }
        }
        setFoodItems([...oldFoodItemsData]);
        setFoodItemData([...oldItemsDataArray]);
        setIsEditDialogVisible(false);
        setIsLoading(false);
    }

    const handleOnSearchButtonPressed = () => {
        setIsLoading(true);
        searchItemFromFoodItems();
    }

    const searchItemFromFoodItems = () => {
        let filteredData = [...foodItemData];
        if (searchedFoodName.length > 0) {
            filteredData = foodItemData.filter((item, index) => item['data']['name'].toLowerCase().includes(searchedFoodName.toLowerCase()))
        }
        console.log('searchItemFromFoodItems func filteredData', filteredData);
        setFoodItems([...filteredData]);
        setIsLoading(false);
    }

    const orderSummaryApi = async (draftFoodItems = []) => {
        const tableId = id;
        let bodyData = {};
        let foodItemsDataArray = [];
        draftFoodItems.map((item) => {
            console.log('item', item)
            let singleItem = {
                itemID: item['_id'],
                note: item['data']['note'] ? item['data']['note'] : "",
                quantity: item['data']['quantity'],
                price: item['data']['price']
            };
            console.log('selected food singleItem', singleItem);
            foodItemsDataArray.push(singleItem);
        })
        bodyData['dataArray'] = foodItemsDataArray;
        bodyData['restaurantTableID'] = tableId;
        console.log('bodyData of order summary api before send', bodyData);
        let statusCode = 0;

        let apiUrl = `${API_URL}restaurant-order`;
        if (orderId) {
            apiUrl += `/${orderId}`
        }
        console.log('order summary api url', apiUrl);
        fetch(apiUrl, {
            method: orderId ? 'put' : 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(bodyData)
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((res) => {
            console.log('response of order summary api', res);
            if (statusCode === 200) {
                console.log('200 response of order summary api', res);
                setIsButtonLoading(false);
                history.push({
                    pathname: `/restaurant/${id}`,
                    state: {
                        orderId: orderId ? orderId : res['_id']
                    }
                })
            } else {
                const reponseMessage = res['message'] ? res['message'] : "Something went wrong!"
                showAlert(reponseMessage, 'danger');
                setIsButtonLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at order summary api', err);
            setIsButtonLoading(false);
        })
    }

    const handleAddCartButtonPressed = () => {
        if (foodItemData && foodItemData.length > 0) {
            let addedOrEditedFoodItems = foodItemData.filter((item) => item['data']['quantity'] && item['data']['quantity'] > 0);
            console.log('addedOrEditedFoodItems', addedOrEditedFoodItems);
            if (addedOrEditedFoodItems && addedOrEditedFoodItems.length > 0) {
                setIsButtonLoading(true);
                orderSummaryApi(addedOrEditedFoodItems);
            } else {
                showAlert(strings.please_select_food, 'error');
            }
        }
    }

    const showAlert = (msg, type) => {
        setIsAlertVisible(true);
        setAlertType(type);
        setAlertMsg(msg);

        setTimeout(() => {
            setIsAlertVisible(false);
        }, 4000)
    }

    return (
        <Container fluid className="main-content-container px-4">
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 10, alignItems: 'flex-start' }}>
                <Link
                    to={{
                        pathname: `/restaurant/${id}`,
                        state: {
                            orderId: orderId
                        }
                    }}
                >
                    <i class="material-icons" style={{ fontSize: 26, marginRight: 4, color: 'black' }}>arrow_back</i>
                </Link>
                <p className='mt-10' style={{ fontSize: 20 }}>{strings.order_summary}</p>
            </div>
            <Row noGutters className="page-header py-4">
                <Col sm="12" md="8" lg="3">
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <FormInput
                                type="text"
                                value={searchedFoodName}
                                placeholder={strings.search_by_food_name}
                                onChange={e =>
                                    setSearchedFoodName(e.target.value)
                                }
                                onKeyDown={(event) => {
                                    if (event.keyCode === 13) {
                                        handleOnSearchButtonPressed();
                                    }
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4 tableCard">
                        <CardBody className="p-3 b-3">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <PageTitle
                                    sm="4"
                                    title={strings.select_food}
                                    className="text-sm-left page-title"
                                />
                                <div style={{ display: 'flex' }}>
                                    <CommonBtn
                                        name={strings.add_cart}
                                        onClick={handleAddCartButtonPressed}
                                        isLoading={isButtonLoading}
                                    />
                                </div>
                            </div>
                            <hr />
                            {
                                isLoading
                                    ? <div className='parent'>
                                        <h4>Loading...</h4>
                                    </div>
                                    :
                                    foodItems.length > 0 ?
                                        foodItems.map((item, index) => {
                                            const itemData = item['data']
                                            return (
                                                <Row noGutters className="foodItemsRowParentContainer">
                                                    <Col sm="10" md="8" lg="8">
                                                        <Row noGutters className="foodItemsRowContainer">
                                                            <Col sm="6" md="5" lg="5" className="foodItemContainer">
                                                                <p className='foodName'>{itemData['name']}</p>
                                                                <p className='foodCode'>{itemData['code']}</p>
                                                                {
                                                                    itemData['quantity'] &&
                                                                    <div style={{ display: 'flex' }}>
                                                                        <p className='addToCart'>Added to cart</p>
                                                                        <p className='quantity'> x{itemData['quantity']}</p>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            <Col sm="4" md="3" lg="3" className="foodPriceContainer">
                                                                <div
                                                                    onClick={() => itemData['quantity'] ? handleEditFoodItem(item, index) : handleAddFoodItem(item, index)}
                                                                    className={itemData['quantity'] ? 'foodEditdBtn' : 'foodAddBtn'}
                                                                >
                                                                    {itemData['quantity'] ? strings.edit.toUpperCase() : strings.add_item.toUpperCase()}
                                                                </div>
                                                                <p className='foodPrice'>{itemData['price'] ? formateNumber(itemData['price']) : ""}</p>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        : <div className='parent'>
                                            <h4>{strings.no_data_available}</h4>
                                        </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                isEditDialogVisible &&
                <ManageFoodItemsDialog
                    showModal={isEditDialogVisible}
                    setShowModal={setIsEditDialogVisible}
                    foodItem={editFoodDialogItem}
                    foodIndex={editFoodItemIndex}
                    onSaveButtonPressed={handleFoodItemDialogSaveButtonPressed}
                    isSaveButtonLoading={isFoodItemSaveButtonLoading}
                    isFromFoodSelection={true}
                />
            }
            <Alert
                open={isAlertVisible}
                theme={alertType}
                style={{
                    position: 'fixed',
                    bottom: 10,
                    width: '80%',
                }}
            >
                {alertMsg}
            </Alert>
        </Container>
    )
}

export default RestaurantFoodSelection