import React from "react";
import { Container, Button } from "shards-react";
import { Link } from "react-router-dom";

const Errors = props => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>{props.number}</h2>
        <p>{props.message}</p>
        <h4>{props.text}</h4>
        {/* <Link to="user-request"> */}
        <Link to="user-request">
          <Button pill>&larr; Go To Home</Button>
        </Link>
      </div>
    </div>
  </Container>
);

export default Errors;
