import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import {
  FormGroup,
  FormInput,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormCheckbox,
  FormSelect,
  Alert
} from 'shards-react';
import BarcodeReader from 'react-barcode-reader';
import 'bootstrap/dist/js/bootstrap.js';
import { strings } from './../../localization';
import { Language, API_URL, LIMIT } from './../../config';
import './Ventas.css';
import Customer from './Customer';
export const CustomButton = props => {
  const buttonStyle = {
    width: props.width ? props.width : 80,
    height: props.height ? props.height : 80,
    backgroundColor: props.backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20,
    opacity: props.disabled ? 0.7 : 1
  };
  return (
    <div
      style={buttonStyle}
      className="icon-general-style"
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
    >
      {props.isLoading && (
        <span
          style={{ marginRight: 5 }}
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {props.text}
    </div>
  );
};

export class Calculator extends Component {
  constructor(props) {
    super(props);
    console.log('props', props);
    this.state = {
      quantity: '',
      simple_price: '0',
      price: '$0',
      finalPrice: '$0',
      multiply: false,
      addDescription: false,
      addItem: false,
      description: '',
      array: [],
      copyArray: [],
      mainTotal: '$0',
      totalItem: 0,
      isModalVisible: false,
      torchMode: 'off',
      cameraType: 'back',
      isBarcodeDisplay: false,
      visible: false,
      color: '#D74848',
      error_color: 'red',
      message: '',
      cnt: true,
      lastBarcode: '',
      press: false,
      discountModel: false,
      discount: 0,
      inPercentage: true,
      isSwitchOn: true,
      totalDiscount: 0,
      duration: 600,
      withoutTax: true,
      itemType: '',
      itemCode: '',
      barcodeNumber: '',
      message_error: '',
      visible_error: false,
      duration_error: 600,
      isDiscountModelShow: false,
      isNextButtonClick: false,
      payable: '',
      originalPayable: '',
      mainPrice: '',
      paymentMode: strings.cash,
      total: '0',
      data: [],
      type: props.type,
      showCustomerScreen: false,
      bypassCustomerScreen: false,
      showPermissionForPropina: false,
      propinaChecked: false,
      propinaAmount: 0,
      propinaPercentage: 0,
      calculatorReference: [],
      calculatorBillingsReference: [],
      titleDigitLength: 9,
      inputDigitLength: 8,
      titleSubstringEndLength: 8,
      isLoading: false,
      apiStoreType: '',
      transactionDocumentId: '',
      transactionDocumentType: '',
      isEditRecord: false,
      recordErrors: '',
      editProductData: [],
      editProductFromTransaction: false
    };
    strings.setLanguage(Language);
    this.description = React.createRef();
    this.handleScan = this.handleScan.bind(this);
  }

  // removeItem = (index, price, discount, mode, mainPrice) => {
  //   let stateMainTotal = this.state.mainTotal.toString().replace(/\./g, '');

  //   // alert(price);

  //   // let newPrice = price.replace(/\./g, '');

  //   // price = parseInt(price.substring(1));

  //   // mainPrice = mainPrice.replace(/\./g, '');
  //   // mainPrice = parseInt(mainPrice.substring(1));

  //   let removeDiscount = 0;
  //   if (discount) {
  //     if (mode) {
  //       // true  = % false = $
  //       removeDiscount = (mainPrice * discount) / 100;
  //     } else {
  //       removeDiscount = discount;
  //     }
  //   }
  //   console.log("🚀 ~ file: Calculator.js:141 ~ removeDiscount:", removeDiscount)
  //   console.log("🚀 ~ file: Calculator.js:141 ~ this.state.totalDiscount:", this.state.totalDiscount)

  //   let totalDiscount = this.state.totalDiscount - removeDiscount;
  //   console.log("🚀 ~ file: Calculator.js:151 ~ totalDiscount:", totalDiscount)

  //   if (totalDiscount < 0) {
  //     totalDiscount = 0;
  //   }

  //   let total = parseInt(stateMainTotal) - price;
  //   total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  //   const list = this.state.array;
  //   list.splice(index, 1);

  //   this.setState(
  //     {
  //       array: list,
  //       totalItem: this.state.totalItem - 1,
  //       mainTotal: total,
  //       visible: true,
  //       totalDiscount: totalDiscount,
  //       color: 'green',
  //       message: strings.item_remove
  //     },
  //     () => {
  //       this.setState({
  //         copyArray: this.state.array,
  //         recordErrors: ''
  //       });
  //       setTimeout(() => {
  //         this.setState({
  //           visible: false,
  //           message: ''
  //         });
  //       }, 2000);
  //     }
  //   );
  // };

  removeItem = (item, index) => {
    let stateMainTotal = this.state.mainTotal.toString().replace(/\./g, '');
    let localArray = JSON.parse(JSON.stringify(this.state.copyArray))
    let removeDiscount = 0;
    if (item.discount) {
      if (item.percent) {
        removeDiscount = item.discount * item.quantity;
      } else {
        removeDiscount = item.discount;
      }
    }
    console.log("🚀 ~ file: Calculator.js:141 ~ removeDiscount:", removeDiscount)

    if (index !== localArray.length - 1) {
      for (let localIndex = index; localIndex < localArray.length; localIndex++) {
        const item = localArray[localIndex];
        item['totalDiscount'] -= removeDiscount;
      }
    }
    console.log("🚀 ~ file: Calculator.js:192 ~ localArray:", localArray)
    localArray.splice(index, 1);
    let totalDiscount = this.state.totalDiscount - removeDiscount;
    console.log("🚀 ~ file: Calculator.js:151 ~ totalDiscount:", totalDiscount)

    if (totalDiscount < 0) {
      totalDiscount = 0;
    }

    let total = parseInt(stateMainTotal) - item.total;
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    this.setState(
      {
        array: localArray,
        copyArray: localArray,
        totalItem: this.state.totalItem - 1,
        mainTotal: total,
        visible: true,
        totalDiscount: totalDiscount,
        color: 'green',
        message: strings.item_remove
      },
      () => {
        this.setState({
          // copyArray: this.state.array,
          recordErrors: ''
        });
        setTimeout(() => {
          this.setState({
            visible: false,
            message: ''
          });
        }, 2000);
      }
    );
  };

  validateAmount = async (
    amount,
    array,
    calculatorReferenceValue,
    isFromPrint
  ) => {
    console.log('🚀 ~ file: Billing.js ~ line 1050 ~ Billing ~ amount', amount);
    // let bodyData = {
    //   validateFor: this.state.apiStoreType,
    //   amount: amount,
    // }
    //  console.log("🚀 ~ file: Billing.js ~ line 1055 ~ Billing ~ bodyData", bodyData)
    const formData = new FormData();
    formData.append('validateFor', this.state.apiStoreType);
    formData.append('amount', amount);
    console.log(`${API_URL}validateAmount`);
    const response = await fetch(`${API_URL}validateAmount`, {
      method: 'post',
      // headers: {
      //   credentials: 'include'
      // },
      body: formData
    });
    const res = await response.json();
    if (response.status === 200) {
      console.log('200 res of validateAmount is', res);
      this.setState(
        {
          data: array,
          payable: this.state.mainTotal,
          originalPayable: this.state.mainTotal,
          payable: this.state.mainTotal,
          finalPrice: this.state.mainTotal,
          total: '0',
          isLoading: false
        },
        () => {
          if (this.props.type !== undefined && this.props.type === 4) {
            this.setState({
              bypassCustomerScreen: false,
              showCustomerScreen: true
            });
          }
          isFromPrint
            ? this.setState({
              bypassCustomerScreen: true,
              showCustomerScreen: true
            })
            : this.setState({
              isNextButtonClick: true,
              propinaChecked: false,
              calculatorReference: calculatorReferenceValue
            });
        }
      );
    } else if (response.status === 400) {
      console.log('400 res of validateAmount is', res);
      this.setState(
        {
          visible_error: true,
          message_error: res.message,
          isLoading: false
        },
        () => {
          setTimeout(() => {
            this.setState({
              visible_error: false,
              message_error: ''
            });
          }, 2000);
        }
      );
    } else {
      console.log('not 200 res of validateAmount is', res);
      this.setState(
        {
          visible_error: true,
          message_error: 'Something went wrong',
          isLoading: false
        },
        () => {
          setTimeout(() => {
            this.setState({
              visible_error: false,
              message_error: ''
            });
          }, 2000);
        }
      );
    }
  };

  async componentDidMount() {
    let permissions = await localStorage.getItem('user_permissions');
    let parse = JSON.parse(permissions);
    let showPermission = parse.enable_propina;
    this.setState({ showPermissionForPropina: showPermission });

    await localStorage.setItem('propinaPercentage', JSON.stringify(10));
    let propinaPercentage = await localStorage.getItem('propinaPercentage');
    let parsedpropinaPercentage = JSON.parse(propinaPercentage);
    this.setState({ propinaPercentage: parsedpropinaPercentage });

    let storeType = 'bills';
    if (this.props.type === 1) {
      storeType = 'bills';
    } else if (this.props.type === 2) {
      storeType = 'invoices';
    } else if (this.props.type === 3) {
      storeType = 'creditNotes';
    } else if (this.props.type === 4) {
      storeType = 'dispatchNotes';
    } else if (this.props.type === 6) {
      storeType = 'quotes';
    } else {
      storeType = 'debitNotes';
    }

    this.setState({ apiStoreType: storeType });

    if (this.props.type === 1 || this.props.type === 2) {
      this.setState({
        titleDigitLength: 12,
        inputDigitLength: 11,
        titleSubstringEndLength: 11
      });
    }
    console.log('this is calculator props : ', this.props);
    const param = this.props;
    if (param !== undefined) {
      this.setState({
        transactionDocumentId: param.transactionDocumentId,
        transactionDocumentType: param.transactionDocumentType
      });

      if (param.editProductFromTransaction !== undefined && param.editProductData !== undefined && param.editProductFromTransaction) {
        let objData = param.editProductData.data;
        console.log('objData', objData)
        let newMainTotal = 0
        let newTotalDiscount = 0
        objData.dataArray.map((i) => {
          newMainTotal += i.total
          newTotalDiscount += i.totalDiscount
        })
        console.log('newTotalDiscount', newTotalDiscount, 'newMainTotal', newMainTotal)
        this.setState({
          array: objData.dataArray,
          oldArray: objData.dataArray,
          mainTotal: newMainTotal.toString(),
          totalDiscount: newTotalDiscount,
          editProductFromTransaction: param.editProductFromTransaction,
          editProductData: param.editProductData
        });
      }
    }
  }

  clearAll = () => {
    this.setState({
      price: '$0',
      finalPrice: '$0',
      simple_price: '0',
      quantity: '',
      multiply: false,
      array: [],
      copyArray: [],
      mainTotal: '$0',
      totalItem: 0,
      totalDiscount: 0,
      isModalVisible: false,
      addItem: false,
      withoutTax: false,
      calculatorBillingsReference: []
    });
  };
  onToggleSwitch = () => this.setState({ isSwitchOn: !this.state.isSwitchOn });
  clearData = () => {
    let total = parseFloat(this.state.finalPrice.substring(1));
    let stateMainTotal = this.state.mainTotal.toString().replace(/\./g, '');

    total = stateMainTotal - total;
    total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    this.setState({
      price: '$0',
      finalPrice: '$0',
      simple_price: '0',
      quantity: '',
      multiply: false,
      discount: ''
    });
  };
  findItem = name => {
    for (const item of this.state.array) {
      if (item.description === name) {
        return true;
      }
    }
  };

  updateMainTotal = () => {
    let updatedValue = parseFloat(this.state.finalPrice.substring(1));
    let mainTotal = this.state.mainTotal.toString();
    let stateMainTotal = mainTotal.replace(/\./g, '');

    updatedValue = stateMainTotal - updatedValue;
    updatedValue = updatedValue.toString();

    this.setState({
      mainTotal: updatedValue
    });
  };
  parseNumberToInt = value => {
    if (value) {
      value = value.replace('$', '');
      console.log('before set', parseInt(value.replace(/\./g, '')));
      return parseInt(value.replace(/\./g, ''));
    }
  };
  roundWithTwoDecimals = value => {
    console.log(
      '🚀 ~ file: Calculator.js ~ line 217 ~ Calculator ~ value',
      value
    );
    if (value) {
      console.log('value type is', typeof value);
      if (typeof value === 'string') {
        console.log('value before assign', value);
        value = value.replace('$', '');
        console.log('value before 2nd assign', value);
        value = value.replace(/\./g, '');
        console.log('value after assign', value);
      }
      console.log('before return value', Math.round(value * 100) / 100);
      return Math.round(value * 100) / 100;
    }
  };

  handleTap = (type, value) => {
    if (this.state.isNextButtonClick) {
      if (type === 'number') {
        let simple_price = this.state.simple_price;
        if (simple_price === '0') {
          simple_price = '';
        }
        let fPrice = simple_price + value;

        if (fPrice === '00' || fPrice === '000') fPrice = '0';
        simple_price = fPrice;
        // fPrice = formatNumber(fPrice);

        // alert(this.state.payable);
        let payable = this.state.payable;
        console.log('---------------------------------');
        console.log('Payable Price: ' + payable);
        payable = payable.toString().replace(/\./g, '');
        console.log('Payable Price: ' + payable);

        let total = fPrice - payable;
        console.log('Final Price: ' + fPrice);
        //console.log('Payable Price: ' + payable);
        console.log('Value: ' + total);

        // total = formatNumber(total);
        // total = total.toFixed(3);

        fPrice = fPrice.toString().replace(/\./g, '');
        fPrice = fPrice.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        total = total.toString().replace(/\./g, '');
        total = total.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        console.log('---------------------------------');
        this.setState({
          finalPrice: fPrice,
          simple_price: simple_price,
          total: total
        });
      } else if (type === 'clear') {
        this.setState({
          finalPrice: '0',
          simple_price: '',
          total: '0'
        });
      } else if (type === 'remove') {
        let finalPrice = this.state.finalPrice.slice(0, -1);
        // alert(finalPrice);
        let total = '';
        if (finalPrice.length > 1) {
          // alert('finalPrice = ' + finalPrice + 'payable = ' + this.state.payable);

          total =
            finalPrice.replace(/\./g, '') -
            this.state.payable.replace(/\./g, '');
          // console.log('finalPrice : ' + finalPrice);
          // console.log('payable : ' + this.state.payable);
          // console.warn('Total : ' + total);
          // finalPrice = finalPrice.slice(0, -1);
          finalPrice = finalPrice.toString().replace(/\./g, '');
          finalPrice = finalPrice.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // alert(finalPrice);
          // total.toFixed(3);
        } else finalPrice = '0';

        let simple_price =
          this.state.simple_price.length > 2
            ? (simple_price =
              this.state.simple_price === '0'
                ? ''
                : this.state.simple_price.substring(
                  0,
                  this.state.simple_price.length - 1
                ))
            : '';

        if (!total) {
          total = '0';
        }
        // alert(total);
        // finalPrice = finalPrice.toString().replace(- '');
        total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // console.log('F Price : ' + total);

        this.setState({
          total: total,
          finalPrice: finalPrice,
          simple_price: simple_price
        });
      }
    } else {
      const multiply = this.state.multiply;

      if (multiply) {
        if (type === 'number') {
          let finalQuantity = this.state.quantity;
          if (value === ',') {
            if (finalQuantity.toString().indexOf(',') === -1) {
              finalQuantity += value;
            }
          } else {
            finalQuantity += value;
          }

          if (finalQuantity === ',') {
            finalQuantity = '1,';
          }
          let replace = finalQuantity.replace(/\,/g, '.');

          let price = this.state.price.substring(1);
          price = price.replace(/\./g, '');
          let total = price * parseFloat(replace);

          let discount = this.state.discount;
          if (discount > 0) {
            let dAmount = 0;
            if (this.state.inPercentage) {
              dAmount = (parseInt(total) * discount) / 100;
            } else {
              dAmount = discount;
            }
            total = parseInt(total) - dAmount;
          }

          total = Math.round(total);
          total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          this.setState({
            quantity: finalQuantity,
            finalPrice: '$' + total
          });
        } else if (type === 'remove') {
          let quantity = this.state.quantity;

          if (quantity.length > 1) quantity = quantity.slice(0, -1);
          else quantity = '1';

          let price = this.state.price.replace(/\./g, '');

          let total = parseFloat(price.substring(1)) * parseInt(quantity);

          let discount = this.state.discount;
          if (discount > 0) {
            let dAmount = 0;
            if (this.state.inPercentage) {
              dAmount = (parseInt(total) * discount) / 100;
            } else {
              dAmount = discount;
            }
            total = parseInt(total) - dAmount;
          }

          if (quantity === '1') quantity = '';

          this.setState({
            quantity: quantity,
            finalPrice: '$' + total
          });
        } else if (type === 'multiply') {
          this.setState({
            multiply: !multiply
          });
        } else if (type === 'clear') {
          this.clearData();
        }
      } else {
        if (type === 'number') {
          if (this.state.price.length < this.state.inputDigitLength) {
            let oldPrice =
              this.state.simple_price === '0' ? '$' : this.state.simple_price;
            let final = oldPrice + value;
            let simple_price = final;
            let finalQuantity = this.state.quantity;

            if (finalQuantity === '') {
              finalQuantity = '1';
            }

            let replace = finalQuantity.replace(/\,/g, '.');
            console.log(final);

            let fPrice = final.substring(1) * parseFloat(replace);
            let discount = this.state.discount;
            if (discount > 0) {
              let dAmount = 0;
              if (this.state.inPercentage) {
                dAmount = (parseInt(fPrice) * discount) / 100;
              } else {
                dAmount = discount;
              }
              fPrice = parseInt(fPrice) - dAmount;
            }

            fPrice = Math.round(fPrice);
            fPrice = fPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            final = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

            if (
              final === '$00' ||
              final === '$000' ||
              final === '$0.000' ||
              final === '$00.000' ||
              final === '$000.000'
            ) {
              final = '$0';
            }

            this.setState({
              simple_price: simple_price,
              price: final,
              finalPrice: '$' + fPrice
            });
          }
        } else if (type === 'clear') {
          this.clearData();
        } else if (type === 'remove') {
          let price =
            this.state.price.length > 2
              ? (price =
                this.state.price === '$0'
                  ? '$0'
                  : this.state.price.substring(
                    0,
                    this.state.price.length - 1
                  ))
              : '$0';

          let simple_price =
            this.state.simple_price.length > 2
              ? (simple_price =
                this.state.simple_price === '$0'
                  ? '0'
                  : this.state.simple_price.substring(
                    0,
                    this.state.simple_price.length - 1
                  ))
              : '0';

          let finalQuantity = this.state.quantity;

          if (finalQuantity === '') {
            finalQuantity = '1';
          }

          let replace = finalQuantity.replace(/\,/g, '.');
          price = price.replace(/\./g, '');

          let newPrice = price.substring(1);
          newPrice = newPrice.replace(/\./g, '');
          newPrice = newPrice.replace(/\./g, '');

          let total = newPrice * parseFloat(replace);

          let discount = this.state.discount;
          if (discount > 0) {
            let dAmount = 0;
            if (this.state.inPercentage) {
              dAmount = (parseInt(total) * discount) / 100;
            } else {
              dAmount = discount;
            }
            total = parseInt(total) - dAmount;
          }

          total = Math.round(total);
          total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          price = price.toString().replace(/\./g, '');
          price = price.substring(1);
          price = price.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

          this.setState({
            price: '$' + price,
            simple_price: simple_price,
            finalPrice: '$' + total
          });
        } else if (type === 'multiply') {
          this.setState({
            multiply: !multiply
          });
        }
      }
    }
  };

  storeInArray = async () => {
    console.log('storeInArray function called');
    // alert('Store in array function : ' + this.state.itemCode);
    let mainPriceWithoutTax = 0;
    let mainPriceWithTax = 0;
    let matchedIndex;
    let count = this.state.totalItem + 1;

    let totalDiscount = this.state.discount;
    let fPrice = this.state.price.substring(1);
    console.log('fprice in', fPrice);
    // This logic is added at 2023-05-12 for check that item is exist or not
    if (this.state.itemCode !== '' && this.state.array.length > 0) {
      matchedIndex = this.state.array.findIndex(
        i => i.itemCode === this.state.itemCode
      );
    }
    fPrice = fPrice.toString().replace(/\./g, '');
    let quantity = parseInt(this.state.quantity.toString().replace(/\,/g, '.'));
    console.log(
      'quantity state',
      quantity,
      'type of quantity',
      typeof quantity
    );
    console.log(
      '🚀 ~ file: Calculator.js:650 ~ Calculator ~ storeInArray= ~ quantity:',
      quantity
    );
    if (!quantity) {
      quantity = 1;
    }

    if (matchedIndex !== undefined && matchedIndex !== -1) {
      console.log('increased quantity');
      console.log(
        'quantity',
        quantity,
        'add quantity',
        this.state.array[matchedIndex]['quantity']
      );
      quantity += this.state.array[matchedIndex]['quantity'];
    }

    fPrice = fPrice * parseFloat(quantity);
    console.log(
      '🚀 ~ file: Calculator.js:730 ~ storeInArray= ~ fPrice:',
      fPrice
    );
    let discount = this.state.discount;
    console.log(
      '🚀 ~ file: Calculator.js:666 ~ Calculator ~ storeInArray= ~ discount:',
      discount
    );

    if (this.state.inPercentage) {
      totalDiscount = (parseInt(fPrice) * discount) / 100;
    }

    totalDiscount = Math.round(totalDiscount);
    let singleItemDiscount = totalDiscount;
    if (matchedIndex !== undefined && matchedIndex !== -1) {
      console.log('increment discount');
      let allExistQuantityDiscount = Math.round(
        this.state.array[matchedIndex]['discount'] * quantity
      );
      singleItemDiscount += allExistQuantityDiscount;
    }
    console.log(
      '🚀 ~ file: Calculator.js:675 ~ Calculator ~ storeInArray= ~ singleItemDiscount:',
      singleItemDiscount
    );
    console.log(
      'before additon of this value in total discount',
      '(1)',
      this.state.totalDiscount,
      '(2)',
      totalDiscount,
      'after parse ',
      '(1)',
      parseInt(this.state.totalDiscount),
      '(2)',
      parseInt(totalDiscount)
    );
    totalDiscount =
      parseInt(this.state.totalDiscount) + parseInt(totalDiscount);
    let AWT = 0;

    console.log('fPrice', fPrice);
    console.log('starting total discount', totalDiscount);

    // This logic is added at 2023-05-13 for add totalDiscount if exist so we don't need to change exist logic
    if (matchedIndex !== undefined && matchedIndex !== -1) {
      console.log('increase total discount');
      totalDiscount += Math.round(this.state.array[matchedIndex]['discount']);
      if (matchedIndex !== this.state.array.length) {
        for (var i = matchedIndex + 1; i < this.state.array.length; i++) {
          console.log('test', this.state.array[i]);
          console.log(
            'totalDiscount add if condition',
            'i is',
            i,
            'array of i is',
            this.state.array[i]
          );
          console.log(
            'if conditio in discount of mathced index',
            this.state.array[matchedIndex]['discount']
          );
          console.log(
            'if conditio in total discount of mathced index',
            this.state.array[i]['totalDiscount']
          );
          this.state.array[i]['totalDiscount'] += Math.round(
            this.state.array[matchedIndex]['discount']
          );
        }
      }
    }
    console.log(
      'this state array after update in total discount',
      this.state.discount
    );
    AWT = this.state.isSwitchOn ? fPrice / 1.19 : fPrice;

    console.log('this.state.discount', this.state.discount);
    console.log('test before set dwt', singleItemDiscount / 1.19);
    // let DWT = this.state.isSwitchOn ? totalDiscount / 1.19 : totalDiscount;
    let DWT = this.state.isSwitchOn
      ? singleItemDiscount / 1.19
      : singleItemDiscount; // change at 2023-05-12
    console.log(
      '🚀 ~ file: Calculator.js:701 ~ Calculator ~ storeInArray= ~ DWT:',
      DWT
    );

    // commented at 2023-05-13 because add new logic that's why below code is not required
    // if (!discount) {
    //     DWT = 0;
    // }
    console.log('AWT : ' + AWT);
    console.log('DWT : ' + DWT);

    let FAWT = AWT - DWT;

    console.log('Fawt : ' + FAWT);

    // FAWT = Math.round(FAWT);
    // console.log('Fawt ceil: ' + FAWT);

    let withoutTax = this.state.withoutTax;
    let iva = 0;

    if (this.state.isSwitchOn) {
      if (this.state.type !== 2) {
        iva = FAWT * 0.19;
      }

      // console.log('iva: ' + iva);
      console.log('======before rounding iva==== ', iva);

      //iva = Math.round(iva);

      console.log('======after rounding iva==== ', iva);
      withoutTax = false;
    } else {
      withoutTax = true;
    }

    // console.log('iva round : ' + iva);
    console.log(
      'test!!!',
      (this.parseNumberToInt(this.state.price) * parseFloat(quantity)) / 1.19
    );
    if (
      (this.state.isSwitchOn && this.state.type === 2) ||
      this.state.type === 5 ||
      this.state.type === 4 ||
      this.state.type === 6
    ) {
      // mainPriceWithoutTax = Math.round(
      //     this.parseNumberToInt(this.state.price) / 1.19,
      // );
      mainPriceWithTax = this.roundWithTwoDecimals(this.state.price);
      console.log(
        '🚀 ~ file: Calculator.js ~ line 587 ~ Calculator ~ storeInArray= ~ mainPriceWithTax',
        mainPriceWithTax
      );
      mainPriceWithoutTax = mainPriceWithTax / 1.19;
      mainPriceWithoutTax = this.roundWithTwoDecimals(mainPriceWithoutTax);
      // FAWT = (this.parseNumberToInt(this.state.price) * parseFloat(quantity)) / 1.19;
      FAWT = mainPriceWithoutTax * quantity;
      // iva = this.parseNumberToInt(this.state.price) * parseFloat(quantity) - FAWT;
      iva = mainPriceWithTax * quantity - FAWT;
      iva = Math.round(iva);
    } else {
      mainPriceWithoutTax = Math.round(this.parseNumberToInt(this.state.price));
    }
    console.log('quantity before set', this.state.quantity);
    console.log('quantity before set', typeof this.state.quantity);
    // Added at 2023-02-22
    let newDiscount = this.roundWithTwoDecimals(
      this.state.inPercentage
        ? (parseInt(
          (this.state.type !== undefined && this.state.type === 2) ||
            this.state.type === 5 ||
            this.state.type === 4 ||
            this.state.type === 6
            ? mainPriceWithTax
            : mainPriceWithoutTax
        ) *
          discount) /
        100
        : this.state.discount
    );
    let FAWTValue = this.roundWithTwoDecimals(FAWT);
    console.log(`finalPrice : ${this.state.finalPrice}`);
    console.log('before set to item iva is', iva);
    console.log('before set total discount', totalDiscount);
    let item = {
      total: this.parseNumberToInt(this.state.finalPrice),
      itemType: this.state.itemType,
      description: this.state.description,
      // quantity:
      //     this.state.quantity === '' ? 1 : this.parseNumberToInt(this.state.quantity),
      quantity: quantity, //add at 2023-05-12
      // quantity:
      //     this.state.quantity === '' ? 1 : this.state.quantity,
      // mainPrice: this.parseNumberToInt(this.state.price),
      mainPrice:
        mainPriceWithoutTax === undefined || isNaN(mainPriceWithoutTax)
          ? 0
          : mainPriceWithoutTax,
      // mainPriceWithTax: this.parseNumberToInt(this.state.price),
      mainPriceWithTax:
        mainPriceWithTax === undefined || isNaN(mainPriceWithTax)
          ? 0
          : mainPriceWithTax,
      // discount: this.parseNumberToInt(this.state.discount),
      // discount: this.roundWithTwoDecimals(this.state.discount), //add at 2023-02-22
      discount:
        newDiscount === undefined || isNaN(newDiscount) ? 0 : newDiscount,
      percent: this.state.inPercentage,
      itemCode: this.state.itemCode,
      barcode: this.state.barcodeNumber,
      tax: this.state.isSwitchOn,
      totalDiscount:
        totalDiscount === undefined || isNaN(totalDiscount) ? 0 : totalDiscount,
      // FAWT: Math.round(FAWT),
      FAWT: FAWTValue === undefined || isNaN(FAWTValue) ? 0 : FAWTValue,
      iva: iva === undefined || isNaN(iva) ? 0 : iva
    };

    // Item.total need to update
    // item.total = (item.mainPrice * item.quantity) - (Math.round(item.discount) * item.quantity);

    // this.setState({
    //     finalPrice: item.total
    // })

    console.log(
      '🚀 ~ file: Calculator.js ~ line 619 ~ Calculator ~ storeInArray= ~ item',
      item
    );

    try {
      await localStorage.setItem('item_name', this.state.description);
    } catch (error) { }

    var joined = this.state.array;
    if (this.state.type !== undefined && this.state.type === 4) {
      //   joined = this.state.array.concat(item);
      if (this.state.itemCode != '' && joined.length > 0) {
        let matchedIndex = joined.findIndex(
          i => i.itemCode === this.state.itemCode
        );
        if (matchedIndex !== -1) {
          console.log('matched!!');
          joined[matchedIndex]['total'] += item.total;
          joined[matchedIndex]['quantity'] = item.quantity;
          joined[matchedIndex]['totalDiscount'] += Math.round(
            joined[matchedIndex]['discount']
          );
          joined[matchedIndex]['FAWT'] = item.FAWT;
          joined[matchedIndex]['iva'] = item.iva;
        } else {
          joined = this.state.array.concat(item);
        }
      } else {
        joined = this.state.array.concat(item);
      }
    } else {
      //   if (item.total > 0) joined = this.state.array.concat(item); // commented at 2023-05-13
      // New Logic For Add Quantitty if exist
      console.log('before add new logic joine', joined);
      if (item.total > 0) {
        if (this.state.itemCode != '' && joined.length > 0) {
          let matchedIndex = joined.findIndex(
            i => i.itemCode === this.state.itemCode
          );
          if (matchedIndex !== -1) {
            console.log('matched!!');
            // let afterRemoveDiscountTotal = item.total - joined[matchedIndex]['discount']
            // console.log("🚀 ~ file: Calculator.js:777 ~ Calculator ~ storeInArray= ~ afterRemoveDiscountTotal:", afterRemoveDiscountTotal)
            // joined[matchedIndex]['total'] += afterRemoveDiscountTotal;
            joined[matchedIndex]['total'] += item.total;
            // joined[matchedIndex]['itemType'] += item.itemType;
            // joined[matchedIndex]['description'] += item.description;
            joined[matchedIndex]['quantity'] = item.quantity;
            // joined[matchedIndex]['mainPrice'] += item.mainPrice;
            // joined[matchedIndex]['mainPriceWithTax']+= item.mainPriceWithTax;
            // joined[matchedIndex]['discount'] += item.discount;
            // joined[matchedIndex]['percent'] += item.percent;
            // joined[matchedIndex]['itemCode'] += item.itemCode;
            // joined[matchedIndex]['tax'] += item.tax;
            // joined[matchedIndex]['totalDiscount'] = item.totalDiscount;
            joined[matchedIndex]['totalDiscount'] += Math.round(
              joined[matchedIndex]['discount']
            );
            joined[matchedIndex]['FAWT'] = item.FAWT;
            // joined[matchedIndex]['FAWT'] = joined[matchedIndex]['FAWT'];
            joined[matchedIndex]['iva'] = item.iva;
            // joined[matchedIndex]['iva'] = joined[matchedIndex]['iva'];
          } else {
            joined = this.state.array.concat(item);
          }
        } else {
          joined = this.state.array.concat(item);
        }
      }
    }

    let mainTotal = this.state.mainTotal;
    let finalPrice = this.state.finalPrice.substring(1);
    mainTotal = mainTotal.toString().replace(/\./g, '');
    mainTotal = mainTotal.toString().replace(/\./g, '');

    if (mainTotal === '0' || isNaN(mainTotal) || mainTotal === '$0') {
      mainTotal = finalPrice;
    } else {
      mainTotal = Math.abs(parseInt(mainTotal));
      finalPrice = finalPrice.toString().replace(/\./g, '');
      mainTotal = mainTotal + parseInt(finalPrice);
    }

    mainTotal = mainTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    console.log('before set withoutTax is ', withoutTax);
    console.log('before set to array joined array', joined);
    console.log('before set to state totalDiscount', totalDiscount);
    this.setState({
      array: joined,
      copyArray: joined,
      totalItem: count,
      price: '$0',
      finalPrice: '$0',
      simple_price: '0',
      quantity: '',
      multiply: false,
      itemCode: '',
      barcodeNumber: '',
      discount: '',
      mainTotal: mainTotal,
      totalDiscount: totalDiscount,
      isSwitchOn: true,
      withoutTax: withoutTax
    });
    console.log('data array without change ', joined);
    return joined;
  };
  addItem = () => {
    this.setState(
      {
        addItem: true,
        visible: true,
        description: '',
        color: 'green',
        message: 'Artículo agregado con éxito'
      },
      () => {
        this.description.focus();
        setTimeout(() => {
          this.setState({
            visible: false
          });
        }, 2000);
      }
    );

    this.storeInArray();
  };
  updatedDataArray = newDataArray => {
    // let mainPriceTotal = 0;
    // let fawtTotal = 0;
    // let arrayLength = newDataArray.length - 1;
    // let lastTaxIndex = 0;
    // let difference = 0;
    // if (this.state.type === 2) {
    //     newDataArray.map((data, i) => {
    //         if (data.tax) {
    //             mainPriceTotal = mainPriceTotal + data.total;
    //             fawtTotal = fawtTotal + data.FAWT;
    //             lastTaxIndex = i;
    //         }
    //     });
    //     console.log('Before calculating', newDataArray);
    //     mainPriceTotal = Math.round(mainPriceTotal / 1.19);
    //     console.log(mainPriceTotal, fawtTotal, arrayLength);
    //     difference = mainPriceTotal - fawtTotal;
    //     console.log('difference', difference);
    //     if (difference > 0) {
    //         newDataArray.map((data, i) => {
    //             if (i === lastTaxIndex) {
    //                 newDataArray[i].FAWT = newDataArray[i].FAWT + difference;
    //                 newDataArray[i].iva = newDataArray[i].total - newDataArray[i].FAWT;
    //                 newDataArray[i].mainPrice = newDataArray[i].mainPrice + difference;
    //             }
    //         });
    //     }
    // }
    // console.log('modified data array', newDataArray);
    return newDataArray;
  };

  onBackPress = data => {
    console.log(
      '🚀 ~ file: Calculator.js ~ line 679 ~ Calculator ~ data',
      data
    );
    if (this.state.type !== undefined && this.state.type !== 4) {
      this.setState({ showCustomerScreen: false, calculatorReference: data });
    }

    if (this.state.type !== undefined && this.state.type === 4) {
      this.setState({
        showCustomerScreen: false,
        calculatorReference: data,
        isNextButtonClick: false
      });
    }
    // Type 4
    // bypassCustomerScreen: false,
    // showCustomerScreen: true

    // debit note
    // bypassCustomerScreen: true,
    // showCustomerScreen: true
  };

  applyDiscount = () => {
    if (
      this.state.discount ||
      this.state.isSwitchOn ||
      !this.state.isSwitchOn
    ) {
      let fPrice = this.state.price.substring(1);
      fPrice = fPrice.toString().replace(/\./g, '');

      let quantity = this.state.quantity.toString().replace(/\,/g, '.');
      if (!quantity) {
        quantity = '1';
      }
      fPrice = fPrice * parseFloat(quantity);

      let discount = this.state.discount;

      if (this.state.inPercentage) {
        discount = (parseInt(fPrice) * discount) / 100;
      }

      let newFPrice = fPrice - discount;

      let stateMainTotal = this.state.mainTotal;
      stateMainTotal = stateMainTotal.toString().replace(/\./g, '');

      // let newMainTotal = parseInt(stateMainTotal) - fPrice + newFPrice;

      newFPrice = Math.round(newFPrice);
      // newMainTotal = Math.round(newMainTotal);

      newFPrice = newFPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      // newMainTotal = newMainTotal
      //   .toString()
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      this.setState({
        isDiscountModelShow: false,
        finalPrice: '$' + newFPrice
      });
    } else {
      this.setState({ isDiscountModelShow: false });
    }
  };

  removeDiscount = () => {
    let price = this.state.price.substring(1);

    // alert(price);

    price = price.replace(/\./g, '');

    let quantity = this.state.quantity.replace(/\,/g, '.');

    if (!quantity) quantity = 1;

    let fPrice = parseFloat(quantity) * price;

    fPrice = fPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    this.setState({
      isDiscountModelShow: false,
      discount: '',
      finalPrice: '$' + fPrice
    });
  };
  autoFill = () => {
    // fetch(`${API_URL}items?filter=true&f_code=${this.state.description}`, { //commented at 2023-05-15
    fetch(
      `${API_URL}items?filter=true&o_code=${this.state.description}&o_barcodeNumber=${this.state.description}`,
      {
        method: 'GET',
        credentials: 'include'
      }
    )
      .then(res => res.json())
      .then(data => {
        let result = data['result'][0]['data'];
        if (result) {
          console.log(
            '🚀 ~ file: Calculator.js:943 ~ Calculator ~ .then ~ result:',
            result
          );
          let price = parseInt(result.price.replace(/\./g, ''));
          // let taxAmount = price * 0.19; // commented by harshit at 2024-08-05 for consider items amount with tax by default
          let taxAmount = 0;
          price = price + taxAmount;
          price = Math.round(price);
          console.log('price before add in discounted amount ', price);
          console.log(
            'discount before cal in autofill func',
            this.state.discount
          );

          let totalPrice = price * (this.state.quantity !== '' ? parseFloat(this.state.quantity) : 1);
          console.log('total price in autoFill', totalPrice)


          //  OLD LOGIC commented by harshit at 2024-07-09
          // let discountedAmount = this.state.inPercentage
          //   ? (price *
          //     (this.state.discount !== undefined ? this.state.discount : 0)) /
          //   100
          //   : this.state.discount !== undefined
          //     ? this.state.discount
          //     : 0;

          let discountedAmount = this.state.inPercentage
            ? (totalPrice *
              (this.state.discount !== undefined ? this.state.discount : 0)) /
            100
            : this.state.discount !== undefined
              ? this.state.discount
              : 0;

          console.log(
            '🚀 ~ file: Calculator.js:981 ~ Calculator ~ .then ~ discountedAmount:',
            discountedAmount
          );
          if (this.state.description !== '' && this.state.array.length > 0) {
            console.log('if condition in....');
            let matchedIndex = this.state.array.findIndex(
              i => i.itemCode === this.state.description
            );
            if (matchedIndex !== undefined && matchedIndex !== -1) {
              discountedAmount = this.state.array[matchedIndex]['discount'];
              discountedAmount = discountedAmount * (this.state.quantity !== '' ? parseFloat(this.state.quantity) : 1)
            }
          }


          console.log('discountAmount before add in discountPrice', discountedAmount);

          // let discountedPrice = Math.round(price - discountedAmount); // OLD LOGIC commented by harshit at 2024-07-09
          let discountedPrice = Math.round(totalPrice - discountedAmount);

          price = '$' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          discountedPrice =
            '$' +
            discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          console.log(
            `itemCode: ${this.state.description}, \n description: ${result.name}, \n price : ${price}, \n finalPrice : ${discountedPrice}`
          );
          this.setState({
            itemCode: result.code,
            description: result.name.toString(),
            price: price,
            barcodeNumber: result.barcodeNumber,
            finalPrice: discountedPrice
          });
          this.storeInArray().then(() => {
            this.setState(
              {
                addItem: true,
                visible: true,
                description: '',
                color: 'green',
                message: 'Artículo agregado con éxito'
              },
              () => {
                this.description.focus();
                setTimeout(() => {
                  this.setState({
                    visible: false
                  });
                }, 2000);
              }
            );
          });
        }
      })
      .catch(err => {
        //   console.log(err);
      });
  };
  parseNumberToFloat = value => {
    if (value) {
      value = value.replace('$', '');
      return parseFloat(value.replace(/\./g, ''));
    }
  };

  getItemCodeFromDescription(array) {
    array.map((item, index) => {
      console.log(
        '🚀 ~ file: Billing.js:1195 ~ Billing ~ array.map ~ item:',
        item.description
      );
      // fetch(`${API_URL}items?filter=true&f_name=${item.description}`, { // commented at 2023-05-15
      fetch(
        `${API_URL}items?filter=true&o_name=${item.description}&o_barcodeNumber=${item.description}`,
        {
          method: 'get',
          credentials: 'include'
        }
      )
        .then(res => res.json())
        .then(data => {
          console.log(
            '🚀 ~ file: Billing.js:1205 ~ Billing ~ .then ~ data:',
            data
          );
          if (data['result'] !== undefined && data['result'].length > 0) {
            console.log(
              '🚀 ~ file: Billing.js:1207 ~ Billing ~ .then ~ data:',
              data
            );
            let code =
              data['result'][0]['data']['code'] === undefined
                ? ''
                : data['result'][0]['data']['code'];
            console.log(
              '🚀 ~ file: Billing.js:1207 ~ Billing ~ .then ~ result:',
              code
            );
            let barcode =
              data['result'][0]['data']['barcodeNumber'] === undefined
                ? ''
                : data['result'][0]['data']['barcodeNumber'];
            console.log(
              '🚀 ~ file: Calculator.js:1284 ~ array.map ~ barcode:',
              barcode
            );
            item.itemCode = code;
            item.barcode = barcode;
          }
        })
        .catch(err => {
          console.log(
            '🚀 ~ file: Billing.js:1226 ~ Billing ~ API_URL ~ err:',
            err
          );
        });
    });
  }

  // barcode scan handle
  handleScan(scanData) {
    console.log(`handlescan data : ${scanData}`);
    fetch(`${API_URL}items?filter=true&f_barcodeNumber=${scanData}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then(data => {
        let result = data['result'][0]['data'];
        if (result) {
          console.log(
            '🚀 ~ file: Calculator.js:943 ~ Calculator ~ .then ~ result:',
            result
          );
          let price = parseInt(result.price.replace(/\./g, ''));
          // let taxAmount = price * 0.19; // commented by harshit at 2024-08-05 for consider items amount with tax by default
          let taxAmount = 0;
          price = price + taxAmount;
          price = Math.round(price);
          let discountedAmount = this.state.inPercentage
            ? (price *
              (this.state.discount !== undefined ? this.state.discount : 0)) /
            100
            : this.state.discount !== undefined
              ? this.state.discount
              : 0;
          if (scanData !== '' && this.state.array.length > 0) {
            console.log('handlescan func in if condition');
            let matchedIndex = this.state.array.findIndex(
              i => i.itemCode === result.code
            );
            console.log(
              '🚀 ~ file: Calculator.js:1317 ~ Calculator ~ handleScan ~ matchedIndex:',
              matchedIndex
            );
            if (matchedIndex !== undefined && matchedIndex !== -1) {
              console.log('handle scan function in get discount of exist item');
              discountedAmount = this.state.array[matchedIndex]['discount'];
            }
          }
          console.log(
            '🚀 ~ file: Calculator.js:981 ~ Calculator ~ .then ~ discountedAmount:',
            discountedAmount
          );
          let discountedPrice = Math.round(price - discountedAmount);
          price = '$' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          discountedPrice =
            '$' +
            discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          console.log(
            `itemCode: ${this.state.description}, \n description: ${result.name}, \n price : ${price}, \n finalPrice : ${price}, \n discountedPrice : ${discountedPrice}`
          );
          this.setState({
            itemCode: result.code,
            description: result.name,
            price: price,
            finalPrice: discountedPrice,
            barcodeNumber: result.barcodeNumber
          });
          this.storeInArray().then(() => {
            this.setState(
              {
                addItem: true,
                visible: true,
                description: '',
                color: 'green',
                message: 'Artículo agregado con éxito'
              },
              () => {
                // this.description.focus()
                setTimeout(() => {
                  this.setState({
                    visible: false
                  });
                }, 2000);
              }
            );
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  // barcode scan error handle
  handleError(err) {
    console.error('Error in barcode', err);
    // this.setState(
    //   {
    //     addItem: true,
    //     visible: true,
    //     description: '',
    //     color: 'red',
    //     message: 'Error al escanear el número de código de barras'
    //   },
    //   () => {
    //     // this.description.focus()
    //     setTimeout(() => {
    //       this.setState({
    //         visible: false
    //       });
    //     }, 2000);
    //   }
    // );
  }

  changeQuantityAfterAdd() {
    let oldArray = JSON.parse(JSON.stringify(this.state.copyArray))
    // let oldArray = [...this.state.copyArray];
    let isValid = false;
    let newRegex = /^[0-9]*$/;
    console.log(
      '🚀 ~ file: Calculator.js: ~ changeQuantityAfterAdd ~ oldArray:',
      oldArray
    );

    for (let index = 0; index < oldArray.length; index++) {
      const item = oldArray[index];
      console.log('🚀 ~ file: Calculator.js:1463 ~ oldArray.map ~ item:', item);
      console.log(
        '🚀 ~ file: Calculator.js:1463 ~ oldArray.map ~ item quantity:',
        item['quantity']
      );
      if (item['quantity'] === '' || !newRegex.test(item['quantity'])) {
        console.log('in if condition');
        isValid = false;
        this.setState({
          // recordErrors: 'Enter a valid quantity'
          recordErrors: 'Introduce una cantidad válida'
        });
        break;
      } else {
        isValid = true;
        this.setState({
          recordErrors: ''
        });
      }
    }

    if (isValid) {
      let newItemsDiscount = 0;
      let newItemsTotal = 0;
      for (let index = 0; index < oldArray.length; index++) {
        console.log('matched index ' + index)
        console.log(
          '🚀 ~ file: Calculator.js:1556 ~ changeQuantityAfterAdd ~ newItemsDiscount:',
          newItemsDiscount
        );
        let totalQuantity = parseInt(oldArray[index]['quantity']);
        console.log(
          '🚀 ~ file: Calculator.js:1417 ~ oldArray.map ~ totalQuantity:',
          totalQuantity
        );
        console.log(
          '🚀 ~ file: Calculator.js:1418:',
          oldArray[index]['mainPriceWithTax']
        );

        let discount = oldArray[index]['discount'];
        let newDiscount = oldArray[index]['totalDiscount'];
        let newQuantity = totalQuantity - this.state.array[index]['quantity'];
        newDiscount += oldArray[index]['percent']
          ? discount * newQuantity
          : 0;
        console.log('1571 newDiscount', newDiscount);
        let totalDiscount = oldArray[index]['percent']
          ? discount * totalQuantity
          : discount;
        console.log(
          '🚀 ~ file: Calculator.js:1497 ~ oldArray.map ~ totalDiscount:',
          totalDiscount
        );
        let fPrice = 0;
        let iva = 0;
        if (
          this.state.type === 2 ||
          this.state.type === 5 ||
          this.state.type === 4 ||
          this.state.type === 6
        ) {
          fPrice = oldArray[index]['mainPriceWithTax'] * totalQuantity;
        } else {
          fPrice = oldArray[index]['mainPrice'] * totalQuantity;
        }
        let AWT = oldArray[index]['tax'] ? fPrice / 1.19 : fPrice;

        console.log('this.state.discount', this.state.discount);
        console.log('test before set dwt', totalDiscount / 1.19);
        let DWT = oldArray[index]['tax']
          ? totalDiscount / 1.19
          : totalDiscount;
        console.log(
          '🚀 ~ file: Calculator.js:701 ~ Calculator ~ storeInArray= ~ DWT:',
          DWT
        );

        console.log('AWT : ' + AWT);
        console.log('DWT : ' + DWT);

        let FAWT = AWT - DWT;

        if (
          this.state.type === 2 ||
          this.state.type === 5 ||
          this.state.type === 4 ||
          this.state.type === 6
        ) {
          FAWT = oldArray[index]['mainPrice'] * totalQuantity;
          iva = oldArray[index]['mainPriceWithTax'] * totalQuantity - FAWT;
          iva = Math.round(iva)
        } else {
          iva = FAWT * 0.19;
        }

        oldArray[index]['FAWT'] = this.roundWithTwoDecimals(FAWT);
        oldArray[index]['total'] = Math.round(fPrice - totalDiscount);
        oldArray[index]['totalDiscount'] = totalDiscount + newItemsDiscount;
        oldArray[index]['iva'] = iva;
        oldArray[index]['quantity'] = totalQuantity;
        newItemsDiscount += totalDiscount;
        newItemsTotal += Math.round(fPrice - totalDiscount);
      }

      console.log(
        '🚀 ~ file: Calculator.js:1415 ~ changeQuantityAfterAdd ~ oldArray:',
        oldArray
      );
      newItemsTotal = newItemsTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      this.setState({
        array: oldArray,
        isEditRecord: false,
        copyArray: oldArray,
        totalDiscount: newItemsDiscount,
        mainTotal: newItemsTotal
      });
    }
  }

  render() {
    console.log('render initiated..')
    console.log('propinaChecked ---', this.state.propinaChecked);
    console.log('isNextButtonClick : ', this.state.isNextButtonClick);
    console.log('totalDiscount', this.state.totalDiscount);
    console.log('render this.state.array', this.state.array);
    console.log('render this.state.copyArray', this.state.copyArray);
    console.log('props.editProductData', this.props.editProductData);
    console.log('props.editProductFromTransaction', this.props.editProductFromTransaction);
    console.log('quantity', this.state.quantity);
    console.log('price', this.state.price)
    console.log('finalPrice', this.state.finalPrice)

    return (
      <>
        {!this.state.showCustomerScreen && (
          <>
            <div className={!this.state.isNextButtonClick ? '' : 'd-none'}>
              <div className="container">
                <div className="row">
                  <div
                    className="col-10"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalCenter2"
                    onClick={() => {
                      this.setState({
                        isEditRecord: false,
                        recordErrors: '',
                        copyArray: this.state.array
                      });
                    }}
                  >
                    <p style={{ fontSize: 20 }} className="icon-general-style">
                      {this.state.array.length > 0
                        ? 'Total items: ' +
                        this.state.array.length +
                        ' = $' +
                        this.state.mainTotal
                        : ''}
                    </p>
                  </div>
                  <div
                    className={this.state.type === 1 ? 'col-2' : 'd-none'}
                    onClick={() => {
                      if (
                        (this.state.finalPrice !== '$0' &&
                          this.state.description !== '') ||
                        this.state.array.length > 0
                      ) {
                        this.storeInArray().then(array => {
                          array = this.updatedDataArray(array);
                          let amount = this.parseNumberToFloat(
                            this.state.mainTotal
                          );
                          this.validateAmount(amount, array, [], true);
                          // this.setState({
                          //     data: array,
                          //     payable: this.state.mainTotal,
                          //     finalPrice: this.state.mainTotal,
                          //     total: '0',
                          // }, () => {
                          //     this.setState({
                          //         bypassCustomerScreen: true,
                          //         showCustomerScreen: true
                          //     })
                          // })
                        });
                      } else {
                        this.setState(
                          {
                            visible_error: true,
                            message_error: 'Fill required data.'
                          },
                          () => {
                            setTimeout(() => {
                              this.setState({
                                visible_error: false,
                                message_error: ''
                              });
                            }, 2000);
                          }
                        );
                      }
                    }}
                  >
                    <i
                      class="material-icons icon-general-style"
                      style={{
                        fontSize: 26,
                        fontWeight: 'bold',
                        color: 'black'
                      }}
                    >
                      print
                    </i>
                  </div>
                </div>
              </div>
              <div className="my-5">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <input
                        type="text"
                        style={{
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          height: 40
                        }}
                        className="hide-input-border"
                        // ref={this.description}
                        ref={input => {
                          this.description = input;
                        }}
                        placeholder="Item description"
                        value={this.state.description}
                        maxLength="20"
                        onChange={text =>
                          this.setState({ description: text.target.value })
                        }
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            this.autoFill();
                          }
                        }}
                      />
                    </div>
                    <div
                      className="col-6"
                      style={{
                        textAlign: 'right',
                        fontWeight: 'bold',
                        fontSize: this.props.type === 2 ? 25 : 30,
                        color: 'black'
                      }}
                    >
                      {' '}
                      {this.state.finalPrice.length <
                        this.state.titleDigitLength
                        ? `${this.state.finalPrice}`
                        : `${this.state.finalPrice.substring(
                          0,
                          this.state.titleSubstringEndLength
                        )}...`}
                    </div>
                  </div>
                </div>

                <div className="container mt-4">
                  <div className="row">
                    <div className="col-6 d-flex justify-content-end align-items-center">
                      <input
                        type="text"
                        style={{
                          borderWidth: 0,
                          borderBottomWidth: this.state.multiply ? 1 : 0,
                          fontSize: 26,
                          fontWeight: 'bold',
                          width: 50,
                          textAlign: 'center',
                          height: 40
                        }}
                        className="hide-input-border"
                        value={
                          this.state.quantity === '' ? '1' : this.state.quantity
                        }
                        onClick={() => this.setState({ multiply: true })}
                      />
                      <p className="col-2" style={{ fontSize: 20 }}>
                        X
                      </p>
                      <i
                        class="material-icons icon-general-style col-2"
                        style={{ fontSize: 26, fontWeight: 'bold' }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCenter"
                      >
                        info
                      </i>
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        style={{
                          borderWidth: 0,
                          borderBottomWidth: this.state.multiply ? 0 : 1,
                          height: 40,
                          fontSize: 26
                        }}
                        className="hide-input-border"
                        value={this.state.price}
                        onClick={() => this.setState({ multiply: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={this.state.isNextButtonClick ? '' : 'd-none'}>
              <div className="container my-4">
                <div className="row">
                  <div className="col-6">
                    <p style={{ fontSize: 16 }}>Ingresar el total</p>
                    <p>Pagado por el cliente:</p>
                  </div>
                  <div className="col-6">
                    <select
                      className="form-control"
                      onChange={e => {
                        this.setState({
                          paymentMode: e.target.value,
                          finalPrice: this.state.payable,
                          total: '0',
                          simple_price: ''
                        });
                      }}
                    >
                      <option value={strings.cash}>{strings.cash}</option>
                      <option value={strings.card}>{strings.card}</option>
                      <option value={strings.credit}>{strings.credit}</option>
                      <option value={strings.transferencia}>
                        {strings.transferencia}
                      </option>
                      <option value={strings.credito}>{strings.credito}</option>
                    </select>
                  </div>
                </div>
                {this.state.showPermissionForPropina && (
                  <div className="mt-2">
                    <div className="d-flex justify-content-end align">
                      <p>PROPINA SUGERIDA {this.state.propinaPercentage}</p>
                      <Form className="mx-3">
                        <Form.Check
                          type="switch"
                          checked={this.state.propinaChecked}
                          id="custom-switch"
                          onClick={e => {
                            let value = e.target.checked;
                            this.setState({
                              propinaChecked: !this.state.propinaChecked
                            });
                            let payableAmount = this.state.originalPayable.replace(
                              /\./g,
                              ''
                            );
                            let totalPrice = parseInt(payableAmount);
                            let tip = 0;
                            let storeTip = '';
                            if (value === true) {
                              tip =
                                (totalPrice * this.state.propinaPercentage) /
                                100;
                              tip = Math.ceil(tip);
                              storeTip = tip;
                              console.log(
                                'storetip is in if block is',
                                storeTip
                              );
                            }
                            console.log('storeTip', storeTip);
                            let totalAmount = (totalPrice = totalPrice + tip);
                            this.setState({ propinaAmount: storeTip });

                            totalAmount = Math.ceil(totalAmount);
                            let total = totalAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                            console.log('total is ------------------', total);
                            this.setState({
                              payable: total,
                              finalPrice: total
                            });
                          }}
                        />
                      </Form>
                    </div>
                  </div>
                )}
              </div>

              <div className="container my-4">
                <div className="row">
                  <div className="col-12">
                    <p
                      className="final-price"
                      style={{ fontWeight: 'bold', fontSize: 26 }}
                    >
                      ${this.state.finalPrice || '0'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="container mt-4">
                <div className="row">
                  <div className="col-12 d-flex">
                    <p
                      className="col-3"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {strings.value}:
                    </p>
                    <p
                      className="col-8"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {' '}
                      ${this.state.total}
                    </p>
                  </div>
                </div>
              </div>
              <div className="container mb-4">
                <div className="row">
                  <div className="col-12 d-flex">
                    <p
                      className="col-3"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {strings.total} :
                    </p>
                    <p
                      className="col-8"
                      style={{ fontWeight: 'bold', fontSize: 20 }}
                    >
                      {' '}
                      ${this.state.payable}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row">
                <div class="col-3">
                  <CustomButton
                    text="7"
                    backgroundColor="gray"
                    onClick={() => this.handleTap('number', '7')}
                    disabled={false}
                  />
                </div>
                <div class="col-3">
                  <CustomButton
                    text="8"
                    backgroundColor="gray"
                    onClick={() => this.handleTap('number', '8')}
                    disabled={false}
                  />
                </div>
                <div class="col-3">
                  <CustomButton
                    text="9"
                    backgroundColor="gray"
                    onClick={() => this.handleTap('number', '9')}
                    disabled={false}
                  />
                </div>
                <div class="col-3">
                  <CustomButton
                    text="C"
                    backgroundColor="red"
                    onClick={() => this.handleTap('clear', '')}
                    disabled={false}
                  />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-3">
                  <CustomButton
                    text="4"
                    backgroundColor="gray"
                    onClick={() => this.handleTap('number', '4')}
                    disabled={false}
                  />
                </div>
                <div class="col-3">
                  <CustomButton
                    text="5"
                    backgroundColor="gray"
                    onClick={() => this.handleTap('number', '5')}
                    disabled={false}
                  />
                </div>
                <div class="col-3">
                  <CustomButton
                    text="6"
                    backgroundColor="gray"
                    onClick={() => this.handleTap('number', '6')}
                    disabled={false}
                  />
                </div>
                <div class="col-3">
                  <CustomButton
                    text="X"
                    backgroundColor="#f09a36"
                    disabled={this.state.isNextButtonClick}
                    underLine={this.state.multiply}
                    onClick={() => this.handleTap('multiply', '')}
                  />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-9">
                  <div class="row ">
                    <div class="col-4">
                      <CustomButton
                        text="1"
                        backgroundColor="gray"
                        onClick={() => this.handleTap('number', '1')}
                        disabled={false}
                      />
                    </div>
                    <div class="col-4">
                      <CustomButton
                        text="2"
                        backgroundColor="gray"
                        onClick={() => this.handleTap('number', '2')}
                        disabled={false}
                      />
                    </div>
                    <div class="col-4">
                      <CustomButton
                        text="3"
                        backgroundColor="gray"
                        onClick={() => this.handleTap('number', '3')}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div class="row mt-2 ">
                    <div class="col-4">
                      <CustomButton
                        text="0"
                        backgroundColor="gray"
                        onClick={() => this.handleTap('number', '0')}
                        disabled={false}
                      />
                    </div>
                    <div class="col-4">
                      <CustomButton
                        text=","
                        backgroundColor="gray"
                        onClick={() => this.handleTap('number', ',')}
                        disabled={this.state.multiply ? false : true}
                      />
                    </div>
                    <div class="col-4 icon-general-style">
                      <div
                        style={{
                          width: 80,
                          height: 80,
                          backgroundColor: 'gray',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: 20
                        }}
                        onClick={() => this.handleTap('remove')}
                      >
                        <i class="material-icons icon-general-style">
                          backspace
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <CustomButton
                    text={strings.add_item}
                    backgroundColor="#f09a36"
                    width={80}
                    height={168}
                    disabled={
                      this.state.type !== undefined && this.state.type === 4
                        ? this.state.description === '' ||
                          this.state.isNextButtonClick
                          ? true
                          : false
                        : this.state.finalPrice === '$0' ||
                          this.state.description === '' ||
                          this.state.isNextButtonClick
                          ? true
                          : false
                    }
                    onClick={() => this.addItem()}
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class={this.state.isNextButtonClick ? 'd-none' : 'col-6'}>
                  <CustomButton
                    text={strings.item_desc}
                    backgroundColor="#2e209b"
                    width={170}
                    height={60}
                    onClick={() => this.description.current.focus()}
                  />
                </div>
                <div class={!this.state.isNextButtonClick ? 'd-none' : 'col-6'}>
                  <CustomButton
                    text={strings.back}
                    backgroundColor="#2e209b"
                    width={170}
                    height={60}
                    onClick={() => {
                      this.clearData();
                      this.setState({
                        isNextButtonClick: false,
                        calculatorBillingsReference: this.state
                          .calculatorReference
                      });
                    }}
                  />
                </div>
                <div class="col-6">
                  <CustomButton
                    isLoading={this.state.isLoading}
                    text={strings.next}
                    backgroundColor="#2e209b"
                    width={170}
                    height={60}
                    disabled={
                      !this.state.isNextButtonClick
                        ? (this.state.finalPrice !== '$0' &&
                          this.state.description !== '') ||
                          this.state.array.length > 0
                          ? false
                          : true
                        : this.state.finalPrice === '' ||
                          this.state.finalPrice === '0' ||
                          parseInt(this.state.total.replace(/\./g, '')) < 0
                          ? true
                          : false
                    }
                    onClick={() => {
                      let newRegex = /^[0-9a-zA-Z ]*$/;
                      this.setState({ isLoading: true });
                      if (this.state.isNextButtonClick) {
                        console.log('clicked on first if');
                        this.setState({
                          bypassCustomerScreen: false,
                          showCustomerScreen: true,
                          isLoading: false
                        });

                        // console.log('Customer screen data web......', {
                        //     dataArray: this.state.data,
                        //     totalPaid: this.state.finalPrice,
                        //     mainPrice: this.state.mainPrice,
                        //     tax: undefined,
                        //     total: this.state.payable,
                        //     return: this.state.total,
                        //     paymentMode: this.state.paymentMode,
                        //     totalDiscount: this.state.totalDiscount,
                        //     type: this.state.type,
                        //     withoutTax: this.state.withoutTax,
                        //     type: this.state.type
                        // })
                      } else if (!this.state.isNextButtonClick) {
                        console.log('in else if');
                        // this if block called when user input value after that click on add items after than click next
                        if (this.state.finalPrice === '$0') {
                          console.log('if block');
                          if (
                            this.state.description !== '' &&
                            !newRegex.test(this.state.description)
                          ) {
                            this.setState(
                              {
                                visible_error: true,
                                message_error:
                                  'Ingrese una descripción válida, solo permite letras y números',
                                isLoading: false
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({
                                    visible_error: false,
                                    message_error: ''
                                  });
                                }, 2000);
                              }
                            );
                          } else {
                            let array = this.updatedDataArray(this.state.array);
                            console.log(
                              'in if block in else block main total is',
                              this.state.mainTotal
                            );
                            this.getItemCodeFromDescription(array);
                            let amount = this.parseNumberToFloat(
                              this.state.mainTotal
                            );
                            if (
                              this.state.type !== undefined &&
                              this.state.type === 5 &&
                              this.state.transactionDocumentId !== undefined &&
                              this.state.transactionDocumentId !== '' &&
                              this.state.transactionDocumentType !==
                              undefined &&
                              this.state.transactionDocumentType !== '' &&
                              this.state.transactionDocumentType === 'invoices'
                            ) {
                              this.validateAmount(amount, array, [], true);
                            } else {
                              this.validateAmount(
                                amount,
                                array,
                                this.state.calculatorBillingsReference
                              );
                            }
                            // this.setState({
                            //     data: array,
                            //     payable: this.state.mainTotal,
                            //     originalPayable: this.state.mainTotal,
                            //     finalPrice: this.state.mainTotal,
                            //     total: '0',
                            // }, () => {

                            //     this.setState({
                            //         isNextButtonClick: true,
                            //         propinaChecked:false,
                            //         calculatorReference: this.state.calculatorBillingsReference
                            //     })
                            // })
                          }
                          // this else block called when user filled input value after that click on click next button
                        } else {
                          console.log(
                            'in else description is ',
                            this.state.description,
                            newRegex.test(this.state.description)
                          );
                          if (!newRegex.test(this.state.description)) {
                            this.setState(
                              {
                                visible_error: true,
                                message_error:
                                  'Ingrese una descripción válida, solo permite letras y números',
                                isLoading: false
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({
                                    visible_error: false,
                                    message_error: ''
                                  });
                                }, 2000);
                              }
                            );
                          } else {
                            this.storeInArray().then(array => {
                              console.log(
                                'is else block mainTotal',
                                this.state.mainTotal
                              );
                              let amount = this.parseNumberToFloat(
                                this.state.mainTotal
                              );
                              console.log('after convert amount', amount);
                              console.log(
                                'after convert amount type',
                                typeof amount
                              );
                              array = this.updatedDataArray(array);
                              this.getItemCodeFromDescription(array);
                              if (
                                this.state.type !== undefined &&
                                this.state.type === 5 &&
                                this.state.transactionDocumentId !==
                                undefined &&
                                this.state.transactionDocumentId !== '' &&
                                this.state.transactionDocumentType !==
                                undefined &&
                                this.state.transactionDocumentType !== '' &&
                                this.state.transactionDocumentType ===
                                'invoices'
                              ) {
                                this.validateAmount(amount, array, [], true);
                              } else {
                                this.validateAmount(amount, array, []);
                              }
                              // this.setState({
                              //     data: array,
                              //     payable: this.state.mainTotal,
                              //     originalPayable: this.state.mainTotal,
                              //     finalPrice: this.state.mainTotal,
                              //     total: '0',
                              // }, () => {
                              //     this.setState({
                              //         isNextButtonClick: true,
                              //         propinaChecked:false,
                              //         calculatorReference: [],
                              //     })
                              // })
                            });
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <>
                      <FormGroup className="col-12">
                        <InputGroup seamless>
                          <FormInput
                            value={this.state.discount}
                            maxLength={this.state.inPercentage ? 2 : 9999}
                            placeholder={
                              this.state.inPercentage
                                ? strings.discount_pr
                                : strings.discount_dollar
                            }
                            onChange={text =>
                              this.setState({ discount: text.target.value })
                            }
                          />
                          <InputGroupAddon
                            type="append"
                            className="d-flex justify-content-center mt-1"
                          >
                            <InputGroupText>
                              <div
                                onClick={() => {
                                  this.setState({
                                    inPercentage: !this.state.inPercentage
                                  });
                                }}
                              >
                                <i
                                  style={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    color: 'black'
                                  }}
                                  className="material-icons"
                                >
                                  {this.state.inPercentage
                                    ? 'percent'
                                    : 'attach_money'}
                                </i>
                                <i
                                  style={{ fontSize: 14, color: 'black' }}
                                  className="material-icons"
                                >
                                  {!this.state.inPercentage
                                    ? 'percent'
                                    : 'attach_money'}
                                </i>
                              </div>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                      <FormSelect
                        placeholder="Seleccionar"
                        value={this.state.itemType}
                        onChange={item => {
                          this.setState({
                            itemType: item.target.value
                          });
                        }}
                      >
                        <option value="" disabled>
                          Seleccionar
                        </option>
                        <option value="Litro">Litro</option>
                        <option value="KG">KG</option>
                        <option value="Unidad">Unidad</option>
                      </FormSelect>
                      <div className="d-flex mt-3">
                        <p className="">IVA :</p>
                        <div>
                          <Form style={{ marginLeft: 15 }}>
                            <Form.Check
                              type="switch"
                              className="form-switch-md"
                              checked={this.state.isSwitchOn}
                              disabled={
                                (this.state.type !== undefined &&
                                  this.state.type === 4) ||
                                  (this.state.type === 5 &&
                                    this.state.transactionDocumentId !==
                                    undefined &&
                                    this.state.transactionDocumentId !== '' &&
                                    this.state.transactionDocumentType !==
                                    undefined &&
                                    this.state.transactionDocumentType !== '' &&
                                    this.state.transactionDocumentType ===
                                    'invoices')
                                  ? true
                                  : false
                              }
                              onChange={() => this.onToggleSwitch()}
                            />
                          </Form>
                        </div>
                      </div>
                    </>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => this.removeDiscount()}
                    >
                      Borrar
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => this.applyDiscount()}
                    >
                      Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="exampleModalCenter2"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              data-keyboard="false"
              data-backdrop="static"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        console.log('clicked on close button');
                        console.log('or this.state.array', this.state.array);
                        console.log(
                          'this.state.coppyarray',
                          this.state.copyArray
                        );
                        this.setState({
                          copyArray: this.state.array,
                          recordErrors: '',
                          isEditRecord: false
                        });
                      }}
                    ></button>
                  </div>
                  <div class="modal-body">
                    <>
                      {this.state.recordErrors && (
                        <p
                          style={{
                            fontSize: 20,
                            fontWeight: '400',
                            color: 'red'
                          }}
                        >
                          {this.state.recordErrors}
                        </p>
                      )}
                      {this.state.copyArray.length > 0 ? (
                        this.state.copyArray.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="row mt-2 align-items-center"
                              >
                                <div className="col-3">
                                  <p
                                    style={{
                                      fontSize: 20,
                                      fontWeight: '400'
                                    }}
                                  >
                                    {item.description.length <= 5
                                      ? `${item.description}`
                                      : `${item.description.substring(
                                        0,
                                        4
                                      )}...`}
                                    {item.discount ? (
                                      <p
                                        style={{
                                          color: 'red',
                                          fontSize: 13
                                        }}
                                      >
                                        {' '}
                                        (-{item.discount}
                                        {item.percent ? '%' : '$'})
                                      </p>
                                    ) : null}
                                  </p>
                                </div>
                                <div class="col-8">
                                  <p style={{}}>
                                    {/* Below commented at 2023-03-28 */}
                                    {/* {item.quantity} X ${item.mainPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} */}
                                    {/* {item.quantity} X $ */}
                                    <input
                                      onChange={e => {
                                        console.log('22', e.target.value);
                                        // let newArrays = [...this.state.copyArray];
                                        let newArrays = JSON.parse(
                                          JSON.stringify(this.state.copyArray)
                                        );
                                        newArrays[index]['quantity'] =
                                          e.target.value;
                                        this.setState({
                                          copyArray: newArrays
                                        });
                                      }}
                                      value={item.quantity}
                                      type="number"
                                      style={{
                                        height: 40,
                                        width: 70,
                                        fontSize: 14,
                                        color: 'black',
                                        backgroundColor: 'white',
                                        border: this.state.isEditRecord
                                          ? '1px solid black'
                                          : '1px solid white',
                                        marginRight: 2,
                                        textAlign: 'center',
                                        fontWeight: 400
                                      }}
                                      disabled={
                                        !this.state.isEditRecord ? true : false
                                      }
                                    />
                                    X $
                                    {item.mainPrice
                                      ? item.mainPrice
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                                      : ''}
                                    = $
                                    {item.total
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                  </p>
                                </div>
                                <div class="col-1">
                                  <div
                                    onClick={() =>
                                      // this.removeItem(
                                      //   index,
                                      //   item.total,
                                      //   item.discount,
                                      //   item.percent,
                                      //   item.mainPrice
                                      // )
                                      this.removeItem(item, index)
                                    }
                                  >
                                    <i
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        color: 'black'
                                      }}
                                      className="material-icons icon-general-style"
                                    >
                                      delete
                                    </i>
                                  </div>
                                </div>
                              </div>
                              {item.tax && (
                                <>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      color: 'tomato'
                                    }}
                                  >
                                    + 19% Tax
                                  </p>
                                </>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <p style={{ textAlign: 'center' }}>
                          No item available{' '}
                        </p>
                      )}
                    </>
                  </div>
                  <div class="modal-footer d-flex justify-content-between align-items-center">
                    <button
                      type="button"
                      class="btn btn-primary"
                      style={{
                        textTransform: 'uppercase'
                      }}
                      onClick={() => {
                        console.log('1');
                        if (this.state.isEditRecord) {
                          this.changeQuantityAfterAdd();
                        } else {
                          this.setState({
                            isEditRecord: !this.state.isEditRecord
                          });
                        }
                      }}
                    >
                      {/* {this.state.isEditRecord ? 'save' : 'edit'} */}
                      {this.state.isEditRecord ? 'guardar' : 'editar'}
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => this.clearAll()}
                    >
                      BORRAR TODO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.showCustomerScreen && (
          <Customer
            // onBack={this.onBackPress()}
            onBack={this.onBackPress}
            // onBack={(data) => {
            //   this.setState({ showCustomerScreen: false, calculatorReference:data })
            // }}
            dataArray={this.state.data}
            calculatorReference={this.state.calculatorReference}
            totalPaid={this.state.finalPrice}
            mainPrice={this.state.mainPrice}
            tax={undefined}
            total={this.state.payable}
            return={this.state.total}
            paymentMode={this.state.paymentMode}
            totalDiscount={this.state.totalDiscount}
            type={this.state.type}
            withoutTax={this.state.withoutTax}
            propinaAmount={
              this.state.bypassCustomerScreen ? 0 : this.state.propinaAmount
            }
            propinaChecked={
              this.state.bypassCustomerScreen
                ? false
                : this.state.propinaChecked
            }
            propinaPercentage={
              this.state.bypassCustomerScreen
                ? 10
                : this.state.propinaPercentage
            }
            showPermissionForPropina={this.state.showPermissionForPropina}
            // type={this.state.type}
            closeAll={() => {
              this.setState({
                showCustomerScreen: false,
                isNextButtonClick: false,
                description: ''
              });
              this.clearAll();
            }}
            onBackWhenDirectPrint={() => {
              this.setState({ showCustomerScreen: false, finalPrice: '$0' });
            }}
            bypassCustomerScreen={this.state.bypassCustomerScreen}
            transactionDocumentId={this.state.transactionDocumentId}
            transactionDocumentType={this.state.transactionDocumentType}
            editProductData={this.state.editProductData}
            editProductFromTransaction={this.state.editProductFromTransaction}
          />
        )}

        <Alert
          open={this.state.visible}
          theme="success"
          style={{
            position: 'fixed',
            bottom: 10,
            width: '80%'
          }}
        >
          {this.state.message}
        </Alert>
        <Alert
          open={this.state.visible_error}
          theme="danger"
          style={{
            position: 'fixed',
            bottom: 10
            // width: '80%',
          }}
        >
          {this.state.message_error}
        </Alert>
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
      </>
    );
  }
}

export default Calculator;
