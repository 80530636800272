import React, { Component } from 'react';
import { strings } from './../../localization';
import { Language, API_URL, LIMIT, emailRegxPattern } from './../../config';
import { FormInput, FormTextarea, Button, Alert, string } from 'shards-react';
import SampleInvoice from './SampleInvoice';
import AgregarReferencias from './AgregarReferencias';
import TransportData from './TransportData';
export class Customer extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);
    console.log('props in customer screen', props)
    this.state = {
      number: '',
      giroRecep: '',
      email: '',
      name: '',
      id: '',
      addr1: '',
      addr2: '',
      state: '',
      country: '',
      contact: '',
      b_details: '',
      cookie: '',
      city: '',
      loading: false,
      isFetched: false,
      visible: false,
      sign: '',
      visible: false,
      showSampleInvoiceScreen: false,
      showAgregarReferenciasScreen: false,
      showTransportData: false,
      transportData: [],
      references: [],
      transactionDocumentId: '',
      transactionDocumentType: '',
      editProductData: [],
      editProductFromTransaction: false,
    };
  }
  componentDidMount() {
    console.log('references array in commentDidmount is', this.state.references);
    console.log('showAgregarReferenciasScreen commentDidmount state is', this.state.showAgregarReferenciasScreen)
    console.log('props from calculator is', this.props)
    if (this.props.calculatorReference !== undefined && this.props.calculatorReference.length > 0) {
      this.setState({ references: this.props.calculatorReference })
    } else {
      this.setState({ references: [] })
    }

    const param = this.props;
    if (param !== undefined) {
      if (param.dataArray !== undefined) {
        let sign = '';

        if (param.type !== 1) {
          sign = ' *';
        }
        this.setState({
          dataArray: param.dataArray,
          withoutTax: param.withoutTax,
          totalPaid: param.totalPaid,
          mainPrice: param.mainPrice,
          type: param.type,
          tax: param.tax,
          total: param.total,
          return: param.return,
          paymentMode: param.paymentMode,
          totalDiscount: param.totalDiscount,
          propinaAmount: param.propinaAmount,
          propinaChecked: param.propinaChecked,
          propinaPercentage: param.propinaPercentage,
          showPermissionForPropina: param.showPermissionForPropina,
          number: '',
          name: '',
          addr1: '',
          addr2: '',
          city: '',
          state: '',
          country: '',
          email: '',
          zipcode: '',
          comment: '',
          contact: '',
          b_details: '',
          isFetched: false,
          sign: sign,
          transactionDocumentId: param.transactionDocumentId,
          transactionDocumentType: param.transactionDocumentType
        }, () => {
          this.setState({
            showSampleInvoiceScreen: param.bypassCustomerScreen,
          })
        });
        // param.dataArray = undefined;
      }
      if (param.editProductFromTransaction !== undefined && param.editProductData !== undefined && param.editProductFromTransaction) {
        this.setState({
          editProductData: param.editProductData,
          editProductFromTransaction: param.editProductFromTransaction,
          number: param.editProductData.data.rutNumber
        },
          () => this.autoFill()
        )
      }
    }
  }
  handleClick = () => {
    this.setState({
      loading: true
    });

    let bodyData = {
      fullName: this.state.name,
      email: this.state.email,
      addr1: this.state.addr1,
      addr2: this.state.addr2,
      state: this.state.state,
      city: this.state.city,
      country: this.state.country,
      phone: this.state.contact,
      bDetails: this.state.b_details
    };

    if (this.state.id === '') {
      bodyData.rutNumber = this.state.number;
    }
    bodyData = JSON.stringify(bodyData);
    if (this.state.number !== '') {
      console.log(`${API_URL}customers${this.state.id}`);
      fetch(`${API_URL}customers${this.state.id}`, {
        method: this.state.id !== '' ? 'put' : 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: bodyData
      })
        .then(res => {
          if (res.status == 200) {
            this.setState({ loading: false, showSampleInvoiceScreen: true });
          }
          return res.json();
        })
        .then(res => {
          console.warn('API response in handleClick', res);
          if (res.message) {
            this.setState(
              {
                message: res.message,
                visible: true,
                loading: false
              },
              () => {
                setTimeout(() => {
                  this.setState({ visible: false });
                }, 2000);
              }
            );
          }
        })
        .catch(err => {
          this.setState({
            loading: false
          });
          console.warn('API error in handleClick', err);
        });
    } else {
      this.setState({
        loading: false,
        showSampleInvoiceScreen: true
      });
    }
  };
  updateNumber = value => {
    value = value.toString();
    let status = value.includes('-');
    if (status === false) {
      value =
        value.substr(0, value.length - 1) +
        '-' +
        value.substr(value.length - 1);
    }
    return value;
  };

  autoFill = () => {
    let number = this.updateNumber(this.state.number);
    this.setState({
      isFetched: true,
      number: number
    });

    console.log(`${API_URL}customers?filter=true&f_rutNumber=${number}`);
    fetch(`${API_URL}customers?filter=true&f_rutNumber=${number}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        let result = res.result;
        console.log('API response', res);
        // console.log(result.length);

        if (result.length > 0) {
          let id = result[0]._id;
          result = result[0]['data'];
          this.setState({
            id: '/' + id,
            name: result.fullName,
            email: result.email,
            addr1: result.addr1,
            addr2: result.addr2,
            state: result.state,
            city: result.city,
            country: result.country,
            contact: result.phone,
            b_details: result.bDetails,
            isFetched: false
          });
        } else {
          this.setState({
            isFetched: false,
            id: ''
          });
        }
      })
      .catch(err => {
        // console.log(err);
        this.setState({
          isFetched: false,
          id: ''
        });
      });
  };

  saveReferencias = (data) => {
    console.log('Referencias saved', data);
    this.setState({ references: data, showAgregarReferenciasScreen: false });
  };

  saveTransportData = (data) => {
    console.log("🚀 ~ file: Customer.js:244 ~ Customer ~ data:", data)
    this.setState({ transportData: data, showTransportData: false });
  }

  render() {
    console.log('references array in render method is', this.state.references);
    console.log('showAgregarReferenciasScreen in render method state is', this.state.showAgregarReferenciasScreen)
    return !this.state.showSampleInvoiceScreen ? (
      !this.state.showAgregarReferenciasScreen && !this.state.showTransportData ? (
        <div>
          {/* <FormInput /> */}
          <FormInput
            size="lg"
            placeholder={strings.comment}
            value={this.state.comment}
            onChange={text => this.setState({ comment: text.target.value })}
          />

          <p
            style={{ textAlign: 'center', fontSize: 22, paddingVertical: 8 }}
            className="mt-4"
            size="lg"
          >
            Información de cliente
          </p>

          <div class="inputcontainer">
            <FormInput
              className="mt-4"
              size="lg"
              placeholder={strings.rut_number + this.state.sign}
              value={this.state.number}
              onChange={text => {
                text = text.target.value.replace(/[^a-zA-Z0-9]/g, '');
                this.setState({ number: text });
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.autoFill();
                }
              }}
              onBlur={() => {
                this.autoFill();
              }}
            />
            {this.state.isFetched && (
              <div class="icon-container">
                <i class="loader"></i>
              </div>
            )}
          </div>
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={strings.name + this.state.sign}
            value={this.state.name}
            onChange={text => this.setState({ name: text.target.value })}
          />
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={'Correo electrónico'}
            value={this.state.email}
            onChange={text => this.setState({ email: text.target.value })}
          />
          <FormTextarea
            className="mt-4"
            size="lg"
            placeholder={strings.addr_1 + this.state.sign}
            value={this.state.addr1}
            onChange={text => this.setState({ addr1: text.target.value })}
          />
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={strings.addr_2 + this.state.sign}
            value={this.state.addr2}
            onChange={text => this.setState({ addr2: text.target.value })}
          />
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={'Ciudad ' + this.state.sign}
            value={this.state.city}
            onChange={text => this.setState({ city: text.target.value })}
          />
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={strings.state + this.state.sign}
            value={this.state.state}
            onChange={text => this.setState({ state: text.target.value })}
          />
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={strings.country}
            value={this.state.country}
            onChange={text => this.setState({ country: text.target.value })}
          />
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={'Nombre de contacto ' + this.state.sign}
            value={this.state.contact}
            onChange={text => this.setState({ contact: text.target.value })}
          />
          <FormInput
            className="mt-4"
            size="lg"
            placeholder={strings.b_details + this.state.sign}
            value={this.state.b_details}
            onChange={text => this.setState({ b_details: text.target.value })}
          />
          {this.state.type !== undefined && this.state.type !== 1 && this.state.type !== 6 &&
            <div className=" mb-2 d-flex justify-content-center">
              <Button
                pill
                disabled={this.state.type === 2 || this.state.type === 4 ? false : true}
                size="lg"
                className="mt-4 mr-2"
                style={{
                  width: '100%',
                  alignSelf: 'center'
                }}
                onClick={() => {
                  this.setState({ showAgregarReferenciasScreen: true });
                }}
              >
                Agregar Referencias
              </Button>
            </div>
          }
          {this.state.type !== undefined && this.state.type !== 1 && this.state.type !== 2 && this.state.type !== 6 &&
            <div className=" mb-2 d-flex justify-content-center">
              <Button
                pill
                disabled={this.state.type === 4 ? false : true}
                size="lg"
                className="mt-4 mr-2"
                style={{
                  width: '100%',
                  alignSelf: 'center'
                }}
                onClick={() => {
                  this.setState({ showTransportData: true });
                }}
              >
                Agregar datos del transporte*
              </Button>
            </div>
          }

          <div className=" mb-2 d-flex justify-content-between">
            <Button
              pill
              size="lg"
              className="mt-4 mr-2"
              style={{
                width: '40%',
                alignSelf: 'center'
              }}
              onClick={() => {
                this.props.onBack(this.state.references)
              }}
            >
              {strings.back}
            </Button>
            <Button
              pill
              size="lg"
              className="mt-4"
              style={{
                width: '40%',
                alignSelf: 'center'
              }}
              onClick={() => {
                if (this.state.type === 1) {
                  if (this.state.email !== undefined && this.state.email !== null && this.state.email !== "" && !emailRegxPattern.test(this.state.email)) {
                    this.setState(
                      {
                        message: 'Por favor, introduce una dirección de correo electrónico válida',
                        visible: true
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            visible: false
                          });
                        }, 2000);
                      }
                    );
                  } else {
                    this.handleClick();
                  }
                } else {
                  if (
                    (this.state.number === undefined || this.state.number.trim() === '') ||
                    (this.state.name === undefined || this.state.name.trim() === '') ||
                    (this.state.addr1 === undefined || this.state.addr1.trim() === '') ||
                    (this.state.addr2 === undefined || this.state.addr2.trim() === '') ||
                    (this.state.city === undefined || this.state.city.trim() === '') ||
                    (this.state.state === undefined || this.state.state.trim() === '') ||
                    (this.state.contact === undefined || this.state.contact.trim() === '') ||
                    (this.state.b_details === undefined || this.state.b_details.trim() === '')
                  ) {
                    this.setState(
                      {
                        message: 'Llena todos los campos requeridos',
                        visible: true
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            visible: false
                          });
                        }, 2000);
                      }
                    );
                  } else if (this.state.number.length < 8) {
                    this.setState(
                      {
                        message: strings.invalid_rut,
                        visible: true
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            visible: false
                          });
                        }, 2000);
                      }
                    );
                  } else if (this.state.type !== undefined && this.state.type === 4 && this.state.transportData !== undefined && this.state.transportData.length <= 0) {
                    this.setState(
                      {
                        message: strings.transport_required,
                        visible: true
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            visible: false
                          });
                        }, 2000);
                      }
                    );
                  } else if (this.state.email !== undefined && this.state.email !== null && this.state.email !== "" && !emailRegxPattern.test(this.state.email)) {
                    this.setState(
                      {
                        message: strings.email_valid_error,
                        visible: true
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            visible: false
                          });
                        }, 2000);
                      }
                    );
                  }
                  else {
                    this.handleClick();
                  }
                }
              }}
            >
              {strings.next}
            </Button>
          </div>

          <Alert
            open={this.state.visible}
            theme="danger"
            style={{
              position: 'fixed',
              bottom: 10,
              minWidth: "30%"
              // width: '80%'
            }}
          >
            {this.state.message}
          </Alert>
        </div>
      ) : (
        <>
          {this.state.showAgregarReferenciasScreen &&
            <AgregarReferencias
              onBack={() => {
                this.setState({ showAgregarReferenciasScreen: false });
              }}
              saveReferencias={(data) => this.saveReferencias(data)}
              references={this.state.references}
            />
          }
          {this.state.showTransportData &&
            <TransportData
              onBack={() => {
                this.setState({ showTransportData: false });
              }}
              transportData={this.state.transportData}
              saveTransportData={(data) => this.saveTransportData(data)}
            />
          }
        </>
      )
    ) : (
      <SampleInvoice
        onBack={() => {
          if (this.props.bypassCustomerScreen) {
            this.setState({ showSampleInvoiceScreen: false });
            this.props.onBackWhenDirectPrint()

          } else {
            this.setState({ showSampleInvoiceScreen: false });
          }
        }}
        dataArray={this.state.dataArray}
        totalPaid={this.state.totalPaid}
        mainPrice={this.state.mainPrice}
        tax={this.state.tax}
        total={this.state.total}
        return={this.state.return}
        paymentMode={this.state.paymentMode}
        totalDiscount={this.state.totalDiscount}
        rutNumber={this.state.number}
        name={this.state.name}
        addr1={this.state.addr1}
        addr2={this.state.addr2}
        city={this.state.city}
        country={this.state.country}
        zipcode={this.state.zipcode}
        state={this.state.state}
        contact={this.state.contact}
        bDetails={this.state.b_details}
        type={this.state.type}
        view={false}
        comment={this.state.comment}
        withoutTax={this.state.withoutTax}
        references={this.state.references}
        transportData={this.state.transportData}
        propinaAmount={this.state.propinaAmount}
        propinaChecked={this.state.propinaChecked}
        propinaPercentage={this.state.propinaPercentage}
        showPermissionForPropina={this.state.showPermissionForPropina}
        transactionDocumentId={this.state.transactionDocumentId}
        transactionDocumentType={this.state.transactionDocumentType}
        editProductData={this.state.editProductData}
        editProductFromTransaction={this.state.editProductFromTransaction}
        email={this.state.email}
        closeAll={() => {
          this.setState({ showSampleInvoiceScreen: false });
          this.props.closeAll();
        }}
      />
    );
  }
}

export default Customer;
