import React, { Component } from 'react';
import { Form } from 'react-bootstrap'
import {
    FormGroup,
    FormInput,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormCheckbox,
    FormSelect
} from "shards-react";
import "bootstrap/dist/js/bootstrap.js";
import { strings } from "./../../localization";
import { Language, API_URL, LIMIT } from "./../../config";
export const CustomButton = (props) => {
    const buttonStyle = { width: props.width ? props.width : 80, height: props.height ? props.height : 80, backgroundColor: props.backgroundColor, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: 20, opacity: props.disabled === 'true' ? 0.7 : 1 }
    return (
        <div style={buttonStyle}
            onClick={props.onClick}
        >
            {props.text}
        </div>
    )
}





export class Calculator extends Component {
    state = {
        quantity: '',
        simple_price: '0',
        price: '$0',
        finalPrice: '$0',
        multiply: false,
        addDescription: false,
        addItem: false,
        description: '',
        array: [],
        mainTotal: '$0',
        totalItem: 0,
        isModalVisible: false,
        torchMode: 'off',
        cameraType: 'back',
        isBarcodeDisplay: false,
        visible: false,
        color: '#D74848',
        error_color: 'red',
        message: '',
        cnt: true,
        lastBarcode: '',
        press: false,
        discountModel: false,
        discount: 0,
        inPercentage: true,
        isSwitchOn: true,
        totalDiscount: 0,
        duration: 600,
        withoutTax: true,
        itemType: '',
        itemCode: '',
        message_error: '',
        visible_error: false,
        duration_error: 600,
        isDiscountModelShow: false,
    };
    constructor(props) {
        super(props);
        strings.setLanguage(Language);
        this.description = React.createRef();
    }

    removeItem = (index, price, discount, mode, mainPrice) => {
        let stateMainTotal = this.state.mainTotal.toString().replace(/\./g, '');

        // alert(price);

        // let newPrice = price.replace(/\./g, '');

        // price = parseInt(price.substring(1));

        // mainPrice = mainPrice.replace(/\./g, '');
        // mainPrice = parseInt(mainPrice.substring(1));

        let removeDiscount = 0;
        if (discount) {
            if (mode) {
                // true  = % false = $
                removeDiscount = (mainPrice * discount) / 100;
            } else {
                removeDiscount = discount;
            }
        }

        let totalDiscount = this.state.totalDiscount - removeDiscount;

        if (totalDiscount < 0) {
            totalDiscount = 0;
        }

        let total = parseInt(stateMainTotal) - price;
        total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        const list = this.state.array;
        list.splice(index, 1);

        this.setState({
            array: list,
            totalItem: this.state.totalItem - 1,
            mainTotal: total,
            visible: true,
            totalDiscount: totalDiscount,
            color: 'green',
            message: strings.item_remove,
        });
    };

    clearAll = () => {
        this.setState({
            price: '$0',
            finalPrice: '$0',
            simple_price: '0',
            quantity: '',
            multiply: false,
            array: [],
            mainTotal: '$0',
            totalItem: 0,
            totalDiscount: 0,
            isModalVisible: false,
            addItem: false,
            withoutTax: false,
        });
    };

    clearData = () => {
        let total = parseFloat(this.state.finalPrice.substring(1));
        let stateMainTotal = this.state.mainTotal.toString().replace(/\./g, '');

        total = stateMainTotal - total;
        total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.setState({
            price: '$0',
            finalPrice: '$0',
            simple_price: '0',
            quantity: '',
            multiply: false,
            discount: '',
        });
    };

    parseNumberToInt = (value) => {
        if (value) {
            value = value.replace('$', '');
            return parseInt(value.replace(/\./g, ''));
        }
    };
    handleTap = (type, value) => {
        const multiply = this.state.multiply;

        if (multiply) {
            if (type === 'number') {
                let finalQuantity = this.state.quantity;
                if (value === ',') {
                    if (finalQuantity.toString().indexOf(',') === -1) {
                        finalQuantity += value;
                    }
                } else {
                    finalQuantity += value;
                }

                if (finalQuantity === ',') {
                    finalQuantity = '1,';
                }
                let replace = finalQuantity.replace(/\,/g, '.');

                let price = this.state.price.substring(1);
                price = price.replace(/\./g, '');
                let total = price * parseFloat(replace);

                let discount = this.state.discount;
                if (discount > 0) {
                    let dAmount = 0;
                    if (this.state.inPercentage) {
                        dAmount = (parseInt(total) * discount) / 100;
                    } else {
                        dAmount = discount;
                    }
                    total = parseInt(total) - dAmount;
                }

                total = Math.round(total);
                total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                this.setState({
                    quantity: finalQuantity,
                    finalPrice: '$' + total,
                });
            } else if (type === 'remove') {
                let quantity = this.state.quantity;

                if (quantity.length > 1) quantity = quantity.slice(0, -1);
                else quantity = '1';

                let price = this.state.price.replace(/\./g, '');

                let total = parseFloat(price.substring(1)) * parseInt(quantity);

                let discount = this.state.discount;
                if (discount > 0) {
                    let dAmount = 0;
                    if (this.state.inPercentage) {
                        dAmount = (parseInt(total) * discount) / 100;
                    } else {
                        dAmount = discount;
                    }
                    total = parseInt(total) - dAmount;
                }

                if (quantity === '1') quantity = '';

                this.setState({
                    quantity: quantity,
                    finalPrice: '$' + total,
                });
            } else if (type === 'multiply') {
                this.setState({
                    multiply: !multiply,
                });
            } else if (type === 'clear') {
                this.clearData();
            }
        } else {
            if (type === 'number') {
                if (this.state.price.length < 8) {
                    let oldPrice =
                        this.state.simple_price === '0' ? '$' : this.state.simple_price;
                    let final = oldPrice + value;
                    let simple_price = final;
                    let finalQuantity = this.state.quantity;

                    if (finalQuantity === '') {
                        finalQuantity = '1';
                    }

                    let replace = finalQuantity.replace(/\,/g, '.');
                    console.log(final);

                    let fPrice = final.substring(1) * parseFloat(replace);
                    let discount = this.state.discount;
                    if (discount > 0) {
                        let dAmount = 0;
                        if (this.state.inPercentage) {
                            dAmount = (parseInt(fPrice) * discount) / 100;
                        } else {
                            dAmount = discount;
                        }
                        fPrice = parseInt(fPrice) - dAmount;
                    }

                    fPrice = Math.round(fPrice);
                    fPrice = fPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    final = final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

                    if (
                        final === '$00' ||
                        final === '$000' ||
                        final === '$0.000' ||
                        final === '$00.000' ||
                        final === '$000.000'
                    ) {
                        final = '$0';
                    }

                    this.setState({
                        simple_price: simple_price,
                        price: final,
                        finalPrice: '$' + fPrice,
                    });
                }
            } else if (type === 'clear') {
                this.clearData();
            } else if (type === 'remove') {
                let price =
                    this.state.price.length > 2
                        ? (price =
                            this.state.price === '$0'
                                ? '$0'
                                : this.state.price.substring(0, this.state.price.length - 1))
                        : '$0';

                let simple_price =
                    this.state.simple_price.length > 2
                        ? (simple_price =
                            this.state.simple_price === '$0'
                                ? '0'
                                : this.state.simple_price.substring(
                                    0,
                                    this.state.simple_price.length - 1,
                                ))
                        : '0';

                let finalQuantity = this.state.quantity;

                if (finalQuantity === '') {
                    finalQuantity = '1';
                }

                let replace = finalQuantity.replace(/\,/g, '.');
                price = price.replace(/\./g, '');

                let newPrice = price.substring(1);
                newPrice = newPrice.replace(/\./g, '');
                newPrice = newPrice.replace(/\./g, '');

                let total = newPrice * parseFloat(replace);

                let discount = this.state.discount;
                if (discount > 0) {
                    let dAmount = 0;
                    if (this.state.inPercentage) {
                        dAmount = (parseInt(total) * discount) / 100;
                    } else {
                        dAmount = discount;
                    }
                    total = parseInt(total) - dAmount;
                }

                total = Math.round(total);
                total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                price = price.toString().replace(/\./g, '');
                price = price.substring(1);
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

                this.setState({
                    price: '$' + price,
                    simple_price: simple_price,
                    finalPrice: '$' + total,
                });
            } else if (type === 'multiply') {
                this.setState({
                    multiply: !multiply,
                });
            }
        }
    };

    storeInArray = async () => {
        // alert('Store in array function : ' + this.state.itemCode);
        let mainPriceWithoutTax = 0;

        let count = this.state.totalItem + 1;

        let totalDiscount = this.state.discount;
        let fPrice = this.state.price.substring(1);
        fPrice = fPrice.toString().replace(/\./g, '');
        let quantity = this.state.quantity.toString().replace(/\,/g, '.');
        if (!quantity) {
            quantity = '1';
        }

        fPrice = fPrice * parseFloat(quantity);
        let discount = this.state.discount;

        if (this.state.inPercentage) {
            totalDiscount = (parseInt(fPrice) * discount) / 100;
        }

        totalDiscount = Math.round(totalDiscount);

        totalDiscount =
            parseInt(this.state.totalDiscount) + parseInt(totalDiscount);
        let AWT = 0;

        AWT = this.state.isSwitchOn ? fPrice / 1.19 : fPrice;

        let DWT = this.state.isSwitchOn ? totalDiscount / 1.19 : totalDiscount;

        if (!discount) {
            DWT = 0;
        }
        // console.log('AWT : ' + AWT);
        // console.log('DWT : ' + DWT);

        let FAWT = AWT - DWT;

        // console.log('Fawt : ' + FAWT);

        // FAWT = Math.round(FAWT);
        // console.log('Fawt ceil: ' + FAWT);

        let withoutTax = this.state.withoutTax;
        let iva = 0;

        if (this.state.isSwitchOn) {
            if (this.state.type !== 2) {
                iva = FAWT * 0.19;
            }

            // console.log('iva: ' + iva);
            console.log('======before rounding iva==== ', iva);

            //iva = Math.round(iva);

            console.log('======after rounding iva==== ', iva);
            withoutTax = false;
        }

        // console.log('iva round : ' + iva);
        if (this.state.isSwitchOn && this.state.type === 2) {
            mainPriceWithoutTax = Math.round(
                this.parseNumberToInt(this.state.price) / 1.19,
            );

            FAWT = (this.parseNumberToInt(this.state.price) * parseFloat(quantity)) / 1.19;
            iva = this.parseNumberToInt(this.state.price) * parseFloat(quantity) - FAWT;
            iva = Math.round(iva);
        } else {
            mainPriceWithoutTax = Math.round(this.parseNumberToInt(this.state.price));
        }

        let item = {
            total: this.parseNumberToInt(this.state.finalPrice),
            itemType: this.state.itemType,
            description: this.state.description,
            quantity:
                this.state.quantity === '' ? 1 : this.parseNumberToInt(this.state.quantity),
            // mainPrice: this.parseNumberToInt(this.state.price),
            mainPrice: mainPriceWithoutTax,
            mainPriceWithTax: this.parseNumberToInt(this.state.price),
            discount: this.parseNumberToInt(this.state.discount),
            percent: this.state.inPercentage,
            itemCode: this.state.itemCode,
            tax: this.state.isSwitchOn,
            totalDiscount: totalDiscount,
            FAWT: Math.round(FAWT),
            iva: iva,
        };

        try {
            await localStorage.setItem('item_name', this.state.description);
        } catch (error) { }

        var joined = this.state.array;
        if (item.total > 0) joined = this.state.array.concat(item);

        let mainTotal = this.state.mainTotal;
        let finalPrice = this.state.finalPrice.substring(1);
        mainTotal = mainTotal.toString().replace(/\./g, '');
        mainTotal = mainTotal.toString().replace(/\./g, '');

        if (mainTotal === '0' || isNaN(mainTotal) || mainTotal === '$0') {
            mainTotal = finalPrice;
        } else {
            mainTotal = Math.abs(parseInt(mainTotal));
            finalPrice = finalPrice.toString().replace(/\./g, '');
            mainTotal = mainTotal + parseInt(finalPrice);
        }

        mainTotal = mainTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.setState({
            array: joined,
            totalItem: count,
            price: '$0',
            finalPrice: '$0',
            simple_price: '0',
            quantity: '',
            multiply: false,
            itemCode: '',
            discount: '',
            mainTotal: mainTotal,
            totalDiscount: totalDiscount,
            isSwitchOn: true,
            withoutTax: withoutTax,
        });
        console.log('data array without change ', joined);
        return joined;
    };
    addItem = () => {
        this.setState({
            addItem: true,
            visible: true,
            color: 'green',
            message: 'Item added successfully',
        });

        this.storeInArray();
    };
    applyDiscount = () => {
        if (
            this.state.discount ||
            this.state.isSwitchOn ||
            !this.state.isSwitchOn
        ) {
            let fPrice = this.state.price.substring(1);
            fPrice = fPrice.toString().replace(/\./g, '');

            let quantity = this.state.quantity.toString().replace(/\,/g, '.');
            if (!quantity) {
                quantity = '1';
            }
            fPrice = fPrice * parseFloat(quantity);

            let discount = this.state.discount;

            if (this.state.inPercentage) {
                discount = (parseInt(fPrice) * discount) / 100;
            }

            let newFPrice = fPrice - discount;

            let stateMainTotal = this.state.mainTotal;
            stateMainTotal = stateMainTotal.toString().replace(/\./g, '');

            // let newMainTotal = parseInt(stateMainTotal) - fPrice + newFPrice;

            newFPrice = Math.round(newFPrice);
            // newMainTotal = Math.round(newMainTotal);

            newFPrice = newFPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            // newMainTotal = newMainTotal
            //   .toString()
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

            this.setState({
                isDiscountModelShow: false,
                finalPrice: '$' + newFPrice,
            });
        } else {
            this.setState({ isDiscountModelShow: false });
        }
    };

    removeDiscount = () => {
        let price = this.state.price.substring(1);

        // alert(price);

        price = price.replace(/\./g, '');

        let quantity = this.state.quantity.replace(/\,/g, '.');

        if (!quantity) quantity = 1;

        let fPrice = parseFloat(quantity) * price;

        fPrice = fPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.setState({
            isDiscountModelShow: false,
            discount: '',
            finalPrice: '$' + fPrice,
        });
    };

    render() {

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-10"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalCenter2"
                        >
                            <p style={{ fontSize: 20, }}>
                                {this.state.array.length > 0
                                    ? 'Total items: ' +
                                    this.state.array.length +
                                    ' = $' +
                                    this.state.mainTotal
                                    : ''}
                            </p>
                        </div>
                        <div className="col-2">
                            <i class="material-icons" style={{ fontSize: 26, fontWeight: 'bold', color: 'black' }}>print</i>
                        </div>
                    </div>
                </div>
                <div className="my-5">
                    <div className="container">
                        <div className="row"
                        >
                            <div className="col-6">
                                <input type="text" style={{ borderWidth: 0, borderBottomWidth: 1, height: 40 }}
                                    ref={this.description}
                                    placeholder="Item description"
                                    value={this.state.description}
                                    onChange={(text) =>
                                        this.setState({ description: text.target.value })
                                    }
                                />
                            </div>
                            <div className="col-6" style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 30, color: 'black' }}> {this.state.finalPrice.length < 9
                                ? `${this.state.finalPrice}`
                                : `${this.state.finalPrice.substring(0, 8)}...`}</div>
                        </div>
                    </div>

                    <div className="container mt-4">
                        <div className="row"
                        >

                            <div className="col-6 d-flex justify-content-end align-items-center">

                                <input type="text" style={{ borderWidth: 0, borderBottomWidth: this.state.multiply ? 1 : 0, fontSize: 26, fontWeight: 'bold', width: 50, textAlign: 'center', height: 40, }}
                                    value={
                                        this.state.quantity === ''
                                            ? '1'
                                            : this.state.quantity
                                    }
                                />
                                <p className="col-2" style={{ fontSize: 20 }}>X</p>
                                <i class="material-icons col-2" style={{ fontSize: 26, fontWeight: 'bold' }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalCenter"

                                >info</i>
                            </div>
                            <div className="col-6">
                                <input type="text" style={{ borderWidth: 0, borderBottomWidth: this.state.multiply ? 0 : 1, height: 40, fontSize: 26 }}
                                    value={this.state.price}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-3">
                            <CustomButton text='7' backgroundColor='gray' onClick={() => this.handleTap('number', '7')} />
                        </div>
                        <div class="col-3">
                            <CustomButton text='8' backgroundColor='gray' onClick={() => this.handleTap('number', '8')} />
                        </div>
                        <div class="col-3">
                            <CustomButton text='9' backgroundColor='gray' onClick={() => this.handleTap('number', '9')} />

                        </div>
                        <div class="col-3">
                            <CustomButton text='C' backgroundColor='red' onClick={() => this.handleTap('clear', '')} />
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-3">
                            <CustomButton text='4' backgroundColor='gray' onClick={() => this.handleTap('number', '4')} />
                        </div>
                        <div class="col-3">
                            <CustomButton text='5' backgroundColor='gray' onClick={() => this.handleTap('number', '5')} />
                        </div>
                        <div class="col-3">
                            <CustomButton text='6' backgroundColor='gray' onClick={() => this.handleTap('number', '6')} />

                        </div>
                        <div class="col-3">
                            <CustomButton text='X' backgroundColor='#f09a36'
                                underLine={this.state.multiply}
                                onClick={() => this.handleTap('multiply', '')} />
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-9">
                            <div class="row ">
                                <div class="col-4">
                                    <CustomButton text='1' backgroundColor='gray' onClick={() => this.handleTap('number', '1')} />
                                </div>
                                <div class="col-4">
                                    <CustomButton text='2' backgroundColor='gray' onClick={() => this.handleTap('number', '2')} />
                                </div>
                                <div class="col-4">
                                    <CustomButton text='3' backgroundColor='gray' onClick={() => this.handleTap('number', '3')} />
                                </div>
                            </div>
                            <div class="row mt-2 ">
                                <div class="col-4">
                                    <CustomButton text='0' backgroundColor='gray' onClick={() => this.handleTap('number', '0')} />
                                </div>
                                <div class="col-4">
                                    <CustomButton text=',' backgroundColor='gray' onClick={() => this.handleTap('number', ',')} />
                                </div>
                                <div class="col-4">
                                    <div style={{ width: 80, height: 80, backgroundColor: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: 20 }}
                                        onClick={() => this.handleTap('remove')}
                                    >
                                        <i class="material-icons">backspace</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <CustomButton text='Add' backgroundColor='#f09a36' width={80} height={168}
                                disabled={
                                    this.state.finalPrice === '$0' ||
                                        this.state.description === ''
                                        ? 'true'
                                        : 'false'
                                }
                                onClick={() => this.addItem()}
                            />
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-6">
                            <CustomButton text='Description' backgroundColor='#2e209b' width={170} height={60}
                                onClick={() => this.description.current.focus()}
                            />
                        </div>
                        <div class="col-6">
                            <CustomButton text='Next' backgroundColor='#2e209b' width={170} height={60}
                                disabled={
                                    (this.state.finalPrice !== '$0' &&
                                        this.state.description !== '') ||
                                        this.state.array.length > 0
                                        ? 'false'
                                        : 'true'
                                }
                                onClick={() => {
                                    {
                                        if (this.state.finalPrice === '$0') {
                                            let array = this.updatedDataArray(
                                                this.state.array,
                                            );
                                            this.props.navigation.navigate('Payment', {
                                                data: array,
                                                total: this.state.mainTotal,
                                                totalDiscount: this.state.totalDiscount,
                                                type: this.state.type,
                                                withoutTax: this.state.withoutTax,
                                            });
                                        } else {
                                            this.storeInArray().then((array) => {
                                                array = this.updatedDataArray(array);
                                                this.props.navigation.navigate('Payment', {
                                                    data: array,
                                                    total: this.state.mainTotal,
                                                    totalDiscount: this.state.totalDiscount,
                                                    type: this.state.type,
                                                    withoutTax: this.state.withoutTax,
                                                });
                                            });
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>


                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                            </div>
                            <div class="modal-body">
                                <>
                                    <FormGroup className="col-12">
                                        <InputGroup seamless>
                                            <FormInput
                                                value={this.state.discount}
                                                maxLength={this.state.inPercentage ? 2 : 9999}
                                                placeholder={
                                                    this.state.inPercentage
                                                        ? strings.discount_pr
                                                        : strings.discount_dollar
                                                }
                                                onChange={(text) => this.setState({ discount: text.target.value })}
                                            />
                                            <InputGroupAddon
                                                type="append"
                                                className='d-flex justify-content-center mt-1'
                                            >
                                                <InputGroupText>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({ inPercentage: !this.state.inPercentage })
                                                        }}
                                                    >
                                                        <i
                                                            style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}
                                                            className="material-icons"
                                                        >
                                                            {this.state.inPercentage ? 'percent' : 'attach_money'}
                                                        </i>
                                                        <i
                                                            style={{ fontSize: 14, color: 'black' }}
                                                            className="material-icons"
                                                        >
                                                            {!this.state.inPercentage ? 'percent' : 'attach_money'}
                                                        </i>
                                                    </div>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormSelect
                                        placeholder="Seleccionar"
                                        onChange={(item) => {
                                            this.setState({
                                                itemType: item.target.value,
                                            })
                                        }
                                        }
                                    >
                                        <option value="Litro">Litro</option>
                                        <option value="KG">KG</option>
                                        <option value="Unidad">Unidad</option>
                                    </FormSelect>
                                    <div className="d-flex mt-3">
                                        <p className="">IVA :</p>
                                        <div>
                                            <Form
                                                style={{ marginLeft: 15 }}
                                            >
                                                <Form.Check
                                                    type="switch"
                                                    className="form-switch-md"
                                                    id="custom-switch"
                                                    value={this.state.isSwitchOn}
                                                    onChange={() => this.setState({ isSwitchOn: !this.state.isSwitchOn })}
                                                />
                                            </Form>
                                        </div>

                                    </div>
                                </>
                            </div>
                            <div class="modal-footer">

                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    onClick={() => this.removeDiscount()}
                                >Borrar</button>
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    onClick={() => this.applyDiscount()}
                                >Aplicar</button>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                            </div>
                            <div class="modal-body">
                                <>
                                    {this.state.array.length > 0 ? (
                                        this.state.array.map((item, index) => {
                                            return (
                                                <>
                                                    <div
                                                        key={index}
                                                        className='row mt-2 align-items-center'
                                                    >
                                                        <div className='col-4'>
                                                            <p
                                                                style={{
                                                                    fontSize: 20,
                                                                    fontWeight: '400',
                                                                }}>
                                                                {item.description.length <= 5
                                                                    ? `${item.description}`
                                                                    : `${item.description.substring(0, 4)}...`}
                                                                {item.discount ? (
                                                                    <p
                                                                        style={{
                                                                            color: 'red',
                                                                            fontSize: 13,
                                                                        }}>
                                                                        {' '}
                                                                        (-{item.discount}
                                                                        {item.percent ? '%' : '$'})
                                                                    </p>
                                                                ) : null}
                                                            </p>
                                                        </div>
                                                        <div class="col-6">
                                                            <p style={{}}>
                                                                {item.quantity} X {item.mainPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                                = {item.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                                            </p>
                                                        </div>
                                                        <div class="col-2">
                                                            <div
                                                                onClick={() =>
                                                                    this.removeItem(
                                                                        index,
                                                                        item.total,
                                                                        item.discount,
                                                                        item.percent,
                                                                        item.mainPrice,
                                                                    )
                                                                }>
                                                                <i
                                                                    style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}
                                                                    className="material-icons"
                                                                >
                                                                    delete
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {item.tax && (
                                                        <>
                                                            <p
                                                                style={{
                                                                    fontSize: 12,
                                                                    color: 'tomato',
                                                                }}>
                                                                + 19% Tax
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <p style={{ textAlign: 'center' }}>
                                            No item available{' '}
                                        </p>
                                    )}
                                </>
                            </div>
                            <div class="modal-footer">

                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    onClick={() => this.clearAll()}
                                >BORRAR TODO</button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default Calculator;
