import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'shards-react'
import ReactDatatable from "@ashvin27/react-datatable";
import { strings } from '../../localization';
import { API_URL, LIMIT, Language } from '../../config';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/common/PageTitle';
import swal from "sweetalert2";
window.Swal = swal;

export default class RestaurantTableList extends Component {
    state = {
        loading: false,
        records: [],
        columns: [],
        total: 0,
        skip: 0,
        limit: LIMIT,
        currentPage: 1,
        prev: true,
        next: false,
    };
    constructor(props) {
        super(props);
        strings.setLanguage(Language);
        this.columns = [
            {
                key: "tableName",
                text: strings.table_name
            },
            {
                key: "restaurantSpaceID",
                text: strings.space_name,
                cell: record => {
                    let spaceName = record.restaurantSpaceID ? record.restaurantSpaceID.spaceName : "";
                    return (
                        <p style={{ fontSize: '11px' }}>{spaceName}</p>
                    )
                }
            },
            {
                key: '',
                text: 'Acción',
                cell: record => {
                    console.log('record of tables', record)
                    return (
                        <div className='mt-2'>
                            <Link
                                to={{
                                    pathname: "/manage-table/" + record.id
                                }}
                            >
                                <Button theme="success" className="mb-2 mr-1">
                                    Editar
                                </Button>
                            </Link>
                            <Button
                                theme="danger"
                                className="mb-2 mx-2"
                                onClick={() => this.deleteItem(record.id)}
                            >
                                {strings.delete}
                            </Button>
                        </div>
                    )
                }
            }
        ];
        this.config = {
            // page_size: 10,
            show_length_menu: false,
            show_info: false,
            show_pagination: false,
            button: {
                excel: false,
                print: false,
                extra: false
            }
        };
    }

    deleteItem(id) {
        swal
            .fire({
                title: strings.are_you_sure,
                text: strings.delete_table,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: strings.yes_cancel,
                confirmButtonColor: "#2E209D",
                cancelButtonColor: "#D61E11",
                confirmButtonText: strings.yes_delete,
                showLoaderOnConfirm: true,
                preConfirm: login => {
                    return fetch(`${API_URL}restaurant-table/${id}`, {
                        method: "delete",
                        credentials: "include",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    })
                        .then(res => {
                            if (res.status == 200) {
                                this.setState({
                                    total: 0,
                                    skip: 0,
                                    currentPage: 1,
                                    prev: true,
                                    next: false,
                                },
                                    () => this.getTableList()
                                );

                                swal.fire(
                                    strings.deleted,
                                    strings.success_item_deleted,
                                    "success"
                                );
                            }
                            return res.json();
                        })
                        .catch(err => {
                            swal.showValidationMessage(`Request failed: ${err}`);
                        });
                }
            })
            .then(result => { });
    }

    componentDidMount() {
        this.getTableList()
    }

    getTableList = async (skip = 0) => {
        this.setState({
            loading: true
        });

        fetch(`${API_URL}restaurant-table?limit=${this.state.limit}&skip=${skip}`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => res.json())
            .then(res => {
                if (res.result.length > 0) {
                    let array = [];

                    res.result.map(item => {
                        let obj = item.data;
                        console.log("🚀 ~ file: index.js:208 ~ Customers ~ obj:", obj)
                        obj = Object.assign({ id: item._id }, obj);
                        array.push(obj);
                    });

                    if (res.result.length !== this.state.limit) {
                        this.setState({
                            next: true
                        });
                    }

                    this.setState({
                        total: res.result.length + 1,
                        records: array,
                        loading: false
                    });
                } else {
                    this.setState({
                        next: true,
                        loading: false,
                        records: []
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
            });
    }

    pageDecrement() {
        if (this.state.skip > 0) {
            let skip = this.state.skip - this.state.limit;
            if (skip >= 0) {
                this.getTableList(skip);
                if (skip === 0) {
                    this.setState({
                        prev: true
                    });
                }
                this.setState({
                    currentPage: this.state.currentPage - 1,
                    next: false,
                    skip: skip
                });
            } else {
                this.setState({
                    prev: true,
                    next: false
                });
            }
        } else {
            this.setState({
                next: false
            });
        }
    }

    pageIncrement() {
        if (!this.state.next) {
            let skip = this.state.currentPage * this.state.limit;
            this.getTableList(skip);
            this.setState({
                currentPage: this.state.currentPage + 1,
                prev: false,
                skip: skip
            });
        }
    }
    render() {
        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        sm="4"
                        title={strings.restaurant_tables}
                        className="text-sm-left"
                    />
                </Row>
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardBody className="p-3 pb-3">
                                <Link to="manage-table">
                                    <Button theme="primary" className="mb-2 float-right">
                                        <i class="material-icons">add</i> {strings.create_table}
                                    </Button>
                                </Link>
                                <div className="MainDiv">
                                    <div className="table-responsive">
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            loading={this.state.loading}
                                        />
                                        <nav className="d-flex justify-content-center align-items-center">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        href="javascript:;"
                                                        onClick={() => this.pageDecrement()}
                                                        style={
                                                            this.state.prev
                                                                ? { cursor: 'not-allowed', color: 'gray' }
                                                                : null
                                                        }
                                                    >
                                                        Anterior
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a
                                                        className="page-link"
                                                        href="javascript:;"
                                                        onClick={() => this.pageIncrement()}
                                                        style={
                                                            this.state.next
                                                                ? { cursor: 'not-allowed', color: 'gray' }
                                                                : null
                                                        }
                                                    >
                                                        Próxima
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
