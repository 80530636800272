import React from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import SearchFilter from "./../search_filter";
import { strings } from "./../../localization";
import { Language, API_URL, LIMIT } from "./../../config";
import { Container, Row, Col, Card, Button, CardBody } from "shards-react";
// import $ from "jquery";
import PageTitle from "./../../components/common/PageTitle";
// import SweetAlert from "react-bootstrap-sweetalert";
import swal from "sweetalert2";
import Offcanvas from 'react-bootstrap/Offcanvas'
import Calculator from "./Calculator"
window.Swal = swal;
const filters = [
  {
    name: "Number"
  },
  {
    name: "Total"
  },
  {
    name: "Payment"
  },
  {
    name: "Customer Name"
  },
  {
    name: "Date"
  }
];
class Customers extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0,
    skip: 0,
    limit: LIMIT,
    currentPage: 1,
    prev: true,
    next: false,
    loading: true,
    apiName: "bills",
    isSideNavebarShow:false,
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: "number",
        text: "Número"
      },
      {
        key: "amount",
        text: "Total"
      },
      {
        key: "payment",
        text: "Medio de Pago"
      },
      {
        key: "customer_name",
        text: "Nombre del cliente"
      },
      {
        key: "date",
        text: "Fecha"
      },
      {
        key: "action",
        text: "Acción",
        // width: 100,
        sortable: false,
        cell: record => {
          return (
            //   /create-customer
            <div className="mt-2">
              <Link
                to={{
                  pathname: `/view-transaction/${this.state.apiName}/${record.id}/barcode`
                }}
              >
                <Button
                  theme="success"
                  className="mb-2 mr-1"
                  style={{ textTransform: "capitalize" }}
                >
                  Barcode XML
                </Button>
              </Link>

              <Link
                to={{
                  pathname: `/view-transaction/${this.state.apiName}/${record.id}`
                }}
              >
                <Button
                  theme="primary"
                  className="mb-2 mr-1"
                  style={{ textTransform: "capitalize" }}
                >
                  {this.state.apiName} XML
                </Button>
              </Link>

              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={() => this.deleteTransaction(record.id)}
              >
                {strings.delete}
              </Button>
            </div>
          );
        }
      }
    ];
    this.config = {
      // page_size: 10,
      show_length_menu: false,
      show_info: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        extra: false
      }
    };
  }

  // editRecord(record) {
  //   console.log("Edit Record", record);
  // }

  deleteTransaction(id) {
    swal
      .fire({
        title: strings.are_you_sure,
        text: "You want to delete this transaction !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E209D",
        cancelButtonColor: "#D61E11",
        confirmButtonText: strings.yes_delete,
        showLoaderOnConfirm: true,
        preConfirm: login => {
          return fetch(`${API_URL}${this.state.apiName}/${id}`, {
            method: "delete",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(res => {
              if (res.status == 200) {
                this.getData();

                swal.fire(
                  strings.deleted,
                  strings.success_item_deleted,
                  "success"
                );
              }
              return res.json();
            })
            .catch(err => {
              swal.showValidationMessage(`Request failed: ${err}`);
            });
        }
      })
      .then(result => {});
  }

  componentDidMount() {
    this.getData();
  }

  getData = (skip = 0, param = "") => {
    this.setState({
      loading: true
    });

    fetch(
      `${API_URL}${this.state.apiName}?limit=${this.state.limit}&skip=${skip}${param}`,
      {
        method: "GET",
        credentials: "include"
      }
    )
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];

          res.result.map(item => {
            let data = item.data;
            let number = data.transactionNumber;
            // console.log(number);
            // if (data.type === 1) number = data.billNumber;
            // else if (data.type === 2) number = data.invoiceNumber;
            // else if (data.type === 3) number = data.creditNoteNumber;
            // else if (data.type === 4) number = data.deliveryNoteNumber;
            // else number = data.debitNoteNumber;
            let obj = {};
            obj = Object.assign(
              {
                id: item._id,
                date: data.date + " " + data.time,
                amount: data.totalPaid,
                customer_name: data.name === "" ? "-" : data.name,
                payment: data.paymentMode,
                number: number
              },
              obj
            );
            array.push(obj);
          });

          if (res.result.length !== this.state.limit) {
            this.setState({
              next: true
            });
          }

          this.setState({
            total: res.result.length + 1,
            records: array,
            loading: false
          });
        } else {
          this.setState({
            next: true,
            loading: false,
            records: []
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  pageIncrement() {
    if (!this.state.next) {
      let skip = this.state.currentPage * this.state.limit;
      this.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    }
  }

  handleChange(e) {
    // alert(e.target.value);
    this.setState(
      {
        apiName: e.target.value,
        prev: true,
        next: false
      },
      () => this.getData()
    );
  }

handleShowSideNavBar(){
if(this.state.isSideNavebarShow){
this.setState({isSideNavebarShow:false});
}else{
this.setState({isSideNavebarShow:true});
}
}
  render() {
    //Datatable HTML

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {/* <a onClick={() => this.deleteThisGoal()} className="btn btn-danger">
          Open alert
        </a>
        {this.state.alert} */}

        <Row noGutters className="page-header py-4 ">

          <div className="d-flex align-content-center">
            <PageTitle
              sm="2"
              title="Transacciones"
              className="text-sm-left"
            />
          </div>
     
          
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <SearchFilter getData={this.getData} filters={filters} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <div className="row">
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      onChange={e => this.handleChange(e)}
                    >
                      <option value="bills">Boleta electrónica</option>
                      <option value="invoices">Factura electrónica</option>
                      <option value="creditnotes">Nota de crédito</option>
                      <option value="debitnotes">Nota de debito</option>
                      <option value="deliverynotes">Guía de despacho</option>
                    </select>
                  </div>
                </div>
                <div className="MainDiv">
                  <div className="table-responsive">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      loading={this.state.loading}
                    />
                    <nav className="d-flex justify-content-center align-items-center">
                      <ul className="pagination">
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageDecrement()}
                            style={
                              this.state.prev
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Anterior
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageIncrement()}
                            style={
                              this.state.next
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Próxima
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ToastContainer /> */}

        <Offcanvas show={this.state.isSideNavebarShow} onHide={this.handleShowSideNavBar.bind(this)} placement='end' >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Calculator</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
      <Calculator/>
        </Offcanvas.Body>
      </Offcanvas>
      </Container>
    );
  }
}
export default Customers;
