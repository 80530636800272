import React, { useEffect, useState } from 'react'
import { strings } from '../../localization';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Card, Button, CardBody, FormTextarea, FormInput } from "shards-react";
import './ManageFoodItemsDialog.css';

function ManageFoodItemsDialog({ showModal, setShowModal, foodItem, foodIndex, onSaveButtonPressed, isFromFoodSelection, isSaveBtnLoading }) {

    const quantity = ['1', '2', '3', '4', '5'];
    const [selectedQuantity, setSelectedQuantity] = useState('');
    const [customQuantity, setCustomQuantity] = useState('');
    const [foodInstruction, setFoodInstruction] = useState('');
    const [isCustomQuanAdded, setIsCustomQuanAdded] = useState(false);
    const [foodItemData, setFoodItemData] = useState('');

    const handleSaveButtonClicked = () => {
        console.log('handleSaveButtonClicked')
        let quantity;
        // This if condition checks whether custom condition added or not
        if (isCustomQuanAdded) {
            if (customQuantity) {
                quantity = customQuantity;
            }
        } else {
            if (selectedQuantity) {
                quantity = selectedQuantity;
            }
        }
        console.log('quantity', quantity);
        if (quantity) {
            let itemData = { ...foodItemData };
            itemData['quantity'] = quantity;
            if (foodInstruction) {
                itemData['note'] = foodInstruction;
            }
            console.log('quantity in if condition', quantity);
            console.log('foodItemData before send', itemData);
            onSaveButtonPressed(itemData, foodIndex);
        }
    }

    useEffect(() => {
        let data = { ...foodItem };
        console.log('foodItem', data)
        if (data) {
            if (data['quantity']) {
                console.log('food item dialog condition', data['quantity'] > 5)
                if (data['quantity'] > 5) {
                    setIsCustomQuanAdded(true);
                    setCustomQuantity(data['quantity'].toString());
                } else {
                    setIsCustomQuanAdded(false);
                    setSelectedQuantity(data['quantity'].toString());
                }
            }
            setFoodItemData(data);
            setFoodInstruction(data['note'])
        }
    }, [])

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <p className='title'>{isFromFoodSelection ? foodItemData['name'] : foodItemData['itemName']}</p>
                    <p className='content'>{isFromFoodSelection ? foodItemData['code'] : foodItemData['itemCode']}</p>
                    <p className='subTitle'>{strings.select_food_quantity}</p>
                    <div style={{ display: 'flex' }}>
                        {
                            quantity.map((value, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setSelectedQuantity(value);
                                            setCustomQuantity('');
                                            setIsCustomQuanAdded(false);
                                        }}
                                        className={selectedQuantity === value ? 'quantityFillBtn' : 'quantityOutlinedBtn'}
                                    >
                                        {value}
                                    </div>
                                )
                            })
                        }
                        <FormInput
                            className={isCustomQuanAdded ? 'customQuantityWithBorder' : 'customQuantity'}
                            placeHolder="Custom"
                            type="number"
                            value={customQuantity}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedQuantity(null);
                                setIsCustomQuanAdded(true);
                                const digitRegx = /^[0-9]+$/;
                                if (value === "") {
                                    setCustomQuantity(value);
                                    return;
                                }
                                if (digitRegx.test(value)) {
                                    setCustomQuantity(value);
                                }
                            }}
                        />
                    </div>
                    <FormTextarea
                        value={foodInstruction}
                        placeHolder={strings.enter_food_instruction}
                        className="note"
                        size="lg"
                        rows="4"
                        onChange={e =>
                            setFoodInstruction(e.target.value)
                        }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    //   disabled={this.state.isUploadCsvLoading}
                    theme="primary"
                    onClick={handleSaveButtonClicked}
                    className="saveBtn"
                >
                    {
                        isSaveBtnLoading
                            ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                            : strings.save.toUpperCase()
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ManageFoodItemsDialog