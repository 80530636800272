import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";

import Chart from "../../utils/chart";

// todo

class UsersByDevice extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  // componentDidMount() {      
  //   const chartConfig = {
  //     type: "pie",      
  //     data: this.props.chartData,
  //     options: {
  //       ...{
  //         legend: {
  //           position: "bottom",
  //           labels: {
  //             padding: 25,
  //             boxWidth: 20
  //           }
  //         },
  //         cutoutPercentage: 0,
  //         tooltips: {
  //           custom: false,
  //           mode: "index",
  //           position: "nearest"
  //         }
  //       },
  //       ...this.props.chartOptions
  //     }
  //   };

  //   new Chart(this.canvasRef.current, chartConfig);
  // }



  render() {
    const { title, pieChartData } = this.props;
    if (typeof (pieChartData) !== "undefined") {
      if (typeof (pieChartData.chartData) !== 'undefined') {
        const chartConfig = {
          type: "pie",
          data: pieChartData.chartData,
          options: {
            ...{
              legend: {
                position: "bottom",
                labels: {
                  padding: 25,
                  boxWidth: 20
                }
              },
              cutoutPercentage: 0,
              // tooltips: {
              //   custom: false,
              //   mode: "index",
              //   position: "nearest"
              // },
              tooltips: {
                mode: "index",
                position: "nearest",
                callbacks: {
                  label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var currentValue = dataset.data[tooltipItem.index];
                    return currentValue + '%';
                  }
                }
              }
            },
            ...this.props.chartOptions
          }
        };

        new Chart(this.canvasRef.current, chartConfig);
      }
    }
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{pieChartData.title}</h6>
        </CardHeader>
        <CardBody className="d-flex py-0">
          <canvas
            height="220"
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
        {/* <CardFooter className="border-top">
          <Row>
            <Col>
              <FormSelect
                size="sm"
                value="last-week"
                style={{ maxWidth: "130px" }}
                onChange={() => {}}
              >
                <option value="last-week">Semana Pasada</option>
                <option value="today">Hoy dia</option>
                <option value="last-month">El mes pasado</option>
                <option value="last-year">El año pasado</option>
              </FormSelect>
            </Col>
            <Col className="text-right view-report">
              eslint-disable-next-line
              <a href="#">Ver informe completo &rarr;</a>
            </Col>
          </Row>
        </CardFooter> */}
      </Card>
    );
  }
}

UsersByDevice.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

// UsersByDevice.defaultProps = {
//   // title: "Tipos de transacciones",
//   chartData: {
//     datasets: [
//       {
//         hoverBorderColor: "#ffffff",
//         data: [68.3, 24.2, 7.5],
//         backgroundColor: [
//           "rgba(0,123,255,0.9)",
//           "rgba(0,123,255,0.5)",
//           "rgba(0,123,255,0.3)"
//         ]
//       }
//     ],
//     labels: ["Facturas", "Boletas", "Notes de Crédito"]
//   }
// };

// UsersByDevice.defaultProps = {
//   title: "Tipos de transacciones",
//   chartData: 
// };


export default UsersByDevice;




