import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    login: "Sign In",
    password: "Password",
    have_code: "I have a reset code.",
    forgot_password: "Forgot Password?",
    required_usercode: "User code required",
    required_orgcode: "Org code required",
    required_password: "Password is required",
    enter_code: "Enter verification code",
    back_login: "Atrás para iniciar sesión",
    email: "Email address",
    enter_email: "Enter email",
    enter_password: "Enter password",
    invalid_code: "Code is incorrect.",
    remember: "Remember me",
    forgot_password_btn: "Send Reset Code",
    required_email: "Email is required",
    invalid_email: "Email is invalid",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    reset_password_btn: "Reset Password",
    passwords_not_match: "New Password & Confirm Password Must be same",
    send_success: "Verification code successfully sent on ",
    fill_fields: "Fill All Fields !",
    verify_code: "Verify OTP",
    change_password: "Change Password",
    password_success: "Password changed successfully",
    usercode: "User Code",
    orgcode: "Org Code",
    cancel: "Cancel",
    forgot_old_passowrd: "Forgot Password?",
    email_instruction: "Write your email and we will send you a message with the instructions to reset your password.",
    send_email_button: "Send",
    email_send_successfully: 'Your mail has been sent correctly, check your inbox.',
    reset_new_password: "New Password",
    new_password: "Password",
    new_password_placeholder: "Password",
    reset_new_password_instruction: "Remember that your password must have at least 6 characters, including at least one letter and one number.",
    reset_confirm_password: "Confirm Password",
    reset_confirm_password_placeholder: "Confirm Password",
    reset_success_password: "New password created!",
    confirm: "CONFIRM",
    require_forgot_email: "Email is required",
    require_valid_email: "Enter a valid email address",
    require_new_password: "Password is required",
    require_new_confirm_password: "Confirm Password is required",
    confirm_password_not_matched: "Passwords must match",
    back_to_login: "Back To Login",
  },
  es: {
    login: "Sign In es",
    password: "Password es",
    have_code: "I have a reset code. es",
    forgot_password: "Forgot Password? es",
    required_usercode: "User code required es",
    required_orgcode: "Org code required es",
    required_password: "Password is required es",
    enter_code: "Enter verification code es",
    back_login: "Atrás para iniciar sesión",
    email: "Email address es",
    enter_email: "Enter email es",
    enter_password: "Enter password es",
    invalid_code: "Code is incorrect. es",
    remember: "Remember me es",
    forgot_password_btn: "Send Reset Code es",
    required_email: "Email is required es",
    invalid_email: "Email is invalid es",
    new_password: "New Password es",
    confirm_password: "Confirm Password es",
    reset_password_btn: "Reset Password es",
    passwords_not_match: "New Password & Confirm Password Must be same es",
    send_success: "Verification code successfully sent on  es",
    fill_fields: "Fill All Fields ! es",
    verify_code: "Verify OTP es",
    change_password: "Change Password es",
    password_success: "Password changed successfully es",
    usercode: "User Code es",
    orgcode: "Org Code es",
    cancel: "Cancelar",
    forgot_old_passowrd: "¿Olvidaste tu contraseña?",
    email_instruction: "Escribe tu email y te enviaremos un mensaje con las instrucciones para restablecer tu contraseña.",
    send_email_button: "ENVIAR",
    email_send_successfully: 'Tu mail ha sido enviado correctamente, revisa tu bandeja de entrada.',
    reset_new_password: "Nueva Contraseña",
    new_password: "Contraseña *",
    new_password_placeholder: "Contraseña",
    reset_new_password_instruction: "Recuerda que tu contraseña debe tener mínimo 6 caracteres, incluidos al menos una letra y un número.",
    reset_confirm_password: "Confirmar contraseña *",
    reset_confirm_password_placeholder: "Confirmar contraseña",
    reset_success_password: "¡Nueva contraseña creada!",
    confirm: "CONFIRMAR",
    require_forgot_email: "Email is required es",
    require_valid_email: "Introduzca una dirección de correo electrónico válida",
    require_new_password: "Contraseña is required es",
    require_new_confirm_password: "Confirmar Contraseña is required es",
    confirm_password_not_matched: "Las contraseñas deben coincidir",
    back_to_login: "Entrar a mi cuenta",
  }
});
