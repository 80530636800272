import React, { Component } from "react";
import styles from "./invoice.module.css";
import Barcode from "./../images/download.png";
export default class extends Component {
  render() {
    return (
      <page size="A4">
        <body size="A4">
          <div className={styles.div1}>
            <div className={styles.div2}>
              <div style={{ display: "flex", paddingBottom: "10px" }}>
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: 17,
                      paddingBottom: "10px"
                    }}
                  >
                    EBOL INNOVATION SPA
                  </p>
                  <p>
                    VENTA Y ARRIENDO DE SOFTWARE Y ARTICULO <br />{" "}
                    COMPUTACIONALES
                  </p>
                </div>
                <div className={styles.div3}>
                  <div>
                    <p>R.U.T.: 77.207.867-6</p>
                    <p>BOLETA ELECTRÓNICA</p>
                    <p>N° 3</p>
                  </div>
                  <p className={styles.margin_top}>S.I.I. - COLINA</p>
                  <span className={styles.margin_top}>
                    <b
                      style={{
                        paddingRight: "10px",
                        fontWeight: "bolder",
                        color: "black"
                      }}
                    >
                      {" "}
                      FECHA DE EMISIÓN:{" "}
                    </b>{" "}
                    24/11/2020
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <p>LT 4 - 6 PC 4 STA ESTER</p>
              </div>
              <br />
              <br />
              <div style={{ height: "67rem" }}>
                <table className={styles.list_table}>
                  <thead>
                    <th style={{ width: "50%" }}>DESCRIPCION</th>
                    <th>CANTIDAD</th>
                    <th>PRECIO</th>
                    <th>EXENTO</th>
                    <th>U. MEDIDA</th>
                    <th>TOTAL</th>
                  </thead>
                  <tbody>
                    <tr className={styles.fit_content}>
                      <td className={styles.remove_border}>Sandwich</td>
                      <td className={styles.remove_border}>2</td>
                      <td className={styles.remove_border}>1.500</td>
                      <td className={styles.remove_border}>0</td>
                      <td className={styles.remove_border}></td>
                      <td className={styles.remove_border}>3.000</td>
                    </tr>
                    <tr className={styles.fit_content}>
                      <td className={styles.remove_border}>Sandwich</td>
                      <td className={styles.remove_border}>2</td>
                      <td className={styles.remove_border}>1.500</td>
                      <td className={styles.remove_border}>0</td>
                      <td className={styles.remove_border}></td>
                      <td className={styles.remove_border}>3.000</td>
                    </tr>
                    <tr className={styles.fit_content}>
                      <td className={styles.add_border}></td>
                      <td className={styles.add_border}></td>
                      <td className={styles.add_border}></td>
                      <td className={styles.add_border}></td>
                      <td className={styles.add_border}></td>
                      <td className={styles.add_border}></td>
                    </tr>
                  </tbody>
                </table>
                <span style={{ fontSize: "10px", fontWeight: "normal" }}>
                  <b>SON : </b>CUATRO MIL CIEN PESOS.
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div className={styles.barcode_div}>
                  <img
                    src={Barcode}
                    height="120"
                    width="300"
                    style={{ marginTop: "40px" }}
                  />
                  <small style={{ color: "black", fontWeight: "600" }}>
                    Timbre Electrónico SII
                  </small>
                  <small
                    style={{
                      color: "black",
                      fontWeight: "600",
                      paddingBottom: "10px"
                    }}
                  >
                    Resolución Ex. SII N° 0 del 2016
                  </small>
                  <p style={{ fontWeight: "600" }}>
                    Verifique documento: www.chilesystems.com/dte
                  </p>
                </div>
                <div className={styles.calculation}>
                  <table
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    className={styles.calculation_table}
                  >
                    <tr>
                      <td>EXENTO</td>
                      <td>(=)</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <b style={{ fontWeight: "bolder" }}>TOTAL</b>
                      </td>
                      <td>(=)</td>
                      <td>4.100</td>
                    </tr>
                  </table>
                  <p
                    style={{
                      fontWeight: "bolder",
                      color: "red",
                      textAlign: "center",
                      marginTop: "3rem"
                    }}
                  >
                    ORIGINAL
                  </p>
                </div>
              </div>
              <br />
              <small
                style={{
                  color: "darkblue",
                  fontWeight: "bolder",
                  fontSize: "10px"
                }}
              >
                Desarrollado por ChileSystems.com +56962212767
              </small>
            </div>
          </div>
        </body>
      </page>
    );
  }
}
