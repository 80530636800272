import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Card, CardHeader, Col, Container, Form, FormInput, InputGroup, InputGroupAddon, InputGroupText, ListGroup, ListGroupItem, Row } from 'shards-react'
import { strings } from '../../localization'
import PageTitle from '../../components/common/PageTitle'
import { Link } from 'react-router-dom'
import { API_URL, Language } from '../../config'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'

const customStyles = {
    control: (provided) => ({
        ...provided,
        marginBottom: '15px',
        fontSize: '14px'
    }),
};

export default class EditRestaurantTable extends Component {
    constructor(props) {
        super(props);
        strings.setLanguage(Language);
        this.state = {
            name: "",
            loader: "",
            id: "",
            isButtonDisabled: false,
            spaces: [],
            selectedSpace: null
        }
    }

    handleSaveButtonClick = () => {
        if (this.state.name === undefined || this.state.name === null || this.state.name === "") {
            toast.error(strings.fill_fields, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } else {
            this.setState({ loader: "fa fa-spinner fa-spin", isButtonDisabled: true });
            let selectedSpaceId = '';
            if (this.state.selectedSpace) {
                selectedSpaceId = this.state.selectedSpace.value;
            }
            console.log('selectedSpaceId', selectedSpaceId);
            let string = {
                tableName: this.state.name,
            };

            if (selectedSpaceId !== '') {
                string['restaurantSpaceID'] = selectedSpaceId;
            }
            console.log('body data for restaurant table', string);

            fetch(`${API_URL}restaurant-table${this.state.id}`, {
                method: this.state.id ? "put" : "post",
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(string)
            })
                .then(res => {
                    if (res.status == 200) {
                        toast.success(strings.successfully_created, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });

                        this.setState({ loader: "", isButtonDisabled: false });
                    }
                    return res.json();
                })
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                    console.warn(err);
                });
        }
    }

    getSpaceData = () => {
        fetch(`${API_URL}restaurant-space`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => res.json())
            .then(res => {
                console.log('response of restaurant space', res);
                const result = res.result;
                if (result && result.length > 0) {
                    let spaceList = [];
                    result.map((item) => {
                        spaceList.push({
                            label: item.data.spaceName,
                            value: item._id
                        })
                    })
                    this.setState({
                        spaces: spaceList
                    })
                }
            })
            .catch(err => {
                console.log('catch block error at getting bar list', err);
            });
    }

    componentDidMount() {
        this.getSpaceData();
        if (this.props.match.params.id) {
            fetch(`${API_URL}restaurant-table/${this.props.match.params.id}`, {
                method: "GET",
                credentials: "include"
            })
                .then(res => res.json())
                .then(({ data }) => {
                    let restaurantSpaceID = data.restaurantSpaceID;
                    let spaceId = restaurantSpaceID ? {
                        label: restaurantSpaceID.spaceName,
                        value: restaurantSpaceID.restaurantSpaceID
                    } : "";
                    this.setState({
                        name: data.tableName,
                        selectedSpace: spaceId,
                        id: "/" + this.props.match.params.id,
                    });
                });
        }
    }

    render() {
        const { name } = this.state;

        return (
            <div>
                <Container
                    fluid
                    className="main-content-container px-4"
                    style={{ marginBottom: "200px" }}
                >
                    <Row noGutters className="page-header py-4">
                        <PageTitle
                            title={this.props.match.params.id ? strings.edit_table : strings.create_table}
                            subtitle={strings.overview}
                            md="12"
                            className="ml-sm-auto mr-sm-auto"
                        />
                    </Row>
                    <Row>
                        <Col lg="6" md="10" sm="12">
                            <Card small className="mb-4">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">{this.props.match.params.id ? strings.edit_table : strings.create_table}</h6>
                                </CardHeader>
                                <ListGroup flush>
                                    <ListGroupItem className="p-3">
                                        <Row>
                                            <Col>
                                                <Form id="TableForm">
                                                    <label htmlFor="code">{strings.table_name}</label>
                                                    <InputGroup seamless className="mb-3">
                                                        <InputGroupAddon type="prepend">
                                                            <InputGroupText>
                                                                <i className="material-icons">dashboard</i>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <FormInput
                                                            type="text"
                                                            id="code"
                                                            value={name}
                                                            placeholder={strings.table_name}
                                                            onChange={e =>
                                                                this.setState({ name: e.target.value })
                                                            }
                                                        />
                                                    </InputGroup>
                                                    <Select
                                                        options={this.state.spaces}
                                                        onChange={(e) => {
                                                            console.log('event', e);
                                                            const selectedValue = e;
                                                            if (selectedValue) {
                                                                this.setState({
                                                                    selectedSpace: e
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    selectedSpace: ""
                                                                })
                                                            }
                                                        }}
                                                        placeholder={strings.select_space}
                                                        value={this.state.selectedSpace}
                                                        styles={customStyles}
                                                    />
                                                    <ToastContainer />
                                                    <Button
                                                        theme="accent"
                                                        onClick={() => this.handleSaveButtonClick()}
                                                        disabled={this.state.isButtonDisabled}
                                                    >
                                                        <i
                                                            className={this.state.loader}
                                                            style={{ fontSize: "15px" }}
                                                        ></i>{" "}
                                                        {strings.save}
                                                    </Button>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col>
                            <Link to={"/tables"} className="float-left">
                                <Button
                                    id="go_back"
                                    theme="light"
                                    className="mb-2 mr-1"
                                    style={{
                                        marginTop: "-84px",
                                        position: "relative",
                                        left: "-100px"
                                    }}
                                >
                                    {strings.go_back}
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
