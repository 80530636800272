import React, { Component } from 'react';
import { Row, Col, FormInput, InputGroup } from 'shards-react';
import { API_URL, Language } from '../../config';
import { Redirect} from "react-router-dom";
import { strings } from '../../localization/Login';
import styles from './../../views/Style.module.scss';
import Logo from './../../images/ebol_logo.png';
import SuccessMessage from './SuccessMessage';




export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    console.log('props: Reset Password',props)
    strings.setLanguage(Language);
    this.state = {
      password: "",
      confirmPassword: "",
      errors: "",
      hide: true,
      confirmPasswordVisible: true,
      isPasswordChange: false,
      showLoader: false,
      informMessage: '',
      code: ''
    };
    // alert(this.props.route);
  }

  confirmResetPassword = () => {
    let password = this.state.password;
    
    let confirmPassword = this.state.confirmPassword;
    this.setState({showLoader: true});
    if (password === "") {
      this.setState({
        errors: strings.require_new_password,
         showLoader: false
      });
    }else if (confirmPassword === "") {
      this.setState({
        errors: strings.require_new_confirm_password,
         showLoader: false
      });
    }
    //  else if (password.length < 6) {
    //   this.setState({
    //     errors: ,
    //      showLoader: false
    //   });
    // } 
    else if (password !== confirmPassword){
      this.setState({
        errors: strings.confirm_password_not_matched,
         showLoader: false
      });
    } else {
      this.resetApiCall()
    }
  }

  componentDidMount(){
    // const {code} = this.props.match.params
    // console.log('code is ', this.props.match)
    //  let search = "";
    //  let code = "";
    //   search = this.props.location.search;
    //  if (search !== undefined) {
    //      code = new URLSearchParams(search).get("code");
    //  }
    // if (code !== undefined) {
    //   console.log("code is >>",code)
      
    // }
    // const code = this.props.match
    // console.log('test', code)
    const search = this.props.location.search;
    const searchQueryCode = new URLSearchParams(search).get("code");
    console.log("🚀 ~ file: ResetPassword.js ~ line 82 ~ ResetPassword ~ componentDidMount ~ searchQueryCode", searchQueryCode)
    if (searchQueryCode !== null && searchQueryCode !== undefined) {
      let localData = this.state
      localData.code = searchQueryCode;
      this.setState({localData})
      console.log("🚀 ~ file: ResetPassword.js ~ line 85 ~ ResetPassword ~ componentDidMount ~ localData", localData)
    }
  }

  resetApiCall = async() => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    let bodyData = {
      code: this.state.code,
      newPassword: this.state.password
    };
    fetch(`${API_URL}resetPassword`,{
      method: 'PUT',
      credentials: "include",
      headers: headers,
      body: JSON.stringify(bodyData)
     })
     .then(async(response) => {
      console.log('response from change-password is', response)
       if (response.status === 200) {
          let data = await response.json()
          console.log('response in 200 is', data)
            this.setState({
            // success: strings.send_success + " your email",
            password: "",
            confirmPassword: "",
            isPasswordChange: true,
            showLoader: false,
            informMessage: data.message
            // orgcode: ""
          });
       }else{
          let data = await response.json()
          console.log('response not in 200 is', data)
               this.setState({
                // success: strings.send_success + " your email",
                // password: "",
                // confirmPassword: "",
                errors: data.message,
                isEmailEntered: false,
                showLoader: false,
                informMessage: ''
                // orgcode: ""
              }); 
       }
     })
  }

  render() {
    // const search = this.props.location.search;
    // const product_id = new URLSearchParams(search).get("code");
    // console.log("test", this.props.location)
    // const data = this.props.location
    // console.log('test', data)
    const { hide, password, confirmPassword, confirmPasswordVisible, code } = this.state; 
    
      console.log("🚀 ~ file: ResetPassword.js ~ line 143 ~ ResetPassword ~ render ~ this.state", this.state.code)
    // if (code === '') {
    //   return(
    //   <Redirect to="/login" />
    //   )
    // }else{
    return (
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Row>
            <Col lg="12">
              <form>
                <img
                  src={Logo}
                  alt=""
                  height="80"
                  id="main_page_logo"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '50%'
                  }}
                />
                <div>
                  {this.state.isPasswordChange ? (
                    <>
                      <SuccessMessage 
                                message = {this.state.informMessage}
                       />
                    </>
                  ) : (
                    <>
                      <div>
                        <h4 className="text-black mt-4 text-left fw-bold">
                          {strings.reset_new_password}
                        </h4>
                        <p className="mt-2" style={{ fontSize: '12px' }}>
                          {strings.reset_new_password_instruction}
                        </p>
                        {this.state.errors &&
                                 <div
                                  className="alert alert-danger alert-dismissible fade show mt-2"
                                  role="alert"
                                >
                                  {this.state.errors}
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                    onClick={() => this.setState({ errors: "" })}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                            }
                        <div className="mt-3">
                          <label htmlFor="password">
                            {strings.new_password}
                          </label>
                          <InputGroup seamless className="mt-1">
                            <FormInput
                              type={hide ? 'password' : 'input'}
                              id="password"
                              placeholder={strings.new_password_placeholder}
                              value={password}
                              onChange={e =>
                                this.setState({ password: e.target.value })
                              }
                            />{' '}
                            <span
                              className={styles.password__show}
                              onClick={() =>
                                this.setState({ hide: !this.state.hide })
                              }
                              //   onClick={this.hideSwitch}
                            >
                              {hide ? (
                                <i
                                  className="material-icons"
                                  style={{ top: '-5px', zIndex: '9999' }}
                                >
                                  visibility
                                </i>
                              ) : (
                                <i
                                  className="material-icons"
                                  style={{ top: '-5px', zIndex: '9999' }}
                                >
                                  visibility_off
                                </i>
                              )}
                            </span>
                          </InputGroup>
                        </div>

                        <div className="mt-3">
                          <label htmlFor="password">
                            {strings.reset_confirm_password}
                          </label>
                          <InputGroup seamless className="mt-1">
                            <FormInput
                              type={confirmPasswordVisible ? 'password' : 'input'}
                              id="password"
                              placeholder={strings.reset_confirm_password_placeholder}
                              style={{}}
                              value={confirmPassword}
                              onChange={e =>
                                this.setState({ confirmPassword: e.target.value })
                              }
                            />{' '}
                            <span
                              className={styles.password__show}
                              onClick={() =>
                                this.setState({ confirmPasswordVisible: !this.state.confirmPasswordVisible })
                              }
                              //   onClick={this.hideSwitch}
                            >
                              {confirmPasswordVisible ? (
                                <i
                                  className="material-icons"
                                  style={{ top: '-5px', zIndex: '9999' }}
                                >
                                  visibility
                                </i>
                              ) : (
                                <i
                                  className="material-icons"
                                  style={{ top: '-5px', zIndex: '9999' }}
                                >
                                  visibility_off
                                </i>
                              )}
                            </span>
                          </InputGroup>
                        </div>
                        
                         <div className="d-flex justify-content-center align-items-center mt-3">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={e => this.confirmResetPassword(e)}
                            style= {{
                              letterSpacing:'1px',
                              height: '40px',
                              width: '100%',
                              display: 'flex',
                              justifyContent:'center',
                              alignItems:'center',
                            }}
                          >
                            {/* <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i> */}
                            {this.state.showLoader &&  <span className="spinner-border spinner-border-sm mx-1" role="status"  aria-hidden="true"></span>}
                            &nbsp;&nbsp;{strings.confirm}&nbsp;<span><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                          </button>
                          </div>

                      </div>
                    </>
                  )}
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  // }
}

export default ResetPassword;
