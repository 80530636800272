import React, { Component } from 'react';
import { strings } from "./../../localization";
import { Language, API_URL, LIMIT, roundWithTwoDecimals, DISPATCH_TYPE, BARCODE_RESPONSE_HEIGHT, BARCODE_RESPONSE_WIDTH, BARCODE_WIDTH, BARCODE_P_LEFT, BARCODE_P_WIDTH, getBarcodeValuesFromResponse, getBarcodeImageHeight, getItemMainPrice, getTotalPriceOfItem } from "./../../config";
import writtenNumber from 'written-number';
import { FormInput, FormTextarea, Button, Alert } from "shards-react";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import Invoice from './Invoice'
import moment from 'moment';
let barcodeImage = ''
let printNumber = ''
let debitNoteReference = ''
var responseHeight = '';
var responseWidth = '';
var pLeft = BARCODE_P_LEFT;
var pWidth = BARCODE_P_WIDTH;
let barcodeWidth = BARCODE_WIDTH;
let barcodeHeight = 0

export class SampleInvoice extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);
    this.componentRef = React.createRef(null);
    this.state = {
      totalPaid: '',
      return: '',
      date: '',
      time: '',
      taxAmount: '',
      dataArray: [],
      taxPR: 19,
      displayType: '',
      dataArray: '',
      paymentMode: '',
      totalDiscount: '',
      type: '',
      displayDiscount: '',
      payable: '',
      // return: '',
      date: '',
      time: '',
      tax: false,
      rutNumber: '',
      name: '',
      addr1: '',
      addr2: '',
      country: '',
      city: '',
      state: '',
      contact: '',
      bDetails: '',
      view: '',
      loading: false,
      organisation_info: [],
      is_creating_record: false,
      showInvoiceScreen: false,
      propinaAmount: 0,
      propinaChecked: null,
      propinaPercentage: 0,
      showPermissionForPropina: null,
      visible: false,
      companyLogo: '',
      organisation_id: '',
      message: '',
      references: [],
      transportData: [],
      transactionDocumentId: '',
      transactionDocumentType: '',
      editProductData: [],
      editProductFromTransaction: false,
      debitNoteReferencias: '',
      email: ""
    };
  }

  parseNumberToInt = (value) => {
    if (value) {
      value = value.replace('$', '');
      return parseInt(value.replace(/\./g, ''));
    }
  };
  storeInDB = async () => {
    let var_tax = undefined;
    let typeOfDocument;
    let displayType;
    if (this.state.type == 1) {
      typeOfDocument = 39;
      displayType = 'Boleta';
    } else if (this.state.type == 2) {
      typeOfDocument = 33;
      displayType = 'Factura';
    } else if (this.state.type == 5) {
      typeOfDocument = 56;
      displayType = 'débito';
    } else if (this.state.type == 4) {
      typeOfDocument = 52;
      displayType = 'GUIA DE DESPACHO';
    } else if (this.state.type == 6) {
      typeOfDocument = 99;
      displayType = 'Cotizacion';
    }

    this.setState({
      loading: true,
    });

    let taxAmount = 0;
    let dataArray = this.state.dataArray;
    let withoutTax = true;
    if (typeOfDocument === 39) {
      dataArray.map((data, i) => {
        taxAmount = taxAmount + data.iva;
        dataArray[i].FAWT = data.total - data.iva;
      });
    }
    dataArray.map((data, i) => {
      // taxAmount = taxAmount + data.iva;
      // dataArray[i].FAWT = data.total - data.iva;
      console.log("🚀 ~ file: SampleInvoice.js ~ line 104 ~ SampleInvoice ~ dataArray.map ~ data", data)
      if (data.tax === true) {
        withoutTax = false;
      }
    });
    console.log('I am dataArray', dataArray);
    let netAmount = 0;

    let totalExcempto = 0;

    dataArray.map((e) => {
      console.log('I am tax', e.tax);
      if (e.tax == true) {
        console.log('i am tax true', e.total);
        // netAmount = netAmount + e.total;
        if (this.state.type === 2 || this.state.type === 4 || this.state.type === 6) {
          netAmount = netAmount + e.FAWT;
        } else {
          netAmount = netAmount + e.total;
        }
        var_tax = true
        this.setState({ tax: true }, () => {

          console.log('tax.........................', this.state.tax);
        });
      } else if (e.tax == false) {
        console.log('i am tax false', e.total);
        totalExcempto = totalExcempto + e.total;
      }
    });
    if (this.state.type === 2 || this.state.type === 4 || this.state.type === 6) {
      taxAmount = Math.round(netAmount * 0.19);
      this.setState({
        taxAmount: taxAmount,
      });
    }
    console.log('totalPaid', this.state.totalPaid, this.state.propinaAmount)
    console.log('totalpayable', this.state.payable, this.state.propinaAmount)
    console.log('return', this.state.return)
    console.log()
    console.log()
    let totalPaid = this.parseNumberToInt(this.state.totalPaid) - this.state.propinaAmount;
    let payable = this.parseNumberToInt(this.state.payable) - this.state.propinaAmount;
    let strings = {
      typeOfDocument: typeOfDocument,
      totalPaid: totalPaid,
      // totalPaid: this.parseNumberToInt(this.state.totalPaid),
      return: this.parseNumberToInt(this.state.return),
      date: this.state.date,
      time: this.state.time,
      taxAmount: taxAmount,
      tasaIVA: 19,
      netAmount: netAmount,
      totalExcempto: totalExcempto,
      dataArray: dataArray,
      taxPR: 19,
      displayType: displayType,
      paymentMode: this.state.paymentMode,
      type: this.state.type,
      displayDiscount: 0, //static manually set
      payable: payable,
      // payable: this.parseNumberToInt(this.state.payable),
      tax: var_tax,
      rutNumber: this.state.rutNumber,
      bDetails: this.state.bDetails,
      propina: { 'percentage': this.state.propinaPercentage, 'amount': this.state.propinaAmount, 'checked': this.state.propinaChecked },
      name: this.state.name,
      addr1: this.state.addr1,
      addr2: this.state.addr2,
      city: this.state.city,
      country: this.state.country,
      state: this.state.state,
      contact: this.state.contact,
      view: false, //static manually set
      loading: true, //static manually set
      comment: this.state.comment,
      withoutTax: withoutTax,
      caseRef: '', //static manually set
      razonRef: '', // static manually set
      mainPrice: this.state.mainPrice,
      totalDiscount: this.state.totalDiscount,
      view: this.state.view,
      references: this.state.references,
      transportData: this.state.transportData,
      email: this.state.email,
      source: "portal"
    };
    if (this.state.type !== undefined && this.state.type === 4) {
      strings['dispatchType'] = DISPATCH_TYPE
    }

    console.log('====================== string objects**********', strings);


    let apiName = 'bills';
    if (this.state.type === 2) {
      apiName = 'invoices';
    } else if (this.state.type === 3) {
      apiName = 'creditnotes';
    } else if (this.state.type === 4) {
      apiName = 'dispatchnotes';
    } else if (this.state.type === 5) {
      apiName = 'debitnotes';
    } else if (this.state.type === 6) {
      apiName = 'quotes';
    } else {
      apiName = 'bills';
    }

    console.log('Creating Record....................');

    // this.setState({
    //   showInvoiceScreen: true
    // })

    /*var _id = '607a91ea16370f6a50cb2ec6';

    API_URL().then((API_URL) => {
      fetch(`${API_URL}${apiName}/${_id}?fields=true&c_transactionNumber=1`, {
        method: 'get',
        headers: {
          Cookie: cookie,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          var c_transactionNumber = res.data.transactionNumber;
          return c_transactionNumber;
        })
        .then((res) => {
          alert(res + ' Res');
        });
    });*/
    if (
      this.state.type !== undefined &&
      this.state.type === 5 &&
      this.state.transactionDocumentId !== undefined &&
      this.state.transactionDocumentId !== '' &&
      this.state.transactionDocumentType !== undefined &&
      this.state.transactionDocumentType !== '' &&
      this.state.transactionDocumentType === 'invoices'
    ) {
      let bodyData = {
        dataArray: dataArray,
        totalDiscount: this.state.totalDiscount,
      };
      let statusCode = 0;
      console.log(`debit for invoice : ${API_URL}debitnotes?invoice=${this.state.transactionDocumentId}`)
      fetch(`${API_URL}debitnotes?invoice=${this.state.transactionDocumentId}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(bodyData),
      }).then((res) => {
        statusCode = res.status;
        return res.json();
      }).then((res) => {
        if (statusCode === 200) {
          console.log('debit note for invoice api response', res);
          let transaction_id = res._id;
          fetch(`${API_URL}barcode/${apiName}/${transaction_id}?withoutTax=${this.state.withoutTax}&responseType=json`, {
            method: 'get',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include'
          })
            .then((response) => {
              console.log("response in barcode api......", response);
              return response.json();
            })
            .then((result) => {
              console.log("result in barcode api than method result......", result);
              if (result.message) {
                this.setState({
                  visible: true,
                  message: result.message,
                });
              } else {
                const data = getBarcodeValuesFromResponse(result);
                responseHeight =
                  data.responseHeight;
                responseWidth = data.responseWidth;
                pLeft = data.pLeft;
                pWidth = data.pWidth;

                // barcodeHeight =
                //   (barcodeWidth * responseWidth) / responseHeight;
                // barcodeHeight = Math.round(barcodeHeight);

                barcodeHeight = getBarcodeImageHeight(barcodeWidth, responseHeight, responseWidth);

                barcodeImage = data.barcodeImage;

                console.log(`sample invoice emitter second api : ${API_URL}${apiName}/${transaction_id}?fields=true&c_transactionNumber=1`,)
                fetch(
                  `${API_URL}${apiName}/${transaction_id}?fields=true&c_transactionNumber=1`,
                  {
                    method: 'get',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                  },
                )
                  .then((res) => res.json())
                  // .then((res) => {
                  //   var c_transactionNumber = res.data.transactionNumber;
                  //   return c_transactionNumber;
                  // })
                  .then((res) => {
                    console.log("🚀 ~ file: SampleInvoice.js:331 ~ SampleInvoice ~ .then ~ res", res)
                    printNumber = res.data.transactionNumber;
                    debitNoteReference = res.data.reference_text;
                    this.setState({
                      showInvoiceScreen: true
                    })

                  });
              }
              this.setState({
                loading: false,
              });
              console.log('Created Record...');
            })
            .catch((err) => { });
          // this.setState({
          //    showInvoiceScreen: true
          // })
        } else if (statusCode === 400) {
          this.setState({
            visible: true,
            message: res.message,
          });
        } else {
          console.log('debit note for invoice api error', res);
        }
        this.setState({
          loading: false,
        });
      }).catch((err) => {
        console.log('debit note for invoice api catch block error', err)
        this.setState({
          loading: false,
        });
      })
    } else {
      fetch(`${API_URL}${apiName}`, {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify(strings),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log('========= store db response', res);
          if (res._id) {
            let transaction_id = res._id;
            fetch(`${API_URL}${apiName}/${transaction_id}`, {
              method: 'get',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              credentials: 'include'
            })
              .then((res) => res.json())
              .then((res) => {
                fetch(`${API_URL}barcode/${apiName}/${transaction_id}?withoutTax=${this.state.withoutTax}&responseType=json`, {
                  method: 'get',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  credentials: 'include'
                })
                  .then((response) => {
                    console.log("response in barcode api......", response);
                    return response.json();
                  })
                  .then((result) => {
                    console.log("result in barcode api than method result......", result);
                    if (result.message) {
                      this.setState({
                        visible: true,
                        message: result.message,
                      });
                    } else {
                      const data = getBarcodeValuesFromResponse(result);
                      responseHeight =
                        data.responseHeight;
                      responseWidth = data.responseWidth;
                      pLeft = data.pLeft;
                      pWidth = data.pWidth;
                      // barcodeHeight =
                      //   (barcodeWidth * responseWidth) / responseHeight;
                      // barcodeHeight = Math.round(barcodeHeight);

                      barcodeHeight = getBarcodeImageHeight(barcodeWidth, responseHeight, responseWidth);

                      barcodeImage = data.barcodeImage;

                      console.log(`sample invoice emitter second api : ${API_URL}${apiName}/${transaction_id}?fields=true&c_transactionNumber=1`,)
                      fetch(
                        `${API_URL}${apiName}/${transaction_id}?fields=true&c_transactionNumber=1`,
                        {
                          method: 'get',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                          credentials: 'include'
                        },
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          var c_transactionNumber = res.data.transactionNumber;
                          return c_transactionNumber;
                        })
                        .then((res) => {
                          printNumber = res;
                          this.setState({
                            showInvoiceScreen: true
                          })

                        });
                    }
                    this.setState({
                      loading: false,
                    });
                    console.log('Created Record...');
                  })
                  .catch((err) => { });
              });
          } else {
            console.log('========= store db response', res.message);
            this.setState({
              loading: false,
              visible: true,
              message: res.message,
              color: 'red',
            });
          }
        })
        .catch((err) => {
          console.log('Error in store db ......')
          this.setState({
            loading: false,
          });
        });
    }
  };

  editedDataStoreInDb = async () => {
    let var_tax = undefined;
    let typeOfDocument;
    let displayType;
    if (this.state.type == 1) {
      typeOfDocument = 39;
      displayType = 'Boleta';
    } else if (this.state.type == 2) {
      typeOfDocument = 33;
      displayType = 'Factura';
    } else if (this.state.type == 5) {
      typeOfDocument = 56;
      displayType = 'débito';
    } else if (this.state.type == 4) {
      typeOfDocument = 52;
      displayType = 'GUIA DE DESPACHO';
    } else if (this.state.type == 6) {
      typeOfDocument = 99;
      displayType = 'Cotizacion';
    }

    this.setState({
      loading: true,
    });

    let taxAmount = 0;
    let dataArray = this.state.dataArray;
    let withoutTax = true;
    if (typeOfDocument === 39) {
      dataArray.map((data, i) => {
        taxAmount = taxAmount + data.iva;
        dataArray[i].FAWT = data.total - data.iva;
      });
    }
    dataArray.map((data, i) => {
      // taxAmount = taxAmount + data.iva;
      // dataArray[i].FAWT = data.total - data.iva;
      console.log("🚀 ~ file: SampleInvoice.js ~ line 104 ~ SampleInvoice ~ dataArray.map ~ data", data)
      if (data.tax === true) {
        withoutTax = false;
      }
    });
    console.log('I am dataArray', dataArray);
    let netAmount = 0;

    let totalExcempto = 0;

    dataArray.map((e) => {
      console.log('I am tax', e.tax);
      if (e.tax == true) {
        console.log('i am tax true', e.total);
        // netAmount = netAmount + e.total;
        if (this.state.type === 2 || this.state.type === 4 || this.state.type === 6) {
          netAmount = netAmount + e.FAWT;
        } else {
          netAmount = netAmount + e.total;
        }
        var_tax = true
        this.setState({ tax: true }, () => {

          console.log('tax.........................', this.state.tax);
        });
      } else if (e.tax == false) {
        console.log('i am tax false', e.total);
        totalExcempto = totalExcempto + e.total;
      }
    });
    if (this.state.type === 2 || this.state.type === 4 || this.state.type === 6) {
      taxAmount = Math.round(netAmount * 0.19);
      this.setState({
        taxAmount: taxAmount,
      });
    }
    console.log('totalPaid', this.state.totalPaid, this.state.propinaAmount)
    console.log('totalpayable', this.state.payable, this.state.propinaAmount)
    console.log('return', this.state.return)
    console.log()
    console.log()
    let totalPaid = this.parseNumberToInt(this.state.totalPaid) - this.state.propinaAmount;
    let payable = this.parseNumberToInt(this.state.payable) - this.state.propinaAmount;
    let strings = {
      typeOfDocument: typeOfDocument,
      totalPaid: totalPaid,
      // totalPaid: this.parseNumberToInt(this.state.totalPaid),
      return: this.parseNumberToInt(this.state.return),
      // date: this.state.date,
      // time: this.state.time,
      taxAmount: taxAmount,
      tasaIVA: 19,
      netAmount: netAmount,
      totalExcempto: totalExcempto,
      dataArray: dataArray,
      taxPR: 19,
      displayType: displayType,
      paymentMode: this.state.paymentMode,
      type: this.state.type,
      displayDiscount: 0, //static manually set
      payable: payable,
      // payable: this.parseNumberToInt(this.state.payable),
      tax: var_tax,
      rutNumber: this.state.rutNumber,
      bDetails: this.state.bDetails,
      propina: { 'percentage': this.state.propinaPercentage, 'amount': this.state.propinaAmount, 'checked': this.state.propinaChecked },

      name: this.state.name,
      addr1: this.state.addr1,
      addr2: this.state.addr2,
      city: this.state.city,
      country: this.state.country,
      state: this.state.state,
      contact: this.state.contact,
      view: false, //static manually set
      loading: true, //static manually set
      comment: this.state.comment,
      withoutTax: withoutTax,
      caseRef: '', //static manually set
      razonRef: '', // static manually set
      mainPrice: this.state.mainPrice,
      totalDiscount: this.state.totalDiscount,
      view: this.state.view,
      references: this.state.references,
      transportData: this.state.transportData,
      email: this.state.email,
      source: "portal"
    };
    if (this.state.type !== undefined && this.state.type === 4) {
      strings['dispatchType'] = DISPATCH_TYPE
    }

    console.log('====================== string objects of edit item **********', strings);
    let newStrings = { 'data': strings };
    console.log('====================== new string objects of edit item **********', strings);

    let apiName = 'bills';
    if (this.state.type === 2) {
      apiName = 'invoices';
    } else if (this.state.type === 3) {
      apiName = 'creditnotes';
    } else if (this.state.type === 4) {
      apiName = 'dispatchnotes';
    } else if (this.state.type === 5) {
      apiName = 'debitnotes';
    } else if (this.state.type === 6) {
      apiName = 'quotes';
    } else {
      apiName = 'bills';
    }

    console.log('Creating Record....................');
    fetch(`${API_URL}${apiName}/${this.state.editProductData._id}`, {
      method: 'put',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(newStrings),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log('========= edit store db response', res);
        if (res._id) {
          let transaction_id = res._id;
          fetch(`${API_URL}${apiName}/${transaction_id}`, {
            method: 'get',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include'
          })
            .then((res) => res.json())
            .then((res) => {
              fetch(`${API_URL}barcode/${apiName}/${transaction_id}?withoutTax=${this.state.withoutTax}&responseType=json`, {
                method: 'get',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                credentials: 'include'
              })
                .then((response) => {
                  console.log("response in edit item barcode api......", response);
                  return response.json();
                })
                .then((result) => {
                  console.log("result in barcode api than method result......", result);
                  if (result.message) {
                    this.setState({
                      visible: true,
                      message: result.message,
                    });
                  } else {
                    const data = getBarcodeValuesFromResponse(result);
                    responseHeight =
                      data.responseHeight;
                    responseWidth = data.responseWidth;
                    pLeft = data.pLeft;
                    pWidth = data.pWidth;
                    // barcodeHeight =
                    //   (barcodeWidth * responseWidth) / responseHeight;
                    // barcodeHeight = Math.round(barcodeHeight);

                    barcodeHeight = getBarcodeImageHeight(barcodeWidth, responseHeight, responseWidth);

                    barcodeImage = data.barcodeImage;

                    console.log(`sample invoice emitter second api : ${API_URL}${apiName}/${transaction_id}?fields=true&c_transactionNumber=1`,)
                    fetch(
                      `${API_URL}${apiName}/${transaction_id}?fields=true&c_transactionNumber=1`,
                      {
                        method: 'get',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },
                        credentials: 'include'
                      },
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        var c_transactionNumber = res.data.transactionNumber;
                        return c_transactionNumber;
                      })
                      .then((res) => {
                        printNumber = res;
                        this.setState({
                          showInvoiceScreen: true
                        })

                      });
                  }
                  this.setState({
                    loading: false,
                  });
                  console.log('Created Record...');
                })
                .catch((err) => { });
            });
        } else {
          console.log('========= store db response', res.message);
          this.setState({
            loading: false,
            visible: true,
            message: res.message,
            color: 'red',
          });
        }
      })
      .catch((err) => {
        console.log('Error in store db ......')
        this.setState({
          loading: false,
        });
      });
  };

  updateData = async () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let time = today.toLocaleTimeString();
    today = dd + '/' + mm + '/' + yyyy;

    const param = this.props;
    console.log('*******', param);
    if (param !== undefined) {
      if (param.dataArray !== undefined) {
        let value = param.totalDiscount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // alert(param.rutNumber);

        this.setState(
          {
            dataArray: param.dataArray,
            paymentMode: param.paymentMode,
            totalDiscount: param.totalDiscount,
            type: param.type,
            displayDiscount: value,
            totalPaid: param.totalPaid,
            payable: param.total,
            return: param.return,
            date: today,
            time: time,
            tax: param.tax,
            rutNumber: param.rutNumber,
            name: param.name,
            addr1: param.addr1,
            addr2: param.addr2,
            country: param.country,
            city: param.city,
            state: param.state,
            contact: param.contact,
            comment: param.comment,
            bDetails: param.bDetails,
            view: param.view,
            withoutTax: param.withoutTax,
            propinaAmount: param.propinaAmount,
            propinaChecked: param.propinaChecked,
            propinaPercentage: param.propinaPercentage,
            showPermissionForPropina: param.showPermissionForPropina,
            references: param.references,
            transportData: param.transportData,
            transactionDocumentId: param.transactionDocumentId,
            transactionDocumentType: param.transactionDocumentType,
            email: param.email
          },
          () => {
            this.afterStateUpdate();
          },
        );

        // param.dataArray = undefined;
      }
      if (
        param.type !== undefined &&
        param.type === 5 &&
        param.transactionDocumentId !== undefined &&
        param.transactionDocumentId !== '' &&
        param.transactionDocumentType !== undefined &&
        param.transactionDocumentType !== '' &&
        param.transactionDocumentType === 'invoices') {
        fetch(`${API_URL}${param.transactionDocumentType}/${param.transactionDocumentId}`, {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        })
          // .then((res) => {
          //   console.log('======responce s', res);
          //   res.json();
          // })
          .then(res => res.json())
          .then(({ data }) => {
            console.log(
              '********************************************** data in invoice',
              data
            );
            if (data !== undefined) {
              let date = data.date ? data.date : '';
              let time = data.time ? data.time : '';

              if (param.newDate) {
                date = today;
                time = time;
              }
              let debitNoteReferenciasFromData = data.transactionNumber !== undefined ? `Corrige montos Factura Electrónica ${data.transactionNumber}` : '';
              let value = data.totalDiscount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              this.setState(
                {
                  // dataArray: data.dataArray,
                  // totalPaid: data.totalPaid,
                  // isCancel: data.isCancel ? data.isCancel : 0,
                  // creditnoteNumber: data.creditnoteNumber
                  //   ? data.creditnoteNumber
                  //   : 0,
                  // payable: data.payable,
                  return: data.return.toString(),
                  paymentMode: data.paymentMode,
                  // totalDiscount: data.totalDiscount,
                  rutNumber: data.rutNumber,
                  name: data.name,
                  addr1: data.addr1,
                  addr2: data.addr2,
                  country: data.country,
                  state: data.state,
                  city: data.city,
                  contact: data.contact,
                  razonRef: data.razonRef,
                  referenceTransactionType: data.referenceTransactionType,
                  netAmount: data.netAmount,
                  comment: data.comment,
                  bDetails: data.bDetails,
                  // type: data.type,
                  view: data.view,
                  withoutTax: data.withoutTax,
                  displayDiscount: value,
                  loading: false,
                  propina: data.propina,
                  // printNumber: param.printNumber,
                  // barcodeImage: param.barcodeImage,
                  // barcodeHeight: param.barcodeHeight,
                  // barcodeWidth: param.barcodeWidth,
                  // pLeft: param.pLeft,
                  // date: date,
                  // time: time,
                  // pWidth: param.pWidth,
                  references: param.references,
                  debitNoteReferencias: debitNoteReferenciasFromData,
                  email: data.email
                },
                () => {
                  this.afterStateUpdate();
                }
              );
            }
          })
          .catch(e => {
            // console.log('$$$$$$$$$$$$$$$$$$$');
            console.log('I am catch', e);
          });
      }
    }
  };
  formateNumber = value => {
    // return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (value.toString().includes('.')) {
      value = value.toString().replace('.', ',')
    }
    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.',);
  };
  afterStateUpdate = () => {
    let type = this.state.type;

    if (type === 1) {
      type = strings.bills;
    } else if (type === 2) {
      type = strings.invoice;
    } else if (type === 3) {
      type = strings.credit_note;
    } else if (type === 4) {
      type = strings.delivery_note;
    } else if (type === 5) {
      type = strings.debit_note;
    } else if (type === 6) {
      type = strings.quote;
    }

    this.setState({
      displayType: type,
    });
  };


  async componentDidMount() {
    this.updateData()
    writtenNumber.defaults.lang = 'es';
    let organisation_info = await localStorage.getItem('organisation_info');
    organisation_info = JSON.parse(organisation_info);

    this.setState({
      organisation_info: organisation_info.data,
      organisation_id: organisation_info._id,
    },
      () => this.getOrganisationData()
    );

    const params = this.props;
    console.log('params in sample invoices: ', params);
    if (params.editProductData !== undefined && params.editProductFromTransaction !== undefined && params.editProductFromTransaction) {
      this.setState({
        editProductData: params.editProductData,
        editProductFromTransaction: params.editProductFromTransaction,
      })
    }
  }

  getOrganisationData() {
    console.log('oragnize data id', this.state.organisation_id);

    if (this.state.organisation_id) {
      fetch(`${API_URL}organisations/${this.state.organisation_id}`, {
        method: "GET",
        credentials: "include"
      })
        .then(res => res.json())
        .then(({ data }) => {
          console.log('organization api response is : ', data);
          this.setState({
            companyLogo: data.companyLogo
          });
        })
    } else {
      console.log('id is not found in organization api', this.state.organisation_info.id);
    }
  }

  render() {
    // console.log('Sample invoice props....', this.props)
    console.log("Sample invoice state.....", this.props)
    console.log('company logo is', this.state.companyLogo)
    console.log('debitNoteReference is', debitNoteReference)

    var subtotal = 0;
    var totalAfecto = '';
    var taxAmount = 0;
    var totalAll = 0;
    var neto = 0;
    let discountAmount = 0;


    return (
      <>
        {!this.state.showInvoiceScreen ? <>
          <div

          >
            <div
              ref={this.componentRef}
              className="sample-invoices"
              style={{
                backgroundColor: '#fff',
                width: '100%',
                borderColor: '#999999',
                borderWidth: 1,
                padding: 20
              }}>

              <div>
                <p style={{
                  textAlign: 'center',
                  fontSize: 15,
                  opacity: 0.7,
                }} >
                  -----------------------------------------------
                </p>
                <p style={{
                  textAlign: 'center',
                  fontSize: 15,
                  opacity: 0.7,
                }}>
                  R.U.T. :{' '}
                  {this.state.organisation_info.hasOwnProperty('rutNumber')
                    ? this.state.organisation_info['rutNumber']
                    : null}
                </p>
                {this.state.date && this.state.time ? (
                  <p style={{
                    textAlign: 'center',
                    fontSize: 15,
                    opacity: 0.7,
                  }}>
                    Fecha : {this.state.date} {this.state.time}
                  </p>
                ) : null}
                <p style={{
                  textAlign: 'center',
                  fontSize: 15,
                  opacity: 0.7,
                }} >
                  -----------------------------------------------
                </p>
              </div>

              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  marginTop: '10px'
                }}
              >
                {
                  this.state.companyLogo !== undefined
                  && this.state.companyLogo !== ''
                  && <img
                    style={{
                      width: "auto",
                      height: "100px",
                    }}
                    src={this.state.companyLogo}
                    alt="EBOL"
                  />
                }
              </div>


              {this.state.organisation_info.hasOwnProperty('name') ? (
                <p style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}>
                  {this.state.organisation_info['name']}
                </p>
              ) : null}


              {this.state.organisation_info.hasOwnProperty('address') ? (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  {this.state.organisation_info['address']}
                </p>
              ) : null}

              {this.state.organisation_info.hasOwnProperty('city') ||
                this.state.organisation_info.hasOwnProperty('state') ? (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  {/* {'\n'} */}
                  {this.state.organisation_info['city'] +
                    ', ' +
                    this.state.organisation_info['state']}
                </p>
              ) : null}

              {this.state.organisation_info.hasOwnProperty('giro') ||
                this.state.organisation_info.hasOwnProperty('giro') ? (
                <p
                  style={{ textAlign: 'left', marginTop: 10, fontSize: 15, opacity: 0.7, }}>
                  GIRO : {this.state.organisation_info['giro']}
                </p>
              ) : null}





              <p
                style={{ textAlign: 'center', fontSize: 15, opacity: 0.7, }}
              >
                -----------------------------------------------
              </p>



              {this.state.rutNumber !== undefined &&
                this.state.rutNumber !== '' && (
                  <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                    RUT : {this.state.rutNumber}
                  </p>
                )}



              {this.state.name !== undefined && this.state.name !== '' && (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  NOMBRE : {this.state.name.toUpperCase()}
                </p>
              )}


              {this.state.addr1 !== undefined && this.state.addr1 !== '' && (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  DIRECCION : {this.state.addr1.toUpperCase()}
                </p>
              )}



              {this.state.addr2 !== undefined && this.state.addr2 !== '' && (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  COMUNA : {this.state.addr2.toUpperCase()}
                </p>
              )}


              {this.state.city !== undefined && this.state.city !== '' && (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  CIUDAD : {this.state.city.toUpperCase()}
                </p>
              )}
              {this.state.state !== undefined && this.state.state !== '' && (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  REGION : {this.state.state.toUpperCase()} {this.state.country.toUpperCase()}
                </p>
              )}
              {this.state.bDetails !== undefined &&
                this.state.bDetails !== '' && (
                  <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                    GIRO : {this.state.bDetails.toUpperCase()}
                  </p>
                )}
              {this.state.contact !== undefined && this.state.contact !== '' && (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  NOMBRE DE CONTACTO : {this.state.contact.toUpperCase()}
                </p>
              )}
              {this.state.type !== undefined &&
                this.state.type === 5 &&
                this.state.transactionDocumentId !== undefined &&
                this.state.transactionDocumentId !== '' &&
                this.state.transactionDocumentType !== undefined &&
                this.state.transactionDocumentType !== '' &&
                this.state.transactionDocumentType === 'invoices' &&
                this.state.debitNoteReferencias !== undefined &&
                this.state.debitNoteReferencias !== '' && (
                  <p style={{ fontSize: 15, opacity: 0.7, textAlign: 'left' }}>
                    Referencias: {this.state.debitNoteReferencias}
                  </p>
                )}

              {/* Transport Section */}
              {this.state.type !== undefined &&
                this.state.type === 4 &&
                this.state.transportData !== undefined &&
                this.state.transportData.length > 0 &&
                <p
                  style={{ textAlign: 'center', fontSize: 15, opacity: 0.7, }}
                >
                  -----------------------------------------------
                </p>
              }

              {this.state.type !== undefined &&
                this.state.type === 4 &&
                this.state.transportData !== undefined &&
                this.state.transportData.length > 0
                ? this.state.transportData.map((data, index) => {
                  return (
                    <>
                      <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                        Tipo de traslado : {data.selectedTypeOfTransfer.label}
                      </p>
                      <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                        Dirección de destino : {data.destinationAddress}
                      </p>
                      <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                        Comuna de destino : {data.communityOfDestination}
                      </p>
                      <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                        Ciudad de destino : {data.destinationCity}
                      </p>
                      {data.patente !== undefined && data.patente !== '' && (
                        <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                          Patente : {data.patente}
                        </p>
                      )}
                      {data.transport !== undefined && data.transport !== '' && (
                        <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                          RUT transporte : {data.transport}
                        </p>
                      )}
                      {data.rutDriver !== undefined && data.rutDriver !== '' && (
                        <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                          RUT chofer : {data.rutDriver}
                        </p>
                      )}
                      {data.driverName !== undefined && data.driverName !== '' && (
                        <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                          Nombre chofer : {data.driverName}
                        </p>
                      )}
                    </>
                  )
                })
                : null
              }


              {(this.state.rutNumber !== undefined &&
                this.state.rutNumber !== '') ||
                (this.state.addr1 !== undefined && this.state.addr1 !== '') ||
                (this.state.addr2 !== undefined && this.state.addr2 !== '') ||
                (this.state.city !== undefined && this.state.city !== '') ||
                (this.state.country !== undefined && this.state.country !== '') ||
                (this.state.state !== undefined && this.state.state !== '') ||
                (this.state.phone !== undefined && this.state.phone !== '') ||
                (this.state.bDetails !== undefined && this.state.bDetails !== '') ||
                (this.state.name !== undefined && this.state.name !== '') ? (
                <p
                  style={{ textAlign: 'center', fontSize: 15, opacity: 0.7, }}
                >
                  -----------------------------------------------
                </p>
              ) : null}
              {this.state.dataArray.length > 0
                ? this.state.dataArray.map((data, i) => {
                  console.log(
                    '================================================================sample invoice data',
                    data,
                  );
                  let quantity = data.quantity.toString().replace(/\,/g, '.');
                  let total = parseFloat(quantity) * data.mainPrice;

                  // subtotal = subtotal + total; //commented at 2023-02-20
                  subtotal += data.total
                  subtotal = Math.round(roundWithTwoDecimals(subtotal));
                  totalAfecto = subtotal - this.state.totalDiscount;
                  totalAfecto = Math.round(roundWithTwoDecimals(totalAfecto));

                  // discount show logic
                  if (data.discount !== undefined && data.discount !== null) {
                    console.log('1111 in if data.discount', data.discount);
                    if (this.state.type === 2 || this.state.type === 5 || this.state.type === 4 || this.state.type === 6 || this.state.type === 1) {
                      discountAmount = parseFloat((data.percent ? data.discount * data.quantity : data.discount) / 1.19).toFixed(2);
                    } else {
                      discountAmount = data.percent ? data.discount * data.quantity : data.discount
                    }
                  } else {
                    console.log('1111 in else data.discount', data.discount);
                    discountAmount = 0;
                  }
                  console.log('1111 discountAmount', discountAmount);

                  if (this.state.type === 2 || this.state.type === 5 || this.state.type === 4 || this.state.type === 6) {
                    // neto = neto + data.FAWT; // this is commented on 2023-01-26
                    neto = neto + (data.FAWT - discountAmount);
                    taxAmount = Math.round(neto * 0.19);
                  } else if (this.state.type === 1) {
                    neto = neto + (data.FAWT);
                    taxAmount = taxAmount + data.iva;
                  } else {
                    neto = neto + data.total;
                    taxAmount = taxAmount + data.iva;
                  }
                  //console.log('---------------');
                  //console.log(data);
                  //console.log(neto);

                  //console.log(data.FAWT);
                  //console.log(data.iva);
                  // taxAmount = taxAmount + data.iva; //change at 2022-10-14

                  //console.log(taxAmount);
                  //totalAll = neto + taxAmount;
                  //totalAll = Math.round(totalAll);
                  totalAll += data.total;
                  //console.log('T: ' + taxAmount);
                  total = total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                  return (
                    <>
                      <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                        {data.description}
                      </p>
                      <div className="d-flex">
                        <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                          {data.quantity} {data.itemType} X{' '}
                          {/* Below line commented on 2024-08-05 by harshit
                            * Bill need to display same as invoice that's why
                            * Below code commented
                          */}
                          {/* {this.formateNumber(this.state.type === 4 ? Math.round(data.mainPrice) : data.mainPrice)} */}
                          {getItemMainPrice(data, this.state.type)}
                        </p>
                        <p
                          style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                          {/* {this.state.type === 2 ? data.FAWT : total} */}
                          {/* Below line commented on 2024-08-05 by harshit
                            * Bill need to display same as invoice that's why
                            * Below code commented
                          */}
                          {/* {this.state.type === 2 || this.state.type === 4 || this.state.type === 6 ? this.formateNumber(Math.round(data.FAWT)) : this.state.type === 5 ? this.formateNumber(parseFloat(data.FAWT)) : this.formateNumber(total)} */}
                          {getTotalPriceOfItem(data, this.state.type)}
                        </p>
                      </div>
                      {discountAmount !== undefined
                        && discountAmount !== null
                        && discountAmount > 0
                        && <div className="d-flex">
                          <p
                            style={{
                              fontSize: 15,
                              opacity: 0.7,
                              textAlign: 'left'
                            }}
                          >
                            DESCUENTO
                          </p>
                          <p
                            style={{
                              marginLeft: 'auto',
                              fontSize: 15,
                              opacity: 0.7
                            }}
                          >
                            {'- '}{this.formateNumber(discountAmount)}
                          </p>
                        </div>
                      }
                    </>
                  );
                })
                : null}

              {/* Below line commented on 2024-08-05 by harshit
              * Bill need to display same as invoice that's why
              * Below code commented
            */}
              {/* {this.state.type !== 2 && this.state.type !== 3 && this.state.type !== 5 && this.state.type !== 4 && this.state.type !== 6 && (
                <div className="d-flex">
                  <p
                    style={{ width: "60%", textAlign: 'right', fontSize: 15, opacity: 0.7, }}>
                    SUBTOTAL
                  </p>
                  <p style={{
                    textAlign: 'center',
                    fontSize: 15,
                    opacity: 0.7,
                    marginLeft: 18
                  }}> {'  '}$</p>
                  <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                    {subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </p>
                </div>
              )} */}

              {/* This is commented on 2023-01-26 for signle discount show */}
              {/* {this.state.type !== 2
                && this.state.displayDiscount !== undefined
                && this.state.displayDiscount !== null
                && this.state.displayDiscount > 0
                && (
                <div className="d-flex">
                  <p
                    style={
                      { width: '60%', textAlign: 'right', fontSize: 15, opacity: 0.7, }}>
                    DESCUENTO
                  </p>
                  <p style={{
                    textAlign: 'center',
                    fontSize: 15,
                    opacity: 0.7,
                    marginLeft: 18
                  }}> {'  '}$</p>
                  <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                    {this.state.displayDiscount}
                  </p>
                </div>
              )} */}

              {/* Below line commented on 2024-08-05 by harshit
              * Bill need to display same as invoice that's why
              * Below code commented
            */}
              {/* {this.state.type !== 1 && ( */}
              <>
                {this.state.type !== 2 && this.state.type !== 3 && this.state.type !== 5 && this.state.type !== 4 && this.state.type !== 6 && this.state.type !== 1 && (
                  <div className="d-flex">
                    <p
                      style={
                        {
                          fontSize: 15,
                          opacity: 0.7,
                          width: '60%', textAlign: 'right'
                        }}>
                      TOTAL AFECTO
                    </p>

                    <p style={{
                      textAlign: 'center',
                      fontSize: 15,
                      opacity: 0.7,
                      marginLeft: 18
                    }}> {'  '}$</p>
                    <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                      {totalAfecto
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </p>
                  </div>
                )}
                <div className="d-flex">
                  <p
                    style={
                      {
                        width: '60%', textAlign: 'right', fontSize: 15,
                        opacity: 0.7,
                      }
                    }>
                    {this.state.withoutTax === true ? 'MONTO EXENTO ' : 'NETO '}
                  </p>
                  <p style={{
                    textAlign: 'center',
                    fontSize: 15,
                    opacity: 0.7,
                    marginLeft: 18
                  }}> {'  '}$</p>
                  <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                    {Math.round(neto)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    {/* {parseInt(neto)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                        } */}
                  </p>
                </div>
                <div className="d-flex">
                  <p
                    style={
                      {
                        fontSize: 15,
                        opacity: 0.7,
                        width: '60%', textAlign: 'right'
                      }
                    }>
                    TOTAL IVA (19 %)
                  </p>
                  <p style={{
                    textAlign: 'center',
                    fontSize: 15,
                    opacity: 0.7,
                    marginLeft: 18
                  }}> {'  '}$</p>
                  <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                    {Math.round(taxAmount)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </p>
                </div>
              </>
              {/* )} */}

              <div className="d-flex">
                <p
                  style={{ width: '60%', textAlign: 'right', fontSize: 15, opacity: 0.7, }}>
                  TOTAL
                </p>
                <p style={{
                  textAlign: 'center',
                  fontSize: 15,
                  opacity: 0.7,
                  marginLeft: 18
                }}> {'  '}$</p>
                <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                  {Math.round(totalAll)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              </div>
              {this.state.showPermissionForPropina === true && typeof (this.state.propinaChecked) !== 'undefined' && this.state.propinaChecked === true &&
                <>
                  <div className="d-flex">
                    <p
                      style={{ width: '60%', textAlign: 'right', fontSize: 15, opacity: 0.7, }}>
                      PROPINA SUGERIDA {this.state.propinaPercentage}%
                    </p>
                    <p style={{
                      textAlign: 'center',
                      fontSize: 15,
                      opacity: 0.7,
                      marginLeft: 18
                    }}> {'  '}$</p>
                    <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                      {this.state.propinaAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </p>
                  </div>

                  <div className="d-flex">
                    <p
                      style={{ width: '60%', textAlign: 'right', fontSize: 15, opacity: 0.7, }}>
                      TOTAL + PROPINA
                    </p>
                    <p style={{
                      textAlign: 'center',
                      fontSize: 15,
                      opacity: 0.7,
                      marginLeft: 18
                    }}> {'  '}$</p>
                    <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                      {(Math.round(totalAll) + this.state.propinaAmount)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </p>
                  </div>
                </>
              }



              {this.state.type && this.state.type !== 3 && this.state.type !== 6 &&
                <div className="d-flex">
                  <p
                    style={
                      { width: '60%', textAlign: 'right', textTransform: 'uppercase', fontSize: 15, opacity: 0.7, }}>
                    {this.state.paymentMode}
                  </p>
                  <p style={{
                    textAlign: 'center',
                    fontSize: 15,
                    opacity: 0.7,
                    marginLeft: 18
                  }}> {'  '}$</p>
                  <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                    {this.state.totalPaid
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </p>
                </div>
              }
              {this.state.type && this.state.type !== 3 && this.state.type !== 6 &&
                <div className="d-flex">
                  <p
                    style={{ width: '60%', textAlign: 'right', fontSize: 15, opacity: 0.7, }}>
                    VUELTO
                  </p>
                  <p style={{
                    textAlign: 'center',
                    fontSize: 15,
                    opacity: 0.7,
                    marginLeft: 18
                  }}> {'  '}$</p>
                  <p style={{ marginLeft: 'auto', fontSize: 15, opacity: 0.7, }}>
                    {this.state.return}
                  </p>
                </div>
              }
              <p
                style={{ textAlign: 'left', marginTop: 5, fontSize: 15, opacity: 0.7, }}>
                son : {writtenNumber(totalAll)}
              </p>

              {/* Below line commented on 2024-08-05 by harshit
              * Bill need to display same as invoice that's why
              * Below code commented
            */}
              {/* {this.state.type === 1 && (
                <p
                  style={{ textAlign: 'left', marginTop: 5, fontSize: 15, opacity: 0.7, }}>
                  El IVA de esta boleta es : $
                  {Math.round(taxAmount)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              )} */}
              {this.state.comment !== undefined && this.state.comment !== '' && (
                <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                  {strings.comment} : {this.state.comment}
                </p>
              )}

              {this.state.references.length > 0 && (
                <>
                  <p
                    style={
                      { textAlign: 'left', marginTop: 5, fontSize: 15, opacity: 0.7 }}>
                    Referencias:
                  </p>
                  {this.state.references.map((item) => {
                    return (
                      <>
                        <div style={{ display: 'flex' }}>
                          <div style={{ display: 'flex' }}>
                            <p
                              style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, }}>
                              {`-`}
                            </p>
                          </div>
                          <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, marginLeft: '1rem' }}>
                            Tipo doc: {item.tipoDoc.label}
                          </p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, marginLeft: '1.5rem' }}>
                            {'   '}N° doc: {item.nDoc}
                          </p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <p style={{ textAlign: 'left', fontSize: 15, opacity: 0.7, marginLeft: '1.5rem' }}>
                            {/* {'   '}Fecha doc: {item.fechaDoc.toLocaleDateString()} */}
                            {'   '}Fecha doc: {moment(item.fechaDoc).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </>
              )}

            </div>
          </div>
          <div className="mt-4 d-flex justify-content-between">
            <Button pill size='lg' className='mt-4 mr-2' style={{
              width: '40%', alignSelf: 'center'
            }}
              onClick={() => { }}
            >{strings.edit}</Button>

            <ReactToPrint content={() => this.componentRef.current}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button pill size='lg' className='mt-4' style={{
                    width: '40%', alignSelf: 'center'
                  }}
                    onClick={handlePrint}
                  >{strings.print}</Button>

                )}
              </PrintContextConsumer>
            </ReactToPrint>
          </div>
          <div className="mt-4 mb-2 d-flex justify-content-between">
            <Button pill size='lg' className='mt-4 mr-2' style={{
              width: '40%', alignSelf: 'center'
            }}
              onClick={this.props.onBack}
            >{strings.back}
            </Button>
            <Button pill size='lg' className='mt-4' style={{
              width: '50%', alignSelf: 'center'
            }}
              onClick={
                () => this.state.editProductFromTransaction !== undefined && this.state.editProductFromTransaction ? this.editedDataStoreInDb() : this.storeInDB()
              }
            >
              {this.state.loading
                ? <div
                  class="spinner-border text-light"
                  role="status"
                  style={{
                    height: 20, width: 20
                  }}
                ></div>
                : this.state.editProductFromTransaction !== undefined && this.state.editProductFromTransaction ? 'Guarder documento' : 'Emitir documento'
              }
            </Button>
          </div>
          <Alert open={this.state.visible} theme='danger' style={{
            position: 'fixed',
            bottom: 10,
            // width: '80%',
          }}>
            {this.state.message}
          </Alert>

        </>
          :
          <Invoice
            closeAll={() => {
              this.setState({ showInvoiceScreen: false })
              this.props.closeAll()
            }}
            dataArray={this.state.dataArray}
            totalPaid={this.parseNumberToInt(this.state.totalPaid) - this.state.propinaAmount}
            mainPrice={this.state.mainPrice}
            tax={this.state.tax}
            total={this.state.payable}
            return={this.state.return}
            paymentMode={this.state.paymentMode}
            totalDiscount={this.state.totalDiscount}
            rutNumber={this.state.rutNumber}
            name={this.state.name}
            addr1={this.state.addr1}
            addr2={this.state.addr2}
            city={this.state.city}
            country={this.state.country}
            zipcode={this.state.zipcode}
            state={this.state.state}
            contact={this.state.contact}
            bDetails={this.state.bDetails}
            type={this.state.type}
            view={false}
            comment={this.state.comment}
            withoutTax={this.state.withoutTax}
            printNumber={printNumber}
            barcodeImage={barcodeImage}
            barcodeHeight={barcodeHeight}
            barcodeWidth={barcodeWidth}
            pLeft={pLeft}
            pWidth={pWidth}
            date={this.state.date}
            time={this.state.time}
            references={this.state.references}
            propinaAmount={this.state.propinaAmount}
            propinaChecked={this.state.propinaChecked}
            propinaPercentage={this.state.propinaPercentage}
            showPermissionForPropina={this.state.showPermissionForPropina}
            propina={{ 'percentage': this.state.propinaPercentage, 'amount': this.state.propinaAmount, 'checked': this.state.propinaChecked }}
            showNewButton={true}
            transactionDocumentId={this.state.transactionDocumentId}
            transactionDocumentType={this.state.transactionDocumentType}
            debitNoteReferencias={debitNoteReference}
            transportData={this.state.transportData}
            email={this.state.email}
          />}
      </>
    )
  }
}

export default SampleInvoice;
