import React, { Component } from 'react'
import {
  FormInput,
  FormTextarea,
  Button,
  Alert,
  FormGroup,
  FormSelect,
  Form
} from 'shards-react';
import {EntypoBack} from 'react-entypo-icons';
import swal from 'sweetalert2';
import { Language, updateNumber } from '../../config';
import { strings } from '../../localization';
import { CustomButton } from './Calculator';

// This rejex will check 10 character in this 9 digit and 1 dash between 8th & 9th digit
const checkDigitWithDashRejex = /^\d{8}-\d{1}$/;

const typeOfTransfer = [
    {
      label: 'Ventas por efectuar',
      value: '2'
    },
    {
      label: 'Consignaciones',
      value: '3'
    },
    {
      label: 'Entrega gratuita',
      value: '4'
    },
    {
      label: 'Traslados internos',
      value: '5'
    },
    {
      label: 'Otros traslados no venta',
      value: '6'
    },
    {
      label: 'Guía de devolución',
      value: '7'
    },
    {
      label: 'Operación constituye venta',
      value: '1'
    },
  ];

export class TransportData extends Component {
  constructor(props) {
    super(props);
   strings.setLanguage(Language);
    this.state = {
      transportData: this.props.transportData,
      addTransportData: false,
      selectedTypeOfTransfer: {
        lable: '',
        value: ''
      },
      destinationAddress : '',
      communityOfDestination : '',
      destinationCity : '',
      patente: '',
      transport: '',
      rutDriver: '',
      driverName: '',
      visible: false,
      message: '',
      type: '',
      index: null
    };
  }

   getTransportData = (data, type, index = 0) => {
    console.log('data,type,index', data, type, index);
    if (type === 'create') {
      let newArray = [...this.state.transportData, data];
      this.setState({ transportData: newArray }, () => {
        this.setState({ addTransportData: false });
      });
    } else if (type === 'edit') {
      let newArray = [...this.state.transportData];
      newArray[index] = data;
      this.setState(
        {
          transportData: newArray
        },
        () => {
          this.setState({ addTransportData: false });
        }
      );
    }
  };

  showMessage(data) {
    this.setState(
      {
        visible: true,
        message: data
      },
      () => {
        setTimeout(() => {
          this.setState({
            visible: false,
            message: ''
          });
        }, 2000);
      }
    );
  }

  render() {
    return (
      <>
        {!this.state.addTransportData && (
          <div>
            {this.state.transportData !== undefined && this.state.transportData.map((item, index) => {
              console.log('item of transportdata', item)
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 10,
                    marginLeft: 6,
                    marginRight: 6
                  }}
                >
                    <div
                      onClick={() => {
                        console.log(item);
                        this.setState(
                          {
                            selectedTypeOfTransfer: item.selectedTypeOfTransfer,
                            destinationAddress: item.destinationAddress,
                            communityOfDestination: item.communityOfDestination,
                            destinationCity: item.destinationCity,
                            patente: item.patente,
                            transport: item.transport,
                            rutDriver: item.rutDriver,
                            driverName: item.driverName,
                            type: 'edit',
                            index: index
                          },
                          () => {
                            this.setState({ addTransportData: true });
                          }
                        );
                      }}
                      style={{
                        borderRadius: 6,
                        width: '90%',
                        //   marginLeft: 6,
                        marginRight: 6,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        backgroundColor: '#CCCCCC'
                      }}
                    >
                      {/* Tipo de traslado section */}
                        <div style={{ display: 'flex' }}>
                          <p
                            className="commonTitle"
                          >
                            Tipo de traslado
                          </p>
                            <p
                              className="commonValue"
                            >
                              : {item.selectedTypeOfTransfer.label}
                            </p>
                        </div>
                        {/* Dirección de destino section */}
                        <div className="commonParent">
                            <p
                              className="commonTitle"
                            >
                              Dirección de destino
                            </p>
                              <p
                                className="commonValue"
                              >
                                : {item.destinationAddress}
                              </p>
                          </div>
                        {/* Comuna de destino section */}
                        <div className="commonParent">
                            <p
                              className="commonTitle"
                            >
                              Comuna de destino
                            </p>
                              <p
                                className="commonValue"
                              >
                                : {item.communityOfDestination}
                              </p>
                          </div>
                        {/* Ciudad de destino section */}
                        <div className="commonParent">
                            <p
                              className="commonTitle"
                            >
                              Ciudad de destino
                            </p>
                              <p
                                className="commonValue"
                              >
                                : {item.destinationCity}
                              </p>
                          </div>
                        {/* Patente section */}
                        {item.patente !== undefined &&
                         item.patente !== "" &&
                          <div className="commonParent">
                            <p
                              className="commonTitle"
                            >
                              Patente
                            </p>
                              <p
                                className="commonValue"
                              >
                                : {item.patente}
                              </p>
                          </div>
                        }
                        {/* RUT transporte section */}
                        {item.transport !== undefined &&
                         item.transport !== "" &&
                          <div className="commonParent">
                            <p
                              className="commonTitle"
                            >
                              RUT transporte
                            </p>
                              <p
                                className="commonValue"
                              >
                                : {item.transport}
                              </p>
                          </div>
                        }
                        {/* RUT chofer section */}
                        {item.rutDriver !== undefined &&
                        item.rutDriver !== "" &&
                          <div className="commonParent">
                            <p
                              className="commonTitle"
                            >
                              RUT chofer
                            </p>
                              <p
                                className="commonValue"
                              >
                                : {item.rutDriver}
                              </p>
                          </div>
                        }
                        {/* Nombre chofer section */}
                        {item.driverName !== undefined &&
                        item.driverName !== "" &&
                          <div className="commonParent">
                            <p
                              className="commonTitle"
                            >
                              Nombre chofer
                            </p>
                              <p
                                className="commonValue"
                              >
                                : {item.driverName}
                              </p>
                          </div>
                        }
                    </div>
                     <div
                    style={{
                      width: '10%',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      onClick={() => {
                        swal
                          .fire({
                            title: '',
                            text: '¿Estás segura de que quieres eliminar este artículo?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Si',
                            cancelButtonText:'Cancelar'
                          })
                          .then(response => {
                            if (response.isConfirmed) {
                              this.setState(
                                {
                                  transportData: this.state.transportData.filter(
                                    (item, itemIndex) => index !== itemIndex
                                  )
                                },
                                () => {
                                  if (
                                    this.state.transportData.length ===
                                    0
                                  ) {
                                    this.props.saveTransportData(
                                      this.state.transportData
                                    );
                                  }
                                }
                              );
                            }
                          });
                      }}
                      style={{
                        borderRadius: 20,
                        backgroundColor: 'red',
                        padding: 5,
                        marginTop: 30
                      }}
                    >
                      <i
                        class="material-icons icon-general-style"
                        style={{
                          fontSize: 26,
                          fontWeight: 'bold',
                          color: 'white'
                        }}
                      >
                        delete
                      </i>
                    </div>
                  </div>
                </div>
              )
            })}
               {this.state.transportData.length === 0 && (
              <div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
         ¡No se encontraron registros!
                </div>
                <div className="mt-4 d-flex justify-content-center">
                  {/* <Button
                    pill
                    size="lg"
                    className="mt-4 mr-2"
                    style={{
                      width: '40%',
                      alignSelf: 'center'
                    }}
                    onClick={this.props.onBack}
                  >
                    {strings.back}
                  </Button> */}
                </div>
              </div>
            )}
               <div>
                  <div
                    onClick={() => {
                      if (this.state.transportData !== undefined && this.state.transportData.length <= 0) {
                        this.setState({
                          addTransportData: true,
                          type: 'create',
                          selectedTypeOfTransfer: {
                            lable: '',
                            value: ''
                          },
                          destinationAddress : '',
                          communityOfDestination : '',
                          destinationCity : '',
                          patente: '',
                          transport: '',
                          rutDriver: '',
                          driverName: '',
                        });
                      }
                    }}
                    style={{
                      position: 'fixed',
                      bottom: 14,
                      right: 14,
                      opacity: this.state.transportData !== undefined && this.state.transportData.length <= 0 ? 1 : 0.5,
                      borderRadius: 60,
                      backgroundColor: '#2e209b',
                      padding: 16,
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center'
                    }}
                  >
                    <i
                      class="material-icons icon-general-style"
                      style={{ fontSize: 26, color: 'white' }}
                    >
                      add
                    </i>
                  </div>
                    <div 
                      onClick={() => {
                        this.props.onBack()
                        this.props.saveTransportData(
                            this.state.transportData
                          )
                      }}
                          style={{
                                position: 'fixed',
                                bottom: 14,
                                right: 320,
                                borderRadius: 60,
                                backgroundColor: '#2e209b',
                                padding: 16,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                height:58,
                                width:58
                              }}
                    >
                      <EntypoBack
                          style={{color: 'white',height:'26px', width:'26px' }}
                      />
                  </div>
            </div>
          </div>
        )}

        {/* Add transport data section */}
        {this.state.addTransportData && (
          <>
            <Form>
              <FormGroup>
                <label htmlFor="username">Tipo de traslado*</label>
                <select
                  className="mt-2 form-control"
                  onChange={e => {
                    let result = {};
                    typeOfTransfer.map(item => {
                      if (item.value === e.target.value) {
                        result = item;
                      }
                    });

                    this.setState({
                      selectedTypeOfTransfer: result
                    });
                  }}
                  value={this.state.selectedTypeOfTransfer.value}
                >
                  <option value="" disabled>
                Seleccione el tipo de documento
                  </option>

                  {typeOfTransfer.map(item => {
                    return (
                      <option value={item.value}>
                        {' '}
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
              {/* Dirección de destino section */}
              <FormGroup>
                <label htmlFor="username">Dirección de destino*</label>
                <FormInput
                  id="destinationAddress"
                  className="mt-2"
                  placeholder={'Dirección de destino'}
                  value={this.state.destinationAddress}
                  onChange={text => this.setState({ destinationAddress: text.target.value })}
                />
              </FormGroup>
              {/* Comuna de destino section */}
               <FormGroup>
                <label htmlFor="username">Comuna de destino*</label>
                <FormInput
                  id="communityOfDestination"
                  className="mt-2"
                  placeholder={'Comuna de destino'}
                  value={this.state.communityOfDestination}
                  onChange={text => this.setState({ communityOfDestination: text.target.value })}
                />
              </FormGroup>
              {/* Ciudad de destino section */}
               <FormGroup>
                <label htmlFor="username">Ciudad de destino*</label>
                <FormInput
                  id="destinationCity"
                  className="mt-2"
                  placeholder={'Ciudad de destino'}
                  value={this.state.destinationCity}
                  onChange={text => this.setState({ destinationCity: text.target.value })}
                />
              </FormGroup>
              {/* Patente section */}
               <FormGroup>
                <label htmlFor="username">Patente</label>
                <FormInput
                  id="patente"
                  className="mt-2"
                  placeholder={'Ingresa la patente'}
                  value={this.state.patente}
                  onChange={text => this.setState({ patente: text.target.value })}
                />
              </FormGroup>
              {/* RUT transporte section */}
               <FormGroup>
                <label htmlFor="username">RUT transporte</label>
                <FormInput
                  id="transport"
                  className="mt-2"
                  placeholder={'RUT empresa de transporte (debe ingresar números o k)'}
                  value={this.state.transport}
                  onChange={text => this.setState({ transport: text.target.value })}
                  onBlur={() => {
                    if (this.state.transport != undefined && this.state.transport !== "") {
                      let value = updateNumber(
                        this.state.transport
                      );
                      this.setState({
                        transport: value
                      });
                    }
                  }}
                />
              </FormGroup>
              {/* RUT chofer section */}
               <FormGroup>
                <label htmlFor="username">RUT chofer</label>
                <FormInput
                  id="driver"
                  className="mt-2"
                  placeholder={'RUT chofer (debe ingresar números o k)'}
                  value={this.state.rutDriver}
                  onChange={text => this.setState({ rutDriver: text.target.value })}
                  onBlur={() => {
                    if (this.state.rutDriver != undefined && this.state.rutDriver !== "") {
                      let value = updateNumber(
                        this.state.rutDriver
                      );
                      this.setState({
                        rutDriver: value
                      });
                    }
                  }}
                />
              </FormGroup>
              {/* Nombre chofer section */}
               <FormGroup>
                <label htmlFor="username">Nombre chofer</label>
                <FormInput
                  id="drivername"
                  className="mt-2"
                  placeholder={'Ingresa el nombre del chofer'}
                  value={this.state.driverName}
                  onChange={text => this.setState({ driverName: text.target.value })}
                />
              </FormGroup>
            </Form>

            <div className="mt-4 d-flex justify-content-between">
              <Button
                pill
                size="lg"
                className="mt-4 mr-2"
                style={{
                  width: '40%',
                  alignSelf: 'center'
                }}
                onClick={() => {
                  this.setState({ addTransportData: false });
                }}
              >
             {strings.back}
              </Button>
              <Button
                pill
                size="lg"
                className="mt-4"
                style={{
                  width: '40%',
                  alignSelf: 'center'
                }}
                onClick={
                () => {
                  // if (
                  //   this.state.selectedTipoDoc !== '' &&
                  //   this.state.nDoc !== '' &&
                  //   this.state.fechaDoc !== ''
                  // ) {
                  //   this.getTransportData(
                  //     {
                  //       selectedTypeOfTransfer: this.state.selectedTypeOfTransfer,
                  //       destinationAddress: this.state.destinationAddress,
                  //       communityOfDestination: this.state.communityOfDestination,
                  //       destinationCity: this.state.destinationCity,
                  //       patente: this.state.patente,
                  //       transport: this.state.transport,
                  //       rutDriver: this.state.rutDriver,
                  //       driverName: this.state.driverName,
                  //     },
                  //     this.state.type,
                  //     this.state.index
                  //   );
                  // } else {
                  //   this.setState(
                  //     {
                  //       visible: true,
                  //       message: 'Fill all required fields. '
                  //     },
                  //     () => {
                  //       setTimeout(() => {
                  //         this.setState({
                  //           visible: false,
                  //           message: ''
                  //         });
                  //       }, 2000);
                  //     }
                  //   );
                  // }

                    if (this.state.selectedTypeOfTransfer === '' || this.state.destinationAddress === '' || this.state.communityOfDestination === '' || this.state.destinationCity === '') {
                      this.showMessage('Rellene todos los campos obligatorios')
                    } else if (this.state.transport !== undefined && this.state.transport !== '' && !checkDigitWithDashRejex.test(this.state.transport)) {
                      this.showMessage('Por favor ingrese una válida RUT Transporte')
                    } else if (this.state.rutDriver !== undefined && this.state.rutDriver !== '' && !checkDigitWithDashRejex.test(this.state.rutDriver)) {
                      this.showMessage('Por favor ingrese una válida RUT Chofer')
                    } else {
                        this.getTransportData(
                        {
                          selectedTypeOfTransfer: this.state.selectedTypeOfTransfer,
                          destinationAddress: this.state.destinationAddress,
                          communityOfDestination: this.state.communityOfDestination,
                          destinationCity: this.state.destinationCity,
                          patente: this.state.patente,
                          transport: this.state.transport,
                          rutDriver: this.state.rutDriver,
                          driverName: this.state.driverName,
                        },
                        this.state.type,
                        this.state.index
                      );
                    }
                }
                }
              >
                Guardar
              </Button>
            </div>
            <Alert
              open={this.state.visible}
              theme="danger"
              style={{
                position: 'fixed',
                bottom: 10,
                width: '80%'
              }}
            >
              {this.state.message}
            </Alert>
          </>
        )}
      </>
    )
  }
}

export default TransportData