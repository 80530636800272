import React, { Component } from "react";
import { Card, Row, Col, Container, Button } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { LineChart, ColumnChart, PieChart } from "@opd/g2plot-react";
import { API_URL } from "../../config";
import Loader from "react-loader-spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    console.log("🚀 ~ file: index.js ~ line 17 ~ EditUserProfile ~ constructor ~ dd", dd)
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    console.log("🚀 ~ file: index.js ~ line 19 ~ EditUserProfile ~ constructor ~ mm", mm)
    let yyyy = String(today.getFullYear());
    console.log("🚀 ~ file: index.js ~ line 21 ~ EditUserProfile ~ constructor ~ yyyy", yyyy)

    let startDate = '01'  + '/' + mm + '/' + yyyy;
    // let endDate = dd + "/" + mm + "/" + yyyy;
    // let startDate = mm + "/" + "01" + "/" + yyyy;
    let endDate = dd + '/' + mm + '/' + yyyy;
    let formatedStartDate = new Date(yyyy + '-' + mm + '-' + '01').valueOf()
    let startDateIsoFormat = new Date(formatedStartDate).toISOString()
    let startDateApi = JSON.stringify(startDateIsoFormat)

    let formatedEndDate = new Date(yyyy + '-' + mm + '-' + dd).valueOf()
    let endDateIsoFormat = new Date(formatedEndDate).toISOString()
    let endDateApi = JSON.stringify(endDateIsoFormat)

    this.state = {
      apiEndPoint: "billsummary",
      chartData: [],
      visible: true,
      displayChart: [],
      startDate: startDate,
      endDate: endDate,
      startDateApi: startDateApi,
      endDateApi: endDateApi,
      reportTypeParams: 'withoutTax=false',
      csvParams: '&withoutTax=false',
      months : {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    },
      locale: {
        applyLabel: "Apply",
        daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        format: 'DD/MM/YYYY'
      }
    };
  }

  componentDidMount() {
    this.getData(false);
  }

  downloadFile = type => {
    let param = `?type=csv${this.state.csvParams}`;

    if (this.state.startDateApi || this.state.endDateApi) {
      param = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}&type=csv${this.state.csvParams}`;
    }

    // alert(`${API_URL}summaries/${type}${param}`);
    fetch(`${API_URL}summaries/${type}${param}`, {
      method: "get",
      headers: {
        "Content-Type": "Application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        let csvContent =
          "data:text/csv;charset=utf-8," +
          data.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ebol_invoices.csv");
        document.body.appendChild(link);

        link.click(); // This will download the data file named "my_data.csv".
      })
      .catch(err => {
        console.log(err);
      });
  };

  getData(addParamsInChart=false) {
    // fetch(`${API_URL}charts/webreport/${this.state.apiEndPoint}`, {
    let params;
      params = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}${this.state.csvParams}`
    console.log('params:', params);
    fetch(`${API_URL}charts/webreport/${this.state.apiEndPoint}${params}`, {
      method: "get",
      headers: {
        "Content-Type": "Application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ chartData: data }, () => this.renderCharts(addParamsInChart));
      });
  }

  renderCharts(dateFilter = false) {
    if (this.state.chartData.length > 0) {
      let param = "";

      // var startDate = new Date(this.state.startDate);
      // startDate = JSON.stringify(startDate);

      // var endDate = new Date(this.state.endDate);
      // endDate = JSON.stringify(endDate);
      // alert(startDate);
      // startDate = startDate.slice(1, 11);

      if (dateFilter) {
        param = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}${this.state.csvParams}`;
      }
      this.state.chartData.map(async value => {
        await fetch(`${API_URL}summaries/${value.type}${param}`, {
          method: "get",
          headers: {
            "Content-Type": "Application/json"
          },
          credentials: "include"
        })
          .then(res => res.json())
          .then(result => {
            if (value.name === "line") {
              this.setState({
                displayChart: [
                  ...this.state.displayChart,
                  <Card small className="mb-4">
                    <Button
                      className="float-right mt-2 mr-2 position-absolute"
                      style={{ width: "100px", right: 0, zIndex: 9 }}
                      onClick={() => {
                        this.downloadFile(value.type);
                      }}
                    >
                      Descargar CSV
                    </Button>
                    <section>
                      <LineChart
                        {...this.state.lineChartConfig}
                        data={result}
                      />
                    </section>
                  </Card>
                ],
                visible: false
              });
            } else if (value.name === "column") {
              this.setState({
                displayChart: [
                  ...this.state.displayChart,
                  <Card small className="mb-4">
                    <Button
                      className="float-right mt-2 mr-2 position-absolute"
                      style={{ width: "100px", right: 0, zIndex: 9 }}
                      onClick={() => {
                        this.downloadFile(value.type);
                      }}
                    >
                      Descargar CSV
                    </Button>
                    <section>
                      <ColumnChart {...value.config} data={result} />
                    </section>
                  </Card>
                ],
                visible: false
              });
            } else if (value.name === "pie") {
              this.setState({
                displayChart: [
                  ...this.state.displayChart,
                  <Card small className="mb-4">
                    <Button
                      className="float-right mt-2 mr-2 position-absolute"
                      style={{ width: "100px", right: 0, zIndex: 9 }}
                      onClick={() => {
                        this.downloadFile(value.type);
                      }}
                    >
                      Descargar CSV
                    </Button>
                    <section>
                      <PieChart {...value.config} data={result} />
                    </section>
                  </Card>
                ],
                visible: false
              });
            } else if (value.name === "sales_summary") {
              this.setState({
                displayChart: [
                  ...this.state.displayChart,
                  <Card small className="mb-4">
                    <section>
                      <p style={{ fontSize: "20px", padding: "5px 13px" }}>
                        {value.title}
                      </p>
                      <table
                        style={{
                          width: "50%",
                          margin: "10px"
                        }}
                      >
                        <tbody>
                          {result.map(value => {
                            return (
                              <tr>
                                <th
                                  style={{
                                    border: "0px",
                                    fontSize: "14px",
                                    width: "40%",
                                    fontWeight: "600"
                                  }}
                                >
                                  {value.label}
                                </th>
                                <td style={{ border: "0px", fontSize: "14px" }}>
                                  {value.value}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </section>
                  </Card>
                ],
                visible: false
              });
            }
          });
      });
    } else {
      this.setState({
        displayChart: (
          <>
            <p>No charts found</p>
          </>
        )
      });
    }
  }

  handleChange(e) {
    let stateValue;
    let params;
    let downloadCsvParam;
    if (e.target.value === 'billsummary' ) {
      params = 'withoutTax=false'
      downloadCsvParam = '&withoutTax=false'
      stateValue = e.target.value
    } else if (e.target.value === 'billsummary_no_tax') {
      stateValue = 'billsummary'
      params = 'withoutTax=true'
      downloadCsvParam = '&withoutTax=true'
    } else if (e.target.value === 'invoices') {
      params = 'withoutTax=false'
      downloadCsvParam = '&withoutTax=false'
      stateValue = e.target.value
    } else if (e.target.value === 'invoices_no_tax') {
      stateValue = 'invoices'
      params = 'withoutTax=true'
      downloadCsvParam = '&withoutTax=true'
    } else {
      stateValue = e.target.value
      params = ''
      downloadCsvParam = ''
    }
    this.setState(
      {
        apiEndPoint: stateValue,
        // apiEndPoint: e.target.value,
        reportTypeParams: params,
        csvParams: downloadCsvParam,
        displayChart: []
      },
      () => this.getData(true)
    );
  }

  // handleEvent(event, picker) {
  //   // alert(picker.startDate);
  //   alert("e");
  //   console.log(picker);
  // }
  handleCallback = (start, end) => {
    var startDate = new Date(start);

    let formatStartDate = startDate.toDateString().split(' ')
    console.log("🚀 ~ file: index.js ~ line 329 ~ EditUserProfile ~ getData ~ formatStartDate", formatStartDate)
    let startDateMonth =  this.state.months[formatStartDate[1]]
    let firstFormatDate = new Date(formatStartDate[3] + '-' + startDateMonth + '-' + formatStartDate[2]).valueOf()
    console.log("🚀 ~ file: index.js ~ line 301 ~ EditUserProfile ~ firstFormatDate",firstFormatDate)
    let startDateIsoFormat = new Date(firstFormatDate).toISOString()
    let startDateApi = JSON.stringify(startDateIsoFormat)
    console.log("🚀 ~ file: index.js ~ line 302 ~ EditUserProfile ~ startDateApi",JSON.stringify(startDateApi))

    // var startDateApi = startDate.setDate(startDate.getDate() + 1);
    // startDateApi = JSON.stringify(startDate);
    // console.log("🚀 ~ file: index.js ~ line 269 ~ EditUserProfile ~ startDateApi", startDateApi)

    // alert(startDateApi);

    // startDate = startDateApi.slice(1, 11);
    // startDate.replace("-", "/");

    var endDate = new Date(end);
    console.log("🚀 ~ file: index.js ~ line 279 ~ EditUserProfile ~ endDate", endDate)
    // var endDateApi = JSON.stringify(endDate);
    // console.log("🚀 ~ file: index.js ~ line 281 ~ EditUserProfile ~ endDateApi", endDateApi)

    let formatEndDate = endDate.toDateString().split(' ')
    let endDateMonth =  this.state.months[formatEndDate[1]]
    let secondFormatDate = new Date(formatEndDate[3] + '-' + endDateMonth + '-' + formatEndDate[2]).valueOf()
    console.log("🚀 ~ file: index.js ~ line 301 ~ EditUserProfile ~ secondFormatDate",secondFormatDate)
    let endDateIsoFormat = new Date(secondFormatDate).toISOString()
    let endDateApi = JSON.stringify(endDateIsoFormat)
    console.log("🚀 ~ file: index.js ~ line 302 ~ EditUserProfile ~ endDateApi",JSON.stringify(endDateApi))
    // endDate = endDateApi.slice(1, 11);
    // endDate.replace("-", "/");
    

    // alert(startDate);
    this.setState(
      {
        startDate: start,
        endDate: end,
        startDateApi,
        endDateApi,
        displayChart: [],
        visible: true
      },
      () => {
        this.renderCharts(true);
      }
    );
    // console.log(start, end, label);
    // alert("call");
    // alert(end);
  };

  render() {
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Gráficos"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              {this.state.visible ? (
                <Card small className="mb-4 p-4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                      visible={this.state.visible}
                    />
                  </div>
                </Card>
              ) : (
                <>
                  <Row>
                    <Col lg="6" md="12" sm="12">
                      <Card small className="mb-4 p-4">
                        <select
                          className="form-control"
                          onChange={e => this.handleChange(e)}
                        >
                          <option value="billsummary">
                            Boleta electrónica
                          </option>
                          <option value="billsummary_no_tax">Boleta no affecta o extenta electrónica</option>
                          <option value="invoices">Factura electrónica</option>
                          <option value="invoices_no_tax">Factura no affecta o extenta electrónica</option>
                          <option value="creditnotes">Nota de crédito</option>
                          <option value="debitnotes">Nota de debito</option>
                          <option value="deliverynotes">
                            Guía de despacho
                          </option>
                          <option value="allsummary">
                            Todos los documentos
                          </option>
                        </select>
                      </Card>
                    </Col>
                    <Col lg="6" md="12" sm="12">
                      <Card small className="mb-4 p-4">
                        <DateRangePicker
                          initialSettings={{
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                            locale: this.state.locale
                          }}
                          // onEvent={this.handleEvent}
                          onCallback={(start, end) =>
                            this.handleCallback(start, end)
                          }
                          locale={this.state.locale}
                        >
                          <input type="text" className="form-control" />
                        </DateRangePicker>
                      </Card>
                    </Col>
                  </Row>
                  {this.state.displayChart}
                </>
              )}
              {/* <Card small className="mb-4">
                <section>
                  <LineChart {...this.state.lineChartConfig} data={data} />
                </section>
              </Card>
              <Card small className="mb-4">
                <ColumnChart
                  {...this.state.columnChartConfig}
                  data={chartData}
                />
              </Card>
              <Card small className="mb-4">
                <PieChart {...this.state.pieChartConfig} data={pieData} />
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
