export default function () {
  let hasRestaurantPermission = false;
  let organizationData = JSON.parse(localStorage.getItem("organisation_info"));
  if (organizationData !== undefined && organizationData !== null &&
    organizationData['data']['restaurantModule'] !== undefined && organizationData['data']['restaurantModule'] !== null
  ) {
    hasRestaurantPermission = organizationData['data']['restaurantModule'];
  }
  let items = [
    {
      title_es: "Menú Principal",
      title: "Dashboard ES",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    // {
    //   title_es: "Inquire Request",
    //   title: "Inquire Request ES",
    //   htmlBefore: '<i class="material-icons">analytics</i>',
    //   to: "/user-request"
    // },s
    // {
    //   title_es: "Create User",
    //   title: "Create User ES",
    //   htmlBefore: '<i class="material-icons">person_add</i>',
    //   to: "/manage-customer/create"
    // },
    {
      title_es: "Usuarios",
      title: "Users Es",
      htmlBefore: '<i class="material-icons">assignment_ind</i>',
      to: "/users"
    },
    // {
    //   title_es: "Transacciones",
    //   title: "Transaction Es",
    //   htmlBefore: '<i class="material-icons">receipt_long</i>',
    //   to: "/transactions"
    // },
    {
      title_es: "Ventas",
      title: "Ventas Es",
      htmlBefore: '<i class="material-icons">receipt_long</i>',
      to: "/ventas"
    },
    {
      title_es: "Clientes",
      title: "Customers Es",
      htmlBefore: '<i class="material-icons">group</i>',
      to: "/customers"
    },
    {
      title_es: "Folios",
      title: "Folios Es",
      htmlBefore: '<i class="material-icons">card_travel</i>',
      to: "/folios"
    },
    {
      title_es: "Documentos de compra",
      title: "Documentos de compras",
      htmlBefore: '<i class="material-icons">topic</i>',
      to: "/PurchaseDocuments"
    },
    // {
    //   title_es: "Folio Consumptions",
    //   title: "Folios Consumptions Es",
    //   htmlBefore: '<i class="material-icons">card_travel</i>',
    //   to: "/folio-consumptions"
    // },
    // {
    //   title_es: "Restaurant",
    //   title: "Restaurant",
    //   htmlBefore: '<i class="material-icons">list_alt</i>',
    //   to: "/tables"
    // },
    {
      title_es: "Items",
      title: "Items Es",
      htmlBefore: '<i class="material-icons">list_alt</i>',
      to: "/items"
    },
    {
      title_es: "Reportes",
      title: "Reports Es",
      htmlBefore: '<i class="material-icons">analytics</i>',
      to: "/charts"
    },
    // {
    //   title_es: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts"
    // },
    // {
    //   title_es: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post"
    // },
    // {
    //   title_es: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview"
    // },
    // {
    //   title_es: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables"
    // },
    // {
    //   title_es: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite"
    // },
    // {
    //   title_es: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors"
    // },
    {
      title_es: "Edita tu perfil",
      title: "Edit Profile ES",
      htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
      to: "/edit-user-profile"
    },
    // {
    //   title_es: "Settings",
    //   title: "Settings ES",
    //   htmlBefore: '<i class="material-icons">settings</i>',
    //   to: "/settings"
    // },
    {
      title_es: "Cerrar sesión",
      title: "Logout ES",
      htmlBefore: '<i class="material-icons" >&#xE879;</i>',
      to: "/logout"
    }
  ];
  if (hasRestaurantPermission) {
    let restaurantTable = {
      title_es: "Mesas de Restaurante",
      title: "Restaurant Tables",
      // htmlBefore: '<i class="material-icons">grid_view</i>',
      htmlBefore: '<i class="material-icons">table_restaurant</i>',
      to: "/tables"
    }
    let restaurantTableWithFilters = {
      title_es: "Restaurante",
      title: "Restaurant",
      htmlBefore: '<i class="material-icons">grid_view</i>',
      to: "/restaurant"
    }
    let restaurantOrders = {
      title_es: "Orden de restaurante",
      title: "Restaurant Order",
      htmlBefore: '<i class="material-icons">grid_view</i>',
      to: "/restaurant-orders"
    }
    let restaurantKitchen = {
      title_es: "Cocinas de restaurante",
      title: "Restaurant Kitchens",
      htmlBefore: '<i class="material-icons">kitchen</i>',
      to: "/restaurant-kitchens"
    }
    let restaurantBar = {
      title_es: "Restaurante Bares",
      title: "Restaurant Bars",
      htmlBefore: '<i class="material-icons">local_bar</i>',
      to: "/restaurant-bars"
    }
    let restaurantSpace = {
      title_es: "Espacios de restaurante",
      title: "Restaurant Spaces",
      htmlBefore: '<i class="material-icons">food_bank</i>',
      to: "/restaurant-spaces"
    }
    items.push(restaurantSpace);
    arrayIndexMove(items, items.length - 1, 6);
    items.push(restaurantKitchen);
    arrayIndexMove(items, items.length - 1, 7);
    items.push(restaurantBar);
    arrayIndexMove(items, items.length - 1, 8);
    items.push(restaurantTable);
    arrayIndexMove(items, items.length - 1, 9);
    // items.push(restaurantTableWithFilters);
    // arrayIndexMove(items, items.length - 1, 10);
    // items.push(restaurantOrders);
    // arrayIndexMove(items, items.length - 1, 11);
  }
  return items;
}

function arrayIndexMove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

// export default function() {
//   return [
//     {
//       title_es: "Dashboard",
//       to: "/dashboard",
//       htmlBefore: '<i class="material-icons">dashboard</i>',
//       htmlAfter: ""
//     },
//     {
//       title_es: "Inquire Request",
//       htmlBefore: '<i class="material-icons">analytics</i>',
//       to: "/user-request"
//     },
//     {
//       title_es: "Create Customer",
//       htmlBefore: '<i class="material-icons">person_add</i>',
//       to: "/manage-customer/create"
//     },
//     {
//       title_es: "Customers",
//       htmlBefore: '<i class="material-icons">group</i>',
//       to: "/customers"
//     },

//     // {
//     //   title_es: "Blog Posts",
//     //   htmlBefore: '<i class="material-icons">vertical_split</i>',
//     //   to: "/blog-posts"
//     // },
//     // {
//     //   title_es: "Add New Post",
//     //   htmlBefore: '<i class="material-icons">note_add</i>',
//     //   to: "/add-new-post"
//     // },
//     // {
//     //   title_es: "Forms & Components",
//     //   htmlBefore: '<i class="material-icons">view_module</i>',
//     //   to: "/components-overview"
//     // },
//     // {
//     //   title_es: "Tables",
//     //   htmlBefore: '<i class="material-icons">table_chart</i>',
//     //   to: "/tables"
//     // },
//     // {
//     //   title_es: "User Profile",
//     //   htmlBefore: '<i class="material-icons">person</i>',
//     //   to: "/user-profile-lite"
//     // },
//     // {
//     //   title_es: "Errors",
//     //   htmlBefore: '<i class="material-icons">error</i>',
//     //   to: "/errors"
//     // },
//     {
//       title_es: "Edit Profile",
//       htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
//       to: "/edit-user-profile"
//     },
//     {
//       title_es: "Settings",
//       htmlBefore: '<i class="material-icons">settings</i>',
//       to: "/settings"
//     },
//     {
//       title_es: "Logout",
//       htmlBefore: '<i class="material-icons" >&#xE879;</i>',
//       to: "/logout"
//     }
//   ];
// }
