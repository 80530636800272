import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect, Link } from "react-router-dom";
import { strings } from "./../../localization";
import { Language, API_URL, updateNumber, emailRegxPattern } from "./../../config";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      phone: "",
      fullName: "",
      loader: "",
      src: "",
      id: "",
      rut: "",
      addr1: "",
      addr2: "",
      city: "",
      bDetails: "",
      country: "",
      state: "",
      giroRecep: "",
      email: "",
      type: "create",
      button: false,
      hide: true,
      redirect: false,
      disable: false
    };
  }

  componentDidMount() {
    // alert(payload);
    // console.log(payload);

    // alert("");
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;
    // alert(id);
    // alert(type);

    // alert(type);

    if (type !== undefined) {
      this.setState({
        type: type
      });
      if (id) {
        fetch(`${API_URL}customers/${id}`, {
          method: "GET",
          credentials: "include"
        })
          .then(res => res.json())
          .then(({ data }) => {
            console.log(data);
            this.setState({
              rut: data.rutNumber,
              addr1: data.addr1,
              addr2: data.addr2,
              bDetails: data.bDetails,
              country: data.country,
              state: data.state,
              city: data.city,
              fullName: data.fullName,
              phone: data.phone,
              giroRecep: data.giroRecep,
              email: data.email,
              id: "/" + id
            });
          });
      }
    }
  }

  Capitalize(str) {
    return str !== "edit" ? "Crear Cliente" : "Editar Cliente";
    // return str.charAt(0).toUpperCase() + str.slice(1);
  }
  render() {
    const {
      phone,
      fullName,
      rut,
      addr1,
      addr2,
      city,
      country,
      state,
      giroRecep,
      bDetails,
      email
    } = this.state;
    const handleClick = () => {

      // alert("yes button click")

      if (city === "" ||
        phone === "" ||
        fullName === "" ||
        rut === "" ||
        addr1 === "" ||
        country === "" ||
        state === "" ||
        // giroRecep === "" ||
        addr2 === "" ||
        bDetails === "") {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else if (email !== undefined && email !== null && email !== "" && !emailRegxPattern.test(email)) {
        toast.error(strings.email_valid_error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });

        // let localData = localStorage.getItem("user_info");

        // let newData = JSON.parse(localData);
        // localData = localData.data;
        // let organisationID = newData.data.organisationID;

        // console.log(localData);

        let string = JSON.stringify({
          rutNumber: rut,
          bDetails: bDetails,
          fullName: fullName,
          phone: phone,
          addr1: addr1,
          addr2: addr2,
          city: city,
          country: country,
          giroRecep: giroRecep,
          state: state,
          email: email
          // organisationID: organisationID
        });

        fetch(`${API_URL}customers${this.state.id}`, {
          method: this.state.id ? "put" : "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: string
        })
          .then(res => {
            if (res.status == 200) {
              toast.success(strings.successfully_created, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false });
            }
            return res.json();
          })
          .then(res => {
            if (res.message) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              this.setState({ loader: "", button: false });
            }
          })
          .catch(err => {
            console.warn(err);
          });
      }
    };

    return (
      <div>
        {this.state.redirect ? (
          <Redirect
            push
            to={{
              pathname: "/customers",
              state: { message: `Customer ${this.state.type} successfully` }
            }}
          />
        ) : null}
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle
                title={this.Capitalize(this.state.type)}
                md="12"
                className="ml-sm-auto mr-sm-auto"
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={"/customers"} className="float-left">
                <Button id="go_back" theme="light" className="mb-2 mr-1">
                  Regresar
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card small className="mb-4">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Form id="ProfileForm">
                      <Row>
                        <Col>
                          <label htmlFor="rut">Número de RUT</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">filter_1</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="rut"
                              value={rut}
                              placeholder="Número de RUT"
                              onChange={e =>
                                this.setState({ rut: e.target.value })
                              }
                              onBlur={() => {
                                let value = updateNumber(rut);
                                this.setState({
                                  rut: value
                                })
                              }}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="fullName">Nombre o Razon Social</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">person</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="fullName"
                              value={fullName}
                              placeholder="Nombre o Razon Social"
                              onChange={e =>
                                this.setState({ fullName: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="phone">Nombre de Contacto</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">person</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="phone"
                              value={phone}
                              placeholder="Nombre de Contacto"
                              onChange={e =>
                                this.setState({ phone: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="state">Región</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">gps_fixed</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="state"
                              value={state}
                              placeholder="Región"
                              onChange={e =>
                                this.setState({ state: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>


                      <Row>
                        <Col>
                          <label htmlFor="country">País</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">room</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="country"
                              value={country}
                              placeholder="País"
                              onChange={e =>
                                this.setState({ country: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="addr2">Comuna</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="addr2"
                              value={addr2}
                              placeholder="Comuna"
                              onChange={e =>
                                this.setState({ addr2: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {" "}
                          <label htmlFor="addr1">Dirección</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">business</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="addr1"
                              value={addr1}
                              placeholder="Dirección"
                              onChange={e =>
                                this.setState({ addr1: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="city">Ciudad</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">location_city</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="city"
                              value={city}
                              placeholder="Ciudad"
                              onChange={e =>
                                this.setState({ city: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {/* Giro is actually Detalles Comerciales in backend because of bDetails is used that's why label is changed at 2023-11-23 by harshit */}
                          <label htmlFor="bDetails">Giro</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">work</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="bDetails"
                              value={bDetails}
                              placeholder="Giro"
                              onChange={e =>
                                this.setState({ bDetails: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="email">Correo electrónico</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">mail</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="email"
                              id="email"
                              value={email}
                              placeholder="Correo electrónico"
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      {/* Detalles Comerciales is actually Giro in backend because of bDetails is used that's why label is changed at 2023-11-23 by harshit */}
                      {/* <label htmlFor="giroRecep">Detalles Comerciales</label>
                      <InputGroup seamless className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>
                            <i className="material-icons">room</i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          id="giroRecep"
                          value={giroRecep}
                          placeholder="Detalles Comerciales"
                          onChange={e =>
                            this.setState({ giroRecep: e.target.value })
                          }
                        />
                      </InputGroup> */}

                      <ToastContainer />
                      <Button
                        theme="accent"
                        onClick={() => handleClick()}
                        disabled={this.state.button}
                        className="float-right"
                      >
                        <i
                          className={this.state.loader}
                          style={{ fontSize: "15px" }}
                        ></i>
                        {this.state.type !== "edit"
                          ? "Guardar"
                          : "Actualizar"}
                      </Button>
                    </Form>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
