import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  DatePicker,
  Button,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormTextarea
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect, Link } from "react-router-dom";
import { strings } from "./../../localization";
import { Language, API_URL } from "./../../config";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      title: "",
      rutEmissor: "",
      rutEmissorName: "",
      typeOfDocument: "",
      folioStart: "",
      folioEnd: "",
      folioDate: new Date(),
      rsaPrivateKey: "",
      rsaPublicKey: "",
      XML: "",
      cafXML: "",
      loader: "",
      id: "",
      button: false,
      hide: true,
      redirect: false,
      disable: false
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;

    if (type !== undefined) {
      this.setState({
        type: type
      });
      if (id) {
        fetch(`${API_URL}folios/${id}`, {
          method: "GET",
          credentials: "include"
        })
          .then(res => res.json())
          .then(({ data }) => {
            console.log(data);
            this.setState({
              title: data.title,
              XML: data.XML,
              rutEmissor: data.rutEmissor,
              rutEmissorName: data.rutEmissorName,
              typeOfDocument: data.typeOfDocument,
              folioStart: data.folioStart,
              folioEnd: data.folioEnd,
              folioDate: data.folioDate,
              rsaPrivateKey: data.rsaPrivateKey,
              rsaPublicKey: data.rsaPublicKey,
              cafXML: data.cafXML,
              id: "/" + id
            });
          });
      }
    }
  }

  Capitalize(str) {
    return str !== "edit" ? "Create Folio" : "Edit Folio";
  }

  handleStartDateChange(value) {
    // alert(new Date(value));
    // let date = JSON.stringify(value);
    // date = date.slice(1, 11);

    value = this.convert(value);

    this.setState({
      folioDate: value
    });
  }

  convert(str) {
    var month, day;
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    var mySQLDate = [date.getFullYear(), month, day].join("-");
    mySQLDate = [mySQLDate].join(" ");

    return mySQLDate;
  }

  render() {
    const {
      title,
      XML,
      rutEmissor,
      rutEmissorName,
      typeOfDocument,
      folioStart,
      folioEnd,
      folioDate,
      rsaPrivateKey,
      rsaPublicKey,
      cafXML
    } = this.state;
    const handleClick = () => {
      // alert(this.state.disable);
      if (
        title === "" ||
        XML === "" ||
        rutEmissor === "" ||
        rutEmissorName === "" ||
        typeOfDocument === "" ||
        folioStart === "" ||
        folioEnd === "" ||
        folioDate === "" ||
        rsaPrivateKey === "" ||
        rsaPublicKey === "" ||
        cafXML === ""
      ) {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });

        let strings = JSON.stringify({
          title,
          XML,
          rutEmissor,
          rutEmissorName,
          typeOfDocument: parseInt(typeOfDocument),
          folioStart: parseInt(folioStart),
          folioEnd: parseInt(folioEnd),
          folioDate,
          rsaPrivateKey,
          rsaPublicKey,
          cafXML
        });

        fetch(`${API_URL}folios${this.state.id}`, {
          method: this.state.id ? "put" : "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: strings
        })
          .then(res => {
            if (res.status == 200) {
              toast.success("Record saved successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false });
            }
            return res.json();
          })
          .then(res => {
            if (res.message) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              this.setState({ loader: "", button: false });
            }
          })
          .catch(err => {
            console.warn(err);
          });
      }
    };

    return (
      <div>
        {this.state.redirect ? (
          <Redirect
            push
            to={{
              pathname: "/folios",
              state: { message: `Folio ${this.state.type} successfully` }
            }}
          />
        ) : null}
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle
                title={this.Capitalize(this.state.type)}
                subtitle={strings.overview}
                md="12"
                className="ml-sm-auto mr-sm-auto"
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={"/folios"} className="float-left">
                <Button id="go_back" theme="light" className="mb-2 mr-1">
                  {strings.go_back}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card small className="mb-4">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Form id="ProfileForm">
                      <Row>
                        <Col>
                          <label htmlFor="name">Title</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">title</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="name"
                              value={title}
                              placeholder="Title"
                              onChange={e =>
                                this.setState({ title: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="typeOfDocument">
                            Type Of Document
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">article</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="typeOfDocument"
                              value={typeOfDocument}
                              placeholder="Type Of Document"
                              onChange={e =>
                                this.setState({
                                  typeOfDocument: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="rutEmissor">RUT Emissor</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">looks_one</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="rutEmissor"
                              value={rutEmissor}
                              placeholder="RUT Emissor"
                              onChange={e =>
                                this.setState({ rutEmissor: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="rutEmissorName">
                            RUT Emissor Name
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">dns</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="rutEmissorName"
                              value={rutEmissorName}
                              placeholder="RUT Emissor Name"
                              onChange={e =>
                                this.setState({
                                  rutEmissorName: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <label htmlFor="folioStart">Folio Start</label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">flag</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormInput
                                  type="number"
                                  id="folioStart"
                                  value={folioStart}
                                  placeholder="Folio Start"
                                  onChange={e =>
                                    this.setState({
                                      folioStart: e.target.value
                                    })
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col>
                              <label htmlFor="folioEnd">Folio End</label>
                              <InputGroup seamless className="mb-3">
                                <InputGroupAddon type="prepend">
                                  <InputGroupText>
                                    <i className="material-icons">flag</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormInput
                                  type="number"
                                  id="folioEnd"
                                  value={folioEnd}
                                  placeholder="Folio End"
                                  onChange={e =>
                                    this.setState({
                                      folioEnd: e.target.value
                                    })
                                  }
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <label htmlFor="folioDate">Folio Date</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">date_range</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <div className="customDatePickerWidth">
                              <DatePicker
                                style={{ width: "100%" }}
                                dateFormat="dd/M/yyyy"
                                id="folioDate"
                                selected={new Date(folioDate)}
                                onChange={e => this.handleStartDateChange(e)}
                                placeholderText="Folio Date"
                                dropdownMode="scroll"
                                className="text-center"
                              />
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="rsaPrivateKey">RSA PrivateKey</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">lock</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              rows="5"
                              id="rsaPrivateKey"
                              value={rsaPrivateKey}
                              placeholder="RSA PrivateKey"
                              onChange={e =>
                                this.setState({ rsaPrivateKey: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="rsaPublicKey">RSA PublicKey</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">lock_open</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              rows="5"
                              id="rsaPublicKey"
                              value={rsaPublicKey}
                              placeholder="RSA PublicKey"
                              onChange={e =>
                                this.setState({
                                  rsaPublicKey: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="xml">XML</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">web</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              id="xml"
                              rows="7"
                              value={XML}
                              placeholder="XML"
                              onChange={e =>
                                this.setState({ XML: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="cafXML">CAF XML</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">web</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              id="cafXML"
                              rows="7"
                              value={cafXML}
                              placeholder="CAF XML"
                              onChange={e =>
                                this.setState({ cafXML: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <ToastContainer />
                      <Button
                        theme="accent"
                        onClick={() => handleClick()}
                        disabled={this.state.button}
                      >
                        <i
                          className={this.state.loader}
                          style={{ fontSize: "15px" }}
                        ></i>
                        {this.state.type !== "edit"
                          ? strings.save
                          : strings.update}
                      </Button>
                    </Form>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            {/* <Col>
            <Link to={"/folios"} className="float-left">
                <Button
                  size="sm"
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                  style={
                    {
                      // marginTop: "-84px",
                      // position: "relative"
                      // left: "-100px"
                    }
                  }
                >
                  {strings.go_back}
                </Button>
              </Link>
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}
