import React, { Component } from 'react';
import {
  FormInput,
  FormTextarea,
  Button,
  Alert,
  FormGroup,
  FormSelect,
  Form
} from 'shards-react';
import DatePicker from 'react-date-picker';
import swal from 'sweetalert2';
import { strings } from './../../localization';
import { Language, API_URL, LIMIT } from './../../config';
import moment from 'moment';
import {EntypoBack} from 'react-entypo-icons';
const tipoDoc = [
  {
    label: 'Orden de Compra',
    value: '801'
  },
  {
    label: 'Nota de pedido',
    value: '802'
  },
  {
    label: 'Contrato',
    value: '803'
  },
  {
    label: 'Resolución',
    value: '804'
  },
  {
    label: 'Proceso ChileCompra',
    value: '805'
  },
  {
    label: 'Ficha ChileCompra',
    value: '806'
  },
  {
    label: 'DUS',
    value: '807'
  },
  {
    label: 'B/L (Conocimiento de embarque)',
    value: '808'
  },
  {
    label: 'AWB (Air Will Bill)',
    value: '809'
  },
  {
    label: 'MIC/DTA',
    value: '810'
  },
  {
    label: 'Carta de Porte',
    value: '811'
  },
  {
    label: 'Resolución del SNA donde califica Servicios de Exportación',
    value: '812'
  },
  {
    label: 'Pasaporte',
    value: '813'
  },
  {
    label: 'Certificado de Depósito Bolsa Prod. Chile.',
    value: '814'
  },
  {
    label: 'Vale de Prenda Bolsa Prod. Chile HES Hoja Entrada Servicio ',
    value: '815'
  },
  {
    label: 'Hoja Entrada Servicio ',
    value: 'HES'
  }
];

export class AgregarReferencias extends Component {
  constructor(props) {
    super(props);
   strings.setLanguage(Language);
    this.state = {
      AgregarReferenciasInfo: this.props.references,
      addReferencias: false,
      selectedTipoDoc: {
        lable: '',
        value: ''
      },
      nDoc: '',
      fechaDoc: new Date(),
      visible: false,
      message: '',
      type: '',
      index: null
    };
  }

  getAgregarReferenciasInfo = (data, type, index = 0) => {
    console.log('data,type,index', data, type, index);
    if (type === 'create') {
      let newArray = [...this.state.AgregarReferenciasInfo, data];
      this.setState({ AgregarReferenciasInfo: newArray }, () => {
        this.setState({ addReferencias: false });
      });
    } else if (type === 'edit') {
      let newArray = [...this.state.AgregarReferenciasInfo];
      newArray[index] = data;
      this.setState(
        {
          AgregarReferenciasInfo: newArray
        },
        () => {
          this.setState({ addReferencias: false });
        }
      );
    }
  };

  render() {
    return (
      <>
        {!this.state.addReferencias && (
          <div>
            {this.state.AgregarReferenciasInfo.map((item, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    marginTop: 10,
                    marginLeft: 6,
                    marginRight: 6
                  }}
                >
                  <div
                    onClick={() => {
                      console.log(item);
                      this.setState(
                        {
                          nDoc: item.nDoc,
                          selectedTipoDoc: item.tipoDoc,
                          fechaDoc: item.fechaDoc,
                          type: 'edit',
                          index: index
                        },
                        () => {
                          this.setState({ addReferencias: true });
                        }
                      );
                    }}
                    style={{
                      borderRadius: 6,
                      width: '90%',
                      //   marginLeft: 6,
                      marginRight: 6,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                      paddingRight: 10,
                      backgroundColor: '#CCCCCC'
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{
                          width: '30%',
                          fontWeight: 'bold',
                          fontSize: 16
                        }}
                      >
                        Tipo doc
                      </p>
                      <p
                        style={{
                          width: '60%',
                          fontWeight: 'bold',
                          fontSize: 14
                        }}
                      >
                        : {item.tipoDoc.label}
                      </p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 8 }}>
                      <p
                        style={{
                          width: '30%',
                          fontWeight: 'bold',
                          fontSize: 16
                        }}
                      >
                        N° doc
                      </p>
                      <p
                        style={{
                          width: '60%',
                          fontWeight: 'bold',
                          fontSize: 14
                        }}
                      >
                        : {item.nDoc}
                      </p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 8 }}>
                      <p
                        style={{
                          width: '30%',
                          fontWeight: 'bold',
                          fontSize: 16
                        }}
                      >
                        Fecha doc
                      </p>
                      <p
                        style={{
                          width: '60%',
                          fontWeight: 'bold',
                          fontSize: 14
                        }}
                      >
                        : {moment(item.fechaDoc).format('DD/MM/YYYY')}
                        {/* : {item.fechaDoc.toLocaleDateString()} */}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '10%',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      onClick={() => {
                        swal
                          .fire({
                            title: '',
                            text: '¿Estás segura de que quieres eliminar este artículo?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Si',
                            cancelButtonText:'Cancelar'
                          })
                          .then(response => {
                            if (response.isConfirmed) {
                              this.setState(
                                {
                                  AgregarReferenciasInfo: this.state.AgregarReferenciasInfo.filter(
                                    (item, itemIndex) => index !== itemIndex
                                  )
                                },
                                () => {
                                  if (
                                    this.state.AgregarReferenciasInfo.length ===
                                    0
                                  ) {
                                    this.props.saveReferencias(
                                      this.state.AgregarReferenciasInfo
                                    );
                                  }
                                }
                              );
                            }
                          });
                      }}
                      style={{
                        borderRadius: 20,
                        backgroundColor: 'red',
                        padding: 5,
                        marginTop: 30
                      }}
                    >
                      <i
                        class="material-icons icon-general-style"
                        style={{
                          fontSize: 26,
                          fontWeight: 'bold',
                          color: 'white'
                        }}
                      >
                        delete
                      </i>
                    </div>
                  </div>
                </div>
              );
            })}

            {this.state.AgregarReferenciasInfo.length === 0 && (
              <div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
         ¡No se encontraron registros!
                </div>
                <div className="mt-4 d-flex justify-content-center">
                  {/* <Button
                    pill
                    size="lg"
                    className="mt-4 mr-2"
                    style={{
                      width: '40%',
                      alignSelf: 'center'
                    }}
                    onClick={this.props.onBack}
                  >
                    {strings.back}
                  </Button> */}
                </div>
              </div>
            )}

            {/* todo */}
            {/* {this.state.AgregarReferenciasInfo.length > 0 && (
              <div className="mt-4 d-flex justify-content-between">
                <Button
                  pill
                  size="lg"
                  className="mt-4 mr-2"
                  style={{
                    width: '40%',
                    alignSelf: 'center'
                  }}
                  onClick={this.props.onBack}
                >
                    {strings.back}
                  
                </Button>

                <Button
                  pill
                  size="lg"
                  className="mt-4"
                  style={{
                    width: '40%',
                    alignSelf: 'center'
                  }}
                  onClick={() => {
                    this.props.saveReferencias(
                      this.state.AgregarReferenciasInfo
                    );
                  }}
                >
                  Guardar
                </Button>
              </div>

            )} */}
            <div>
            
                  <div
                    onClick={() => {
                      this.setState({
                        addReferencias: true,
                        type: 'create',
                        nDoc: '',
                        selectedTipoDoc: {
                          label: '',
                          value: ''
                        },
                        fechaDoc: new Date()
                      });
                    }}
                    style={{
                      position: 'fixed',
                      bottom: 14,
                      right: 14,
                      borderRadius: 60,
                      backgroundColor: '#2e209b',
                      padding: 16,
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center'
                    }}
                  >
                    <i
                      class="material-icons icon-general-style"
                      style={{ fontSize: 26, color: 'white' }}
                    >
                      add
                    </i>
                  </div>
                    <div 
                      onClick={() => {
                        this.props.onBack()
                        this.props.saveReferencias(
                            this.state.AgregarReferenciasInfo
                          )
                      }}
                          style={{
                                position: 'fixed',
                                bottom: 14,
                                right: 320,
                                borderRadius: 60,
                                backgroundColor: '#2e209b',
                                padding: 16,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                height:58,
                                width:58
                              }}
                    >
                      <EntypoBack
                          style={{color: 'white',height:'26px', width:'26px' }}
                      />
                  </div>
            </div>
          </div>
        )}

        {this.state.addReferencias && (
          <>
            <Form>
              <FormGroup>
                <label htmlFor="username">Tipo doc :</label>
                <select
                  className="mt-2 form-control"
                  onChange={e => {
                    let result = {};
                    tipoDoc.map(item => {
                      if (item.value === e.target.value) {
                        result = item;
                      }
                    });

                    this.setState({
                      selectedTipoDoc: result
                    });
                  }}
                  value={this.state.selectedTipoDoc.value}
                >
                  <option value="" disabled>
                Seleccione una opción
                  </option>

                  {tipoDoc.map(item => {
                    return (
                      <option value={item.value}>
                        {' '}
                        {item.value} {item.label}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
              <FormGroup>
                <label htmlFor="username">N° doc :</label>
                <FormInput
                  id="username"
                  className="mt-2"
                  placeholder={'N° doc'}
                  value={this.state.nDoc}
                  onChange={text => this.setState({ nDoc: text.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="username">Fecha doc :</label>
                <DatePicker
                  className="mt-2 form-control"
                  onChange={e => {
                    console.log('original date is',e)
                     console.log("date is",new Date(e));
                    this.setState({ fechaDoc: e });
                  }}
                  value={this.state.fechaDoc}
                  maxDate={new Date()}
                />
              </FormGroup>
            </Form>

            <div className="mt-4 d-flex justify-content-between">
              <Button
                pill
                size="lg"
                className="mt-4 mr-2"
                style={{
                  width: '40%',
                  alignSelf: 'center'
                }}
                onClick={() => {
                  this.setState({ addReferencias: false });
                }}
              >
             {strings.back}
              </Button>
              <Button
                pill
                size="lg"
                className="mt-4"
                style={{
                  width: '40%',
                  alignSelf: 'center'
                }}
                onClick={() => {
                  if (
                    this.state.selectedTipoDoc !== '' &&
                    this.state.nDoc !== '' &&
                    this.state.fechaDoc !== ''
                  ) {
                    this.getAgregarReferenciasInfo(
                      {
                        tipoDoc: this.state.selectedTipoDoc,
                        nDoc: this.state.nDoc,
                        fechaDoc: this.state.fechaDoc
                      },
                      this.state.type,
                      this.state.index
                    );
                  } else {
                    this.setState(
                      {
                        visible: true,
                        message: 'Fill all required fields. '
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            visible: false,
                            message: ''
                          });
                        }, 2000);
                      }
                    );
                  }
                }}
              >
                Guardar
              </Button>
            </div>
            <Alert
              open={this.state.visible}
              theme="danger"
              style={{
                position: 'fixed',
                bottom: 10,
                width: '80%'
              }}
            >
              {this.state.message}
            </Alert>
          </>
        )}
      </>
    );
  }
}

export default AgregarReferencias;
