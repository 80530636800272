import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChangeUserPassword from "./ChangePassword";
import { strings } from "./../localization";
import { Language, API_URL } from "./../config";
// import Avatar from "react-avatar-edit";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      full_name: "",
      email: "",
      user_name: "",
      phone: "",
      loader: "",
      src: "",
      button: false
    };
  }

  componentDidMount() {
    fetch(`${API_URL}authlogin`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        

        if(res.data){
          
          this.setState({
            full_name: res.data.fullName,
            organisationID: res.data.organisationID,
            phone: res.data.phone,
            email: res.data.email,
            user_name: res.data.userName,
            id: res._id
          });
        }
      });
  }
  render() {
    const { full_name, email, phone, user_name } = this.state;
    const handleClick = () => {
      if (
        full_name === "" ||
        email === "" ||
        phone === "" ||
        user_name === ""
      ) {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });
        // let user_info = localStorage.getItem("login_info");
        // user_info = JSON.parse(user_info);
        // var user_id = user_info.id;

        let bodyStrings = JSON.stringify({
          email: email,
          fullName: full_name,
          userName: user_name,
          phone: phone,
          organisationID: this.state.organisationID
        });

        fetch(`${API_URL}users/${this.state.id}`, {
          method: "put",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: bodyStrings
        })
          .then(res => {
            if (res.status == 200) {
              toast.success(strings.profile_updated, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false });
            }
            return res.json();
          })
          .then(res => {
            if (res.message) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              this.setState({ loader: "", button: false });
            }
          })
          .catch(err => {
            console.warn(err);
          });

        // axios
        //   .post("http://localhost/lumen/public/api/update-user-profile", {
        //     id: user_id,
        //     fname: fname,
        //     lname: lname,
        //     email: email,
        //     image: preview
        //   })
        //   .then(response => {
        //     // console.log(response);

        //     this.setState({ loader: "" });
        //     if (response.data) {
        //       document.getElementById("user-name").innerHTML =
        //         fname + " " + lname;

        //       document.getElementById("user-img").src = preview;

        //       toast.success("Profile updated successfully", {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: false,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined
        //       });
        //     } else {
        //       toast.error("Profile not updated !!", {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: false,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined
        //       });
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error);
        //   });
      }
    };
    return (
      <div>
        <Container
          fluid
          className="main-content-container px-4"
          style={{ marginBottom: "200px" }}
        >
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Editar Perfil"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
          <Row>
            <Col lg="6" md="10" sm="12">
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Editar Perfil</h6>
                </CardHeader>
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form id="ProfileForm">
                          {/* <Row form>
                            <Col lg="12" className="form-group"> */}
                          <label htmlFor="full_name">Nombre completo</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">person</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="full_name"
                              value={full_name}
                              placeholder="Nombre completo"
                              onChange={e =>
                                this.setState({ full_name: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="userName">RUT usuario</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">person</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="userName"
                              value={user_name}
                              placeholder="RUT usuario"
                              onChange={e =>
                                this.setState({ user_name: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="email">Correo</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">email</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="email"
                              placeholder="Correo"
                              value={email}
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </InputGroup>

                          <label htmlFor="phone">Teléfono</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">phone</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="phone"
                              value={phone}
                              placeholder="Teléfono"
                              onChange={e =>
                                this.setState({ phone: e.target.value })
                              }
                            />
                          </InputGroup>

                          {/* <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="profile">Profile Picture</label>
                              <div className="mt-2 d-flex justify-content-around">
                                {/* <Avatar
                                  height={200}
                                  imageWidth={200}
                                  onCrop={this.onCrop}
                                  onClose={this.onClose}
                                /> */}
                          {/* <img
                                  src={this.state.preview}
                                  height="100"
                                  alt="Preview"
                                /> 
                              </div>
                            </Col>
                          </Row> */}
                          <ToastContainer />
                          <Button
                            theme="accent"
                            onClick={() => handleClick()}
                            disabled={this.state.button}
                          >
                            <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i>{" "}
                           Actualización Perfil
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            <Col lg="6" md="10" sm="12">
              <ChangeUserPassword />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
