import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  DatePicker,
  Button,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormTextarea
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect, Link } from "react-router-dom";
import { strings } from "./../../localization";
import { Language, API_URL } from "./../../config";

export default class ManageFolioConsumption extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      transactionType: "",
      transactionID: "",
      templateID: "",
      barcodeXML: "",
      xmlType: "",
      loader: "",
      id: "",
      button: false,
      hide: true,
      redirect: false,
      disable: false
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;

    if (type !== undefined) {
      this.setState({
        type: type
      });
      if (id) {
        fetch(`${API_URL}transaction-xml/${id}`, {
          method: "GET",
          credentials: "include"
        })
          .then(res => res.json())
          .then(({ data }) => {
            console.log(data);
            this.setState({
              transactionType: data.transactionType,
              transactionID: data.transactionID,
              templateID: data.templateID,
              barcodeXML: data.barcodeXML,
              xmlType: data.xmlType,
              id: "/" + id
            });
          });
      }
    }
  }

  Capitalize(str) {
    return str !== "edit"
      ? "Create Folio Consumption"
      : "Edit Folio Consumption";
  }

  handleStartDateChange(value) {
    value = this.convert(value);

    this.setState({
      folioDate: value
    });
  }

  convert(str) {
    var month, day;
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    var mySQLDate = [date.getFullYear(), month, day].join("-");
    mySQLDate = [mySQLDate].join(" ");

    return mySQLDate;
  }

  render() {
    const {
      transactionType,
      transactionID,
      templateID,
      barcodeXML,
      xmlType
    } = this.state;
    const handleClick = () => {
      // alert(this.state.disable);
      if (
        transactionType === "" ||
        transactionID === "" ||
        templateID === "" ||
        barcodeXML === "" ||
        xmlType === ""
      ) {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });

        let strings = JSON.stringify({
          transactionType,
          transactionID,
          templateID,
          barcodeXML,
          xmlType
        });

        fetch(`${API_URL}transaction-xml${this.state.id}`, {
          method: this.state.id ? "put" : "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: strings
        })
          .then(res => {
            if (res.status == 200) {
              toast.success("Record saved successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false });
            }
            return res.json();
          })
          .then(res => {
            if (res.message) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              this.setState({ loader: "", button: false });
            }
          })
          .catch(err => {
            console.warn(err);
          });
      }
    };

    return (
      <div>
        {this.state.redirect ? (
          <Redirect
            push
            to={{
              pathname: "/folio-consumptions",
              state: {
                message: `Folio Consumption ${this.state.type} successfully`
              }
            }}
          />
        ) : null}
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <Col>
              <PageTitle
                title={this.Capitalize(this.state.type)}
                subtitle={strings.overview}
                md="12"
                className="ml-sm-auto mr-sm-auto"
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={"/folios"} className="float-left">
                <Button id="go_back" theme="light" className="mb-2 mr-1">
                  {strings.go_back}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <Card small className="mb-4">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Form id="ProfileForm">
                      <Row>
                        <Col>
                          <label htmlFor="transactionType">
                            Transaction Type
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">title</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="transactionType"
                              value={transactionType}
                              placeholder="Transaction type"
                              onChange={e =>
                                this.setState({
                                  transactionType: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="transactionID">Transaction ID</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">article</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="transactionID"
                              value={transactionID}
                              placeholder="Transaction ID"
                              onChange={e =>
                                this.setState({
                                  transactionID: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="templateID">Template ID</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">looks_one</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="templateID"
                              value={templateID}
                              placeholder="Template ID"
                              onChange={e =>
                                this.setState({ templateID: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <label htmlFor="xmlType">XML Type</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">dns</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="xmlType"
                              value={xmlType}
                              placeholder="XML Type"
                              onChange={e =>
                                this.setState({
                                  xmlType: e.target.value
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label htmlFor="barcodeXML">barcodeXML</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">lock</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormTextarea
                              rows="5"
                              id="barcodeXML"
                              value={barcodeXML}
                              placeholder="Barcode XML"
                              onChange={e =>
                                this.setState({ barcodeXML: e.target.value })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <ToastContainer />
                      <Button
                        theme="accent"
                        onClick={() => handleClick()}
                        disabled={this.state.button}
                      >
                        <i
                          className={this.state.loader}
                          style={{ fontSize: "15px" }}
                        ></i>
                        {this.state.type !== "edit"
                          ? strings.save
                          : strings.update}
                      </Button>
                    </Form>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            {/* <Col>
            <Link to={"/folios"} className="float-left">
                <Button
                  size="sm"
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                  style={
                    {
                      // marginTop: "-84px",
                      // position: "relative"
                      // left: "-100px"
                    }
                  }
                >
                  {strings.go_back}
                </Button>
              </Link>
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}
