import React from "react";
import { Row, Col, FormInput } from "shards-react";
import styles from "./../../views/Style.module.scss";

export default class PasswordField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: true
    };
  }

  hideSwitch = ev => {
    this.setState({ hide: !this.state.hide });
  };

  render() {
    const { title, name } = this.props;
    const { hide } = this.state;
    return (
      <Row form>
        <Col lg="12" className="form-group">
          <label>{title} </label>
          <FormInput type={hide ? "password" : "input"} id={name} />
          <span className={styles.password__show} onClick={this.hideSwitch}>
            {hide ? (
              <i className="material-icons" style={{ top: "-4px" }}>
                visibility
              </i>
            ) : (
              <i className="material-icons" style={{ top: "-4px" }}>
                visibility_off
              </i>
            )}
          </span>
        </Col>
      </Row>
    );
  }
}
