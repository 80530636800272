import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'shards-react'
import Select, { components } from 'react-select';
import PageTitle from '../../components/common/PageTitle'
import { strings } from '../../localization'
import './Restaurant.css'
import { API_URL } from '../../config'
import { Link } from 'react-router-dom';


const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 15,
        width: 15,
    },
});

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: '40px',
    }),
    input: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.bgColor) }),
};


function RestaurantTables(props) {

    // status dropdown
    const [selectedStaus, setSelectedStaus] = useState("");
    const [items, setItems] = useState([]);
    const [tableStatusData, setTableStatusData] = useState([]);

    // Table  states
    const [tableList, setTableList] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleChangeStatus = (e) => {
        console.log('event', e);

        const selectedValue = e.value;

        setSelectedStaus(selectedValue);

        if (tableData.length > 0) {
            let result = [];
            if (selectedValue === 'all') {
                result = [...tableData];
            } else {
                result = tableData.filter((singleItem) => singleItem.data.status.value === selectedValue)
            }
            console.log('table filter result', result)

            setTableList([...result])
        }
    }

    const getRestaurantTableStaus = async () => {
        let statusCode = 0;
        let tableData = [];
        fetch(`${API_URL}restaurant-table-status`, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((res) => {
            console.log('response of table status', res)
            if (statusCode == 200) {
                getTableList();
                let data = res['result'];
                console.log('data', data);
                if (data !== undefined && data !== null && data.length > 0) {
                    data.push({
                        label: strings.select_all,
                        value: 'all',
                        bgColor: 'transparent'
                    });
                    data.map((item, index) => {
                        console.log('item', item)
                        tableData.push({
                            label: item['label'],
                            value: item['value'],
                            bgColor: item['bgColor']
                        });
                    })
                    console.log('tableData', tableData)
                    setItems([...tableData])
                    setTableStatusData(data);
                    getTableList();
                }
            }
        }).catch((err) => {
            console.log('catch error at restaurant table status', err);
            setIsLoading(false);
        })
    }

    const getTableList = async () => {
        let statusCode = 0;

        fetch(`${API_URL}restaurant-table/status`, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((res) => {
            console.log('response of table list', res)
            if (statusCode == 200) {
                let data = res['result'];
                console.log('data', data);
                if (data !== undefined && data !== null && data.length > 0) {
                    setIsLoading(false);
                    setTableList([...data]);
                    setTableData([...data]);
                }
            }
        }).catch((err) => {
            console.log('catch error at table list', err);
            setIsLoading(false);
        })
    }

    const CustomOption = (props) => {
        console.log('props,', props)
        return (
            <components.Option {...props}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        props.data.bgColor &&
                        <div className='dropDownIcon' style={{
                            backgroundColor: props.data.bgColor,
                            marginRight: 5
                        }} />
                    }
                    <div>
                        <div>{props.data.label}</div>
                    </div>
                </div>
            </components.Option>
        );
    };

    useEffect(() => {
        getRestaurantTableStaus();
    }, [])

    const handleTableClick = (item) => {
        const data = item['data'];
        const tableId = item['_id'];
        const orderId = data['orderID'];
    }

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col sm="12" md="8" lg="3">
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <Select
                                options={items}
                                onChange={handleChangeStatus}
                                components={{ Option: CustomOption }}
                                placeholder={strings.select_table_status}
                                styles={customStyles}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4 tableCard">
                        <CardBody className="p-3 b-3">
                            <PageTitle
                                sm="4"
                                title={strings.choose_table}
                                className="text-sm-left page-title"
                            />
                            <hr />
                            <Row noGutters className="py-4 parent" >
                                <Col sm="12" md="10" lg="10">


                                    <div className="MainDiv tableParentContainer">
                                        {
                                            isLoading
                                                ? <h4>Loading...</h4>
                                                :
                                                tableList.length > 0
                                                    ?
                                                    tableList.length > 0 && tableList.map((item, index) => {
                                                        return (
                                                            <Link
                                                                to={{
                                                                    pathname: `/restaurant/${item._id}`,
                                                                    state: {
                                                                        orderId: item['data']['orderID']
                                                                    }
                                                                }}
                                                            >
                                                                <div className='tableContainer'>
                                                                    <div className='tableItemContainer'>
                                                                        <div className='tableItem' style={{ borderColor: item['data']['status']['bgColor'], backgroundColor: item['data']['status']['bgColor'] }} />
                                                                        <div className='tableItem' style={{ borderColor: item['data']['status']['bgColor'], backgroundColor: item['data']['status']['bgColor'] }} />
                                                                    </div>
                                                                    <div className='tableContentContainer' style={{ backgroundColor: item['data']['status']['bgColor'] }}>
                                                                        <p className='tableInfoFontStyle' style={{ color: item['data']['status']['textColor'] }}>{item['data']['tableName']}</p>
                                                                        <p className='tableInfoFontStyle' style={{ color: item['data']['status']['textColor'] }}>{item['data']['status']['label']}</p>
                                                                    </div>
                                                                    <div className='tableItemContainer'>
                                                                        <div className='tableItem' style={{ borderColor: item['data']['status']['bgColor'], backgroundColor: item['data']['status']['bgColor'] }} />
                                                                        <div className='tableItem' style={{ borderColor: item['data']['status']['bgColor'], backgroundColor: item['data']['status']['bgColor'] }} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )
                                                    })
                                                    : <h4 style={{ textAlign: 'center', width: '100%' }}>{strings.no_data_available}</h4>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RestaurantTables