import React from 'react'
import { Button, Card, CardBody, Col, Container, Row, FormInput, FormTextarea, Alert } from 'shards-react'
import PageTitle from '../../components/common/PageTitle'
import { strings } from '../../localization'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function RestaurantOrders() {
    return (
        <Container fluid className="main-content-container px-4 mx-10">
            <Row>
                <Col>
                    <Card small className="mb-4 tableCard mt-5">
                        <CardBody className="p-3 b-3">
                            <PageTitle
                                sm="4"
                                title={strings.dashboard}
                                className="text-sm-left page-title"
                            />
                            <hr />
                            <Tabs
                                defaultActiveKey="profile"
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="home" title="In queue">
                                    In queue
                                </Tab>
                                <Tab eventKey="profile" title="Cooking">
                                    Cooking
                                </Tab>
                                <Tab eventKey="longer-tab" title="Done">
                                    Done
                                </Tab>
                            </Tabs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default RestaurantOrders