import React from 'react';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from 'react-router-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import { strings } from './../../localization';
import { Language, API_URL, LIMIT, BARCODE_RESPONSE_HEIGHT, BARCODE_RESPONSE_WIDTH, BARCODE_WIDTH, BARCODE_P_LEFT, BARCODE_P_WIDTH, getBarcodeValuesFromResponse, getBarcodeImageHeight } from './../../config';
import classNames from 'classnames';
import { Container, Row, Col, Card, Button, CardBody } from 'shards-react';
// import $ from "jquery";
// import SweetAlert from "react-bootstrap-sweetalert";
import swal from 'sweetalert2';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Calculator from './Calculator';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './Ventas.css';
import Modal from 'react-bootstrap/Modal';
import Invoice from '../Ventas/Invoice';
import moment from 'moment';
window.Swal = swal;
const classes = classNames('text-md-left', 'mb-sm-0');

let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

let start_date = '01' + '/' + mm + '/' + yyyy;
let end_date = dd + '/' + mm + '/' + yyyy;

let startDateApi = yyyy + '-' + mm + '-' + '01';
let endDateApi = yyyy + '-' + mm + '-' + dd;

class Customers extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0,
    skip: 0,
    limit: LIMIT,
    currentPage: 1,
    prev: true,
    next: false,
    loading: true,
    apiName: 'bills',
    isSideNavebarShow: false,
    startDate: start_date,
    endDate: end_date,
    startDateApi: startDateApi,
    endDateApi: endDateApi,
    months: {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    },
    locale: {
      format: 'DD/MM/YYYY'
    },
    documentsType: '',
    permissions: {},
    type: '',
    // reportType: 'bills',
    reportType: 'all',
    // dropDownReportTypeValue: 'bills',
    dropDownReportTypeValue: 'all',
    // reportTypeParams: '&withoutTax=false',
    reportTypeParams: '',
    // bacodeParams: '?withoutTax=false',
    bacodeParams: '',
    isViewDocumentButtonLoading: false,
    isEliminarButtonLoading: false,
    showDocumentDetailsModal: false,
    showLoader: false,
    detailModalData: {},
    transactionDocumentId: '',
    transactionDocumentType: '',
    editProductFromTransaction: false,
    editProductData: []
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: 'documents',
        text: 'Documentos'
      },
      {
        key: 'folio',
        text: 'Folio'
      },
      {
        key: 'total',
        text: 'Total'
      },
      {
        key: 'paymentMethod',
        text: 'Medio de Pago'
      },
      {
        key: 'customerName',
        text: 'Nombre del cliente'
      },
      {
        key: 'date',
        text: 'Fecha'
      },
      {
        key: 'user',
        text: 'Usuario'
      },
      {
        key: '',
        text: 'Acción',
        cell: record => {
          console.log('record', record);
          let newData = record.data;
          let number = newData.transactionNumber;
          return (
            <div className="mt-2">
              <Button
                size="sm"
                theme="success"
                className="mb-2"
                onClick={() => this.viewDocument(record._id, number, record)}
              >
                Ver documento
              </Button>

              <Button
                style={{ marginLeft: 20, height: 32.19 }}
                size="sm"
                theme="success"
                className="mb-2"
                onClick={() => this.downloadPdfFile(record)}
              >
                <i class="material-icons" style={{ fontSize: 25, top: -4, color: 'white' }}>
                  picture_as_pdf
                </i>
                {/* <span className="spinner-border spinner-border-sm" role="status"  aria-hidden="true"></span>
                <i class="material-icons" style={{ fontSize: 25, top: -4, color: 'white' }}>
                    picture_as_pdf
                </i> */}
              </Button>

              {(record.data.typeOfDocument === 39 ||
                record.data.typeOfDocument === 33 ||
                record.data.typeOfDocument === 61
              ) && (
                  <Button
                    // disabled={record.isCreditnoteGenerated}
                    style={{ marginLeft: 20 }}
                    size="sm"
                    theme="danger"
                    className="mb-2"
                    onClick={() => this.generateCreaditNote(record._id, record)}
                  >
                    Eliminar
                  </Button>
                )}

              {record.data.typeOfDocument === 33 || record.data.typeOfDocument === 99 ? (
                <Button
                  // disabled={record.data.typeOfDocument === 99 && record.data.invoiceCreated ? true : false}
                  disabled={record.data.typeOfDocument === 99 && record.data.invoiceCreated !== undefined && record.data.invoiceCreated || record.data.quoteConverted !== undefined && record.data.quoteConverted ? true : false}
                  style={{ marginLeft: 20 }}
                  size="sm"
                  theme="success"
                  className="mb-2"
                  onClick={() => {
                    const type = this.setDefaultApiName(record.data.typeOfDocument);
                    console.log('type', type);
                    if (type === "invoices") {
                      this.setState(
                        {
                          type: 5,
                          // documentsType: strings.debit_note
                          documentsType: 'débito',
                          transactionDocumentId: record._id,
                          transactionDocumentType: type
                        },
                        () => {
                          this.handleShowSideNavBar();
                        });
                    } else if (type === "quotes") {
                      this.handleConvertQuoteToInvoice(record._id, record);
                    }
                  }}
                >
                  agregar
                </Button>
              ) : null
              }
              {record.data.typeOfDocument == 99 &&
                <Button
                  disabled={record.data.typeOfDocument === 99 && record.data.invoiceCreated !== undefined && record.data.invoiceCreated || record.data.quoteConverted !== undefined && record.data.quoteConverted ? true : false}
                  style={{
                    marginLeft: 20
                  }}
                  size="sm"
                  // theme="success"
                  className="mb-2"
                  onClick={() => {
                    let data = { '_id': record._id, 'data': record.data };
                    this.setState({
                      type: 6,
                      documentsType: 'Cotizacion',
                      editProductFromTransaction: true,
                      editProductData: data
                    },
                      () => this.handleShowSideNavBar()
                    )
                  }}
                >
                  Editar
                </Button>
              }
            </div>
          );
        }
      }
    ];
    this.config = {
      // page_size: 10,
      show_length_menu: false,
      show_info: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        extra: false
      }
    };
  }
  setDefaultApiName(type) {
    let name = '';
    if (this.state.permissions.get_bill && type === 39) {
      name = 'bills';
    } else if (this.state.permissions.get_invoice && type === 33) {
      name = 'invoices';
    } else if (this.state.permissions.get_creditnote && type === 61) {
      name = 'creditnotes';
    } else if (this.state.permissions.get_debitnote && type === 56) {
      name = 'debitnotes';
    } else if (this.state.permissions.get_dispatchnote && type === 52) {
      name = 'dispatchnotes';
    } else if (type === 99) {
      name = 'quotes';
    } else {
      name = 'bills';
    }
    return name;
  }

  generateCreaditNote = (id, item) => {
    this.setState({ isEliminarButtonLoading: true });
    let name = '';
    if (name === '') name = this.setDefaultApiName(item.data.typeOfDocument);

    if (name === 'invoices') {
      swal
        .fire({
          title: 'Nota de crédito',
          text:
            '¿Deseas emitir una nota de crédito para anular el documento seleccionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'
        })
        .then(response => {
          if (response.isConfirmed) {
            let status = '';
            fetch(`${API_URL}creditnotes?invoice=${id}`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              credentials: 'include'
            })
              .then(res => {
                status = res.status;
                return res.json();
              })
              .then(res => {
                this.setState({ isEliminarButtonLoading: false });
                if (status == 200) {
                  swal.fire(
                    '',
                    'La nota de crédito ha sido creada con éxito. Para ver el documento debe ingresar a Transacciones.',
                    'success'
                  );
                  this.getData();
                } else if (status == 400) {
                  swal.fire('', res.message, 'error');
                }
              })
              .catch(err => {
                console.log(err);
                this.setState({ isEliminarButtonLoading: false });
              });
          }
        });
    } else if (name === 'bills' || name === '') {
      swal
        .fire({
          title: 'Nota de crédito',
          text:
            '¿Deseas emitir una nota de crédito para anular el documento seleccionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'

        })
        .then(response => {
          if (response.isConfirmed) {
            let status = '';
            fetch(`${API_URL}creditnotes?bill=${id}`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              credentials: 'include'
            })
              .then(res => {
                status = res.status;
                return res.json();
              })
              .then(res => {
                this.setState({ isEliminarButtonLoading: false });
                if (status == 200) {
                  swal.fire(
                    '',
                    'La nota de crédito ha sido creada con éxito. Para ver el documento debe ingresar a Transacciones.',
                    'success'
                  );
                  this.getData();
                } else if (status == 400) {
                  swal.fire('', res.message, 'error');
                }
              })
              .catch(err => {
                console.log(err);
                this.setState({ isEliminarButtonLoading: false });
              });
          }
        });
    } else if (name !== '' && name === 'creditnotes') {
      swal
        .fire({
          title: 'Nota de crédito',
          text:
            '¿Deseas emitir una nota de débito para anular el documento seleccionado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'
        }).then(response => {
          if (response.isConfirmed) {
            let status = '';
            fetch(`${API_URL}debitnotes?creditnote=${id}`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              credentials: 'include'
            })
              .then(res => {
                status = res.status;
                return res.json();
              })
              .then(res => {
                this.setState({ isEliminarButtonLoading: false });
                if (status == 200) {
                  console.log('debit note for credit note api 200 response', res);
                  swal.fire(
                    '',
                    'La nota de débito ha sido creada con éxito. Para ver el documento debe ingresar a Transacciones.',
                    'success'
                  );
                  this.getData();
                } else if (status == 400) {
                  console.log('debit note for credit note api 400 response', res);
                  swal.fire('', res.message, 'error');
                } else {
                  console.log('debit note for credit note error', res)
                  swal.fire('', 'Something Went Wrong', 'error');
                }
              })
              .catch(err => {
                console.log("debit note for credit note api error", err);
                this.setState({ isEliminarButtonLoading: false });
              });
          }
        });
    } else {
      swal
        .fire({
          title: '',
          text: '¿Está seguro de que desea eliminar estos datos?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'
        })
        .then(response => {
          if (response.isConfirmed) {
            let status = '';
            fetch(`${API_URL}${name}/${id}`, {
              method: 'delete',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              credentials: 'include'
            })
              .then(res => {
                status = res.status;
                return res.json();
              })
              .then(res => {
                console.log(res);
                this.setState({ isEliminarButtonLoading: false });
                if (status == 200) {
                  swal.fire('', 'Transacción eliminada con éxito', 'success');
                  this.getData();
                }
              })
              .catch(err => {
                this.setState({ isEliminarButtonLoading: false });
                console.log(err);
              });
          }
        });
    }
  };

  // viewDocument = async (id, number, item) => {
  //   console.log('Item in viewDocument', id, number, item);

  //   this.setState({ isViewDocumentButtonLoading: true });
  //   var responseHeight = '';
  //   var responseWidth = '';
  //   var pLeft = BARCODE_P_LEFT;
  //   var pWidth = BARCODE_P_WIDTH;
  //   let name = '';
  //   if (name === '') name = this.setDefaultApiName(item.data.typeOfDocument);

  //   fetch(`${API_URL}barcode/${name}/${id}${this.state.bacodeParams}`, {
  //     method: 'get',
  //     credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //     .then(response => {
  //       console.log('response headers', ...response.headers);
  //       if (Object.keys(response.headers).length > 0) {
  //         responseHeight = response.headers.map['barcode-image-height'];
  //         responseWidth = response.headers.map['barcode-image-width'];
  //         pLeft = parseInt(response.headers.map['barcode-p-left']);
  //         pWidth = parseInt(response.headers.map['barcode-p-width']);
  //       } else {
  //         responseHeight = BARCODE_RESPONSE_HEIGHT;
  //         responseWidth = BARCODE_RESPONSE_WIDTH;
  //       }


  //       if (response.status === 404) {
  //         return response.json();
  //       } else {
  //         return response.text();
  //       }

  //     })
  //     .then(result => {
  //       // console.log('result.......', result);
  //       this.setState({ isViewDocumentButtonLoading: false });

  //       if (result.message) {
  //         swal.fire('', result.message, 'error');
  //       } else {
  //         let barcodeWidth = BARCODE_WIDTH;
  //         console.log('barcode width', barcodeWidth);
  //         console.log('responseHeight', responseHeight);
  //         console.log('responseWidth', responseWidth);
  //         let barcodeHeight = (barcodeWidth * responseHeight) / responseWidth;
  //         barcodeHeight = Math.round(barcodeHeight);
  //         console.log('barcodeHeight in ventas', barcodeHeight);
  //         console.log('barcodeWidth in ventas', barcodeWidth);
  //         this.setState(
  //           {
  //             detailModalData: {
  //               bill_id: id,
  //               type: name,
  //               view: true,
  //               printNumber: number,
  //               barcodeImage: result,
  //               barcodeHeight: barcodeHeight,
  //               barcodeWidth: barcodeWidth,
  //               pLeft: pLeft,
  //               pWidth: pWidth,
  //               transaction_view: true,
  //               references: item.data.references
  //             }
  //           },
  //           () => {
  //             this.setState({ showDocumentDetailsModal: true });
  //           }
  //         );
  //       }
  //     })
  //     .catch(err => {
  //       this.setState({ isViewDocumentButtonLoading: false });
  //       console.log(err);
  //     });
  // };


  viewDocument = async (id, number, item) => {
    console.log('Item in viewDocument', id, number, item);

    this.setState({ isViewDocumentButtonLoading: true });
    var responseHeight = '';
    var responseWidth = '';
    var pLeft = BARCODE_P_LEFT;
    var pWidth = BARCODE_P_WIDTH;
    let name = '';
    if (name === '') name = this.setDefaultApiName(item.data.typeOfDocument);

    let params = this.state.bacodeParams;
    if (params.includes("?")) {
      params += '&';
    } else {
      params += '?'
    }
    params += 'responseType=json'

    fetch(`${API_URL}barcode/${name}/${id}${params}`, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        console.log('response headers', ...response.headers);
        return response.json();
      })
      .then(result => {
        console.log('result.......', result);
        this.setState({ isViewDocumentButtonLoading: false });

        if (result.message) {
          swal.fire('', result.message, 'error');
        } else {
          let barcodeWidth = BARCODE_WIDTH;
          const data = getBarcodeValuesFromResponse(result);
          responseHeight = data.responseHeight;
          responseWidth = data.responseWidth;
          pLeft = data.pLeft;
          pWidth = data.pWidth;

          console.log('barcode width', barcodeWidth);
          console.log('responseHeight', responseHeight);
          console.log('responseWidth', responseWidth);
          let barcodeHeight = getBarcodeImageHeight(barcodeWidth, responseHeight, responseWidth);
          console.log('barcodeHeight in ventas', barcodeHeight);
          console.log('barcodeWidth in ventas', barcodeWidth);
          this.setState(
            {
              detailModalData: {
                bill_id: id,
                type: name,
                view: true,
                printNumber: number,
                // barcodeImage: result,
                barcodeImage: data.barcodeImage,
                barcodeHeight: barcodeHeight,
                barcodeWidth: barcodeWidth,
                pLeft: pLeft,
                pWidth: pWidth,
                transaction_view: true,
                references: item.data.references
              }
            },
            () => {
              this.setState({ showDocumentDetailsModal: true });
            }
          );
        }
      })
      .catch(err => {
        this.setState({ isViewDocumentButtonLoading: false });
        console.log(err);
      });
  };

  downloadPdfFile = (record) => {
    console.log('record: ', record);
    console.log('record id: ' + record._id);
    const type = this.setDefaultApiName(record.data.typeOfDocument);

    console.log('type: ' + type);
    fetch(`${API_URL}download-pdf/${type}/${record._id}/ORIGINAL`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => {
        res.blob().then((blob) => {
          console.log('blob', blob);
          const fileURL = window.URL.createObjectURL(blob);
          console.log('fileURL', fileURL)
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'ebol_ventas.pdf';
          alink.click();
        });
      })
      .catch(err => {
        console.log('error at download pdf', err);
      });
  }

  async componentDidMount() {
    this.getData();
    let permissions = await localStorage.getItem('user_permissions');
    this.setState({
      permissions: JSON.parse(permissions)
    });

    // console.log(this.state.permissions);
  }

  getData = (skip = 0, param = '') => {
    console.log(`getData parameter is 1. skip : ${skip} and param : ${param} state skip is ${this.state.skip}`);
    this.setState({
      loading: true
    });
    console.log(`before add to url startDateApi is ${this.state.startDateApi} and endDateApi is ${this.state.endDateApi}`)
    let urlParam = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}&limit=${this.state.limit}&skip=${skip}${param}${this.state.reportTypeParams}`;
    let url = `${API_URL}sales/${this.state.reportType}${urlParam}`;

    fetch(`${url}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(res => {
        console.log('sales api response', res);
        if (res.result.length > 0) {
          if (res.result.length !== this.state.limit) {
            this.setState({
              next: true
            });
          }

          this.setState({
            total: res.result.length + 1,
            records: res.result,
            loading: false
          });
        } else {
          this.setState({
            next: true,
            loading: false,
            records: []
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  pageIncrement() {
    if (!this.state.next) {
      console.log(`this.state.currentPage is ${this.state.currentPage} and this.state.limit is ${this.state.limit}`)
      let skip = this.state.currentPage * this.state.limit;
      console.log(`in before call getData function skip state is : ${skip}`)
      this.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    }
  }

  handleChange(e) {
    // alert(e.target.value);
    this.setState({
      documentsType: e.target.value
    });
  }
  handleReportChange(e) {
    console.log('ev is', e)
    // alert(e.target.value);
    let stateValue;
    let params;
    let barcodeParam;
    if (e.target.value === 'bills') {
      params = '&withoutTax=false'
      barcodeParam = '?withoutTax=false'
      stateValue = e.target.value
    } else if (e.target.value === 'bills_no_tax') {
      stateValue = 'bills'
      params = '&withoutTax=true'
      barcodeParam = '?withoutTax=true'
    } else if (e.target.value === 'invoices') {
      params = '&withoutTax=false'
      barcodeParam = '?withoutTax=false'
      stateValue = e.target.value
    } else if (e.target.value === 'invoices_no_tax') {
      stateValue = 'invoices'
      params = '&withoutTax=true'
      barcodeParam = '?withoutTax=true'
    } else {
      stateValue = e.target.value
      params = ''
      barcodeParam = ''
    }
    this.setState(
      {
        reportType: stateValue,
        dropDownReportTypeValue: e.target.value,
        reportTypeParams: params,
        bacodeParams: barcodeParam,
        // reportType: e.target.value,
        prev: true,
        next: false,
        skip: 0,
        currentPage: 1
      },
      () => {
        this.getData();
      }
    );
  }

  downloadCsvFile() {
    this.setState({ showLoader: true });
    let param = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}&type=csv${this.state.reportTypeParams}`;
    let url = `${API_URL}sales/${this.state.reportType}${param}`;

    fetch(`${url}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ showLoader: false });
        console.log('sales download csv api response ......', data);
        let csvContent =
          'data:text/csv;charset=utf-8,' +
          data.result.map(e => e.join(',')).join('\n');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'ebol_ventas.csv');
        document.body.appendChild(link);

        link.click();
      })
      .catch(err => {
        console.log('sales download csv api error ......', err);
      });
  }

  handleShowSideNavBar() {
    if (this.state.isSideNavebarShow) {
      this.setState({ isSideNavebarShow: false });
    } else {
      this.setState({ isSideNavebarShow: true });
    }
  }

  handleCallback = (start, end) => {
    var startDate = new Date(start);
    console.log(`startDate is ${startDate} `)
    let formatStartDate = startDate.toDateString().split(' ')
    let startDateMonth = this.state.months[formatStartDate[1]]
    let startDateApi = formatStartDate[3] + '-' + startDateMonth + '-' + formatStartDate[2]
    console.log("🚀 ~ file: index.js ~ line 532 ~ Customers ~ startDateApi1", startDateApi)


    // var startDateApi = startDate.setDate(startDate.getDate() + 1);
    // startDateApi = startDate.toISOString().slice(0, 10);
    var endDate = new Date(end);
    console.log("🚀 ~ file: index.js ~ line 550 ~ Customers ~ endDate", endDate)
    // var endDateApi = endDate.toISOString().slice(0, 10);


    let formatEndDate = endDate.toDateString().split(' ')
    let endDateMonth = this.state.months[formatEndDate[1]]
    let endDateApi = formatEndDate[3] + '-' + endDateMonth + '-' + formatEndDate[2]


    // var endDateApi = endDate.toDateString();

    // let startDateApi1 =  moment(startDate).format('YYYY/DD/MM')
    // let endDateApi1 =  moment(endDate).format('YYYY/DD/MM')
    // console.log(`startDateApi1 is ${startDateApi1} and endDateApi1 is ${endDateApi1}`)

    console.log(`var startDateApi = ${startDateApi} and endDateApi = ${endDateApi}`)
    this.setState(
      {
        startDateApi,
        endDateApi,
        startDate: start,
        endDate: end
      },
      () => {
        this.getData();
      }
    );
  };

  handleConvertQuoteToInvoice(id, item) {
    let today = new Date();
    let time = today.toLocaleTimeString();
    console.log("🚀 ~ file: Transactions.js:597 ~ addDebitNote ~ time:", time)
    let formatedDate = moment(today).format('DD/MM/YYYY')
    console.log("🚀 ~ file: Transactions.js:598 ~ addDebitNote ~ formatedDate:", formatedDate)
    // item.data.invoiceCreated

    // commented at 2023-07-26
    // swal
    //       .fire({
    //         title: 'Factura electrónica',
    //         text:
    //           '¿Deseas convertir la cotización en una factura electrónica?',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'SI',
    //         cancelButtonText: 'NO'
    //       }).then(response => {
    //         if (response.isConfirmed) {
    //           let status = '';
    //           console.log(`${API_URL}quotes/${id}/invoice?date=${formatedDate}&time=${time}`);
    //           fetch(`${API_URL}quotes/${id}/invoice?date=${formatedDate}&time=${time}`, {
    //             method: 'POST',
    //             headers: {
    //               Accept: 'application/json',
    //               'Content-Type': 'application/json'
    //             },
    //             credentials: 'include'
    //           })
    //             .then(res => {
    //               status = res.status;
    //               return res.json();
    //             })
    //             .then(res => {
    //               console.log("🚀 ~ file: index.js:783 ~ Customers ~ handleConvertQuoteToInvoice ~ res:", res)
    //               if (status == 200) {
    //                 fetch(`${API_URL}barcode/invoices/${res._id}?withoutTax=false`, {
    //                   method: 'get',
    //                   headers: {
    //                     Accept: 'application/json',
    //                     'Content-Type': 'application/json',
    //                   },
    //                   credentials: 'include'
    //                 })
    //                 .then((response) => {
    //                   console.log("response in barcode api......", response);
    //                   if (response.status !== 200) {
    //                     return response.json();
    //                   } else {
    //                     return response.text();
    //                   }
    //                 })
    //                 .then((result) => {
    //                   console.log("result in barcode api than method result......", result);
    //                   if (result.message) {
    //                     swal.fire(
    //                       '',
    //                       result.message,
    //                       'error'
    //                     )
    //                   } else {
    //                     swal.fire(
    //                       '',
    //                       'La factura electrónica ha sido creada con éxito. Para ver el documento debe ingresar a Transacciones.',
    //                       'success'
    //                     );
    //                     this.getData();
    //                   }
    //                 })
    //                 .catch((err) => {
    //                   console.log("🚀 ~ file: index.js:818 ~ Customers ~ handleConvertQuoteToInvoice ~ err:", err)
    //                 });
    //               } else {
    //                 console.log('debit note for credit note error', res)
    //                 swal.fire('', res.message, 'error');
    //               }
    //             })
    //             .catch(err => {
    //               console.log("🚀 ~ file: index.js:804 ~ Customers ~ handleConvertQuoteToInvoice ~ err:", err)
    //             });
    //         }
    //   });
    // Todo
    swal
      .fire({
        title: 'Convertir documento',
        text: 'convertir documento a factura electrónica o boleta electrónica?',
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'factura'.toUpperCase(),
        cancelButtonText: 'Cancelar'.toUpperCase(),
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: 'boleta'.toUpperCase(),
        denyButtonColor: 'blue',
        confirmButtonColor: 'blue',
        reverseButtons: true,
      }).then(response => {
        console.log("🚀 ~ file: index.js:884 ~ Customers ~ handleConvertQuoteToInvoice ~ response:", response)
        if (response.isConfirmed) {
          // invoice code
          let status = '';
          console.log(`${API_URL}quotes/${id}/invoice?date=${formatedDate}&time=${time}`);
          fetch(`${API_URL}quotes/${id}/invoice?date=${formatedDate}&time=${time}`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          })
            .then(res => {
              status = res.status;
              return res.json();
            })
            .then(res => {
              console.log("🚀 ~ file: index.js:783 ~ Customers ~ handleConvertQuoteToInvoice ~ res:", res)
              if (status == 200) {
                fetch(`${API_URL}barcode/invoices/${res._id}?withoutTax=false&responseType=json`, {
                  method: 'get',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  credentials: 'include'
                })
                  .then((response) => {
                    console.log("response in barcode api......", response);
                    return response.json();
                  })
                  .then((result) => {
                    console.log("result in barcode api than method result......", result);
                    if (result.message) {
                      swal.fire(
                        '',
                        result.message,
                        'error'
                      )
                    } else {
                      swal.fire(
                        '',
                        'La factura electrónica ha sido creada con éxito. Para ver el documento debe ingresar a Transacciones.',
                        'success'
                      );
                      this.getData();
                    }
                  })
                  .catch((err) => {
                    console.log("🚀 ~ file: index.js:818 ~ Customers ~ handleConvertQuoteToInvoice ~ err:", err)
                  });
              } else {
                console.log('debit note for credit note error', res)
                swal.fire('', res.message, 'error');
              }
            })
            .catch(err => {
              console.log("🚀 ~ file: index.js:804 ~ Customers ~ handleConvertQuoteToInvoice ~ err:", err)
            });
        } else if (response.isDenied) {
          // bills code
          let status = '';
          console.log(`${API_URL}quotes/${id}/bill?date=${formatedDate}&time=${time}`);
          fetch(`${API_URL}quotes/${id}/bill?date=${formatedDate}&time=${time}`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          })
            .then(res => {
              status = res.status;
              return res.json();
            })
            .then(res => {
              console.log("🚀 ~ file: index.js:783 ~ Customers ~ handleConvertQuoteToInvoice ~ res:", res)
              if (status == 200) {
                fetch(`${API_URL}barcode/bills/${res._id}?withoutTax=false&responseType=json`, {
                  method: 'get',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  credentials: 'include'
                })
                  .then((response) => {
                    console.log("response in barcode api......", response);
                    return response.json();
                  })
                  .then((result) => {
                    console.log("result in barcode api than method result......", result);
                    if (result.message) {
                      swal.fire(
                        '',
                        result.message,
                        'error'
                      )
                    } else {
                      swal.fire(
                        '',
                        'La boleta electrónica ha sido creada con éxito. Para ver el documento debe ingresar a Transacciones.',
                        'success'
                      );
                      this.getData();
                    }
                  })
                  .catch((err) => {
                    console.log("🚀 ~ file: index.js:818 ~ Customers ~ handleConvertQuoteToInvoice ~ err:", err)
                  });
              } else {
                console.log('debit note for credit note error', res)
                swal.fire('', res.message, 'error');
              }
            })
            .catch(err => {
              console.log("🚀 ~ file: index.js:804 ~ Customers ~ handleConvertQuoteToInvoice ~ err:", err)
            });
        }
      });
  }

  render() {
    console.log('detailModalData', this.state.detailModalData)
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4 d-flex align-content-center">
          <Col sm="12" md="12" lg="1" className={classes}>
            <div className="d-flex align-content-center mt-4">
              <h3 className="page-title">Ventas</h3>
            </div>
          </Col>
          <Col sm="12" md="8" lg="3">
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <select
                  className="form-control"
                  onChange={e => this.handleChange(e)}
                  value={this.state.documentsType}
                >
                  <option value="" disabled>
                    Seleccione una opción
                  </option>
                  {this.state.permissions.display_bill && <option value="Boleta">Boleta electrónica</option>}
                  {this.state.permissions.display_invoice && <option value="Factura">Factura electrónica</option>}
                  {this.state.permissions.display_dispatchnote && <option value="Guía de despacho">Guía de despacho electrónica</option>}
                  <option value="Cotizacion">Cotizacion electrónica</option>
                </select>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="2" lg="2">
            <div
              className="mt-3 icon-general-style"
              style={{
                height: 40,
                width: 40,
                backgroundColor: 'gray',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: 20
              }}
              onClick={() => {
                if (this.state.documentsType !== '') {
                  if (this.state.documentsType === 'Boleta') {
                    this.setState({
                      type: 1,
                      transactionDocumentId: '',
                      transactionDocumentType: '',
                      editProductData: [],
                      editProductFromTransaction: false
                    }, () => {
                      this.handleShowSideNavBar();
                    });
                  } else if (this.state.documentsType === 'Factura') {
                    this.setState({
                      type: 2,
                      transactionDocumentId: '',
                      transactionDocumentType: '',
                      editProductData: [],
                      editProductFromTransaction: false
                    }, () => {
                      this.handleShowSideNavBar();
                    });
                  } else if (this.state.documentsType === 'Guía de despacho') {
                    this.setState({
                      type: 4,
                      transactionDocumentId: '',
                      transactionDocumentType: '',
                      editProductData: [],
                      editProductFromTransaction: false
                    }, () => {
                      this.handleShowSideNavBar();
                    });
                  } else if (this.state.documentsType === 'Cotizacion') {
                    this.setState({
                      type: 6,
                      transactionDocumentId: '',
                      transactionDocumentType: '',
                      editProductData: [],
                      editProductFromTransaction: false
                    }, () => {
                      this.handleShowSideNavBar();
                    });
                  }
                } else {
                  alert('Seleccione una Boleta o Factura para continuar.');
                }
              }}
            >
              <i
                class="material-icons icon-general-style"
                style={{ fontSize: 26, color: 'white' }}
              >
                add
              </i>
            </div>
          </Col>
        </Row>
        <Row noGutters className="page-header d-flex align-content-center">
          <Col sm="12" md="12" lg="1" className={classes}>
            <div className="d-flex align-content-center mt-4">
              <h3 className="page-title">Reporte</h3>
            </div>
          </Col>
          <Col sm="12" md="12" lg="3">
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <select
                  className="form-control"
                  onChange={e => this.handleReportChange(e)}
                  value={this.state.dropDownReportTypeValue}
                >
                  <option value="" disabled>
                    Seleccione una opción
                  </option>
                  {this.state.permissions.add_bill && <option value="bills">Boleta electrónica</option>}
                  {this.state.permissions.add_bill && <option value="bills_no_tax">Boleta no afecta o exenta electronica</option>}
                  {this.state.permissions.add_invoice && <option value="invoices">Factura electrónica</option>}
                  {this.state.permissions.add_invoice && <option value="invoices_no_tax">Factura no afecta o exenta electronica</option>}
                  {this.state.permissions.get_creditnote && <option value="creditnotes">Nota de crédito</option>}
                  {this.state.permissions.add_debitnote && <option value="debitnotes">Nota de débito</option>}
                  {this.state.permissions.add_dispatchnote && <option value="dispatchnotes">Guía de despacho</option>}
                  <option value="quotes">Cotizacion electrónica</option>
                  <option value="all">Todos Ios Documentos</option>
                </select>
              </CardBody>
            </Card>
          </Col>

          <Col lg="3" md="12" sm="12">
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <DateRangePicker
                  initialSettings={{
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    locale: this.state.locale
                  }}
                  // onEvent={this.handleEvent}
                  onApply={(event, picker) => {
                    console.log(`picker startDate is ${picker.startDate} and endDate is ${picker.endDate}`)
                    console.log(picker.endDate);
                    picker.element.val(
                      picker.startDate.format('DD/MM/YYYY') +
                      ' - ' +
                      picker.endDate.format('DD/MM/YYYY')
                    );
                  }}
                  onCallback={(start, end) => this.handleCallback(start, end)}
                  locale={this.state.locale}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="12" sm="12">
            <div className="mb-2 pb-2">
              <div className="row">
                <Button
                  theme="primary"
                  className="mb-1 mt-3"
                  style={{ width: '30%', height: 50 }}
                  disabled={this.state.showLoader ? true : false}
                  onClick={() => {
                    this.downloadCsvFile();
                  }}
                >
                  {/* <span className="spinner-border spinner-border-sm " role="status"  aria-hidden="true"></span> */}
                  {this.state.showLoader && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                  Descargar CSV
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <div className="MainDiv">
                  <div className="table-responsive">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      loading={this.state.loading}
                    />
                    <nav className="d-flex justify-content-center align-items-center">
                      <ul className="pagination">
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageDecrement()}
                            style={
                              this.state.prev
                                ? { cursor: 'not-allowed', color: 'gray' }
                                : null
                            }
                          >
                            Anterior
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageIncrement()}
                            style={
                              this.state.next
                                ? { cursor: 'not-allowed', color: 'gray' }
                                : null
                            }
                          >
                            Próxima
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ToastContainer /> */}

        <Offcanvas
          show={this.state.isSideNavebarShow}
          // onHide={this.handleShowSideNavBar.bind(this)}
          placement="end"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>{this.state.documentsType}</Offcanvas.Title>
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              onClick={() => {
                swal
                  .fire({
                    title: '',
                    text:
                      '¿Desea cerrar esta pestaña?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si',
                    cancelButtonText: 'Cancelar'
                  })
                  .then(response => {
                    if (response.isConfirmed) {
                      this.getData();
                      this.setState({
                        isSideNavebarShow: false,
                      });
                      if (this.state.type === 5) {
                        this.setState({
                          documentsType: ''
                        })
                      }
                    }
                  });
              }}
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModalCenter3"
            ></button>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Calculator
              type={this.state.type}
              transactionDocumentId={this.state.transactionDocumentId}
              transactionDocumentType={this.state.transactionDocumentType}
              editProductFromTransaction={this.state.editProductFromTransaction}
              editProductData={this.state.editProductData}
            />
          </Offcanvas.Body>
        </Offcanvas>

        <div
          class="modal fade"
          id="exampleModalCenter3"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                Are you sure you want to close this modal?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    swal
                      .fire({
                        title: 'Nota de crédito',
                        text:
                          '¿Deseas emitir una nota de crédito para anular el documento seleccionado?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si',
                        cancelButtonText: 'Cancelar'
                      })
                      .then(response => {
                        if (response.isConfirmed) {
                          this.setState({
                            isSideNavebarShow: false
                          });
                        }
                      })
                  }
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showDocumentDetailsModal}
          onHide={() => this.setState({ showDocumentDetailsModal: false })}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Invoice
              bill_id={this.state.detailModalData.bill_id}
              type={this.state.detailModalData.type}
              view={true}
              printNumber={this.state.detailModalData.printNumber}
              barcodeImage={this.state.detailModalData.barcodeImage}
              barcodeHeight={this.state.detailModalData.barcodeHeight}
              barcodeWidth={this.state.detailModalData.barcodeWidth}
              pLeft={this.state.detailModalData.pLeft}
              pWidth={this.state.detailModalData.pWidth}
              transaction_view={true}
              references={
                this.state.detailModalData.references !== undefined
                  ? this.state.detailModalData.references
                  : []
              }
              showNewButton={false}
            />
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              onClick={() => this.setState({ showDocumentDetailsModal: false })}
            >
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      </Container>
    );
  }
}
export default Customers;
