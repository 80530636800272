import React from "react";
import { Dropdown, NavItem } from "shards-react";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      name: ""
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  componentDidMount() {
    let userInfo = localStorage.getItem("user_info");
    userInfo = JSON.parse(userInfo);

    if (userInfo) {
      this.setState({
        name: userInfo.data.fullName
      });
    }

    // alert(userInfo.data.fullName);
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <div className="text-nowrap px-3 pt-2">
        {/* <div className="px-3 pt-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}> */}
          {/* <img
            className="user-avatar rounded-circle mr-2"
            // src={"https://www.dlpbuilders.com/wp-content/uploads/2018/06/bigstock-203801206.jpg"}
            alt="User Avatar"
          /> */}
           <i class="material-icons" style={{ fontSize: 25,color: 'black' }}>
                    person
                </i>
          {/* <span className="d-none d-md-inline-block">{this.state.name}</span> */}
          <span>{this.state.name}</span>
          {/* <p>{this.state.name}</p> */}
        </div>
      </NavItem>
    );
  }
}
