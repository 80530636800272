import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    create_customer: 'Create User',
    f_name: 'Full Name',
    email: 'Email',
    create: 'Create User',
    save: 'Save',
    update: 'Update',
    edit_profile: 'Edit Profile',
    password: 'Password',
    update_profile: 'Update Profile',
    change_password: 'Change Password',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    update_password: 'Update Password',
    password_not_match: 'New password and password confirmation do not match',
    password_success: 'Password changed successfully',
    profile_updated: 'profile updated successfully',
    phone: 'Phone',
    userName: 'Username',
    status: 'Status',
    org_name: 'Organization Name',
    org_code: 'Organization Code',
    user_code: 'User Code',
    action: 'Action',
    accept: 'Accept',
    reject: 'Reject',
    accepted: 'Accepted',
    rejected: 'Rejected',
    pending: 'pending',
    proceed: 'Proceed',
    edit: 'Edit',
    delete: 'Delete',
    successfully_created: 'Record saved successfully',
    are_you_sure: 'Are you sure?',
    confirm_reject: 'You want to reject this request!',
    delete_customer: 'You want to delete this customer!',
    delete_item: 'You want to delete this item!',
    success_item_deleted: 'The item has been deleted.',
    yes_button: 'Yes, Reject it!',
    yes_delete: 'Yes, delete it!',
    yes_cancel: 'Cancel',
    rejected: 'Rejected!',
    deleted: 'Eliminated !',
    success_deleted: 'The customer has been deleted ',
    success_rejected: 'The request has been rejected.',
    inquire_request: 'Inquire Request',
    search_placeholder: 'Search Name / Email / Phone / Organization Name.',
    search_placeholder_customers:
      'Search Name / Email / Phone / Organization Name / Organization Code / User Code .',
    search_placeholder_items: 'Search Item Code / Item Description',
    item_code: 'Item Code',
    item_description: 'Item Description',
    item_stock: 'Stock',
    item_barcode: 'Barcode Number',
    item_price: 'Total price with VAT included',
    manage_item: 'Manage Item',
    create_item: 'Create Item',
    edit_item: 'Edit Item',
    save_success: 'Record save successfully.',
    go_back: 'Go Back',
    fill_fields: 'Fill in all fields!',

    description_valid_error: 'Enter a valid description not include any special characters or symbols',
    users: 'Users',
    discount_pr: 'Discount in percent',
    discount_dollar: 'Discount in dollar',
    value: 'Value',
    total: 'Total',
    cash: 'Cash',
    card: 'Card',
    credit: 'Cheque',
    transferencia: 'Transfer',
    credito: 'Credit',
    comment: 'Comment',
    rut_number: 'RUT Number',
    name: 'Name',
    addr_1: 'Address Line 1',
    addr_2: 'Address Line 2',
    state: 'State',
    country: 'Country',
    b_details: 'Business Details',
    edit: 'Edit',
    print: 'Print',
    new: 'New',
    search: 'Search here',
    item_remove: 'Item deleted successfully',
    back: 'Back',
    item_desc: 'Description',
    next: 'Next',
    add_item: 'Add',
    bills: 'Bills',
    invoice: 'Invoice',
    credit_note: 'Credit Note',
    delivery_note: 'Delivery Note',
    quote: 'Quote',
    debit_note: 'Debit Note',
    transport_required: 'Add transport data is required',
    required_fields: 'Fill in all the required fields',
    add_excel_file: 'Upload multiple items',
    upload: 'Upload',
    preview: 'Preview',
    valid_items: 'Valid items',
    invalid_items: 'Invalid items',
    accepted_file_format: 'Accepted file format (.csv, .xlsx, .xls)',
    expiration_date: 'Expiration date',
    expiration_day: 'Expiration day',
    expiration_month: 'Expiration month',
    expiration_year: 'Expiration year',
    email_valid_error: "Please enter a valid email",
    invalid_rut: 'Invalid RUT number',
    restaurant: "Restaurant",
    restaurant_tables: "Restaurant Tables",
    table_name: "Table name",
    tables: "Tables",
    delete_table: "You want to delete this table!",
    create_table: "Create Table",
    edit_table: "Edit Table",
    select_user_role: "Select user role",
    select_table_status: 'Select table status',
    select_all: "Select all",
    choose_table: 'Choose Table',
    no_data_available: 'No Data Available',
    add_food: 'Add Food',
    order_summary: 'Order Summary',
    no_food_added: "No Food Added",
    add_more_food: "Add More Food",
    place_order: "Place Order",
    mark_as_paid: "Mark As Paid",
    generic_note: "Generic Note",
    delete_item: "Delete item?",
    delete_item_msg: "Are you sure you want to delete item?",
    select_food_quantity: "Select Food Quantity",
    enter_food_instruction: "Enter Food Instruction",
    grand_total: "Grand total",
    order_placed_successfully: "Order Placed Successfully",
    continue: "Continue",
    mark_as_paid_msg: "Are you sure you want to mark this order as paid?",
    yes_mark_as_paid: "Yes,Marked as Paid",
    mark_as_paid_success_msg: "The order has been successfully marked as paid.",
    mark_as_paid_success_title: "Successfully marked order as paid",
    select_food: "Select Food",
    add_cart: "Add cart",
    search_by_food_name: "Search by food name",
    please_select_food: "Please select a food",
    restaurant_order: "Restaurant Order",
    dashboard: "Dashboard",
    restaurant_kitchens: "Restaurant Kitchens",
    kitchen_name: "Kitchen name",
    chefs: "Chefs",
    create_kitchen: "Create Kitchen",
    delete_kitchen: "You want to delete this kitchen!",
    select_chefs: "Select chefs",
    edit_kitchen: "Edit Kitchen",
    restaurant_bars: "Restaurant Bars",
    bar_name: "Bar name",
    waiters: "Waiters",
    create_bar: "Create Bar",
    delete_bar: "You want to delete this Bar!",
    select_waiters: "Select waiters",
    edit_bar: "Edit Bar",
    restaurant_spaces: "Restaurant Spaces",
    create_space: "Create Space",
    edit_space: "Edit Space",
    delete_space: "You want to delete this Space!",
    space_name: "Space name",
    space_description: "Space description",
    select_tables: "Select tables",
    success_kitchen_deleted: "The kitchen has been deleted",
    success_bar_deleted: "The bar has been deleted",
    select_kitchens: "Select kitchens",
    select_bars: "Select bars",
    success_space_deleted: "The space has been deleted",
    select_space: "Select space",
    item_type: "Item type",
    preparation_area: "Preparation Area"
  },
  es: {
    add_item: 'Agregar',
    next: 'Continuar',
    item_desc: 'Descripción',
    back: 'Regresar',
    item_remove: 'Item eliminado exitosamente',
    print: 'Imprimir',
    new: 'Nuevo',
    edit: 'Editar',
    addr_1: 'Dirección',
    addr_2: 'Comuna',
    state: 'Región',
    country: 'País',
    b_details: 'Giro',
    comment: 'Comentario',
    rut_number: 'RUT (Debe ingresar números o k)',
    name: 'Nombre o Razón Social',
    create_customer: 'Create User',
    f_name: 'Full Name',
    email: ' Correo electrónico',
    create: 'Crear usuario',
    save: 'Guardar',
    update: 'Actualizar',
    edit_profile: 'Edit Profile',
    password: ' Clave',
    update_profile: 'Update Profile',
    change_password: 'Change Password',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    update_password: 'Update Password',
    password_not_match: 'La nueva contraseña y la confirmación de la contraseña no coinciden',
    password_success: 'Password changed successfully',
    profile_updated: 'profile updated successfully',
    phone: 'Teléfono',
    userName: 'Rut de usuario',
    status: 'Estado',
    org_name: 'Organization Name',
    org_code: 'Organization Code',
    user_code: 'User Code',
    action: 'Action',
    accept: 'Accept',
    reject: 'Reject',
    delete: 'Borrar',
    successfully_created: 'Registro guardado con éxito',
    accepted: 'Aceptado',
    rejected: 'Reclamado',
    pending: 'Pendiente',
    search: 'Busca aquí',
    proceed: 'Continuar',
    are_you_sure: '¿Está seguro?',
    confirm_reject: 'You want to reject this request!',
    delete_customer: 'Quiere eliminar este cliente',
    delete_item: 'Quiere eliminar este Item',
    success_item_deleted: 'The item has been deleted.',
    yes_button: 'Yes, Reject it!',
    yes_delete: 'Sí, eliminar',
    yes_cancel: 'Cancelar',
    rejected: 'Rejected!',
    deleted: '¡Eliminado!',
    // deleted: 'Eliminated !',
    success_deleted: 'El registro ha sido eliminado ',
    success_rejected: 'The request has been rejected.',
    inquire_request: 'Inquire Request',
    search_placeholder: 'Search Name / Email / Phone / Organization Name.',
    search_placeholder_customers:
      'Search Name / Email / Phone / Organization Name / Organization Code / User Code .',
    search_placeholder_items: 'Search Item Code / Item Description',
    item_code: 'SKU',
    item_description: 'Nombre',
    item_stock: 'Stock',
    item_price: 'Precio total con IVA incluido',
    item_barcode: 'Código de barra',
    manage_item: 'Crear Item',
    create_item: 'Create Item',
    edit_item: 'Editar Item',
    save_success: 'Record save successfully.',
    go_back: 'Regresar',
    fill_fields: 'Fill in all fields!',
    description_valid_error: 'Enter a valid description not include any special characters or symbols',
    users: 'Users',
    discount_pr: 'Descuento %',
    discount_dollar: 'Descuento $',
    value: 'Vuelto',
    total: 'Total',
    cash: 'Efectivo',
    card: 'Tarjeta',
    credit: 'Cheque',
    transferencia: 'Transferencia',
    credito: 'Credito',
    bills: 'Boleta',
    invoice: 'Factura',
    credit_note: 'Nota de crédito',
    delivery_note: 'Guía de despacho',
    quote: 'Cotizacion',
    debit_note: 'Nota de débito',
    transport_required: 'Agregar datos del transporte se requiere',
    required_fields: 'Llena todos los campos requeridos',
    add_excel_file: 'Subir varios elementos',
    upload: 'Subir',
    preview: 'Avance',
    valid_items: 'Artículos válidos',
    invalid_items: 'Artículos no válidos',
    accepted_file_format: 'Formato de archivo aceptado (.csv, .xlsx, .xls)',
    expiration_date: 'Fecha de expiración',
    expiration_day: "Dia de expiracion",
    expiration_month: "Mes de expiracion",
    expiration_year: 'ano de caducidad',
    email_valid_error: "Por favor, introduce una dirección de correo electrónico válida",
    invalid_rut: 'Invalid RUT number',
    restaurant: "Restaurante",
    restaurant_tables: "Mesas de Restaurante",
    table_name: "Nombre de la mesa",
    tables: "Mesas",
    delete_table: "Quiere eliminar este mesa",
    create_table: "Crear mesa",
    edit_table: "Editar mesa",
    select_user_role: "Seleccione rol de usuario",
    select_table_status: 'Seleccionar estado de la tabla',
    select_all: "Seleccionar todo",
    choose_table: 'Elegir Mesa',
    no_data_available: 'Datos no disponibles',
    add_food: 'Agregar comida',
    order_summary: 'Resumen del pedido',
    no_food_added: 'No se agrega comida',
    add_more_food: "Agregar más comida",
    place_order: "Realizar pedido",
    mark_as_paid: "Marcar como pagado",
    generic_note: "Nota genérica",
    delete_item: "¿Eliminar elemento?",
    delete_item_msg: "¿Está seguro de que desea eliminar el elemento?",
    select_food_quantity: "Seleccione la cantidad de comida",
    enter_food_instruction: "Ingrese la instrucción de alimentos",
    grand_total: "gran total",
    order_placed_successfully: "Pedido realizado con éxito",
    continue: "Continuar",
    mark_as_paid_msg: "¿Está seguro de que desea marcar este pedido como pagado?",
    yes_mark_as_paid: "Sí, marcado como pagado",
    mark_as_paid_success_msg: "El pedido se ha marcado correctamente como pagado.",
    mark_as_paid_success_title: "Pedido marcado correctamente como pagado",
    select_food: "Seleccionar comida",
    add_cart: "Añadir carrito",
    search_by_food_name: "Buscar por nombre de alimento",
    please_select_food: "Por favor seleccione una comida",
    restaurant_order: "Orden de restaurante",
    dashboard: "Panel",
    restaurant_kitchens: "Cocinas de restaurante",
    kitchen_name: "Nombre de la cocina",
    chefs: "cocineros",
    create_kitchen: "crear cocina",
    delete_kitchen: "¡Quieres eliminar esta cocina!",
    select_chefs: "Seleccionar chefs",
    edit_kitchen: "Editar cocina",
    restaurant_bars: "Restaurante Bares",
    bar_name: "Nombre de la barra",
    waiters: "camareros",
    create_bar: "Crear barra",
    delete_bar: "¡Quieres eliminar esta barra!",
    select_waiters: "Seleccionar camareros",
    edit_bar: "Barra de edición",
    restaurant_spaces: "Espacios de restaurante",
    create_space: "Crear espacio",
    edit_space: "Editar espacio",
    delete_space: "¡Quieres eliminar este Espacio!",
    space_name: "Nombre del espacio",
    space_description: "Descripción del espacio",
    select_tables: "Seleccionar mesas",
    success_kitchen_deleted: "La cocina ha sido eliminada",
    success_bar_deleted: "La barra ha sido eliminada",
    select_kitchens: "Seleccionar cocinas",
    select_bars: "Seleccionar barras",
    success_space_deleted: "El espacio ha sido eliminado",
    select_space: "Seleccionar espacio",
    item_type: "Tipo de artículo",
    preparation_area: "Área de preparación"
  }
});
