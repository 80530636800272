import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";
import { Link } from "react-router-dom";

import { Dispatcher, Constants } from "../../../flux";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    return (
      <Link to="/dashboard">
        <div className="main-navbar">
          <Navbar
            className="align-items-stretch flex-md-nowrap p-0"
            type="light"
          >
            <center>
              <NavbarBrand
                className="w-100 mr-0 d-flex justify-content-center align-items-center ml-3"
                style={{ lineHeight: "40px" }}
              >
                <img
                  id="main-logo"
                  className="align-top"
                  style={{
                    // display: "block",
                    // marginLeft: "2.3rem",
                    // marginRight: "auto",
                    width: "auto",
                    height: "50px"
                  }}
                  src={require("../../../images/ebol_logo.png")}
                  alt="EBOL"
                />
                <div className="d-table m-auto">
                  {/* {!hideLogoText && (
                  <span className="d-none d-md-inline ml-1">EBOL</span>
                )} */}
                </div>
              </NavbarBrand>
            </center>
            {/* eslint-disable-next-line */}
            <a
              className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
              onClick={this.handleToggleSidebar}
            >
              <i className="material-icons">&#xE5C4;</i>
            </a>
          </Navbar>
        </div>
      </Link>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
