import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row, FormTextarea, Alert } from 'shards-react'
import { strings } from '../../localization';
import './Restaurant.css'
import PageTitle from '../../components/common/PageTitle';
import { Link } from 'react-router-dom';
import swal from "sweetalert2";
import ManageFoodItemsDialog from '../../components/common/ManageFoodItemsDialog';
import { API_URL, RESTAURANT, formateNumber } from '../../config';
window.Swal = swal;


function RestaurantOrderSummary(props) {
    const { state } = props.location;
    const { history } = props;
    const { id } = props.match.params;
    console.log('data', props);
    console.log('state data', state)

    // food items states
    const [orderId, setOrderId] = useState("");
    const [tableId, setTableId] = useState("");
    const [orderData, setOrderData] = useState(null);
    const [grandTotal, setGrandTotal] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [genericNote, setGenericNote] = useState("");
    const [foodItems, setFoodItems] = useState([1, 1, 1, 1, 1]);

    // edit food items states
    const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
    const [editFoodDialogItem, setEditFoodDialogItem] = useState('');
    const [editFoodItemIndex, setEditFoodItemIndex] = useState('');
    const [isFoodItemSaveButtonLoading, setIsFoodItemSaveButtonLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPlaceOrderButtonLoading, setIsPlaceOrderButtonLoading] = useState(false);
    const [isMarkAsPaidButtonLoading, setIsMarkAsPaidButtonLoading] = useState(false);

    // alert states
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('danger');

    useEffect(() => {
        setOrderId(state.orderId);
        setTableId(id);
        if (state.orderId) {
            getOrderSummaryData();
        }
    }, []);

    const deleteRestaurantFoodItem = (item) => {
        console.log('item', item)
        swal
            .fire({
                title: strings.delete_item,
                text: strings.delete_item_msg,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#2E209D",
                cancelButtonColor: "#D61E11",
                confirmButtonText: strings.yes_delete,
                showLoaderOnConfirm: true,
                preConfirm: login => {
                    return fetch(`${API_URL}restaurant-order/${orderId}/item/${item['id']}`, {
                        method: "delete",
                        credentials: "include",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    })
                        .then(res => {
                            console.log('response of delete food item api is', res);
                            if (res.status == 200) {
                                setIsLoading(true);
                                getOrderSummaryData();

                                swal.fire(
                                    strings.deleted,
                                    strings.success_item_deleted,
                                    "success"
                                );
                            }
                            return res.json();
                        })
                        .catch(err => {
                            console.log('catch block error', err)
                            swal.showValidationMessage(`Request failed: ${err}`);
                        });
                }
            })
            .then(result => {
                console.log('result of delete', result);
            });
    }

    const editFoodItem = (item, index) => {
        setIsEditDialogVisible(true);
        setEditFoodDialogItem(item);
        setEditFoodItemIndex(index);
    }

    const getOrderSummaryData = async () => {
        setIsLoading(true);
        let statusCode = 0;
        let orderId = state.orderId;
        const apiUrl = `${API_URL}restaurant-order/${orderId}`;
        console.log('apiUrl', apiUrl)
        fetch(apiUrl, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then((res) => {
            statusCode = res.status;
            return res.json();
        }).then((response) => {
            console.log('response of order summary', response);
            if (statusCode === 200) {
                let data = response['data'];
                console.log('data of order summary api', data);
                if (data) {
                    let amount = data['netAmount'] ? data['netAmount'] : "";
                    setIsLoading(false);
                    setOrderData(response);
                    setFoodItems(data['dataArray']);
                    setOrderId(response['_id']);
                    setGenericNote(data['genericNote']);
                    setGrandTotal(amount);
                    setIsPaid(data['isPaid']);
                } else {
                    setFoodItems([]);
                }
            } else {
                setIsLoading(false);
            }
        })
    }

    const handleFoodItemDialogSaveButtonPressed = (item, index) => {
        console.log('updated item', item);
        console.log('updated index', index);
        setIsFoodItemSaveButtonLoading(true);
        orderSummarySingleFoodItemUpdate(item);
    }

    const orderSummarySingleFoodItemUpdate = async (apiData) => {
        console.log('api data for single item update', apiData);
        let statusCode = 0;
        const apiUrl = `${API_URL}restaurant-order/${orderId}/item/${apiData['id']}`;
        console.log('apiUrl of order summary single update', apiUrl);
        fetch(apiUrl, {
            method: 'patch',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(apiData)
        }).then((res) => {
            statusCode = res.status;
            console.log('statuscode of single item update api', res.status);
            return res.json()
        })
            .then((res) => {
                console.log('response of single item update api', res);
                if (statusCode === 200) {
                    setIsEditDialogVisible(false);
                    setIsFoodItemSaveButtonLoading(false);
                    setIsLoading(true);
                    getOrderSummaryData();
                } else {
                    const apiMsg = res['message'] ? res['message'] : "Something went wrong!";
                    showAlert(apiMsg, 'danger');
                    setIsEditDialogVisible(false);
                    setIsFoodItemSaveButtonLoading(false);
                }
            })
            .catch((err) => {
                console.log('catch block error at order summary single update api', err);
                setIsEditDialogVisible(false);
                setIsFoodItemSaveButtonLoading(false);
                showAlert("Something went wrong!", 'danger');
            })
    }


    const getDraftStatusItems = () => {
        let draftItems = [];
        if (foodItems.length > 0) {
            let data = foodItems.filter((item) => item['status'] === "draft");
            draftItems = [...data];
        }
        console.log('draft items are', draftItems);
        return draftItems;
    }

    const sendFoodItemsIntoKOT = async () => {
        setIsPlaceOrderButtonLoading(true);
        let statusCode = 0;
        const draftItems = getDraftStatusItems();
        let bodyData = {
            "restaurantTableID": tableId,
            "restaurantOrderID": orderId,
            "dataArray": draftItems,
            "genericNote": genericNote
        }
        console.log('bodyData of kot post api', bodyData);
        const apiUrl = `${API_URL}restaurant-kot`
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(bodyData)
        }).then((res) => {
            statusCode = res.status;
            console.log('statusCode of kot post api', statusCode);
            return res.json();
        }).then((res) => {
            console.log('response of kot post api', res);
            if (statusCode === 200) {
                updateStatusOfOrderFoodItems(res['_id']);
            } else {
                const errorMsg = res['message'] ? res['message'] : "Something went wrong!";
                showAlert(errorMsg, 'danger');
                setIsPlaceOrderButtonLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at kot post api', err);
            setIsPlaceOrderButtonLoading(false);
        })
    }

    const updateStatusOfOrderFoodItems = async (kotId = "") => {
        let statusCode = 0;
        const apiUrl = `${API_URL}restaurant-order/${orderId}?status=prepared`;
        console.log('order status update api url', apiUrl);
        let bodyData = {
            "kotID": kotId
        }
        console.log('bodyData of update status', bodyData);
        fetch(apiUrl, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(bodyData)
        }).then((res) => {
            statusCode = res.status;
            console.log('statuscode of order status update api', statusCode);
            return res.json();
        }).then((res) => {
            console.log('response of order status update api', res);
            if (statusCode === 200) {
                setIsPlaceOrderButtonLoading(false);
                swal
                    .fire({
                        text: strings.order_placed_successfully,
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#2E209D",
                        cancelButtonColor: "#D61E11",
                        confirmButtonText: strings.continue,
                        showLoaderOnConfirm: true,
                    })
                    .then(result => {
                        console.log('result of delete', result);
                        history.push({
                            pathname: `/restaurant`,
                        })
                    });
            } else {
                const errorMsg = res['message'] ? res['message'] : "Something went wrong!";
                showAlert(errorMsg, 'danger');
                setIsPlaceOrderButtonLoading(false);
            }
        }).catch((err) => {
            console.log('catch block error at order status update api', err);
            setIsPlaceOrderButtonLoading(false);
        })
    }

    const handleMarkAsPaid = () => {
        swal
            .fire({
                // title: strings.delete_item,
                text: strings.mark_as_paid_msg,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#2E209D",
                cancelButtonColor: "#D61E11",
                confirmButtonText: strings.yes_mark_as_paid,
                showLoaderOnConfirm: true,
                preConfirm: login => {
                    return fetch(`${API_URL}restaurant-order/${orderId}`, {
                        method: 'put',
                        credentials: "include",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            'isPaid': true,
                            "dataArray": []
                        })
                    })
                        .then(res => {
                            console.log('response of mark as paid api is', res);
                            if (res.status == 200) {
                                navigateToPaymentScreen();

                                swal.fire(
                                    strings.mark_as_paid_success_title,
                                    strings.mark_as_paid_success_msg,
                                    "success"
                                );
                            }
                            return res.json();
                        })
                        .catch(err => {
                            console.log('catch block error', err)
                            swal.showValidationMessage(`Request failed: ${err}`);
                        });
                }
            })
            .then(result => {
                console.log('result of delete', result);
            });
    }

    const navigateToPaymentScreen = () => {
        let dataArray = [];

        const mergedFoodItems = Object.values(foodItems.reduce((acc, curr) => {
            if (!acc[curr.itemID]) {
                acc[curr.itemID] = { ...curr };
            } else {
                acc[curr.itemID].quantity = String(Number(acc[curr.itemID].quantity) + Number(curr.quantity));
                acc[curr.itemID].totalPrice = Number(acc[curr.itemID].totalPrice) + Number(curr.totalPrice);
            }
            return acc;
        }, {}));

        console.log('mergedDuplicate food items', mergedFoodItems)

        const formattedFoodItems = mergedFoodItems.map(({ itemCode, itemID, itemName, price, quantity, totalPrice }) => ({
            itemCode,
            itemID,
            itemName,
            price,
            quantity,
            totalPrice
        }));

        console.log('formatted food items', formattedFoodItems);


        formattedFoodItems.map((item, index) => {

            let singleObj = {
                "FAWT": item.totalPrice ? item.totalPrice : 0,
                "discount": 0,
                "iva": 0,
                "mainPriceWithTax": item.price ? item.price : 0,
                "percent": true,
                "tax": true,
                "totalDiscount": 0,
                "itemType": "",

                "itemCode": item.itemCode ? item.itemCode : "",
                "description": item.itemName ? item.itemName : "",
                "quantity": item.quantity ? item.quantity : 0,
                "total": item.totalPrice ? item.totalPrice : 0,
                "mainPrice": item.price ? item.mainPrice : 0,
            }
            dataArray.push(singleObj);
        })


        let params = {
            orderId: orderId,
            data: dataArray,
            total: formateNumber(grandTotal, true),
            totalDiscount: 0,
            type: 0,
            withoutTax: false,
            propinaChecked: false,
            editProductFromTransaction: false,
            editProductData: [],
            "moduleName": RESTAURANT
        }

        console.log('params which passed in navigation', params);
        // TODO: navigation is remaining to do
    }

    const showAlert = (msg, type) => {
        setIsAlertVisible(true);
        setAlertType(type);
        setAlertMsg(msg);

        setTimeout(() => {
            setIsAlertVisible(false);
        }, 4000)
    }

    const CommonBtn = ({ name, onClick, isLoading }) => {
        return (
            <Button
                theme="primary"
                className="mx-1"
                style={{ width: 'auto', height: 40 }}
                onClick={onClick}
            >
                {
                    isLoading
                        ? <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                        : name
                }
            </Button>
        )
    }

    const hasAnyDraftStatusInOrder = () => {
        if (foodItems.length > 0) {
            const draftIndex = foodItems.findIndex((item) => item['status'] === "draft");
            if (draftIndex !== -1) {
                return true;
            }
            return false;
        }
        return false;
    }

    const hasAnyNonDraftStatusInOrder = () => {
        if (foodItems.length > 0) {
            const draftIndex = foodItems.findIndex((item) => item['status'] !== "draft");
            console.log('draftIndex', draftIndex);
            if (draftIndex !== -1) {
                return true;
            }
            return false;
        }
        return false;
    }



    return (
        <Container fluid className="main-content-container px-4">
            <div style={{ display: 'flex', marginTop: 20, marginBottom: 10, alignItems: 'flex-start' }}>
                <Link
                    to={{
                        pathname: "/restaurant"
                    }}
                >
                    <i class="material-icons" style={{ fontSize: 26, marginRight: 4, color: 'black' }}>arrow_back</i>
                </Link>
                <p className='mt-10' style={{ fontSize: 20 }}>{strings.order_summary}</p>
            </div>
            <Row>
                <Col>
                    <Card small className="mb-4 tableCard">
                        <CardBody className="p-3 b-3">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <PageTitle
                                    sm="4"
                                    title={strings.order_summary}
                                    className="text-sm-left page-title"
                                />
                                <div style={{ display: 'flex' }}>
                                    <Link
                                        to={{
                                            pathname: orderId ? `/restaurant/${tableId}/manage-food-item/edit/${orderId}` : `/restaurant/${tableId}/manage-food-item/create`,
                                            state: {
                                                draftItems: orderId ? getDraftStatusItems() : []
                                            }
                                        }}
                                    >
                                        <CommonBtn
                                            name={orderId ? strings.add_more_food : strings.add_food}
                                            onClick={() => { }}
                                            isLoading={false}
                                        />
                                        {/* Test */}
                                    </Link>

                                    {
                                        orderId && !isPaid &&
                                        <>
                                            {
                                                hasAnyDraftStatusInOrder() &&
                                                <CommonBtn
                                                    name={strings.place_order}
                                                    onClick={sendFoodItemsIntoKOT}
                                                    isLoading={isPlaceOrderButtonLoading}
                                                />
                                            }
                                            {
                                                hasAnyNonDraftStatusInOrder() &&
                                                <CommonBtn
                                                    name={strings.mark_as_paid}
                                                    onClick={handleMarkAsPaid}
                                                    isLoading={isMarkAsPaidButtonLoading}
                                                />
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <hr />
                            <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50,
                                marginBottom: 50
                            }}>
                                {
                                    orderId ?
                                        <div style={{ width: '100%' }}>
                                            <Row noGutters className="py-4 parent" >
                                                <Col sm="11" md="8" lg="8">
                                                    <Row>
                                                        <Col className="firstCol orderSummaryCommonTitle" sm="8" md="6" lg="6"><p>Item</p></Col>
                                                        <Col className="secondCol orderSummaryCommonTitle" sm="2" md="3" lg="3"><p>Qty</p></Col>
                                                        <Col className="thirdCol orderSummaryCommonTitle" sm="2" md="3" lg="3"><p>Price</p></Col>
                                                    </Row>
                                                    <hr />
                                                    {
                                                        foodItems.length > 0
                                                            ? foodItems.map((item, index) => {
                                                                const foodStatus = item['status'];
                                                                const isDraft = foodStatus && foodStatus === "draft";
                                                                const commonOpacity = isDraft ? 1 : 0.5;
                                                                return (
                                                                    <div style={{ opacity: commonOpacity }}>
                                                                        <Row>
                                                                            <Col className="firstCol" sm="8" md="6" lg="6">
                                                                                <div>
                                                                                    <p className='orderSummaryCommonTitle'>{item['itemName']}</p>
                                                                                    <p className='itemCode'>{item['itemCode']}</p>
                                                                                    {
                                                                                        item['note'] &&
                                                                                        <div style={{ display: 'flex' }}>
                                                                                            <p className='noteTitle'>Note:-  </p>
                                                                                            <p className='note'>{item['note']}</p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="secondCol" sm="2" md="3" lg="3">
                                                                                <p className='orderSummaryCommonTitle'>{item['quantity']}</p>
                                                                            </Col>
                                                                            <Col className="thirdCol" sm="2" md="3" lg="3">
                                                                                <p className='orderSummaryCommonTitle'>{item['price'] ? formateNumber(item['price']) : ''}</p>
                                                                                {
                                                                                    isDraft &&
                                                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                                                        <i onClick={() => deleteRestaurantFoodItem(item)} class="material-icons" style={{ fontSize: 26, color: 'red', cursor: 'pointer' }}>delete</i>
                                                                                        <div className='editIconContainer' onClick={() => editFoodItem(item, index)}>
                                                                                            <i class="material-icons" style={{ fontSize: 16, color: 'white' }}>edit</i>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        <hr />
                                                                    </div>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                    <p className='orderSummaryCommonTitle'>{strings.generic_note}</p>
                                                    <FormTextarea
                                                        value={genericNote}
                                                        className="genericNote"
                                                        rows="4"
                                                        onChange={e =>
                                                            setGenericNote(e.target.value)
                                                        }
                                                    />
                                                    <div className='hrTag' />
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p className='orderSummaryCommonTitle'>{strings.grand_total}</p>
                                                        <p className='orderSummaryCommonTitle'>{grandTotal ? formateNumber(grandTotal) : ""}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <div>
                                            <img
                                                src={require('../../images/empty_order.png')}
                                            />
                                            <h5 style={{ textAlign: 'center', marginTop: 20 }}>{strings.no_food_added}</h5>
                                        </div>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                isEditDialogVisible &&
                <ManageFoodItemsDialog
                    showModal={isEditDialogVisible}
                    setShowModal={setIsEditDialogVisible}
                    foodItem={editFoodDialogItem}
                    foodIndex={editFoodItemIndex}
                    onSaveButtonPressed={handleFoodItemDialogSaveButtonPressed}
                    isSaveButtonLoading={isFoodItemSaveButtonLoading}
                />
            }
            <Alert
                open={isAlertVisible}
                theme={alertType}
                style={{
                    position: 'fixed',
                    bottom: 10,
                    width: '80%',
                }}
            >
                {alertMsg}
            </Alert>
        </Container>
    )
}

export default RestaurantOrderSummary