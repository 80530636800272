import React, { Component } from 'react';
import { Row, Col, FormInput, InputGroup } from 'shards-react';
import { Link } from "react-router-dom";
import { API_URL, Language } from '../../config';
import { strings } from '../../localization/Login';
import styles from './../../views/Style.module.scss';
import Logo from './../../images/ebol_logo.png';
import SuccessMessage from './SuccessMessage';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);
    this.state = {
      email: '',
      hide: true,
      showLoader: false,
      errors: "",
      isEmailEntered: false,
      isPasswordChange: false,
      informMessage: '',
    };
    // alert(this.props.route);
  }

  ForgotPasswordSendEmail() {
    let email = this.state.email;
    const isEmailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    this.setState({showLoader: true});
    if (email === "") {
      this.setState({
        errors: strings.required_email,
         showLoader: false
      });
    } else if (!isEmailValid.test(email)) {
      this.setState({
        errors: strings.require_valid_email,
         showLoader: false
      });
    } else {
    this.ForgotPasswordApi()
    }
  }

    ForgotPasswordApi = async() => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    let bodyData = {
      email: this.state.email
    };
    fetch(`${API_URL}forgetPassword`,{
      method: 'POST',
      credentials: "include",
      headers: headers,
      body: JSON.stringify(bodyData)
     })
    //  .then(res => {
    //     console.log('res is in first than', res.status)
    //    return res.json();
    //  })
     .then(async(response) => {
      console.log('response is in second than', response.status)
       if (response.status === 200) {
        let data = await response.json()
          console.log('response in 200 is', data)
          // const data = JSON.stringify(response) 
             this.setState({
                // success: strings.send_success + " your email",
                errors: "",
                usercode: "",
                isEmailEntered: true,
                showLoader: false,
                informMessage: data.message
                // orgcode: ""
              });
       }else{
          console.log('response not in 200 is', response)
          // const data = JSON.stringify(response)
            let data = await response.json()
             this.setState({
                // success: strings.send_success + " your email",
                errors: data.message,
                usercode: "",
                isEmailEntered: false,
                showLoader: false,
                informMessage: ''
                // orgcode: ""
              }); 
       }
     })
  }

  render() {
    const { } = this.state;
    return (
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Row>
            <Col lg="12">
              <form>
                <img
                  src={Logo}
                  alt=""
                  height="80"
                  id="main_page_logo"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '50%'
                  }}
                />
                <div>
                  {this.state.isEmailEntered ? (
                    <>
                    {/* {this.state.informMessage !== ''} */}
                      <SuccessMessage 
                                message = {this.state.informMessage}
                       />
                    </>
                  ) : (
                      <>
                          <div className="form-group">
                            <h4 className="text-black mt-2 text-center fw-bold">{strings.forgot_old_passowrd}</h4>
                            <p className="font-size-5 mt-4 ">{strings.email_instruction}</p>

                            {this.state.errors &&
                                 <div
                                  className="alert alert-danger alert-dismissible fade show mt-2"
                                  role="alert"
                                >
                                  {this.state.errors}
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                    onClick={() => this.setState({ errors: "" })}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                            }
                            <InputGroup seamless className="mb-3 mt-3">
                              {/* <InputGroupAddon type="prepend">
                                <InputGroupText>
                                  <i className="material-icons">code</i>
                                </InputGroupText>
                              </InputGroupAddon> */}
                              <input
                                type="text"
                                className="form-control textBox"
                                placeholder="Email"
                                // placeholder="RUT de usuario"
                                value={this.state.email}
                                onChange={e =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                            </InputGroup>
                          </div>

                         <div className="d-flex justify-content-center align-items-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={e => this.ForgotPasswordSendEmail(e)}
                            style= {{
                              letterSpacing:'1px',
                              height: '40px',
                              width: '130px',
                              display: 'flex',
                              justifyContent:'center',
                              alignItems:'center',
                            }}
                          >
                            {/* <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i> */}
                            {this.state.showLoader &&  <span className="spinner-border spinner-border-sm mx-1" role="status"  aria-hidden="true"></span>}
                            &nbsp;&nbsp;{strings.send_email_button}
                          </button>
                          </div>

                          {/* <div className="d-flex justify-content-end align-items-end"> */}
                            <p className="forgot-password text-right">
                              <Link
                                href="javascrit:;"
                                className="link_class fst-italic text-black fw-bold"
                                to={'/login'}
                                onClick={() =>
                                  this.setState({ forgot_password: false })
                                }
                              >
                                {strings.cancel}
                              </Link>
                            </p>
                          {/* </div> */}
                            </>
                  )}
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
